import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { getDateNameLao, getDateNameSlashShortYear, getResultTime } from "../../utils/ConvertDate";
const { useBreakpoint } = Grid;

const color = "#001152";
const bgTopicColor = "white";
export default function LaoPaTuXayOldPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState(undefined);
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const listName = {
        digit2_top: {
            name: "ເລກ 2 ໂຕ",
            length: 2,
        },
        digit5: {
            name: "ເລກ 5 ໂຕ",
            length: 5,
        },
        digit1: {
            name: "ເລກ 1 ໂຕ",
            length: 1,
        },
        digit3: {
            name: "ເລກ 3 ໂຕ",
            length: 3,
        },
        digit2_special: {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
        },
        digit4: {
            name: "ເລກ 4 ໂຕ",
            length: 4,
        },
        digit2_bottom: {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
        },
    };

    const sortKey = {
        digit5: 0,
        digit4: 1,
        digit3: 2,
        digit2_special: 5,
        digit2_top: 3,
        digit1: 4,
        digit2_bottom: 5,
    };

    const [_numberList] = useState([
        {
            name: "ເລກ 5 ໂຕ",
            length: 5,
            result: "",
        },
        {
            name: "ເລກ 4 ໂຕ",
            length: 4,
            result: "",
        },
        {
            name: "ເລກ 3 ໂຕ",
            length: 3,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕ",
            length: 2,
            result: "",
        },
        {
            name: "ເລກ 1 ໂຕ",
            length: 1,
            result: "",
        },
    ]);

    const onFetch = () => {
        // fetch data
        setData({
            start_spin: "2024-07-30 05:40:00",
            lotto_date: "2024-07-30",
            show_result: "2024-07-30 05:45:00",
            results: {
                digit2_top: "64",
                digit5: "33264",
                digit1: "4",
                digit3: "264",
                digit2_special: "91",
                digit4: "3264",
                digit2_bottom: "33",
            },
        });
        setHistoryData([
            {
                lotto_date: "2024-07-29",
                results: {
                    digit2_top: "69",
                    digit5: "94269",
                    digit1: "9",
                    digit3: "269",
                    digit2_special: "38",
                    digit4: "4269",
                    digit2_bottom: "94",
                },
            },
            {
                lotto_date: "2024-07-28",
                results: {
                    digit2_top: "76",
                    digit5: "24776",
                    digit1: "6",
                    digit3: "776",
                    digit2_special: "78",
                    digit4: "4776",
                    digit2_bottom: "24",
                },
            },
            {
                lotto_date: "2024-07-27",
                results: {
                    digit2_top: "03",
                    digit5: "04203",
                    digit1: "3",
                    digit3: "203",
                    digit2_special: "42",
                    digit4: "4203",
                    digit2_bottom: "04",
                },
            },
            {
                lotto_date: "2024-07-26",
                results: {
                    digit2_top: "54",
                    digit5: "18954",
                    digit1: "4",
                    digit3: "954",
                    digit2_special: "96",
                    digit4: "8954",
                    digit2_bottom: "18",
                },
            },
            {
                lotto_date: "2024-07-25",
                results: {
                    digit2_top: "15",
                    digit5: "02115",
                    digit1: "5",
                    digit3: "115",
                    digit2_special: "42",
                    digit4: "2115",
                    digit2_bottom: "02",
                },
            },
            {
                lotto_date: "2024-07-24",
                results: {
                    digit2_top: "78",
                    digit5: "17578",
                    digit1: "8",
                    digit3: "578",
                    digit2_special: "13",
                    digit4: "7578",
                    digit2_bottom: "17",
                },
            },
            {
                lotto_date: "2024-07-23",
                results: {
                    digit2_top: "60",
                    digit5: "49760",
                    digit1: "0",
                    digit3: "760",
                    digit2_special: "26",
                    digit4: "9760",
                    digit2_bottom: "49",
                },
            },
            {
                lotto_date: "2024-07-22",
                results: {
                    digit2_top: "32",
                    digit5: "15632",
                    digit1: "2",
                    digit3: "632",
                    digit2_special: "27",
                    digit4: "5632",
                    digit2_bottom: "15",
                },
            },
            {
                lotto_date: "2024-07-21",
                results: {
                    digit2_top: "03",
                    digit5: "77403",
                    digit1: "3",
                    digit3: "403",
                    digit2_special: "32",
                    digit4: "7403",
                    digit2_bottom: "77",
                },
            },
            {
                lotto_date: "2024-07-20",
                results: {
                    digit2_top: "52",
                    digit5: "68552",
                    digit1: "2",
                    digit3: "552",
                    digit2_special: "88",
                    digit4: "8552",
                    digit2_bottom: "68",
                },
            },
            {
                lotto_date: "2024-07-19",
                results: {
                    digit2_top: "78",
                    digit5: "01978",
                    digit1: "8",
                    digit3: "978",
                    digit2_special: "74",
                    digit4: "1978",
                    digit2_bottom: "01",
                },
            },
            {
                lotto_date: "2024-07-18",
                results: {
                    digit2_top: "24",
                    digit5: "38224",
                    digit1: "4",
                    digit3: "224",
                    digit2_special: "76",
                    digit4: "8224",
                    digit2_bottom: "38",
                },
            },
            {
                lotto_date: "2024-07-17",
                results: {
                    digit2_top: "25",
                    digit5: "51725",
                    digit1: "5",
                    digit3: "725",
                    digit2_special: "93",
                    digit4: "1725",
                    digit2_bottom: "51",
                },
            },
            {
                lotto_date: "2024-07-16",
                results: {
                    digit2_top: "09",
                    digit5: "80209",
                    digit1: "9",
                    digit3: "209",
                    digit2_special: "13",
                    digit4: "0209",
                    digit2_bottom: "80",
                },
            },
            {
                lotto_date: "2024-07-15",
                results: {
                    digit2_top: "66",
                    digit5: "29766",
                    digit1: "6",
                    digit3: "766",
                    digit2_special: "46",
                    digit4: "9766",
                    digit2_bottom: "29",
                },
            },
            {
                lotto_date: "2024-07-14",
                results: {
                    digit2_top: "30",
                    digit5: "43230",
                    digit1: "0",
                    digit3: "230",
                    digit2_special: "28",
                    digit4: "3230",
                    digit2_bottom: "43",
                },
            },
            {
                lotto_date: "2024-07-13",
                results: {
                    digit2_top: "92",
                    digit5: "57392",
                    digit1: "2",
                    digit3: "392",
                    digit2_special: "80",
                    digit4: "7392",
                    digit2_bottom: "57",
                },
            },
            {
                lotto_date: "2024-07-12",
                results: {
                    digit2_top: "56",
                    digit5: "74056",
                    digit1: "6",
                    digit3: "056",
                    digit2_special: "98",
                    digit4: "4056",
                    digit2_bottom: "74",
                },
            },
            {
                lotto_date: "2024-07-11",
                results: {
                    digit2_top: "71",
                    digit5: "30871",
                    digit1: "1",
                    digit3: "871",
                    digit2_special: "63",
                    digit4: "0871",
                    digit2_bottom: "30",
                },
            },
            {
                lotto_date: "2024-07-10",
                results: {
                    digit2_top: "26",
                    digit5: "53826",
                    digit1: "6",
                    digit3: "826",
                    digit2_special: "09",
                    digit4: "3826",
                    digit2_bottom: "53",
                },
            },
            {
                lotto_date: "2024-07-09",
                results: {
                    digit2_top: "98",
                    digit5: "66398",
                    digit1: "8",
                    digit3: "398",
                    digit2_special: "06",
                    digit4: "6398",
                    digit2_bottom: "66",
                },
            },
            {
                lotto_date: "2024-07-08",
                results: {
                    digit2_top: "46",
                    digit5: "59046",
                    digit1: "6",
                    digit3: "046",
                    digit2_special: "46",
                    digit4: "9046",
                    digit2_bottom: "59",
                },
            },
            {
                lotto_date: "2024-07-07",
                results: {
                    digit2_top: "44",
                    digit5: "18744",
                    digit1: "4",
                    digit3: "744",
                    digit2_special: "08",
                    digit4: "8744",
                    digit2_bottom: "18",
                },
            },
            {
                lotto_date: "2024-07-06",
                results: {
                    digit2_top: "95",
                    digit5: "82595",
                    digit1: "5",
                    digit3: "595",
                    digit2_special: "39",
                    digit4: "2595",
                    digit2_bottom: "82",
                },
            },
            {
                lotto_date: "2024-07-05",
                results: {
                    digit2_top: "67",
                    digit5: "78967",
                    digit1: "7",
                    digit3: "967",
                    digit2_special: "63",
                    digit4: "8967",
                    digit2_bottom: "78",
                },
            },
            {
                lotto_date: "2024-07-04",
                results: {
                    digit2_top: "31",
                    digit5: "88231",
                    digit1: "1",
                    digit3: "231",
                    digit2_special: "13",
                    digit4: "8231",
                    digit2_bottom: "88",
                },
            },
            {
                lotto_date: "2024-07-03",
                results: {
                    digit2_top: "76",
                    digit5: "05176",
                    digit1: "6",
                    digit3: "176",
                    digit2_special: "45",
                    digit4: "5176",
                    digit2_bottom: "05",
                },
            },
            {
                lotto_date: "2024-07-02",
                results: {
                    digit2_top: "47",
                    digit5: "21647",
                    digit1: "7",
                    digit3: "647",
                    digit2_special: "46",
                    digit4: "1647",
                    digit2_bottom: "21",
                },
            },
            {
                lotto_date: "2024-07-01",
                results: {
                    digit2_top: "22",
                    digit5: "86722",
                    digit1: "2",
                    digit3: "722",
                    digit2_special: "54",
                    digit4: "6722",
                    digit2_bottom: "86",
                },
            },
        ]);
        setLoadingHistory(false);
        setLoading(false);
    };

    const RenderResult = ({ number }) => {
        return (
            <Flex
                align="center"
                justify="center"
                className="text-[4rem] font-extrabold text-[#eee] border-solid border-[2px] border-white rounded-[10px] w-[64px] h-[79px] leading-[1.2] bg-[#696969]"
            >
                {number}
            </Flex>
        );
    };

    useEffect(() => {
        document.title = "ລາວປະທຸໄຊ";
        onFetch();
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/laopatuxay/laopatuxay-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1100px]"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: "#D9D9D9" }} />
                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/laopatuxay/laopatuxay-header.png")}
                        rootClassName="max-w-[1024px]"
                        style={{ width: 1024 }}
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "#001152",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: "1024px",
                        width: "100%",
                        padding: "10px 20px",
                    }}
                >
                    <Image src={require("../../assets/images/laopatuxay/laopatuxay--1.png")} preview={false} />
                    <Flex gap={10} className="pt-[10px] flex-col min-[990px]:flex-row">
                        <Flex className="w-full min-[990px]:max-w-[598px]" vertical gap={10}>
                            <Flex
                                style={{
                                    background: "#e1e1e1",
                                }}
                                vertical
                            >
                                <center className="pt-4">
                                    <div className="text-[1.3rem] text-[#222] font-semibold text-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji]">
                                        ງວດປະຈຳວັນທີ່ {getDateNameLao(data?.lotto_date)}
                                    </div>
                                    <Flex gap={5} justify="center" className="my-2">
                                        {data?.results?.digit5?.split("")?.map((item, index) => {
                                            return <RenderResult number={item} key={index} />;
                                        })}
                                    </Flex>
                                    <div className="">
                                        <h3 className="text-[1.1rem] text-[#222] font-semibold">
                                            ເວລາອອກເລກ {getResultTime(data?.show_result)}
                                        </h3>
                                    </div>
                                </center>
                                <Flex className="py-[10px] px-[50px]">
                                    <Flex vertical flex={1} gap={2}>
                                        {Object.entries(data?.results || {}).map(([key, value]) => {
                                            if (key !== "digit2_special" && key !== "digit2_bottom") {
                                                return (
                                                    <Flex
                                                        style={{
                                                            order: sortKey[key],
                                                            color: "#000",
                                                        }}
                                                        gap={2}
                                                    >
                                                        <div
                                                            style={{
                                                                border: "2px solid #fff",
                                                            }}
                                                            className="h-[40px] text-[20px] w-[50%] flex items-center justify-center font-base font-medium bg-[#eee] rounded-ss-[10px] rounded-es-[10px] leading-[1.2]"
                                                        >
                                                            {listName[key]?.name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                border: "2px solid #fff",
                                                            }}
                                                            className="h-[40px] text-[20px] w-[50%] flex items-center justify-center font-base font-medium bg-[#eee] rounded-ee-[10px] rounded-se-[10px] leading-[1.2]"
                                                        >
                                                            {value ? (
                                                                <div>{value}</div>
                                                            ) : (
                                                                Array.from(Array(listName[key]?.length).keys()).map(() => (
                                                                    <div>-</div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </Flex>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Flex>
                                </Flex>
                            </Flex>

                            {/* content history */}
                            <Flex vertical className="min-h-[150px">
                                <Flex
                                    className=" text-[#222]"
                                    style={{
                                        backgroundColor: "#fff",
                                        backgroundImage: `url(${require("../../assets/images/laopatuxay/laopatuxay--header-history.png")})`,
                                        backgroundPosition: "center",
                                    }}
                                    vertical
                                >
                                    <center className="text-[26px] font-bold">ຜົນການອອກເລກລາງວັນ</center>
                                </Flex>
                                <div className="border-[#999] border-solid border-t-[1px] mt-2 flex bg-white z-[1]">
                                    <div className="flex flex-1 border-x-[1px] p-[5px] font-base border-[#999] border-solid justify-center border-b-[1px]">
                                        ງວດວັນທີ່
                                    </div>
                                    {_numberList?.map((number) => {
                                        return (
                                            <div className="w-[15%] flex p-[5px] justify-center font-base border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                <center>{number.name}</center>
                                            </div>
                                        );
                                    })}
                                </div>
                                {historyData.map((result) => {
                                    return (
                                        <Flex className="bg-white z-[1] hover:bg-[#EFEFEF]">
                                            <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#999] border-solid border-b-[1px] border-r-[1px] border-l-[1px] hover:text-[#212529]">
                                                <center className="font-normal min-[500px]:block hidden">
                                                    {getDateNameLao(result?.lotto_date)}
                                                </center>
                                                <center className="font-normal block min-[500px]:hidden">
                                                    {getDateNameSlashShortYear(result?.lotto_date)}
                                                </center>
                                            </div>
                                            {Object.entries(result.results).map(([key, value]) => {
                                                if (key !== "digit2_special" && key !== "digit2_bottom")
                                                    return (
                                                        <div
                                                            style={{
                                                                order: sortKey[key],
                                                            }}
                                                            className="w-[15%] flex p-[5px] justify-center font-base  border-[#999] border-solid border-b-[1px] border-r-[1px]"
                                                        >
                                                            <center className="font-normal hover:text-[#212529]">{value}</center>
                                                        </div>
                                                    );
                                                return null;
                                            })}
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        </Flex>
                        <div className="min-[990px]:max-w-[400px] w-full">
                            <Image
                                src={require("../../assets/images/laopatuxay/laopatuxay--2.png")}
                                rootClassName="w-full"
                                preview={false}
                            />
                            <Image
                                src={require("../../assets/images/laopatuxay/laopatuxay--3.png")}
                                rootClassName="w-full"
                                preview={false}
                            />
                            <Image
                                src={require("../../assets/images/laopatuxay/laopatuxay-4.png")}
                                rootClassName="w-full"
                                preview={false}
                            />
                            <Image
                                src={require("../../assets/images/laopatuxay/laopatuxay-5.png")}
                                rootClassName="w-full"
                                preview={false}
                            />
                        </div>
                    </Flex>
                    <Divider className="bg-[#FFF] h-[2px] mb-0" />
                    <center className="text-white font-normal text-lg pt-3 bg-[#001152]">
                        Copyright ©2024 - laopatuxay.com
                    </center>
                </Flex>
            </Container>
        </div>
    );
}
