export const LAD_RED_CROSS_ID = process.env.REACT_APP_LAD_RED_CROSS_ID || "66059b8904a7d07018e512d5";
export const LAO_SAN_TI_PAP_ID = process.env.REACT_APP_LAO_SAN_TI_PAP_ID || "66704b1f3243e4edc7045103";
export const LAO_CITIZEN_ID = process.env.REACT_APP_LAO_CITIZEN_ID || "66704b06db678109b90b5042";
export const LAO_EXTRA_ID = process.env.REACT_APP_LAO_EXTRA_ID || "66704ae726691d45a802abe2";
export const DOW_JONES_EXTRA_ID = process.env.REACT_APP_DOW_JONES_EXTRA_ID || "66704b7808584f429a0ea0f3";
export const DOW_JONES_MIDNIGHT_ID = process.env.REACT_APP_DOW_JONES_MIDNIGHT_ID || "66704bb55819a76de40a4ab3";
export const DOW_JONES_STAR_ID = process.env.REACT_APP_DOW_JONES_STAR_ID || "66704be4c2e794fdc0010303";
export const DOW_JONES_TV_ID = process.env.REACT_APP_DOW_JONES_TV_ID || "66704b58c1ae5b760808bc23";
export const HANOI_EXTRA_ID = process.env.REACT_APP_HANOI_EXTRA_ID || "666ee6e2d3e63b4f2d0d9652";
export const LAO_SA_SEAN_ID = process.env.REACT_APP_LAO_SA_SEAN_ID || "6670250f9c40fd4f3a06f8b3";
export const LAO_STAR_VIP_ID = process.env.REACT_APP_LAO_STAR_VIP_ID || "663737027107a044ae53cb53";
export const LAO_UNION_VIP_ID = process.env.REACT_APP_LAO_UNION_VIP_ID || "667024f39c40fd4f3a06f8b2";
export const LAO_UNION_ID = process.env.REACT_APP_LAO_UNION_ID || "6656e3e9f1b31c20ee385872";
export const LAO_PA_TU_XAY_ID = process.env.REACT_APP_LAO_PA_TU_XAY_ID || "66704b3dc2e794fdc0010302";
export const XOSODEVELOP_ID = process.env.REACT_APP_XOSODEVELOP_ID || "663737647107a044ae53cb54";
export const HANOI_ASEAN_ID = process.env.REACT_APP_HANOI_ASEAN_ID || "66704a5a0ef3e2f64e0ef123";
export const LAO_TV_ID = process.env.REACT_APP_LAO_TV_ID || "66704a435819a76de40a4ab2";
export const XO_SO_ID = process.env.REACT_APP_XO_SO_ID || "66704a2c5cbbe343e400b4a2";
export const LAO_HD_ID = process.env.REACT_APP_LAO_HD_ID || "667049ed8e3a1e9d7606d852";
export const MINHNGOC_TV_ID = process.env.REACT_APP_MINHNGOC_TV_ID || "667049cc0ef3e2f64e0ef122";
export const LAO_STAR_ID = process.env.REACT_APP_LAO_STAR_ID || "667049ab3243e4edc7045102";
export const MINHNGOC_ID = process.env.REACT_APP_MINHNGOC_ID || "6666e1cb84199441f0060cb2";
export const XO_SO_UNION_ID = process.env.REACT_APP_XO_SO_UNION_ID || "6665429590afeba8290c8df2";
export const XO_SO_RED_CROSS_ID = process.env.REACT_APP_XO_SO_RED_CROSS_ID || "66704976c1ae5b760808bc22";
export const MINHNGOC_STAR_ID = process.env.REACT_APP_MINHNGOC_STAR_ID || "66704a1008584f429a0ea0f2";

export const LAD_RED_CROSS_DOMAIN = process.env.REACT_APP_LAD_RED_CROSS_DOMAIN || "lao-redcrossjackpot.com";
export const LAO_SAN_TI_PAP_DOMAIN = process.env.REACT_APP_LAO_SAN_TI_PAP_DOMAIN || "laosantipapjackpot.com";
export const LAO_CITIZEN_DOMAIN = process.env.REACT_APP_LAO_CITIZEN_DOMAIN || "laocitizenjackpot.com";
export const LAO_EXTRA_DOMAIN = process.env.REACT_APP_LAO_EXTRA_DOMAIN || "laoextrajackpot.com";
export const DOW_JONES_EXTRA_DOMAIN = process.env.REACT_APP_DOW_JONES_EXTRA_DOMAIN || "dowjonesextrajackpot.com";
export const DOW_JONES_MIDNIGHT_DOMAIN = process.env.REACT_APP_DOW_JONES_MIDNIGHT_DOMAIN || "dowjones-midnightjackpot.com";
export const DOW_JONES_STAR_DOMAIN = process.env.REACT_APP_DOW_JONES_STAR_DOMAIN || "dowjonestarjackpot.com";
export const DOW_JONES_TV_DOMAIN = process.env.REACT_APP_DOW_JONES_TV_DOMAIN || "tvdowjonesjackpot.com";
export const HANOI_EXTRA_DOMAIN = process.env.REACT_APP_HANOI_EXTRA_DOMAIN || "xosoextrajackpot.com";
export const LAO_SA_SEAN_DOMAIN = process.env.REACT_APP_LAO_SA_SEAN_DOMAIN || "laosaseanjackpot.com";
export const LAO_STAR_VIP_DOMAIN = process.env.REACT_APP_LAO_STAR_VIP_DOMAIN || "laostarsvipjackpot.com";
export const LAO_UNION_VIP_DOMAIN = process.env.REACT_APP_LAO_UNION_VIP_DOMAIN || "laounionvipjackpot.com";
export const LAO_UNION_DOMAIN = process.env.REACT_APP_LAO_UNION_DOMAIN || "laounionjackpot.com";
export const LAO_PA_TU_XAY_DOMAIN = process.env.REACT_APP_LAO_PA_TU_XAY_DOMAIN || "laopatuxayjackpot.com";
export const XOSODEVELOP_DOMAIN = process.env.REACT_APP_XOSODEVELOP_DOMAIN || "xosodevelopjackpot.com";
export const HANOI_ASEAN_DOMAIN = process.env.REACT_APP_HANOI_ASEAN_DOMAIN || "hanoiaseanjackpot.com";
export const LAO_TV_DOMAIN = process.env.REACT_APP_LAO_TV_DOMAIN || "lao-tvjackpot.com";
export const XO_SO_DOMAIN = process.env.REACT_APP_XO_SO_DOMAIN || "xosohdjackpot.com";
export const LAO_HD_DOMAIN = process.env.REACT_APP_LAO_HD_DOMAIN || "laoshdjackpot.com";
export const MINHNGOC_TV_DOMAIN = process.env.REACT_APP_MINHNGOC_TV_DOMAIN || "minhngoctvjackpot.com";
export const LAO_STAR_DOMAIN = process.env.REACT_APP_LAO_STAR_DOMAIN || "laostarsjackpot.com";
export const MINHNGOC_DOMAIN = process.env.REACT_APP_MINHNGOC_DOMAIN || "minhngocjackpot.com";
export const XO_SO_RED_CROSS_DOMAIN = process.env.REACT_APP_XO_SO_RED_CROSS_DOMAIN || "xosoredcrossjackpot.com";
export const XO_SO_UNION_DOMAIN = process.env.REACT_APP_XO_SO_UNION_DOMAIN || "xosounionjackpot.com";
export const MINHNGOC_STAR_DOMAIN = process.env.REACT_APP_MINHNGOC_STAR_DOMAIN || "minhngocstarjackpot.com";

export const JACKPOT_LAD_RED_CROSS_DOMAIN = process.env.REACT_APP_JACKPOT_LAD_RED_CROSS_DOMAIN || "jackpot.lao-redcross.com";
export const JACKPOT_LAO_SAN_TI_PAP_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_SAN_TI_PAP_DOMAIN || "jackpot.laosantipap.com";
export const JACKPOT_LAO_CITIZEN_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_CITIZEN_DOMAIN || "jackpot.laocitizen.com";
export const JACKPOT_LAO_EXTRA_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_EXTRA_DOMAIN || "jackpot.laoextra.com";
export const JACKPOT_DOW_JONES_EXTRA_DOMAIN = process.env.REACT_APP_JACKPOT_DOW_JONES_EXTRA_DOMAIN || "jackpot.dowjonesextra.com";
export const JACKPOT_DOW_JONES_MIDNIGHT_DOMAIN =
    process.env.REACT_APP_JACKPOT_DOW_JONES_MIDNIGHT_DOMAIN || "jackpot.dowjones-midnight.com";
export const JACKPOT_DOW_JONES_STAR_DOMAIN = process.env.REACT_APP_JACKPOT_DOW_JONES_STAR_DOMAIN || "jackpot.dowjonestar.com";
export const JACKPOT_DOW_JONES_TV_DOMAIN = process.env.REACT_APP_JACKPOT_DOW_JONES_TV_DOMAIN || "jackpot.tvdowjones.com";
export const JACKPOT_HANOI_EXTRA_DOMAIN = process.env.REACT_APP_JACKPOT_HANOI_EXTRA_DOMAIN || "jackpot.xosoextra.com";
export const JACKPOT_LAO_SA_SEAN_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_SA_SEAN_DOMAIN || "jackpot.lotterylaosasean.com";
export const JACKPOT_LAO_STAR_VIP_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_STAR_VIP_DOMAIN || "jackpot.laostars-vip.com";
export const JACKPOT_LAO_UNION_VIP_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_UNION_VIP_DOMAIN || "jackpot.laounionvip.com";
export const JACKPOT_LAO_UNION_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_UNION_DOMAIN || "jackpot.laounion.com";
export const JACKPOT_LAO_PA_TU_XAY_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_PA_TU_XAY_DOMAIN || "jackpot.laopatuxay.com";
export const JACKPOT_XOSODEVELOP_DOMAIN = process.env.REACT_APP_JACKPOT_XOSODEVELOP_DOMAIN || "jackpot.xosodevelop.com";
export const JACKPOT_HANOI_ASEAN_DOMAIN = process.env.REACT_APP_JACKPOT_HANOI_ASEAN_DOMAIN || "jackpot.hanoiasean.com";
export const JACKPOT_LAO_TV_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_TV_DOMAIN || "jackpot.lao-tv.com";
export const JACKPOT_XO_SO_DOMAIN = process.env.REACT_APP_JACKPOT_JACKPOT_XO_SO_DOMAIN || "jackpot.xosohd.com";
export const JACKPOT_LAO_HD_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_HD_DOMAIN || "jackpot.laoshd.com";
export const JACKPOT_MINHNGOC_TV_DOMAIN = process.env.REACT_APP_JACKPOT_MINHNGOC_TV_DOMAIN || "jackpot.minhngoctv.com";
export const JACKPOT_LAO_STAR_DOMAIN = process.env.REACT_APP_JACKPOT_LAO_STAR_DOMAIN || "jackpot.laostars.com";
export const JACKPOT_MINHNGOC_DOMAIN = process.env.REACT_APP_JACKPOT_MINHNGOC_DOMAIN || "minhngocjackpot.com"; // ยังไม่มี
export const JACKPOT_XO_SO_RED_CROSS_DOMAIN = process.env.REACT_APP_JACKPOT_XO_SO_RED_CROSS_DOMAIN || "jackpot.xosoredcross.com";
export const JACKPOT_XO_SO_UNION_DOMAIN = process.env.REACT_APP_JACKPOT_XO_SO_UNION_DOMAIN || "jackpot.xosounion.com";
export const JACKPOT_MINHNGOC_STAR_DOMAIN = process.env.REACT_APP_JACKPOT_MINHNGOC_STAR_DOMAIN || "jackpot.minhngocstar.com";

// export const LAD_RED_CROSS_DOMAIN = process.env.REACT_APP_LAD_RED_CROSS_DOMAIN || "lao-redcrossplus.com";
// export const LAO_SAN_TI_PAP_DOMAIN = process.env.REACT_APP_LAO_SAN_TI_PAP_DOMAIN || "laosantipapplus.com";
// export const LAO_CITIZEN_DOMAIN = process.env.REACT_APP_LAO_CITIZEN_DOMAIN || "laocitizenplus.com";
// export const LAO_EXTRA_DOMAIN = process.env.REACT_APP_LAO_EXTRA_DOMAIN || "laoextra-plus.com";
// export const DOW_JONES_EXTRA_DOMAIN = process.env.REACT_APP_DOW_JONES_EXTRA_DOMAIN || "dowjonesextraplus.com";
// export const DOW_JONES_MIDNIGHT_DOMAIN = process.env.REACT_APP_DOW_JONES_MIDNIGHT_DOMAIN || "dowjones-midnightplus.com";
// export const DOW_JONES_STAR_DOMAIN = process.env.REACT_APP_DOW_JONES_STAR_DOMAIN || "dowjonestarplus.com";
// export const DOW_JONES_TV_DOMAIN = process.env.REACT_APP_DOW_JONES_TV_DOMAIN || "tvdowjonesplus.com";
// export const HANOI_EXTRA_DOMAIN = process.env.REACT_APP_HANOI_EXTRA_DOMAIN || "xosoextraplus.com";
// export const LAO_SA_SEAN_DOMAIN = process.env.REACT_APP_LAO_SA_SEAN_DOMAIN || "laosaseanplus.com";
// export const LAO_STAR_VIP_DOMAIN = process.env.REACT_APP_LAO_STAR_VIP_DOMAIN || "laostarsvipplus.com";
// export const LAO_UNION_VIP_DOMAIN = process.env.REACT_APP_LAO_UNION_VIP_DOMAIN || "laounionvipplus.com";
// export const LAO_UNION_DOMAIN = process.env.REACT_APP_LAO_UNION_DOMAIN || "laounionplus.com";
// export const LAO_PA_TU_XAY_DOMAIN = process.env.REACT_APP_LAO_PA_TU_XAY_DOMAIN || "laopatuxayplus.com";
// export const XOSODEVELOP_DOMAIN = process.env.REACT_APP_XOSODEVELOP_DOMAIN || "xosodevelopplus.com";
// export const HANOI_ASEAN_DOMAIN = process.env.REACT_APP_HANOI_ASEAN_DOMAIN || "hanoiaseanplus.com";
// export const LAO_TV_DOMAIN = process.env.REACT_APP_LAO_TV_DOMAIN || "lao-tvplus.com";
// export const XO_SO_DOMAIN = process.env.REACT_APP_XO_SO_DOMAIN || "xosohdplus.com";
// export const LAO_HD_DOMAIN = process.env.REACT_APP_LAO_HD_DOMAIN || "laoshdplus.com";
// export const MINHNGOC_TV_DOMAIN = process.env.REACT_APP_MINHNGOC_TV_DOMAIN || "minhngoctvplus.com";
// export const LAO_STAR_DOMAIN = process.env.REACT_APP_LAO_STAR_DOMAIN || "laostarsplus.com";
// export const MINHNGOC_DOMAIN = process.env.REACT_APP_MINHNGOC_DOMAIN || "minhngocplus.com";
// export const XO_SO_RED_CROSS_DOMAIN = process.env.REACT_APP_XO_SO_RED_CROSS_DOMAIN || "xosoredcrossplus.com";
// export const XO_SO_UNION_DOMAIN = process.env.REACT_APP_XO_SO_UNION_DOMAIN || "xosounionplus.com";
// export const MINHNGOC_STAR_DOMAIN = process.env.REACT_APP_MINHNGOC_STAR_DOMAIN || "minhngocstarplus.com";
