import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { DOW_JONES_STAR_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getDay, getDayName } from "../../utils/ConvertDate";
import { generateData } from "../../utils/GenerateNumber";
import "./index.css";

const color = "#212529";
export default function DowJonesStarOldPage() {
    const [date, setDate] = useState(new Date());
    const [result, setResult] = useState({
        firstPrize: "81697",
        secondPrize: "72654",
        thirdPrize: ["58768", "98765", "54321"],
        specialPrize: ["64856", "98765", "54321", "12345", "64856", "98765", "54321", "12345"],
        consolationPrize: ["10", "20", "30", "40"],
    });

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: DOW_JONES_STAR_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: DOW_JONES_STAR_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "LOTTO DOWJONES STAR";
        onFetch();
    }, []);

    const onFetch = (date) => {
        setLoading(true);
        setDate(date);
        // fetch data
        setLoadingHistory(false);
        setLoading(false);
    };

    const renderResult = (number, width) => {
        return (
            <Image
                src={require(`../../assets/images/dowjonestar/${number}.png`)}
                preview={false}
                className="my-[2px] mx-[5px]"
                style={{ width: width || 33 }}
            />
        );
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/dowjonestar/dowjonestar-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <Flex
                    style={{
                        backgroundColor: "#fff",
                        backgroundImage: `url(${require("../../assets/images/dowjonestar/dowjonestar-warpper.png")})`,
                        backgroundSize: "100% 100%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="w-full !max-w-[960px] border-solid border-[#013d76] border-[3px] min-h-[1200px]"
                    vertical
                >
                    {/* header logo */}
                    <Flex align="center" className="sm:justify-center justify-between">
                        <Image
                            src={require("../../assets/images/dowjonestar/menu.png")}
                            preview={false}
                            className="!h-[40px] !w-[40px] m-[15px]"
                            rootClassName="cursor-pointer sm:hidden block"
                        />
                        <Image
                            src={require("../../assets/images/dowjonestar/dowjonestar-header.png")}
                            preview={false}
                            className="sm:!h-[120px] !h-[90px] sm:mt-[30px] mt-0"
                        />
                        <Image
                            src={require("../../assets/images/dowjonestar/refresh.png")}
                            preview={false}
                            className="!h-[40px] !w-[40px]  m-[15px]"
                            rootClassName="cursor-pointer sm:hidden block"
                        />
                    </Flex>
                    <Flex vertical align="center">
                        <Flex justify="center" align="center" className="w-[80%]" vertical>
                            <Flex justify="center" align="center" className="w-full mb-[15px]">
                                <Image
                                    src={require("../../assets/images/dowjonestar/dowjonestar-button-date.png")}
                                    preview={false}
                                    className="!h-[35px]"
                                    rootClassName="mr-[5px] cursor-pointer"
                                    onClick={() => {
                                        onFetch(dayjs(date).subtract(1, "day").toDate());
                                    }}
                                />

                                <Flex
                                    justify="center"
                                    align="center"
                                    className="border-solid border-[#222] border-[3px] py-[5px] w-[100%] "
                                    flex={1}
                                >
                                    <div
                                        style={{ lineHeight: 1.2 }}
                                        className="!font-[Orbitron,sans-serif] text-[30px] font-medium date"
                                    >{`${getDay(date)}[${getDayName(date)}]`}</div>
                                </Flex>
                                {getDay(date) !== getDay(new Date()) && (
                                    <Image
                                        src={require("../../assets/images/dowjonestar/dowjonestar-button-date.png")}
                                        preview={false}
                                        className="!h-[35px]"
                                        rootClassName="ml-[5px] cursor-pointer rotate-180"
                                        onClick={() => {
                                            onFetch(dayjs(date).add(1, "day").toDate());
                                        }}
                                    />
                                )}
                            </Flex>

                            <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium">1st Prize</div>

                            <Flex
                                style={{
                                    backgroundColor: "#fff",
                                    backgroundImage: `url(${require("../../assets/images/dowjonestar/dowjonestar-result.png")})`,
                                    backgroundSize: "100% 100%",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    height: 78,
                                    maxWidth: 450,
                                    width: "100%",
                                    marginBottom: 15,
                                }}
                                justify="center"
                                align="center"
                            >
                                {result?.firstPrize?.split("").map((number) => renderResult(number))}
                            </Flex>

                            <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium">2nd Prize</div>

                            <Flex
                                style={{
                                    backgroundColor: "#fff",
                                    backgroundImage: `url(${require("../../assets/images/dowjonestar/dowjonestar-result.png")})`,
                                    backgroundSize: "100% 100%",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    height: 63,
                                    maxWidth: 450,
                                    width: "100%",
                                    marginBottom: 15,
                                }}
                                justify="center"
                                align="center"
                            >
                                {result?.secondPrize?.split("").map((number) => renderResult(number, 25))}
                            </Flex>

                            <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium">3rd Prize</div>

                            <div className="rd-3">
                                {result?.thirdPrize?.map((item) => (
                                    <div className="rd-box text-center" key={item}>
                                        <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium result-item-3">
                                            {item}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium">Special</div>

                            <div className="spacai">
                                {result?.specialPrize?.map((item) => (
                                    <div className="sp-box text-center" key={item}>
                                        <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium result-item-spacai">
                                            {item}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium">Consulation</div>
                            <div className="spacai">
                                {result?.consolationPrize?.map((item) => (
                                    <div className="sp-box text-center" key={item}>
                                        <div className="!font-[Orbitron,sans-serif] text-[25px] font-medium result-item">
                                            {item}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>
        </div>
    );
}
