import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { getDateNameLao } from "../../utils/ConvertDate";

export default function LaoUnionVipOldPage() {
    const [data, setData] = useState({
        start_spin: "2024-07-15 23:25:00",
        lotto_date: "2024-07-15",
        show_result: "2024-07-15 23:30:00",
        results: {
            digit2_top: "41",
            digit5: "19741",
            digit1: "1",
            digit3: "741",
            digit2_special: "12",
            digit4: "9741",
            digit2_bottom: "19",
        },
    });
    const [historyData, setHistoryData] = useState([
        {
            lotto_date: "2024-07-14",
            results: {
                digit2_top: "94",
                digit5: "35094",
                digit1: "4",
                digit3: "094",
                digit2_special: "98",
                digit4: "5094",
                digit2_bottom: "35",
            },
        },
        {
            lotto_date: "2024-07-13",
            results: {
                digit2_top: "01",
                digit5: "89701",
                digit1: "1",
                digit3: "701",
                digit2_special: "65",
                digit4: "9701",
                digit2_bottom: "89",
            },
        },
        {
            lotto_date: "2024-07-12",
            results: {
                digit2_top: "32",
                digit5: "07832",
                digit1: "2",
                digit3: "832",
                digit2_special: "12",
                digit4: "7832",
                digit2_bottom: "07",
            },
        },
        {
            lotto_date: "2024-07-11",
            results: {
                digit2_top: "68",
                digit5: "42768",
                digit1: "8",
                digit3: "768",
                digit2_special: "93",
                digit4: "2768",
                digit2_bottom: "42",
            },
        },
        {
            lotto_date: "2024-07-10",
            results: {
                digit2_top: "91",
                digit5: "63991",
                digit1: "1",
                digit3: "991",
                digit2_special: "19",
                digit4: "3991",
                digit2_bottom: "63",
            },
        },
        {
            lotto_date: "2024-07-09",
            results: {
                digit2_top: "24",
                digit5: "17724",
                digit1: "4",
                digit3: "724",
                digit2_special: "21",
                digit4: "7724",
                digit2_bottom: "17",
            },
        },
        {
            lotto_date: "2024-07-08",
            results: {
                digit2_top: "64",
                digit5: "10664",
                digit1: "4",
                digit3: "664",
                digit2_special: "72",
                digit4: "0664",
                digit2_bottom: "10",
            },
        },
        {
            lotto_date: "2024-07-07",
            results: {
                digit2_top: "33",
                digit5: "06833",
                digit1: "3",
                digit3: "833",
                digit2_special: "31",
                digit4: "6833",
                digit2_bottom: "06",
            },
        },
        {
            lotto_date: "2024-07-06",
            results: {
                digit2_top: "20",
                digit5: "47820",
                digit1: "0",
                digit3: "820",
                digit2_special: "88",
                digit4: "7820",
                digit2_bottom: "47",
            },
        },
        {
            lotto_date: "2024-07-05",
            results: {
                digit2_top: "66",
                digit5: "42166",
                digit1: "6",
                digit3: "166",
                digit2_special: "40",
                digit4: "2166",
                digit2_bottom: "42",
            },
        },
        {
            lotto_date: "2024-07-04",
            results: {
                digit2_top: "85",
                digit5: "92185",
                digit1: "5",
                digit3: "185",
                digit2_special: "11",
                digit4: "2185",
                digit2_bottom: "92",
            },
        },
        {
            lotto_date: "2024-07-03",
            results: {
                digit2_top: "04",
                digit5: "67004",
                digit1: "4",
                digit3: "004",
                digit2_special: "11",
                digit4: "7004",
                digit2_bottom: "67",
            },
        },
        {
            lotto_date: "2024-07-02",
            results: {
                digit2_top: "05",
                digit5: "43105",
                digit1: "5",
                digit3: "105",
                digit2_special: "02",
                digit4: "3105",
                digit2_bottom: "43",
            },
        },
        {
            lotto_date: "2024-07-01",
            results: {
                digit2_top: "58",
                digit5: "66458",
                digit1: "8",
                digit3: "458",
                digit2_special: "08",
                digit4: "6458",
                digit2_bottom: "66",
            },
        },
        {
            lotto_date: "2024-06-30",
            results: {
                digit2_top: "24",
                digit5: "56424",
                digit1: "4",
                digit3: "424",
                digit2_special: "64",
                digit4: "6424",
                digit2_bottom: "56",
            },
        },
        {
            lotto_date: "2024-06-29",
            results: {
                digit2_top: "29",
                digit5: "09729",
                digit1: "9",
                digit3: "729",
                digit2_special: "64",
                digit4: "9729",
                digit2_bottom: "09",
            },
        },
        {
            lotto_date: "2024-06-28",
            results: {
                digit2_top: "75",
                digit5: "80975",
                digit1: "5",
                digit3: "975",
                digit2_special: "10",
                digit4: "0975",
                digit2_bottom: "80",
            },
        },
        {
            lotto_date: "2024-06-27",
            results: {
                digit2_top: "02",
                digit5: "92202",
                digit1: "2",
                digit3: "202",
                digit2_special: "64",
                digit4: "2202",
                digit2_bottom: "92",
            },
        },
        {
            lotto_date: "2024-06-26",
            results: {
                digit2_top: "88",
                digit5: "53388",
                digit1: "8",
                digit3: "388",
                digit2_special: "09",
                digit4: "3388",
                digit2_bottom: "53",
            },
        },
        {
            lotto_date: "2024-06-25",
            results: {
                digit2_top: "90",
                digit5: "77590",
                digit1: "0",
                digit3: "590",
                digit2_special: "25",
                digit4: "7590",
                digit2_bottom: "77",
            },
        },
        {
            lotto_date: "2024-06-24",
            results: {
                digit2_top: "12",
                digit5: "96512",
                digit1: "2",
                digit3: "512",
                digit2_special: "52",
                digit4: "6512",
                digit2_bottom: "96",
            },
        },
        {
            lotto_date: "2024-06-23",
            results: {
                digit2_top: "92",
                digit5: "14392",
                digit1: "2",
                digit3: "392",
                digit2_special: "19",
                digit4: "4392",
                digit2_bottom: "14",
            },
        },
        {
            lotto_date: "2024-06-22",
            results: {
                digit2_top: "66",
                digit5: "83066",
                digit1: "6",
                digit3: "066",
                digit2_special: "52",
                digit4: "3066",
                digit2_bottom: "83",
            },
        },
        {
            lotto_date: "2024-06-21",
            results: {
                digit2_top: "07",
                digit5: "81807",
                digit1: "7",
                digit3: "807",
                digit2_special: "92",
                digit4: "1807",
                digit2_bottom: "81",
            },
        },
        {
            lotto_date: "2024-06-20",
            results: {
                digit2_top: "70",
                digit5: "45370",
                digit1: "0",
                digit3: "370",
                digit2_special: "06",
                digit4: "5370",
                digit2_bottom: "45",
            },
        },
        {
            lotto_date: "2024-06-19",
            results: {
                digit2_top: "41",
                digit5: "88141",
                digit1: "1",
                digit3: "141",
                digit2_special: "88",
                digit4: "8141",
                digit2_bottom: "88",
            },
        },
        {
            lotto_date: "2024-06-18",
            results: {
                digit2_top: "99",
                digit5: "01699",
                digit1: "9",
                digit3: "699",
                digit2_special: "98",
                digit4: "1699",
                digit2_bottom: "01",
            },
        },
        {
            lotto_date: "2024-06-17",
            results: {
                digit2_top: "35",
                digit5: "07235",
                digit1: "5",
                digit3: "235",
                digit2_special: "88",
                digit4: "7235",
                digit2_bottom: "07",
            },
        },
        {
            lotto_date: "2024-06-16",
            results: {
                digit2_top: "65",
                digit5: "91765",
                digit1: "5",
                digit3: "765",
                digit2_special: "19",
                digit4: "1765",
                digit2_bottom: "91",
            },
        },
    ]);

    const listName = {
        digit2_top: {
            name: "ເລກ 2 ໂຕ",
            length: 2,
        },
        digit5: {
            name: "ເລກ 5 ໂຕ",
            length: 5,
        },
        digit1: {
            name: "ເລກ 1 ໂຕ",
            length: 1,
        },
        digit3: {
            name: "ເລກ 3 ໂຕ",
            length: 3,
        },
        digit2_special: {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
        },
        digit4: {
            name: "ເລກ 4 ໂຕ",
            length: 4,
        },
        digit2_bottom: {
            name: "ເລກ 2 ລາງ",
            length: 2,
        },
    };

    const sortKey = {
        digit5: 0,
        digit4: 1,
        digit3: 2,
        digit2_special: 5,
        digit2_top: 3,
        digit1: 4,
        digit2_bottom: 5,
    };

    const [loadingHistory, setLoadingHistory] = useState(true);
    const [loading, setLoading] = useState(true);

    const [_numberList] = useState([
        {
            name: "ເລກ 5 ໂຕ",
            length: 5,
            result: "",
        },
        {
            name: "ເລກ 4 ໂຕ",
            length: 4,
            result: "",
        },
        {
            name: "ເລກ 3 ໂຕ",
            length: 3,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕ",
            length: 2,
            result: "",
        },
        {
            name: "ເລກ 1 ໂຕ",
            length: 1,
            result: "",
        },
        {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
            result: "",
        },
    ]);
    // const [_resultLottery] = useState([
    //     {
    //         date: "19/03/2565",
    //         result: [
    //             {
    //                 type: 1,
    //                 number: "12345",
    //             },
    //             {
    //                 type: 2,
    //                 number: "2345",
    //             },
    //             {
    //                 type: 3,
    //                 number: "345",
    //             },
    //             {
    //                 type: 4,
    //                 number: "45",
    //             },
    //             {
    //                 type: 5,
    //                 number: "5",
    //             },
    //             {
    //                 type: 6,
    //                 number: "78",
    //             },
    //         ],
    //     },
    // ]);

    useEffect(() => {
        document.title = "ຫວຍລາວສາມັກຄີ";
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setLoadingHistory(false);
        setLoading(false);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/lao-union/lao-union-image-logo.png")} />
            </Helmet>

            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">ກຳລັງໂຫຼດ ...</div>
                </div>
            )}

            <Container className={"max-w-[1050px]"}>
                <div className="z-[-100] w-full h-[80%] absolute left-0" style={{ background: "#4CCAF4" }} />

                {/* header logo */}
                <div className="relative">
                    <Image src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip.png")} preview={false} />
                </div>

                <div className="h-[5px] border-b-[5px] border-solid border-[#fff]" />
                <Image
                    src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-1-0.png")}
                    preview={false}
                    rootClassName="!w-full"
                />
                <div className="flex flex-col sm:px-10 min-[901px]:flex-row min-[901px]:gap-3 min-[901px]:p-0">
                    <div className="w-full min-[901px]:max-w-[440px] ">
                        <Flex vertical className="border-[3px] border-solid border-[#07a9e1] bg-white">
                            <center className="bg-[#eee] py-2">
                                <div className="text-[1.3rem] font-extranormal text-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji]">
                                    ງວດປະຈຳວັນທີ່ {getDateNameLao(data.lotto_date)}
                                </div>
                                <div className="text-[4rem] font-bold tracking-[10px]">{data.results?.digit5}</div>
                                <div className="text-[1.2rem] font-extranormal">ເວລາອອກເລກ 23:30</div>
                            </center>
                            <Flex className="border-[#dee2e6] border-solid border-t-[2px]" vertical>
                                {Object.entries(data.results).map(([key, value]) => {
                                    if (key !== "digit2_bottom") {
                                        return (
                                            <div
                                                className="flex"
                                                style={{
                                                    order: sortKey[key],
                                                    marginTop: key === "digit2_special" ? -2 : 0,
                                                    borderTopWidth: key === "digit2_special" ? 3 : 0,
                                                    borderTopColor: key === "digit2_special" ? "rgb(187,187,187)" : "#dee2e6",
                                                }}
                                            >
                                                <div className="text-[1.8rem] w-[60%] flex justify-center font-base font-normal border-[#dee2e6] border-solid border-b-[2px] border-l-[2px] border-r-[2px]">
                                                    {listName[key]?.name}
                                                </div>
                                                <div className="text-[1.8rem] w-[40%] flex justify-center font-base font-normal border-[#dee2e6] border-solid border-b-[2px] border-r-[2px]">
                                                    {value ? (
                                                        <div>{value}</div>
                                                    ) : (
                                                        Array.from(Array(listName[key]?.length).keys()).map(() => <div>-</div>)
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </Flex>
                        </Flex>

                        {/* mobile */}
                        <div className="block max-[900px]:hidden mt-[10px]">
                            <Image
                                src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-1-1.png")}
                                preview={false}
                                rootClassName="!w-full"
                            />
                        </div>
                        <div className="hidden max-[900px]:block">
                            <Image
                                src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-1-1.png")}
                                preview={false}
                                rootClassName="w-full mt-3"
                            />
                            <Image
                                src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-1-2.png")}
                                preview={false}
                                rootClassName="w-full mt-3"
                            />
                            <div className="border-[#07a9e1] border-solid border-[3px] flex flex-col mt-2 items-center">
                                <div className="text-[1.3rem]">ຜົນການອອກເລກລາງວັນ</div>
                            </div>
                            <div className="border-[#dee2e6] border-solid border-t-[1px] mt-2 flex">
                                <div className="flex flex-1 border-x-[1px] font-base font-medium border-[#dee2e6] border-solid justify-center border-b-[2px]">
                                    ງວດວັນທີ່
                                </div>
                                {_numberList?.map((number) => {
                                    return (
                                        <div className="w-[13%] flex justify-center font-base font-normal border-[#dee2e6] border-solid border-b-[2px] border-r-[1px] p-[5px]">
                                            <center className="font-medium">{number.name}</center>
                                        </div>
                                    );
                                })}
                            </div>
                            {historyData.map((result) => {
                                return (
                                    <div className="flex">
                                        <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#dee2e6] border-solid border-b-[1px] border-r-[1px] border-l-[1px]">
                                            <center className="max-[500px]:hidden block font-medium">
                                                {getDateNameLao(result.lotto_date)}
                                            </center>
                                            <Flex align="center" className="font-medium flex-col max-[500px]:flex hidden">
                                                {getDateNameLao(result.lotto_date)
                                                    .split(" ")
                                                    .map((item) => (
                                                        <Flex>{item}</Flex>
                                                    ))}
                                            </Flex>
                                        </div>
                                        {Object.entries(result.results).map(([key, value]) => {
                                            if (key !== "digit2_bottom")
                                                return (
                                                    <div className="w-[13%] flex p-[5px] justify-center font-base  border-[#dee2e6] border-solid border-b-[1px] border-r-[1px]">
                                                        <center className="font-medium">{value}</center>
                                                    </div>
                                                );
                                            return null;
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* web */}
                    <div className="text-[#212529] max-[900px]:hidden block">
                        <div className="border-[#999] border-solid border-[3px] flex flex-col mt-0 items-center bg-[#efefef]">
                            <div className="text-[1.5rem] font-semibold text-[#666]">ຜົນການອອກເລກລາງວັນ</div>
                        </div>
                        <div className="border-[#dee2e6] border-solid border-t-[1px] mt-2 flex bg-[#efefef]">
                            <div className="flex flex-1 border-x-[1px] p-[5px] font-base font-medium border-[rgb(187,187,187)] border-solid justify-center border-b-[2px]">
                                ງວດວັນທີ່
                            </div>
                            {_numberList?.map((number) => {
                                return (
                                    <div className="w-[13%] flex p-[5px] justify-center font-base font-medium border-[rgb(187,187,187)] border-solid border-b-[2px] border-r-[1px]">
                                        <center>{number.name}</center>
                                    </div>
                                );
                            })}
                        </div>
                        {historyData.map((result) => {
                            return (
                                <div className="flex bg-white">
                                    <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#dee2e6] border-solid border-b-[1px] border-r-[1px] border-l-[1px]">
                                        <center className="font-medium">{getDateNameLao(result.lotto_date)}</center>
                                    </div>
                                    {Object.entries(result.results).map(([key, value]) => {
                                        if (key !== "digit2_bottom")
                                            return (
                                                <div
                                                    style={{
                                                        order: sortKey[key],
                                                    }}
                                                    className="w-[13%] flex p-[5px] justify-center font-base  border-[#dee2e6] border-solid border-b-[1px] border-r-[1px]"
                                                >
                                                    <center className="font-medium">{value}</center>
                                                </div>
                                            );
                                        return null;
                                    })}
                                </div>
                            );
                        })}
                        <Image
                            src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-1-2.png")}
                            preview={false}
                            className="mt-[10px]"
                        />
                    </div>
                </div>
            </Container>
            <div className="h-[5px] border-b-[5px] border-solid border-[#07a9e1] my-3 mt-8" />

            <center className="bg-[#07a9e1] text-[#FFF] font-semibold py-3">Copyright 2024 ©laounionvip.com</center>
        </div>
    );
}
