import { Chart } from "react-google-charts";

const defaultTime = [
    {
        time: "08:00",
    },
    {
        time: "08:01",
    },
    {
        time: "08:02",
    },
    {
        time: "08:03",
    },
    {
        time: "08:04",
    },
    {
        time: "08:05",
    },
    {
        time: "08:06",
    },
    {
        time: "08:07",
    },
    {
        time: "08:08",
    },
    {
        time: "08:09",
    },
    {
        time: "08:10",
    },
    {
        time: "08:11",
    },
    {
        time: "08:12",
    },
    {
        time: "08:13",
    },
    {
        time: "08:14",
    },
    {
        time: "08:15",
    },
    {
        time: "08:16",
    },
    {
        time: "08:17",
    },
    {
        time: "08:18",
    },
    {
        time: "08:19",
    },
    {
        time: "08:20",
    },
    {
        time: "08:21",
    },
    {
        time: "08:22",
    },
    {
        time: "08:23",
    },
    {
        time: "08:24",
    },
    {
        time: "08:25",
    },
    {
        time: "08:26",
    },
    {
        time: "08:27",
    },
    {
        time: "08:28",
    },
    {
        time: "08:29",
    },
    {
        time: "08:30",
    },
    {
        time: "08:31",
    },
    {
        time: "08:32",
    },
    {
        time: "08:33",
    },
    {
        time: "08:34",
    },
    {
        time: "08:35",
    },
    {
        time: "08:36",
    },
    {
        time: "08:37",
    },
    {
        time: "08:38",
    },
    {
        time: "08:39",
    },
    {
        time: "08:40",
    },
    {
        time: "08:41",
    },
    {
        time: "08:42",
    },
    {
        time: "08:43",
    },
    {
        time: "08:44",
    },
    {
        time: "08:45",
    },
    {
        time: "08:46",
    },
    {
        time: "08:47",
    },
    {
        time: "08:48",
    },
    {
        time: "08:49",
    },
    {
        time: "08:50",
    },
    {
        time: "08:51",
    },
    {
        time: "08:52",
    },
    {
        time: "08:53",
    },
    {
        time: "08:54",
    },
    {
        time: "08:55",
    },
    {
        time: "08:56",
    },
    {
        time: "08:57",
    },
    {
        time: "08:58",
    },
    {
        time: "08:59",
    },
    {
        time: "09:00",
    },
    {
        time: "09:01",
    },
    {
        time: "09:02",
    },
    {
        time: "09:03",
    },
    {
        time: "09:04",
    },
    {
        time: "09:05",
    },
    {
        time: "09:06",
    },
    {
        time: "09:07",
    },
    {
        time: "09:08",
    },
    {
        time: "09:09",
    },
    {
        time: "09:10",
    },
    {
        time: "09:11",
    },
    {
        time: "09:12",
    },
    {
        time: "09:13",
    },
    {
        time: "09:14",
    },
    {
        time: "09:15",
    },
    {
        time: "09:16",
    },
    {
        time: "09:17",
    },
    {
        time: "09:18",
    },
    {
        time: "09:19",
    },
    {
        time: "09:20",
    },
    {
        time: "09:21",
    },
    {
        time: "09:22",
    },
    {
        time: "09:23",
    },
    {
        time: "09:24",
    },
    {
        time: "09:25",
    },
    {
        time: "09:26",
    },
    {
        time: "09:27",
    },
    {
        time: "09:28",
    },
    {
        time: "09:29",
    },
    {
        time: "09:30",
    },
    {
        time: "09:31",
    },
    {
        time: "09:32",
    },
    {
        time: "09:33",
    },
    {
        time: "09:34",
    },
    {
        time: "09:35",
    },
    {
        time: "09:36",
    },
    {
        time: "09:37",
    },
    {
        time: "09:38",
    },
    {
        time: "09:39",
    },
    {
        time: "09:40",
    },
    {
        time: "09:41",
    },
    {
        time: "09:42",
    },
    {
        time: "09:43",
    },
    {
        time: "09:44",
    },
    {
        time: "09:45",
    },
    {
        time: "09:46",
    },
    {
        time: "09:47",
    },
    {
        time: "09:48",
    },
    {
        time: "09:49",
    },
    {
        time: "09:50",
    },
    {
        time: "09:51",
    },
    {
        time: "09:52",
    },
    {
        time: "09:53",
    },
    {
        time: "09:54",
    },
    {
        time: "09:55",
    },
    {
        time: "09:56",
    },
    {
        time: "09:57",
    },
    {
        time: "09:58",
    },
    {
        time: "09:59",
    },
    {
        time: "10:00",
    },
    {
        time: "10:01",
    },
    {
        time: "10:02",
    },
    {
        time: "10:03",
    },
    {
        time: "10:04",
    },
    {
        time: "10:05",
    },
    {
        time: "10:06",
    },
    {
        time: "10:07",
    },
    {
        time: "10:08",
    },
    {
        time: "10:09",
    },
    {
        time: "10:10",
    },
    {
        time: "10:11",
    },
    {
        time: "10:12",
    },
    {
        time: "10:13",
    },
    {
        time: "10:14",
    },
    {
        time: "10:15",
    },
    {
        time: "10:16",
    },
    {
        time: "10:17",
    },
    {
        time: "10:18",
    },
    {
        time: "10:19",
    },
    {
        time: "10:20",
    },
    {
        time: "10:21",
    },
    {
        time: "10:22",
    },
    {
        time: "10:23",
    },
    {
        time: "10:24",
    },
    {
        time: "10:25",
    },
    {
        time: "10:26",
    },
    {
        time: "10:27",
    },
    {
        time: "10:28",
    },
    {
        time: "10:29",
    },
    {
        time: "10:30",
    },
    {
        time: "10:31",
    },
    {
        time: "10:32",
    },
    {
        time: "10:33",
    },
    {
        time: "10:34",
    },
    {
        time: "10:35",
    },
    {
        time: "10:36",
    },
    {
        time: "10:37",
    },
    {
        time: "10:38",
    },
    {
        time: "10:39",
    },
    {
        time: "10:40",
    },
    {
        time: "10:41",
    },
    {
        time: "10:42",
    },
    {
        time: "10:43",
    },
    {
        time: "10:44",
    },
    {
        time: "10:45",
    },
    {
        time: "10:46",
    },
    {
        time: "10:47",
    },
    {
        time: "10:48",
    },
    {
        time: "10:49",
    },
    {
        time: "10:50",
    },
    {
        time: "10:51",
    },
    {
        time: "10:52",
    },
    {
        time: "10:53",
    },
    {
        time: "10:54",
    },
    {
        time: "10:55",
    },
    {
        time: "10:56",
    },
    {
        time: "10:57",
    },
    {
        time: "10:58",
    },
    {
        time: "10:59",
    },
    {
        time: "11:00",
    },
    {
        time: "11:01",
    },
    {
        time: "11:02",
    },
    {
        time: "11:03",
    },
    {
        time: "11:04",
    },
    {
        time: "11:05",
    },
    {
        time: "11:06",
    },
    {
        time: "11:07",
    },
    {
        time: "11:08",
    },
    {
        time: "11:09",
    },
    {
        time: "11:10",
    },
    {
        time: "11:11",
    },
    {
        time: "11:12",
    },
    {
        time: "11:13",
    },
    {
        time: "11:14",
    },
    {
        time: "11:15",
    },
    {
        time: "11:16",
    },
    {
        time: "11:17",
    },
    {
        time: "11:18",
    },
    {
        time: "11:19",
    },
    {
        time: "11:20",
    },
    {
        time: "11:21",
    },
    {
        time: "11:22",
    },
    {
        time: "11:23",
    },
    {
        time: "11:24",
    },
    {
        time: "11:25",
    },
    {
        time: "11:26",
    },
    {
        time: "11:27",
    },
    {
        time: "11:28",
    },
    {
        time: "11:29",
    },
    {
        time: "11:30",
    },
    {
        time: "11:31",
    },
    {
        time: "11:32",
    },
    {
        time: "11:33",
    },
    {
        time: "11:34",
    },
    {
        time: "11:35",
    },
    {
        time: "11:36",
    },
    {
        time: "11:37",
    },
    {
        time: "11:38",
    },
    {
        time: "11:39",
    },
    {
        time: "11:40",
    },
    {
        time: "11:41",
    },
    {
        time: "11:42",
    },
    {
        time: "11:43",
    },
    {
        time: "11:44",
    },
    {
        time: "11:45",
    },
    {
        time: "11:46",
    },
    {
        time: "11:47",
    },
    {
        time: "11:48",
    },
    {
        time: "11:49",
    },
    {
        time: "11:50",
    },
    {
        time: "11:51",
    },
    {
        time: "11:52",
    },
    {
        time: "11:53",
    },
    {
        time: "11:54",
    },
    {
        time: "11:55",
    },
    {
        time: "11:56",
    },
    {
        time: "11:57",
    },
    {
        time: "11:58",
    },
    {
        time: "11:59",
    },
    {
        time: "12:00",
    },
    {
        time: "12:01",
    },
    {
        time: "12:02",
    },
    {
        time: "12:03",
    },
    {
        time: "12:04",
    },
    {
        time: "12:05",
    },
    {
        time: "12:06",
    },
    {
        time: "12:07",
    },
    {
        time: "12:08",
    },
    {
        time: "12:09",
    },
    {
        time: "12:10",
    },
    {
        time: "12:11",
    },
    {
        time: "12:12",
    },
    {
        time: "12:13",
    },
    {
        time: "12:14",
    },
    {
        time: "12:15",
    },
    {
        time: "12:16",
    },
    {
        time: "12:17",
    },
    {
        time: "12:18",
    },
    {
        time: "12:19",
    },
    {
        time: "12:20",
    },
    {
        time: "12:21",
    },
    {
        time: "12:22",
    },
    {
        time: "12:23",
    },
    {
        time: "12:24",
    },
    {
        time: "12:25",
    },
    {
        time: "12:26",
    },
    {
        time: "12:27",
    },
    {
        time: "12:28",
    },
    {
        time: "12:29",
    },
    {
        time: "12:30",
    },
    {
        time: "12:31",
    },
    {
        time: "12:32",
    },
    {
        time: "12:33",
    },
    {
        time: "12:34",
    },
    {
        time: "12:35",
    },
    {
        time: "12:36",
    },
    {
        time: "12:37",
    },
    {
        time: "12:38",
    },
    {
        time: "12:39",
    },
    {
        time: "12:40",
    },
    {
        time: "12:41",
    },
    {
        time: "12:42",
    },
    {
        time: "12:43",
    },
    {
        time: "12:44",
    },
    {
        time: "12:45",
    },
    {
        time: "12:46",
    },
    {
        time: "12:47",
    },
    {
        time: "12:48",
    },
    {
        time: "12:49",
    },
    {
        time: "12:50",
    },
    {
        time: "12:51",
    },
    {
        time: "12:52",
    },
    {
        time: "12:53",
    },
    {
        time: "12:54",
    },
    {
        time: "12:55",
    },
    {
        time: "12:56",
    },
    {
        time: "12:57",
    },
    {
        time: "12:58",
    },
    {
        time: "12:59",
    },
    {
        time: "13:00",
    },
    {
        time: "13:01",
    },
    {
        time: "13:02",
    },
    {
        time: "13:03",
    },
    {
        time: "13:04",
    },
    {
        time: "13:05",
    },
    {
        time: "13:06",
    },
    {
        time: "13:07",
    },
    {
        time: "13:08",
    },
    {
        time: "13:09",
    },
    {
        time: "13:10",
    },
    {
        time: "13:11",
    },
    {
        time: "13:12",
    },
    {
        time: "13:13",
    },
    {
        time: "13:14",
    },
    {
        time: "13:15",
    },
    {
        time: "13:16",
    },
    {
        time: "13:17",
    },
    {
        time: "13:18",
    },
    {
        time: "13:19",
    },
    {
        time: "13:20",
    },
    {
        time: "13:21",
    },
    {
        time: "13:22",
    },
    {
        time: "13:23",
    },
    {
        time: "13:24",
    },
    {
        time: "13:25",
    },
    {
        time: "13:26",
    },
    {
        time: "13:27",
    },
    {
        time: "13:28",
    },
    {
        time: "13:29",
    },
    {
        time: "13:30",
    },
    {
        time: "13:31",
    },
    {
        time: "13:32",
    },
    {
        time: "13:33",
    },
    {
        time: "13:34",
    },
    {
        time: "13:35",
    },
    {
        time: "13:36",
    },
    {
        time: "13:37",
    },
    {
        time: "13:38",
    },
    {
        time: "13:39",
    },
    {
        time: "13:40",
    },
    {
        time: "13:41",
    },
    {
        time: "13:42",
    },
    {
        time: "13:43",
    },
    {
        time: "13:44",
    },
    {
        time: "13:45",
    },
    {
        time: "13:46",
    },
    {
        time: "13:47",
    },
    {
        time: "13:48",
    },
    {
        time: "13:49",
    },
    {
        time: "13:50",
    },
    {
        time: "13:51",
    },
    {
        time: "13:52",
    },
    {
        time: "13:53",
    },
    {
        time: "13:54",
    },
    {
        time: "13:55",
    },
    {
        time: "13:56",
    },
    {
        time: "13:57",
    },
    {
        time: "13:58",
    },
    {
        time: "13:59",
    },
    {
        time: "14:00",
    },
    {
        time: "14:01",
    },
    {
        time: "14:02",
    },
    {
        time: "14:03",
    },
    {
        time: "14:04",
    },
    {
        time: "14:05",
    },
    {
        time: "14:06",
    },
    {
        time: "14:07",
    },
    {
        time: "14:08",
    },
    {
        time: "14:09",
    },
    {
        time: "14:10",
    },
    {
        time: "14:11",
    },
    {
        time: "14:12",
    },
    {
        time: "14:13",
    },
    {
        time: "14:14",
    },
    {
        time: "14:15",
    },
    {
        time: "14:16",
    },
    {
        time: "14:17",
    },
    {
        time: "14:18",
    },
    {
        time: "14:19",
    },
    {
        time: "14:20",
    },
    {
        time: "14:21",
    },
    {
        time: "14:22",
    },
    {
        time: "14:23",
    },
    {
        time: "14:24",
    },
    {
        time: "14:25",
    },
    {
        time: "14:26",
    },
    {
        time: "14:27",
    },
    {
        time: "14:28",
    },
    {
        time: "14:29",
    },
    {
        time: "14:30",
    },
    {
        time: "14:31",
    },
    {
        time: "14:32",
    },
    {
        time: "14:33",
    },
    {
        time: "14:34",
    },
    {
        time: "14:35",
    },
    {
        time: "14:36",
    },
    {
        time: "14:37",
    },
    {
        time: "14:38",
    },
    {
        time: "14:39",
    },
    {
        time: "14:40",
    },
    {
        time: "14:41",
    },
    {
        time: "14:42",
    },
    {
        time: "14:43",
    },
    {
        time: "14:44",
    },
    {
        time: "14:45",
    },
    {
        time: "14:46",
    },
    {
        time: "14:47",
    },
    {
        time: "14:48",
    },
    {
        time: "14:49",
    },
    {
        time: "14:50",
    },
    {
        time: "14:51",
    },
    {
        time: "14:52",
    },
    {
        time: "14:53",
    },
    {
        time: "14:54",
    },
    {
        time: "14:55",
    },
    {
        time: "14:56",
    },
    {
        time: "14:57",
    },
    {
        time: "14:58",
    },
    {
        time: "14:59",
    },
    {
        time: "15:00",
    },
    {
        time: "15:01",
    },
    {
        time: "15:02",
    },
    {
        time: "15:03",
    },
    {
        time: "15:04",
    },
    {
        time: "15:05",
    },
    {
        time: "15:06",
    },
    {
        time: "15:07",
    },
    {
        time: "15:08",
    },
    {
        time: "15:09",
    },
    {
        time: "15:10",
    },
    {
        time: "15:11",
    },
    {
        time: "15:12",
    },
    {
        time: "15:13",
    },
    {
        time: "15:14",
    },
    {
        time: "15:15",
    },
    {
        time: "15:16",
    },
    {
        time: "15:17",
    },
    {
        time: "15:18",
    },
    {
        time: "15:19",
    },
    {
        time: "15:20",
    },
    {
        time: "15:21",
    },
    {
        time: "15:22",
    },
    {
        time: "15:23",
    },
    {
        time: "15:24",
    },
    {
        time: "15:25",
    },
    {
        time: "15:26",
    },
    {
        time: "15:27",
    },
    {
        time: "15:28",
    },
    {
        time: "15:29",
    },
    {
        time: "15:30",
    },
    {
        time: "15:31",
    },
    {
        time: "15:32",
    },
    {
        time: "15:33",
    },
    {
        time: "15:34",
    },
    {
        time: "15:35",
    },
    {
        time: "15:36",
    },
    {
        time: "15:37",
    },
    {
        time: "15:38",
    },
    {
        time: "15:39",
    },
    {
        time: "15:40",
    },
    {
        time: "15:41",
    },
    {
        time: "15:42",
    },
    {
        time: "15:43",
    },
    {
        time: "15:44",
    },
    {
        time: "15:45",
    },
    {
        time: "15:46",
    },
    {
        time: "15:47",
    },
    {
        time: "15:48",
    },
    {
        time: "15:49",
    },
    {
        time: "15:50",
    },
    {
        time: "15:51",
    },
    {
        time: "15:52",
    },
    {
        time: "15:53",
    },
    {
        time: "15:54",
    },
    {
        time: "15:55",
    },
    {
        time: "15:56",
    },
    {
        time: "15:57",
    },
    {
        time: "15:58",
    },
    {
        time: "15:59",
    },
    {
        time: "16:00",
    },
];
const LineChart2 = ({ result, className, style, color }) => {
    const data = [["Time", "Value"], ...(result?.map((item) => [item.time, item.value]) || [])];

    return (
        <div className={className} style={style}>
            <Chart
                width={"100%"}
                height={"400px"}
                className="min-[700px]:!w-[750px] !w-full min-w-[343px] cursor-default"
                chartType="AreaChart"
                loader={<div style={{ width: "100%", paddingLeft: 200, paddingTop: 200 }}>Loading Chart...</div>}
                data={data}
                options={{
                    // title: "Stock Price Over Time",
                    curveType: "function",
                    legend: { position: "none" },
                    hAxis: {},
                    tooltip: { trigger: "none" },
                    // width: 343,
                    // width: 750,
                    series: {
                        0: { color: color },
                    },
                    height: 400,
                }}
            />
        </div>
    );
};

export default LineChart2;
