import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Wrapper from "../../assets/images/tvdowjones/tvdowjones-warpper.png";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { DOW_JONES_TV_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
const { useBreakpoint } = Grid;

const color = "#032a67";
export default function DowJonesTvPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: DOW_JONES_TV_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: DOW_JONES_TV_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "DOW JONES TV JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/tvdowjones/tvdowjones-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col pb-20"}>
                {/* <Image
                    src={require("../../assets/images/tvdowjones/tvdowjones-warpper.png")}
                    preview={false}
                    rootClassName="absolute z-[-1] w-full max-w-[940px]"
                    style={{ height: "100%", width: "100%" }}
                /> */}

                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: "#D9D9D9" }} />
                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/tvdowjones/tvdowjones-header-2.png")}
                        rootClassName="max-w-[940px]"
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    align="center"
                    style={{
                        backgroundImage: `url(${Wrapper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: "940px",
                        width: "100%",
                    }}
                >
                    <div className="w-full max-w-[700px] pt-6">
                        <FindResult
                            date={rewardDetail?.roundName}
                            rewardData={data}
                            color={color}
                            bgColor={"#B7BCCA"}
                            lang="ENG"
                        />
                        <Image
                            src={require("../../assets/images/tvdowjones/tvdowjones-1.png")}
                            rootClassName="max-w-[800px]"
                            preview={false}
                        />

                        {/* content result */}
                        <ResultLottery
                            status={statusReward}
                            data={data}
                            date={rewardDetail?.roundName}
                            color={color}
                            bgColor={"#B7BCCA"}
                            lang="ENG"
                        />

                        {/* history */}
                        <div className="mt-[-24px]">
                            <HistoryResult data={historyData} color={color} bgColor={"#B7BCCA"} lang="ENG" />
                        </div>
                    </div>

                    <Flex
                        justify="space-between"
                        gap={20}
                        className="my-3 max-w-[700px] w-full"
                        vertical={!screens.sm}
                        align={screens.sm ? "start" : "center"}
                    >
                        <div className="border-solid border-[2px] border-[#eee] rounded-xl w-full h-full overflow-hidden max-w-[210px] max-h-[210px]">
                            <Image src={require("../../assets/images/tvdowjones/tvdowjones-2.png")} preview={false} />
                        </div>
                        <div className="border-solid border-[2px] border-[#eee] rounded-xl w-full h-full overflow-hidden max-w-[210px] max-h-[210px]">
                            <Image src={require("../../assets/images/tvdowjones/tvdowjones-3.png")} preview={false} />
                        </div>
                        <div className="border-solid border-[2px] border-[#eee] rounded-xl w-full h-full overflow-hidden max-w-[210px] max-h-[210px]">
                            <Image src={require("../../assets/images/tvdowjones/tvdowjones-4.png")} preview={false} />
                        </div>
                    </Flex>
                </Flex>
                <div className="absolute bottom-[8px] w-full max-w-[940px] ">
                    <center className="text-white font-normal text-lg py-3 bg-[#027F36]">
                        Copyright ©2024 - www.tvdowjones.com
                    </center>
                </div>
            </Container>
        </div>
    );
}
