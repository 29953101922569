import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, PlusResult } from "../../components";

const color = "#0057C1";
function VnIndexVipPage() {
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        document.title = "Dữ liệu tài chính và công cụ đầu tư chứng khoán";
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setData({
            date: "2024-08-04",
            results: {
                prize_4th_1: "03800",
                prize_4th_2: "52601",
                prize_5th_3: "8221",
                prize_4th_3: "96231",
                prize_5th_4: "9008",
                prize_2digits_4: "26",
                prize_4th_4: "28492",
                prize_5th_1: "9111",
                prize_4th_5: "20208",
                prize_5th_2: "8065",
                prize_4th_6: "74132",
                prize_7th_3: "771",
                prize_7th_1: "306",
                prize_7th_2: "261",
                prize_1st: "41500",
                prize_6th_1: "6962",
                prize_6th_6: "2548",
                prize_3rd_2: "51383",
                prize_3rd_1: "06239",
                prize_2nd: "72809",
                prize_6th_2: "1250",
                prize_6th_3: "3077",
                prize_6th_4: "3091",
                prize_6th_5: "9117",
                prize_2digits_3: "72",
                prize_2digits_2: "85",
                prize_2digits_1: "50",
            },
        });
        setLoadingHistory(false);
        setLoading(false);
    };
    return (
        <div style={{ background: "white" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/plus/vnindexvip-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative flex flex-col max-w-[900px] gap-[10px]"}>
                <Flex>
                    <Image src={require("../../assets/images/plus/vnindexvip-logo.png")} className="!h-[60px]" preview={false} />
                </Flex>
                <Flex
                    style={{
                        backgroundColor: color,
                    }}
                    className={`py-1 px-4 text-white rounded-ss-lg rounded-se-lg`}
                    justify="space-between"
                >
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Vĩ mô</Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Thị trường</Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Ngành</Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Doanh nghiệp</Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Phái sinh </Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Công cụ đầu tư</Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">Truy xuất Dữ liệu</Flex>
                    <Flex className="hover:text-[#4183c4] cursor-pointer">TIN TỨC</Flex>
                </Flex>
                <Flex>
                    <PlusResult
                        color={color}
                        date={data?.date}
                        resultNumber={"12345"}
                        status={"ANNOUNCED"}
                        value={{
                            open: 5937.94,
                            close: 5900.56,
                            change: -37.78,
                            change_percent: -0.64,
                            high: 5977.05,
                            low: 5925.22,
                            total_value: 60966000,
                            total_volume: 901700,
                        }}
                        closeNumber={5930.23}
                    />
                </Flex>
            </Container>
        </div>
    );
}

export default VnIndexVipPage;
