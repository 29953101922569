import { createApi } from "@reduxjs/toolkit/query/react";
import { GET_HISTORY_REWARD_LOTTO, GET_REWARD_LOTTO } from "../constants/PathConfig";
import FetchService from "./FetchService";

const RewardService = createApi({
    reducerPath: "reward_service",
    baseQuery: FetchService,
    endpoints: (builder) => ({
        getRewardLotto: builder.mutation({
            query: ({ huayId }) => ({
                url: GET_REWARD_LOTTO,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: {
                    huayId: huayId,
                },
            }),
        }),
        getHistoryRewardLotto: builder.mutation({
            query: ({ huayId }) => ({
                url: GET_HISTORY_REWARD_LOTTO,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: {
                    huayId: huayId,
                },
            }),
        }),
    }),
});

export const { useGetRewardLottoMutation, useGetHistoryRewardLottoMutation } = RewardService;

export default RewardService;
