import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import FindResultHeader from "../../assets/images/minhngocstar/minhngocstar-find-result-header.png";
import WarperResult from "../../assets/images/minhngocstar/minhngocstar-result-bg.png";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { MINHNGOC_STAR_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const { useBreakpoint } = Grid;

const color = "#e80000";
const warper_bg = "#e1e3e4";
const color_bg = "white";
export default function MinhngocStarPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: MINHNGOC_STAR_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: MINHNGOC_STAR_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "Hà Nội ngôi sao JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/minhngocstar/minhngocstar-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex
                    align={!screens.sm ? "start" : "center"}
                    style={{
                        background: "white",
                        maxWidth: 1024,
                        width: "100%",
                        height: "100%",
                    }}
                    vertical={!screens.sm}
                >
                    <Image
                        src={require("../../assets/images/minhngocstar/minhngocstar-header-2.png")}
                        rootClassName="max-w-[400px] mt-2"
                        preview={false}
                    />

                    <Image src={require("../../assets/images/minhngocstar/minhngocstar-1.gif")} preview={false} />
                </Flex>

                <Flex className="w-full my-2">
                    <Flex justify="center" className="py-2 px-8 bg-[#194E82] text-white text-base">
                        Thông báo
                    </Flex>
                    <Flex
                        flex={1}
                        justify="space-between"
                        className="border-sold border-[1px] border-[#379999] bg-white px-2 text-base"
                        align="center"
                    >
                        <div>Kỳ mở thưởng xổ số Đồng Tháp tiếp theo ngày</div>
                        <Flex align="center">
                            <Image
                                src={require("../../assets/images/minhngocstar/minhngocstar-arrow.png")}
                                rootClassName="opacity-[0.2]"
                                preview={false}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                    }}
                >
                    <div className="pt-2">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="hidden md:block md:w-full md:max-w-[200px] order-1 md:order-0" vertical gap={10}>
                                <Image
                                    src={require("../../assets/images/minhngocstar/minhngocstar-1.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />

                                <Image
                                    src={require("../../assets/images/minhngocstar/minhngocstar-3.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                            </Flex>
                            <Flex className="w-full order-0 md:order-1 sm:mx-0" vertical>
                                <Flex vertical gap={10} className="mb-3 px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/minhngocstar/minhngocstar-2.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                                <div className="md:hidden block">
                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                        lang="VIM"
                                    />
                                </div>

                                <div className="px-3 sm:px-0">
                                    <Flex
                                        style={{
                                            backgroundImage: `url(${WarperResult})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "100% 100%",
                                            width: "100%",
                                            height: 45,
                                            fontSize: 18,
                                            color: "white",
                                        }}
                                        justify="center"
                                        align="center"
                                    >
                                        Hà Nội ngôi sao:
                                        {rewardDetail?.roundName}
                                    </Flex>
                                </div>
                                {/* content result */}
                                <ResultLottery
                                    status={statusReward}
                                    data={data}
                                    date={rewardDetail?.roundName}
                                    color={color}
                                    bgColor={color_bg}
                                    lang="VIM"
                                />
                                {/* history */}
                                <div className="my-0">
                                    <HistoryResult data={historyData} color={color} bgColor={color_bg} lang="VIM" />
                                </div>
                                {/* <Flex vertical gap={10} className="px-3 sm:px-0">
                                    <Image
                                        src={require("../../assets/images/minhngocstar/minhngocstar-6.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngocstar/minhngocstar-7.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex> */}
                            </Flex>

                            <Flex className="w-full md:max-w-[330px] order-1 md:order-0" vertical>
                                <Flex
                                    style={{
                                        backgroundImage: `url(${FindResultHeader})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "100% 100%",
                                        width: "100%",
                                        color: "white",
                                        padding: "10px 0",
                                        fontSize: 14,
                                        fontWeight: "bold",
                                    }}
                                    align="center"
                                    justify="center"
                                >
                                    <Image
                                        src={require("../../assets/images/minhngoctv/minhngoctv-find-result-gif.gif")}
                                        preview={false}
                                    />
                                    BÌNH CHỌN CẶP SỐ ĐẸP
                                </Flex>
                                <Image
                                    src={require("../../assets/images/minhngoctv/minhngoctv-8.gif")}
                                    rootClassName="w-full mb-2"
                                    preview={false}
                                />

                                <div className="md:block hidden">
                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                        lang="VIM"
                                    />
                                </div>
                                <Flex vertical gap={10}>
                                    <Image
                                        src={require("../../assets/images/minhngocstar/minhngocstar-4.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/minhngocstar/minhngocstar-5.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>

                <center className="text-white font-normal text-lg bg-[#5D0000] w-full py-3 mt-3">www.minhngocstar.com</center>
            </Container>
        </div>
    );
}
