import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Wrapper from "../../assets/images/laocitizen/laocitizen-warper.png";
import Container from "../../components/Container";
import { getDateNameLao, getResultTime } from "../../utils/ConvertDate";
const { useBreakpoint } = Grid;

const color = "#b8860b";
const warper_bg = "#444";
const color_bg = "white";
export default function LaoCitizenOldPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState(undefined);
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const listName = {
        digit2_top: {
            name: "ເລກ 2 ໂຕ",
            length: 2,
        },
        digit5: {
            name: "ເລກ 5 ໂຕ",
            length: 5,
        },
        digit1: {
            name: "ເລກ 1 ໂຕ",
            length: 1,
        },
        digit3: {
            name: "ເລກ 3 ໂຕ",
            length: 3,
        },
        digit2_special: {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
        },
        digit4: {
            name: "ເລກ 4 ໂຕ",
            length: 4,
        },
        digit2_bottom: {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
        },
    };

    const sortKey = {
        digit5: 0,
        digit4: 1,
        digit3: 2,
        digit2_special: 5,
        digit2_top: 3,
        digit1: 4,
        digit2_bottom: 5,
    };

    const [_numberList] = useState([
        {
            name: "ເລກ 5 ໂຕ",
            length: 5,
            result: "",
        },
        {
            name: "ເລກ 4 ໂຕ",
            length: 4,
            result: "",
        },
        {
            name: "ເລກ 3 ໂຕ",
            length: 3,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕ",
            length: 2,
            result: "",
        },
        {
            name: "ເລກ 1 ໂຕ",
            length: 1,
            result: "",
        },
    ]);

    const onFetch = () => {
        // fetch data
        setData({
            start_spin: "2024-07-30 07:40:00",
            lotto_date: "2024-07-30",
            show_result: "2024-07-30 07:45:00",
            results: {
                digit2_top: "50",
                digit5: "24150",
                digit1: "0",
                digit3: "150",
                digit2_special: "70",
                digit4: "4150",
                digit2_bottom: "24",
            },
        });
        setHistoryData([
            {
                lotto_date: "2024-07-29",
                results: {
                    digit2_top: "78",
                    digit5: "69178",
                    digit1: "8",
                    digit3: "178",
                    digit2_special: "23",
                    digit4: "9178",
                    digit2_bottom: "69",
                },
            },
            {
                lotto_date: "2024-07-28",
                results: {
                    digit2_top: "36",
                    digit5: "98636",
                    digit1: "6",
                    digit3: "636",
                    digit2_special: "90",
                    digit4: "8636",
                    digit2_bottom: "98",
                },
            },
            {
                lotto_date: "2024-07-27",
                results: {
                    digit2_top: "37",
                    digit5: "21837",
                    digit1: "7",
                    digit3: "837",
                    digit2_special: "65",
                    digit4: "1837",
                    digit2_bottom: "21",
                },
            },
            {
                lotto_date: "2024-07-26",
                results: {
                    digit2_top: "54",
                    digit5: "60954",
                    digit1: "4",
                    digit3: "954",
                    digit2_special: "35",
                    digit4: "0954",
                    digit2_bottom: "60",
                },
            },
            {
                lotto_date: "2024-07-25",
                results: {
                    digit2_top: "35",
                    digit5: "81535",
                    digit1: "5",
                    digit3: "535",
                    digit2_special: "12",
                    digit4: "1535",
                    digit2_bottom: "81",
                },
            },
            {
                lotto_date: "2024-07-24",
                results: {
                    digit2_top: "40",
                    digit5: "73340",
                    digit1: "0",
                    digit3: "340",
                    digit2_special: "68",
                    digit4: "3340",
                    digit2_bottom: "73",
                },
            },
            {
                lotto_date: "2024-07-23",
                results: {
                    digit2_top: "55",
                    digit5: "24655",
                    digit1: "5",
                    digit3: "655",
                    digit2_special: "58",
                    digit4: "4655",
                    digit2_bottom: "24",
                },
            },
            {
                lotto_date: "2024-07-22",
                results: {
                    digit2_top: "39",
                    digit5: "81439",
                    digit1: "9",
                    digit3: "439",
                    digit2_special: "71",
                    digit4: "1439",
                    digit2_bottom: "81",
                },
            },
            {
                lotto_date: "2024-07-21",
                results: {
                    digit2_top: "16",
                    digit5: "96816",
                    digit1: "6",
                    digit3: "816",
                    digit2_special: "07",
                    digit4: "6816",
                    digit2_bottom: "96",
                },
            },
            {
                lotto_date: "2024-07-20",
                results: {
                    digit2_top: "92",
                    digit5: "32392",
                    digit1: "2",
                    digit3: "392",
                    digit2_special: "98",
                    digit4: "2392",
                    digit2_bottom: "32",
                },
            },
            {
                lotto_date: "2024-07-19",
                results: {
                    digit2_top: "19",
                    digit5: "61419",
                    digit1: "9",
                    digit3: "419",
                    digit2_special: "06",
                    digit4: "1419",
                    digit2_bottom: "61",
                },
            },
            {
                lotto_date: "2024-07-18",
                results: {
                    digit2_top: "27",
                    digit5: "05727",
                    digit1: "7",
                    digit3: "727",
                    digit2_special: "94",
                    digit4: "5727",
                    digit2_bottom: "05",
                },
            },
            {
                lotto_date: "2024-07-17",
                results: {
                    digit2_top: "70",
                    digit5: "87970",
                    digit1: "0",
                    digit3: "970",
                    digit2_special: "49",
                    digit4: "7970",
                    digit2_bottom: "87",
                },
            },
            {
                lotto_date: "2024-07-16",
                results: {
                    digit2_top: "55",
                    digit5: "76155",
                    digit1: "5",
                    digit3: "155",
                    digit2_special: "76",
                    digit4: "6155",
                    digit2_bottom: "76",
                },
            },
            {
                lotto_date: "2024-07-15",
                results: {
                    digit2_top: "35",
                    digit5: "01435",
                    digit1: "5",
                    digit3: "435",
                    digit4: "1435",
                    digit2_bottom: "01",
                },
            },
            {
                lotto_date: "2024-07-14",
                results: {
                    digit2_top: "50",
                    digit5: "96450",
                    digit1: "0",
                    digit3: "450",
                    digit2_special: "92",
                    digit4: "6450",
                    digit2_bottom: "96",
                },
            },
            {
                lotto_date: "2024-07-13",
                results: {
                    digit2_top: "17",
                    digit5: "73217",
                    digit1: "7",
                    digit3: "217",
                    digit2_special: "61",
                    digit4: "3217",
                    digit2_bottom: "73",
                },
            },
            {
                lotto_date: "2024-07-12",
                results: {
                    digit2_top: "37",
                    digit5: "26037",
                    digit1: "7",
                    digit3: "037",
                    digit2_special: "81",
                    digit4: "6037",
                    digit2_bottom: "26",
                },
            },
            {
                lotto_date: "2024-07-11",
                results: {
                    digit2_top: "95",
                    digit5: "84395",
                    digit1: "5",
                    digit3: "395",
                    digit2_special: "91",
                    digit4: "4395",
                    digit2_bottom: "84",
                },
            },
            {
                lotto_date: "2024-07-10",
                results: {
                    digit2_top: "16",
                    digit5: "05316",
                    digit1: "6",
                    digit3: "316",
                    digit2_special: "02",
                    digit4: "5316",
                    digit2_bottom: "05",
                },
            },
            {
                lotto_date: "2024-07-09",
                results: {
                    digit2_top: "21",
                    digit5: "85321",
                    digit1: "1",
                    digit3: "321",
                    digit2_special: "04",
                    digit4: "5321",
                    digit2_bottom: "85",
                },
            },
            {
                lotto_date: "2024-07-08",
                results: {
                    digit2_top: "03",
                    digit5: "88903",
                    digit1: "3",
                    digit3: "903",
                    digit2_special: "67",
                    digit4: "8903",
                    digit2_bottom: "88",
                },
            },
            {
                lotto_date: "2024-07-07",
                results: {
                    digit2_top: "36",
                    digit5: "12436",
                    digit1: "6",
                    digit3: "436",
                    digit2_special: "09",
                    digit4: "2436",
                    digit2_bottom: "12",
                },
            },
            {
                lotto_date: "2024-07-06",
                results: {
                    digit2_top: "13",
                    digit5: "48113",
                    digit1: "3",
                    digit3: "113",
                    digit2_special: "76",
                    digit4: "8113",
                    digit2_bottom: "48",
                },
            },
            {
                lotto_date: "2024-07-05",
                results: {
                    digit2_top: "38",
                    digit5: "72138",
                    digit1: "8",
                    digit3: "138",
                    digit2_special: "69",
                    digit4: "2138",
                    digit2_bottom: "72",
                },
            },
            {
                lotto_date: "2024-07-04",
                results: {
                    digit2_top: "64",
                    digit5: "99764",
                    digit1: "4",
                    digit3: "764",
                    digit2_special: "11",
                    digit4: "9764",
                    digit2_bottom: "99",
                },
            },
            {
                lotto_date: "2024-07-03",
                results: {
                    digit2_top: "08",
                    digit5: "79008",
                    digit1: "8",
                    digit3: "008",
                    digit2_special: "40",
                    digit4: "9008",
                    digit2_bottom: "79",
                },
            },
            {
                lotto_date: "2024-07-02",
                results: {
                    digit2_top: "92",
                    digit5: "40192",
                    digit1: "2",
                    digit3: "192",
                    digit2_special: "72",
                    digit4: "0192",
                    digit2_bottom: "40",
                },
            },
            {
                lotto_date: "2024-07-01",
                results: {
                    digit2_top: "78",
                    digit5: "25878",
                    digit1: "8",
                    digit3: "878",
                    digit2_special: "45",
                    digit4: "5878",
                    digit2_bottom: "25",
                },
            },
        ]);
        setLoadingHistory(false);
        setLoading(false);
    };

    const RenderResult = ({ number }) => {
        return (
            <Flex
                align="center"
                justify="center"
                className="text-[4rem] font-extrabold text-white border-solid border-[2px] border-white rounded-[10px] w-[64px] h-[79px] leading-[1.2] bg-[#696969]"
            >
                {number}
            </Flex>
        );
    };

    useEffect(() => {
        document.title = "ປະຊາກອນລາວ";
        onFetch();
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/laocitizen/laocitizen-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1100px]"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/laocitizen/laocitizen-header.png")}
                        rootClassName="max-w-[1024px]"
                        style={{ width: 1024 }}
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        backgroundImage: `url(${Wrapper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                    }}
                >
                    <div className="pt-[10px] px-3 min-[990px]:px-5">
                        <Image
                            src={require("../../assets/images/laocitizen/laocitizen--1.png")}
                            rootClassName="w-full"
                            preview={false}
                        />

                        <Flex gap={10} className="min-[990px]:flex-row flex-col">
                            <Flex className="w-full min-[990px]:max-w-[440px] order-0 min-[990px]:order-1" vertical gap={10}>
                                <Flex className="border-solid border-[4px] bg-[#cbcbcb]" style={{ borderColor: color }} vertical>
                                    <center className="pt-4">
                                        <div className="text-[1.3rem] text-[#032c98] font-semibold text-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji]">
                                            ງວດປະຈຳວັນທີ່ {getDateNameLao(data?.lotto_date)}
                                        </div>
                                        <Flex gap={5} justify="center" className="my-2">
                                            {data?.results?.digit5?.split("")?.map((item, index) => {
                                                return <RenderResult number={item} key={index} />;
                                            })}
                                        </Flex>
                                        <div className="">
                                            <h3 className="text-[1.1rem] text-[#032c98] font-semibold">
                                                ເວລາອອກເລກ {getResultTime(data?.show_result)}
                                            </h3>
                                        </div>
                                    </center>
                                    <Flex className="py-[10px] px-[50px]">
                                        <Flex vertical flex={1} gap={2}>
                                            {Object.entries(data?.results || {}).map(([key, value]) => {
                                                if (key !== "digit2_special" && key !== "digit2_bottom") {
                                                    return (
                                                        <Flex
                                                            style={{
                                                                order: sortKey[key],
                                                                color: "#000",
                                                            }}
                                                            gap={2}
                                                        >
                                                            <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium bg-[#eee] rounded-ss-[10px] rounded-es-[10px]">
                                                                {listName[key]?.name}
                                                            </div>
                                                            <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium bg-[#eee] rounded-ee-[10px] rounded-se-[10px]">
                                                                {value ? (
                                                                    <div>{value}</div>
                                                                ) : (
                                                                    Array.from(Array(listName[key]?.length).keys()).map(() => (
                                                                        <div>-</div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </Flex>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/laocitizen/laocitizen--2.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laocitizen/laocitizen--3.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                            <Flex className="w-full order-1 min-[990px]:order-0 min-[990px]:mx-0" vertical>
                                {/* history */}
                                <Flex vertical className="min-h-[150px">
                                    <Flex
                                        className="border-solid border-[3px] bg-[#032c9a] text-white"
                                        style={{ borderColor: "#032c9a" }}
                                        vertical
                                    >
                                        <center className="text-[26px] font-medium">ຜົນການອອກເລກລາງວັນ</center>
                                    </Flex>
                                    <div className="border-[#999] border-solid border-t-[1px] mt-2 flex bg-white z-[1]">
                                        <div className="flex flex-1 border-x-[1px] p-[5px] font-base border-[#999] border-solid justify-center border-b-[1px]">
                                            ງວດວັນທີ່
                                        </div>
                                        {_numberList?.map((number) => {
                                            return (
                                                <div className="w-[15%] flex p-[5px] justify-center font-base border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                    <center>{number.name}</center>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {historyData.map((result) => {
                                        return (
                                            <Flex className="bg-white z-[1] hover:bg-[#EFEFEF]">
                                                <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#999] border-solid border-b-[1px] border-r-[1px] border-l-[1px] hover:text-[#212529]">
                                                    <center className="font-normal">{getDateNameLao(result?.lotto_date)}</center>
                                                </div>
                                                {Object.entries(result.results).map(([key, value]) => {
                                                    if (key !== "digit2_special" && key !== "digit2_bottom")
                                                        return (
                                                            <div
                                                                style={{
                                                                    order: sortKey[key],
                                                                }}
                                                                className="w-[15%] flex p-[5px] justify-center font-base  border-[#999] border-solid border-b-[1px] border-r-[1px]"
                                                            >
                                                                <center className="font-normal hover:text-[#212529]">
                                                                    {value}
                                                                </center>
                                                            </div>
                                                        );
                                                    return null;
                                                })}
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <div className="px-3 min-[990px]:px-5">
                        <Divider className="bg-[#FFF] h-[2px] mb-[10px]" />
                    </div>

                    <center className="text-white font-normal text-lg mb-[10px]">Copyright ©2024 - laocitizen.com</center>
                </Flex>
            </Container>
        </div>
    );
}
