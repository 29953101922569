import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { XO_SO_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
const { useBreakpoint } = Grid;

const color = "#e80000";
const warper_bg = "white";
const color_bg = "white";
export default function XoSoHdPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: XO_SO_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: XO_SO_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "Hà nội hd";
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setData({
            prize_4th_1: "67904",
            prize_4th_2: "20995",
            prize_5th_3: "7597",
            prize_4th_3: "95213",
            prize_5th_4: "0823",
            prize_2digits_4: "83",
            prize_4th_4: "39484",
            prize_5th_1: "4788",
            prize_4th_5: "15537",
            prize_5th_2: "7354",
            prize_4th_6: "01121",
            prize_7th_3: "766",
            prize_7th_1: "987",
            prize_7th_2: "675",
            prize_1st: "74135",
            prize_6th_1: "5283",
            prize_6th_6: "1775",
            prize_3rd_2: "80280",
            prize_3rd_1: "03468",
            prize_2nd: "95310",
            prize_6th_2: "4217",
            prize_6th_3: "5319",
            prize_6th_4: "1265",
            prize_6th_5: "4303",
            prize_2digits_3: "55",
            prize_2digits_2: "57",
            prize_2digits_1: "57",
        });
        setLoadingHistory(false);
        setLoading(false);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/xosohd/xosohd-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1040px]"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />

                {/* header logo */}
                <Flex justify="center" vertical gap={5}>
                    <Image
                        src={require("../../assets/images/xosohd/xosohd--header-top.png")}
                        rootClassName="max-w-[1040px]"
                        style={{ width: 1040 }}
                        preview={false}
                    />
                    <Image
                        src={require("../../assets/images/xosohd/xosohd-header.png")}
                        rootClassName="max-w-[1040px]"
                        style={{ width: 1040 }}
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "white",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1040,
                        width: "100%",
                    }}
                >
                    <div className="pt-3">
                        <Flex gap={5}>
                            <Flex
                                className="hidden min-[830px]:block min-[830px]:w-full min-[830px]:max-w-[200px] order-1 min-[830px]:order-0"
                                vertical
                                gap={10}
                            >
                                <Image
                                    src={require("../../assets/images/xosohd/xosohd-1.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosohd/xosohd-2.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                            </Flex>
                            <Flex className="w-full order-0 min-[830px]:order-1 sm:mx-0" vertical>
                                <Flex vertical gap={10} className="mb-3 px-[5px] sm:px-0">
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-3.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-4.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                                <div className="px-[5px] sm:px-0">
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-5.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </div>
                                {/* content result */}
                                <Flex className="min-[830px]:px-0 px-[5px]">
                                    <Flex vertical className="w-[20%] text-sm">
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Đặc biệt
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải nhất
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Giải nhì
                                        </div>
                                        <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải ba
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0  font-medium">
                                            Giải tư
                                        </div>
                                        <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải năm
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Giải sáu
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải bảy
                                        </div>
                                    </Flex>
                                    <Flex flex={1} vertical>
                                        {/* biet */}
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-r-0 border-[1.5px] w-full border-b-0 text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                            <div>{data.prize_1st}</div>
                                        </div>
                                        {/* nhat */}
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-r-0 border-[1.5px] w-full border-b-0 bg-[#efefef] text-[#000] font-extrabold text-[20px]">
                                            <div>{data.prize_2nd}</div>
                                        </div>
                                        {/* nhi */}
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            <Flex className="h-full" flex={1}>
                                                <Flex
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {data.prize_3rd_1}
                                                </Flex>
                                                <Flex
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {data.prize_3rd_2}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* ba */}
                                        <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <Flex className="h-full" flex={1} wrap="wrap">
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_1}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_2}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_3}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_4}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_5}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_6}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* tu */}
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-y-0 font-medium">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_3}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_4}
                                                </div>
                                            </div>
                                        </div>
                                        {/* nam */}
                                        <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <Flex className="h-full" flex={1} wrap="wrap">
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_1}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_2}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_3}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_4}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_5}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_6}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* sau */}
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_7th_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_7th_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_7th_3}
                                                </div>
                                            </div>
                                        </div>
                                        {/* bay */}
                                        <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_3}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_4}
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                </Flex>

                                <Flex vertical gap={10} className="px-[5px] sm:px-0 mt-[3px]">
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd--result-footer.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-7.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-8.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-9.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-10.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                className="min-[830px]:max-w-[300px] w-[calc(100%-530px)] min-[700px]:flex hidden order-1 min-[830px]:order-0"
                                vertical
                            >
                                <Flex vertical gap={10} className="px-[5px] sm:px-0">
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-12.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-13.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-14.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-15.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosohd/xosohd-16.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <div>
                                        <Image
                                            src={require("../../assets/images/xosohd/xosohd-17.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                        <Image
                                            src={require("../../assets/images/xosohd/xosohd-18.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                        <Image
                                            src={require("../../assets/images/xosohd/xosohd-19.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                    </div>
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
                <Divider className="bg-[#FFBB04] h-[10px] my-0 mt-5" />
            </Container>
        </div>
    );
}
