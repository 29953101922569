import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { getDateNameLao } from "../../utils/ConvertDate";

const color = "#BA0909";
const color_bg = "white";
const { useBreakpoint } = Grid;

export default function LaoStarVipOldPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState(undefined);
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const listName = {
        digit2_top: {
            name: "ເລກ 2 ໂຕເທິ່ງ",
            length: 2,
        },
        digit5: {
            name: "ເລກ 5 ໂຕ",
            length: 5,
        },
        digit1: {
            name: "ເລກ 1 ໂຕ",
            length: 1,
        },
        digit3: {
            name: "ເລກ 3 ໂຕ",
            length: 3,
        },
        digit2_special: {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
        },
        digit4: {
            name: "ເລກ 4 ໂຕ",
            length: 4,
        },
        digit2_bottom: {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
        },
    };

    const sortKey = {
        digit5: 0,
        digit4: 1,
        digit3: 2,
        digit2_special: 5,
        digit2_top: 3,
        digit1: 4,
        digit2_bottom: 5,
    };

    const [_numberList] = useState([
        {
            name: "ເລກ 5 ໂຕ",
            length: 5,
            result: "",
        },
        {
            name: "ເລກ 3 ໂຕ",
            length: 3,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕເທິ່ງ	",
            length: 2,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
            result: "",
        },
    ]);

    const onFetch = () => {
        // fetch data
        setData({
            start_spin: "2024-07-28 21:50:00",
            lotto_date: "2024-07-28",
            show_result: "2024-07-28 22:00:00",
            results: {
                digit2_top: "49",
                digit5: "65349",
                digit3: "349",
                digit4: "5349",
                digit2_bottom: "65",
            },
        });
        setHistoryData([
            {
                lotto_date: "2024-07-27",
                results: {
                    digit2_top: "73",
                    digit5: "30573",
                    digit3: "573",
                    digit4: "0573",
                    digit2_bottom: "30",
                },
            },
            {
                lotto_date: "2024-07-26",
                results: {
                    digit2_top: "07",
                    digit5: "56907",
                    digit3: "907",
                    digit4: "6907",
                    digit2_bottom: "56",
                },
            },
            {
                lotto_date: "2024-07-25",
                results: {
                    digit2_top: "63",
                    digit5: "31463",
                    digit3: "463",
                    digit4: "1463",
                    digit2_bottom: "31",
                },
            },
            {
                lotto_date: "2024-07-24",
                results: {
                    digit2_top: "97",
                    digit5: "07497",
                    digit3: "497",
                    digit4: "7497",
                    digit2_bottom: "07",
                },
            },
            {
                lotto_date: "2024-07-23",
                results: {
                    digit2_top: "51",
                    digit5: "92151",
                    digit3: "151",
                    digit4: "2151",
                    digit2_bottom: "92",
                },
            },
            {
                lotto_date: "2024-07-22",
                results: {
                    digit2_top: "57",
                    digit5: "29257",
                    digit3: "257",
                    digit4: "9257",
                    digit2_bottom: "29",
                },
            },
            {
                lotto_date: "2024-07-21",
                results: {
                    digit2_top: "39",
                    digit5: "29939",
                    digit3: "939",
                    digit4: "9939",
                    digit2_bottom: "29",
                },
            },
            {
                lotto_date: "2024-07-20",
                results: {
                    digit2_top: "48",
                    digit5: "13848",
                    digit3: "848",
                    digit4: "3848",
                    digit2_bottom: "13",
                },
            },
            {
                lotto_date: "2024-07-19",
                results: {
                    digit2_top: "84",
                    digit5: "65384",
                    digit3: "384",
                    digit4: "5384",
                    digit2_bottom: "65",
                },
            },
            {
                lotto_date: "2024-07-18",
                results: {
                    digit2_top: "09",
                    digit5: "02109",
                    digit3: "109",
                    digit4: "2109",
                    digit2_bottom: "02",
                },
            },
            {
                lotto_date: "2024-07-17",
                results: {
                    digit2_top: "04",
                    digit5: "38804",
                    digit3: "804",
                    digit4: "8804",
                    digit2_bottom: "38",
                },
            },
            {
                lotto_date: "2024-07-16",
                results: {
                    digit2_top: "71",
                    digit5: "40871",
                    digit3: "871",
                    digit4: "0871",
                    digit2_bottom: "40",
                },
            },
            {
                lotto_date: "2024-07-15",
                results: {
                    digit2_top: "98",
                    digit5: "03798",
                    digit3: "798",
                    digit4: "3798",
                    digit2_bottom: "03",
                },
            },
            {
                lotto_date: "2024-07-14",
                results: {
                    digit2_top: "66",
                    digit5: "06766",
                    digit3: "766",
                    digit4: "6766",
                    digit2_bottom: "06",
                },
            },
            {
                lotto_date: "2024-07-13",
                results: {
                    digit2_top: "58",
                    digit5: "35758",
                    digit3: "758",
                    digit4: "5758",
                    digit2_bottom: "35",
                },
            },
            {
                lotto_date: "2024-07-12",
                results: {
                    digit2_top: "69",
                    digit5: "11969",
                    digit3: "969",
                    digit4: "1969",
                    digit2_bottom: "11",
                },
            },
            {
                lotto_date: "2024-07-11",
                results: {
                    digit2_top: "91",
                    digit5: "07491",
                    digit3: "491",
                    digit4: "7491",
                    digit2_bottom: "07",
                },
            },
            {
                lotto_date: "2024-07-10",
                results: {
                    digit2_top: "33",
                    digit5: "03933",
                    digit3: "933",
                    digit4: "3933",
                    digit2_bottom: "03",
                },
            },
            {
                lotto_date: "2024-07-09",
                results: {
                    digit2_top: "16",
                    digit5: "81316",
                    digit3: "316",
                    digit4: "1316",
                    digit2_bottom: "81",
                },
            },
            {
                lotto_date: "2024-07-08",
                results: {
                    digit2_top: "05",
                    digit5: "91605",
                    digit3: "605",
                    digit4: "1605",
                    digit2_bottom: "91",
                },
            },
            {
                lotto_date: "2024-07-07",
                results: {
                    digit2_top: "32",
                    digit5: "92532",
                    digit3: "532",
                    digit4: "2532",
                    digit2_bottom: "92",
                },
            },
            {
                lotto_date: "2024-07-06",
                results: {
                    digit2_top: "23",
                    digit5: "36423",
                    digit3: "423",
                    digit4: "6423",
                    digit2_bottom: "36",
                },
            },
            {
                lotto_date: "2024-07-05",
                results: {
                    digit2_top: "68",
                    digit5: "11868",
                    digit3: "868",
                    digit4: "1868",
                    digit2_bottom: "11",
                },
            },
            {
                lotto_date: "2024-07-04",
                results: {
                    digit2_top: "66",
                    digit5: "75466",
                    digit3: "466",
                    digit4: "5466",
                    digit2_bottom: "75",
                },
            },
            {
                lotto_date: "2024-07-03",
                results: {
                    digit2_top: "33",
                    digit5: "90533",
                    digit3: "533",
                    digit4: "0533",
                    digit2_bottom: "90",
                },
            },
            {
                lotto_date: "2024-07-02",
                results: {
                    digit2_top: "82",
                    digit5: "33082",
                    digit3: "082",
                    digit4: "3082",
                    digit2_bottom: "33",
                },
            },
            {
                lotto_date: "2024-07-01",
                results: {
                    digit2_top: "76",
                    digit5: "17676",
                    digit3: "676",
                    digit4: "7676",
                    digit2_bottom: "17",
                },
            },
            {
                lotto_date: "2024-06-30",
                results: {
                    digit2_top: "72",
                    digit5: "19672",
                    digit3: "672",
                    digit4: "9672",
                    digit2_bottom: "19",
                },
            },
            {
                lotto_date: "2024-06-29",
                results: {
                    digit2_top: "56",
                    digit5: "58656",
                    digit3: "656",
                    digit4: "8656",
                    digit2_bottom: "58",
                },
            },
        ]);
        setLoadingHistory(false);
        setLoading(false);
    };

    useEffect(() => {
        document.title = "ລາວສະຕ່າ VIP";
        onFetch();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/lao-star-vip/lao-star-vip-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"!max-w-[1000px]"}>
                <Image src={require("../../assets/images/lao-star-vip/lao-star-vip-header.png")} preview={false} />
                <Flex gap={10} className="bg-white pb-[10px]">
                    <div
                        style={{
                            height: "100vh",
                            width: "100vw",
                            position: "fixed",
                            left: 0,
                            top: 0,
                            zIndex: -2,
                            backgroundImage: `url(${require("../../assets/images/lao-star-vip/lao-star-vip--warper.png")})`,
                        }}
                    />

                    <div className="w-full">
                        {data && (
                            <>
                                {/* result */}
                                <Flex className="bg-white" vertical>
                                    <center className="pt-5">
                                        <div className="text-[21px] font-semibold text-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji]">
                                            ງວດປະຈຳວັນທີ່ {getDateNameLao(data?.lotto_date)}
                                        </div>
                                        <div className="text-[4rem] font-bold tracking-[10px]">{data.results?.digit5}</div>
                                        <div className="text-[24px] font-medium">
                                            <h3 data-v-11dd4c9b="">ລາວສະຕ່າ VIP</h3>
                                        </div>
                                        <div className="text-[24px] font-medium">
                                            <h3 data-v-11dd4c9b="">ເລີ່ມຕົ້ນເວລາ 22:00</h3>
                                        </div>
                                    </center>
                                    <Flex className="py-[20px] px-[50px]">
                                        <Flex className="border-[#999] border-solid border-t-[1px]" vertical flex={1}>
                                            {Object.entries(data.results).map(([key, value]) => {
                                                if (key !== "digit2_special" && key !== "digit1") {
                                                    return (
                                                        <div
                                                            className="flex"
                                                            style={{
                                                                order: sortKey[key],
                                                                background: "white",
                                                                color: "#000",
                                                            }}
                                                        >
                                                            <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium border-[#999] border-solid border-b-[1px] border-l-[1px] border-r-[1px]">
                                                                {listName[key]?.name}
                                                            </div>
                                                            <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                                {value ? (
                                                                    <div>{value}</div>
                                                                ) : (
                                                                    Array.from(Array(listName[key]?.length).keys()).map(() => (
                                                                        <div>-</div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Flex>
                                    </Flex>
                                </Flex>
                                {/* history */}
                                <Flex vertical className="min-h-[150px] mt-[10px]">
                                    <Flex className=" bg-white" vertical>
                                        <center className="text-[26px] font-semibold">ຜົນການອອກເລກລາງວັນ</center>
                                    </Flex>
                                    <div className="border-[#999] border-solid border-t-[1px] mt-2 flex bg-white font-bold z-[1]">
                                        <div className="flex flex-1 border-x-[1px] p-[5px] font-base border-[#999] border-solid justify-center border-b-[1px]">
                                            ງວດວັນທີ່
                                        </div>
                                        {_numberList?.map((number) => {
                                            return (
                                                <div className="w-[18%] flex p-[5px] justify-center font-base border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                    <center>{number.name}</center>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {historyData.map((result) => {
                                        return (
                                            <div className="flex bg-white z-[1]">
                                                <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#999] border-solid border-b-[1px] border-r-[1px] border-l-[1px]">
                                                    <center className="font-normal">{getDateNameLao(result?.lotto_date)}</center>
                                                </div>
                                                {Object.entries(result.results).map(([key, value]) => {
                                                    if (key !== "digit2_special" && key !== "digit1" && key !== "digit4")
                                                        return (
                                                            <div
                                                                style={{
                                                                    order: sortKey[key],
                                                                }}
                                                                className="w-[18%] flex p-[5px] justify-center font-base  border-[#999] border-solid border-b-[1px] border-r-[1px]"
                                                            >
                                                                <center className="font-normal">{value}</center>
                                                            </div>
                                                        );
                                                    return null;
                                                })}
                                            </div>
                                        );
                                    })}
                                </Flex>
                            </>
                        )}
                    </div>

                    <div className="hidden w-[500px] sm:block">
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip--1.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip--2.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip--3.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip--4.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                    </div>
                </Flex>
                <center className="py-[15px] bg-[#870000] mb-[10px] text-white">Copyright 2024 © ລາວສະຕ່າ</center>
            </Container>
        </>
    );
}
