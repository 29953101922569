import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Wrapper from "../../assets/images/laosantipap/laosantipap-warper.png";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { LAO_SAN_TI_PAP_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
const { useBreakpoint } = Grid;

const color = "#B70000";
const warper_bg = "#222";
const color_bg = "white";
const bgTopicColor = "white";
export default function LaoSanTiPapPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: LAO_SAN_TI_PAP_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: LAO_SAN_TI_PAP_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "ລາວສັນຕິພາບ JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/laosantipap/laosantipap-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/laosantipap/laosantipap-header-2.png")}
                        rootClassName="max-w-[1024px]"
                        style={{ width: 1024 }}
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        backgroundImage: `url(${Wrapper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                    }}
                >
                    <div className="p-5 px-3 md:px-5">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="w-full md:max-w-[400px] order-1 md:order-0" vertical>
                                <div className="md:block hidden">
                                    <Image
                                        src={require("../../assets/images/laosantipap/laosantipap-1.png")}
                                        preview={false}
                                        rootClassName="w-full"
                                    />

                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                    />
                                </div>
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/laosantipap/laosantipap-2.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laosantipap/laosantipap-3.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laosantipap/laosantipap-4.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                            <Flex className="w-full order-0 md:order-1 md:mx-0" vertical>
                                <div className="md:hidden block">
                                    <Image
                                        src={require("../../assets/images/laosantipap/laosantipap-1.png")}
                                        preview={false}
                                        rootClassName="w-full px-3"
                                    />

                                    <FindResult
                                        date={rewardDetail?.roundName}
                                        rewardData={data}
                                        color={color}
                                        bgColor={color_bg}
                                    />
                                </div>
                                {/* content result */}
                                <ResultLottery
                                    status={statusReward}
                                    data={data}
                                    date={rewardDetail?.roundName}
                                    color={color}
                                    bgColor={color_bg}
                                    bgTopicColor={bgTopicColor}
                                />

                                {/* history */}
                                <div className="mt-[-24px]">
                                    <HistoryResult data={historyData} color={color} bgColor={color_bg} />
                                </div>
                            </Flex>
                        </Flex>
                        <div className="pt-3">
                            <Image
                                src={require("../../assets/images/laosantipap/laosantipap-5.png")}
                                rootClassName="w-full"
                                preview={false}
                            />
                        </div>
                    </div>

                    <center className="text-white font-normal text-lg mb-5">Copyright ©2024 - laosantipap.com</center>
                </Flex>
            </Container>
        </div>
    );
}
