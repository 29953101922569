import dayjs from "dayjs";

import { useEffect } from "react";

import { Modal } from "antd";
import { useState } from "react";
import { ChartGen } from "../../components";
import { SGX_VIP_DEFAULT } from "../../constants/ConfigPlus";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getResultTime } from "../../utils/ConvertDate";
import { nameResult } from "../../utils/ConvertNamePlus";

const color = SGX_VIP_DEFAULT?.color;
const bgDayReward = SGX_VIP_DEFAULT?.bgReward;
const idFirst = SGX_VIP_DEFAULT?.first_id;
const idSecond = SGX_VIP_DEFAULT?.second_id;
const idThird = SGX_VIP_DEFAULT?.third_id;
const starTime = SGX_VIP_DEFAULT?.start_time;
const endTime = SGX_VIP_DEFAULT?.end_time;
const startFirstTime = SGX_VIP_DEFAULT?.start_first_time;
const startSecondTime = SGX_VIP_DEFAULT?.start_second_time;
const startThirdTime = SGX_VIP_DEFAULT?.start_third_time;
const firstName = nameResult[SGX_VIP_DEFAULT?.lang]?.firstName;
const secondName = nameResult[SGX_VIP_DEFAULT?.lang]?.secondName;
const thirdName = nameResult[SGX_VIP_DEFAULT?.lang]?.thirdName;
const closeTime = SGX_VIP_DEFAULT?.disable_time_list;

function TestInterval() {
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(undefined);
    const [resultNumber, serResultNumber] = useState("");
    const [name, setName] = useState("");

    const [statusReward, setStatusReward] = useState("");
    const [getReward, result_reward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [history, setHistory] = useState([]);
    const [firstReward, setFirstReward] = useState("");
    const [secondReward, setSecondReward] = useState("");
    const [thirdReward, setThirdReward] = useState("");

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            window.location.reload();

            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                window.location.reload();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    function generateTimes(start, end, interval = 1) {
        const times = [];
        const [startHours, startMinutes] = start.split(":").map(Number);
        const [endHours, endMinutes] = end.split(":").map(Number);

        let startTimeInMinutes = startHours * 60 + startMinutes;
        const endTimeInMinutes = endHours * 60 + endMinutes;

        for (let i = startTimeInMinutes; i <= endTimeInMinutes; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            times.push(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`);
        }

        return times;
    }
    const getCloseTimeList = () => {
        let result_disable_time = [];
        const interval = 1; // interval in minutes
        closeTime.map((item) => {
            result_disable_time = [...result_disable_time, ...generateTimes(item.start, item.end, interval)];
        });
        return result_disable_time || [];
    };

    useEffect(() => {
        document.title = "SGX VIP";
        onFetch();
    }, []);

    const onFetch = async () => {
        try {
            let result_number = "";

            const result_first = await getReward({ huayId: idFirst }).unwrap();
            const result_history_first = await getHistoryReward({ huayId: idFirst }).unwrap();

            setReloadTime(result_first?.data?.reloadTime);
            setCurrentTime(result_first?.data?.serverTime);

            // const result_second = await getReward({ huayId: idSecond }).unwrap();
            // const result_history_second = await getHistoryReward({ huayId: idSecond }).unwrap();

            // const result_third = await getReward({ huayId: idThird }).unwrap();
            // const result_history_third = await getHistoryReward({ huayId: idThird }).unwrap();
            const disable_list = getCloseTimeList();
            const history = [
                ...(result_history_first?.data?.map((item) => {
                    return {
                        ...item,
                        roundName: item?.roundName + `(${firstName})`,
                    };
                }) || []),
                // ...(result_history_second?.data?.map((item) => {
                //     return {
                //         ...item,
                //         roundName: item?.roundName + `(${secondName})`,
                //     };
                // }) || []),
                // ...(result_history_third?.data?.map((item) => {
                //     return {
                //         ...item,
                //         roundName: item?.roundName + `(${thirdName})`,
                //     };
                // }) || []),
            ];
            const _history = history.sort(function (a, b) {
                return new Date(b.announceAt) - new Date(a.announceAt);
            });

            const now = getResultTime(dayjs());
            let name = "";
            const roundName = result_first?.data?.roundName;
            if (result_first?.data?.viewStatus === "LOADING") {
                result_number = "";
                name = `${roundName} (${firstName})`;
                setStatusReward("LOADING");
            }
            //  else if (result_second?.data?.viewStatus === "LOADING") {
            //     result_number = "";
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("LOADING");
            // } else if (result_third?.data?.viewStatus === "LOADING") {
            //     result_number = "";
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("LOADING");
            // }
            else if (startFirstTime < now && result_first?.data?.viewStatus == "PREPARE") {
                result_number = "";
                name = `${roundName} (${firstName})`;
                setStatusReward("PREPARE");
            }
            //  else if (startSecondTime < now && result_second?.data?.viewStatus == "PREPARE") {
            //     result_number = "";
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("PREPARE");
            // } else if (startThirdTime < now && result_third?.data?.viewStatus == "PREPARE") {
            //     result_number = "";
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("PREPARE");
            // }
            else if (result_first?.data?.viewStatus === "ANNOUNCED") {
                result_number = result_first?.data?.result?.primaryNumber;
                name = `${roundName} (${firstName})`;
                setStatusReward("ANNOUNCED");
            }
            //  else if (result_second?.data?.viewStatus === "ANNOUNCED" && now < getResultTime(result_third?.data?.announceAt)) {
            //     result_number = result_second?.data?.result?.primaryNumber;
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("ANNOUNCED");
            // } else if (result_third?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_third?.data?.announceAt)) {
            //     result_number = result_third?.data?.result?.primaryNumber;
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("ANNOUNCED");
            // }

            // id,date,start,end,current,result,disable_time_list
            const _result = ChartGen(
                idFirst,
                result_first?.data?.roundName,
                starTime,
                endTime,
                getResultTime(result_first?.data?.serverTime),
                result_number,
                disable_list
            );

            const __result = {
                ..._result,
                graph: _result?.graph?.map((item) => {
                    if (
                        item.time === getResultTime(result_first?.data?.announceAt) &&
                        result_first?.data?.viewStatus === "ANNOUNCED"
                    ) {
                        return {
                            time: item.time,
                            value: Number(
                                String(item.value)?.split(".")[0]?.slice(0, -1) +
                                    result_first?.data?.result?.primaryNumber.slice(0, 1) +
                                    "." +
                                    result_first?.data?.result?.primaryNumber.slice(1, 3)
                            ),
                        };
                    }
                    // if (
                    //     item.time === getResultTime(result_second?.data?.announceAt) &&
                    //     result_second?.data?.viewStatus === "ANNOUNCED"
                    // ) {
                    //     return {
                    //         time: item.time,
                    //         value: Number(
                    //                                             String(item.value)?.split(".")[0]?.slice(0, -1) +

                    //                 result_second?.data?.result?.primaryNumber.slice(0, 1) +
                    //                 "." +
                    //                 result_second?.data?.result?.primaryNumber.slice(1, 3)
                    //         ),
                    //     };
                    // }
                    // if (
                    //     item.time === getResultTime(result_third?.data?.announceAt) &&
                    //     result_third?.data?.viewStatus === "ANNOUNCED"
                    // ) {
                    //     return {
                    //         time: item.time,
                    //         value: Number(
                    //                                             String(item.value)?.split(".")[0]?.slice(0, -1) +

                    //                 result_third?.data?.result?.primaryNumber.slice(0, 1) +
                    //                 "." +
                    //                 result_third?.data?.result?.primaryNumber.slice(1, 3)
                    //         ),
                    //     };
                    // }
                    else {
                        return item;
                    }
                }),
            };

            setHistory(_history);
            setFirstReward(result_first?.data?.result?.primaryNumber || "");
            // setSecondReward(result_second?.data?.result?.primaryNumber || "");
            // setThirdReward(result_third?.data?.result?.primaryNumber || "");
            setName(name);
            setResult(__result);
            serResultNumber(result_number);
            setLoadingHistory(false);
            setLoading(false);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
            setLoadingHistory(false);
            setLoading(false);
        }
    };
    return <div style={{ background: "white" }}>Test interval</div>;
}

export default TestInterval;
