export const permute = (num) => {
    const result = [];

    const digits = Array.from(String(num), Number);

    const generate = (current, remaining) => {
        if (remaining.length === 0) {
            result.push(current.join(""));
        } else {
            for (let i = 0; i < remaining.length; i++) {
                const next = current.concat([remaining[i]]);
                const rest = remaining.slice(0, i).concat(remaining.slice(i + 1));
                generate(next, rest);
            }
        }
    };

    generate([], digits);

    return result.filter((value, index, array) => array.indexOf(value) === index);
};
export const getRealData = (number) => {
    return [
        Array.from(Array(5 - (number.length % 5 === 0 ? 5 : number.length % 5)).keys())
            .map(() => "0")
            .join("") + number,
    ];
};

export const generateData = (number_) => {
    const number = number_.slice(0, 5);
    const _number =
        Array.from(Array(5 - (number.length % 5 === 0 ? 5 : number.length % 5)).keys())
            .map(() => "0")
            .join("") + number;
    return {
        "3permute": permute(_number.slice(-3)).filter((value) => value !== _number.slice(-3)), // 3 โต้ด
        "4permute": permute(_number.slice(-4)).filter((value) => value !== _number.slice(-4)), // 4 โต้ด
        "5permute": permute(_number).filter((value) => value !== _number && value.slice(0, 3) !== _number.slice(0, 3)), // 5 โต้ด
        3: [_number.slice(-3)], // 3 ตัวท้าย
        2: [_number.slice(-4)], //  4 ตัวท้าย
        "2back": [_number.slice(-2)], // 2 หน้า
        "2front": [_number.slice(0, 2)], // 2 หน้า
        "3front": [_number.slice(0, 3)], // 3 หน้า
        1: getRealData(number), // 1
        near: getNearData(number),
    };
};

export const getNearData = (number) => {
    const low = number === "0" ? "99999" : (Number(number) - 1).toString();
    const high = number === "99999" ? "0" : (Number(number) + 1).toString();
    return [
        Array.from(Array(5 - (low.length % 5 === 0 ? 5 : low.length % 5)).keys())
            .map(() => "0")
            .join("") + low,
        Array.from(Array(5 - (high.length % 5 === 0 ? 5 : high.length % 5)).keys())
            .map(() => "0")
            .join("") + high,
    ];
};

export const findResult = (search, data) => {
    if (data["1"]) {
        if (search.length === 5) {
            const index = data["1"]?.findIndex((item) => item === search);
            if (index > -1) {
                return "1";
            } else {
                const index = data["near"]?.findIndex((item) => item === search);
                if (index > -1) {
                    return "near";
                } else {
                    const index = data["2"]?.findIndex((item) => item === search.slice(-4));
                    if (index > -1) {
                        return "2";
                    } else {
                        const index = data["3"]?.findIndex((item) => item === search.slice(-3));
                        if (index > -1) {
                            return "3";
                        } else {
                            const index = data["3front"]?.findIndex((item) => item === search.slice(0, 3));
                            if (index > -1) {
                                return "3front";
                            } else {
                                const index = data["5permute"]?.findIndex((item) => item === search);
                                if (index > -1) {
                                    return "5permute";
                                } else {
                                    const index = data["4permute"]?.findIndex((item) => item === search.slice(-4));
                                    if (index > -1) {
                                        return "4permute";
                                    } else {
                                        const index = data["3permute"]?.findIndex((item) => item === search.slice(-3));
                                        if (index > -1) {
                                            return "3permute";
                                        } else {
                                            const index = data["2front"]?.findIndex((item) => item === search.slice(0, 2));
                                            if (index > -1) {
                                                return "2front";
                                            } else {
                                                const index = data["2back"]?.findIndex((item) => item === search.slice(-2));
                                                if (index > -1) {
                                                    return "2back";
                                                } else {
                                                    return "0";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } else {
        return "0";
    }
};
