import { Flex } from "antd";

export default function HistoryResult({
    data,
    dateText = "ງວດວັນທີ່",
    rewardHistoryText = "ຜົນການອອກເລກລາງວັນ",
    color = "#BA0909",
    style,
    bgColor = "white",
    lang = "lo",
}) {
    const textLang = {
        lo: {
            dateText: "ງວດວັນທີ່",
            resultTopText: "ເລກ 3 ໂຕເທິ່ງ",
            resultBottomText: "ເລກ 2 ໂຕລຸ່ມ",
            rewardHistoryText: "ຜົນການອອກເລກລາງວັນ",
        },
        vi: {
            dateText: "của ngày",
            resultTopText: "3 chữ số hàng đầu",
            resultBottomText: "2 chữ số dưới",
            rewardHistoryText: "Kết quả xổ số",
        },
        en: {
            dateText: "Date Round",
            resultTopText: "Number 3 digit top",
            resultBottomText: "Number 2 digit bottom",
            rewardHistoryText: "Result Number History",
        },
    };
    dateText = textLang[lang].dateText;
    const resultTopText = textLang[lang].resultTopText;
    const resultBottomText = textLang[lang].resultBottomText;
    rewardHistoryText = textLang[lang].rewardHistoryText;
    return (
        <div className="px-3 sm:px-0" style={{ ...style }}>
            <center
                style={{ color: color, borderColor: color }}
                className="mt-6 text-[20px] py-[10px] bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]"
            >
                {rewardHistoryText}
            </center>
            <Flex>
                <Flex
                    justify="center"
                    className="w-[33.33%] text-[20px] border-t-0 border-[1.5px] border-solid border-[#B9B9B9] border-r-0 border-b-0"
                    style={{ background: bgColor, borderColor: color }}
                >
                    <div className={`py-[7px] flex justify-center text-center`}>{dateText}</div>
                </Flex>
                <Flex
                    justify="center"
                    className="w-[33.33%] text-[20px] border-t-0 border-r-0 border-[1.5px] border-solid border-[#B9B9B9] border-b-0"
                    style={{ background: bgColor, borderColor: color }}
                >
                    <div className={`py-[7px] flex justify-center text-center`}>{resultTopText}</div>
                </Flex>
                <Flex
                    justify="center"
                    className="w-[33.33%] text-[20px] border-t-0 border-[1.5px] border-solid border-[#B9B9B9] border-b-0"
                    style={{ background: bgColor, borderColor: color }}
                >
                    <div className={`py-[7px] flex justify-center text-center`}>{resultBottomText}</div>
                </Flex>
            </Flex>
            <div
                className="rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] overflow-hidden"
                style={{
                    borderColor: color,
                }}
            >
                {data?.map((item, index) => {
                    return (
                        <Flex key={index}>
                            <Flex
                                justify="center"
                                wrap="wrap"
                                className="w-[33.33%] text-[20px] border-s-0 border-l-0 border-b-0 border-[1.5px] border-solid border-[#B9B9B9]"
                                style={{ background: bgColor, borderColor: color }}
                            >
                                <div>
                                    {item.roundName?.split("(").map((value, index) => {
                                        return (
                                            <div key={index} className={`py-[7px] flex justify-center`}>
                                                {index == 1 ? "(" + value : value}
                                            </div>
                                        );
                                    })}
                                </div>
                            </Flex>
                            <Flex
                                justify="center"
                                wrap="wrap"
                                className="w-[33.33%] text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9]"
                                style={{ background: bgColor, borderColor: color }}
                            >
                                <div className={`py-[7px] flex justify-center`}>{item.result?.primaryNumber?.slice(0, 3)}</div>
                            </Flex>
                            <Flex
                                justify="center"
                                wrap="wrap"
                                className="w-[33.33%] text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                                style={{ background: bgColor, borderColor: color }}
                            >
                                <div className={`py-[7px] flex  justify-center`}>{item.result?.primaryNumber?.slice(3, 5)}</div>
                            </Flex>
                        </Flex>
                    );
                })}
            </div>
        </div>
    );
}
