import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { DOW_JONES_EXTRA_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getDateName } from "../../utils/ConvertDate";
import { generateData } from "../../utils/GenerateNumber";
import "./index.css";

const color = "#032a67";
export default function DowJonesExtraOldPage() {
    const [date, setDate] = useState(new Date());
    const [result, setResult] = useState({
        firstPrize: "96008",
        countDown: "02:45:00",
        historyData: [
            {
                lotto_date: "2024-07-12",
                results: {
                    digit5: "53710",
                },
            },
            {
                lotto_date: "2024-07-11",
                results: {
                    digit5: "37624",
                },
            },
            {
                lotto_date: "2024-07-10",
                results: {
                    digit5: "16479",
                },
            },
            {
                lotto_date: "2024-07-09",
                results: {
                    digit5: "81058",
                },
            },
            {
                lotto_date: "2024-07-08",
                results: {
                    digit5: "15476",
                },
            },
            {
                lotto_date: "2024-07-07",
                results: {
                    digit5: "83945",
                },
            },
            {
                lotto_date: "2024-07-06",
                results: {
                    digit5: "12495",
                },
            },
            {
                lotto_date: "2024-07-05",
                results: {
                    digit5: "47024",
                },
            },
            {
                lotto_date: "2024-07-04",
                results: {
                    digit5: "53271",
                },
            },
            {
                lotto_date: "2024-07-03",
                results: {
                    digit5: "34566",
                },
            },
            {
                lotto_date: "2024-07-02",
                results: {
                    digit5: "18293",
                },
            },
            {
                lotto_date: "2024-07-01",
                results: {
                    digit5: "75121",
                },
            },
        ],
    });

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: DOW_JONES_EXTRA_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: DOW_JONES_EXTRA_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "Dow Jones Extra";
        onFetch();
    }, []);

    const onFetch = (date) => {
        setLoading(true);
        // fetch data
        setLoadingHistory(false);
        setLoading(false);
    };

    const RenderResult = ({ number, width }) => {
        return (
            <div className="c-extra-number">
                <Flex align="center" justify="center" className="result-number-extra">
                    {number}
                </Flex>
            </div>
        );
    };

    const RenderResultHistory = ({ lotto_date, results }) => {
        return (
            <div className="col-md-4">
                <div className="h-box">
                    <div className="h-date">{getDateName(lotto_date)}</div>
                    <h2>{results?.digit5}</h2>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/dowjonesextra/dowjonesextra-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: "#a8b1ba" }} />

            <Container
                className={"relative items-center flex flex-col text-white"}
                style={{
                    fontFamily:
                        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important",
                }}
            >
                <Flex
                    style={{
                        backgroundColor: "#fff",
                        backgroundImage: `url(${require("../../assets/images/dowjonesextra/dowjonesextra-warpper.png")})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="w-full !max-w-[950px] min-h-[1200px]"
                    vertical
                >
                    {/* header logo */}
                    <Flex align="center" vertical>
                        <Image
                            src={require("../../assets/images/dowjonesextra/dowjonesextra-header.png")}
                            rootClassName="max-w-[950px] border-solid border-[#a90b03] border-b-[8px]"
                            preview={false}
                        />
                        <div className="now-extra-result">
                            <div className="result-extra-head">
                                <h1>Winning Number</h1>
                                <h2>
                                    <span>Saturday, 13 Jul 2024</span>
                                </h2>
                            </div>
                            <div className="result-countdown">
                                <span>03:45:00</span>
                            </div>
                            <div className="result-body">
                                <Flex justify="center" align="center">
                                    {result.firstPrize?.split("").map((item) => (
                                        <RenderResult number={item} />
                                    ))}
                                </Flex>
                            </div>
                        </div>
                        <div className="text-center mt-12 mb-4 px-[10px]">
                            <Image
                                src={require("../../assets/images/dowjonesextra/dowjonesextra-1.png")}
                                // rootClassName="max-w-[80%]"
                                className=" rounded-[10px]"
                                preview={false}
                            />
                        </div>

                        <div className="h-results">
                            <div className="h-head">
                                <h2 className="m-0 font-bold">Latest Dow Jones Extra Results</h2>
                            </div>
                            <div className="h-body">
                                <div className="row">
                                    {result?.historyData?.map((item) => (
                                        <RenderResultHistory {...item} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="footer-inner"> Copyright ©2024 - www.dowjonesextra.com </div>
                        </div>
                    </Flex>
                </Flex>
            </Container>
        </div>
    );
}
