import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { LAO_STAR_VIP_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const color = "#BA0909";
const color_bg = "white";

export default function LaoStarVipPage() {
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();
    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);

            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 1000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: LAO_STAR_VIP_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: LAO_STAR_VIP_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "ລາວສະຕ່າ VIP JACKPOT";
        fetchReward();
    }, [fetchReward]);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/lao-star-vip/lao-star-vip-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"!max-w-[1000px]"}>
                <Image src={require("../../assets/images/lao-star-vip/lao-star-vip-header-2.png")} preview={false} />
                <Flex gap={10}>
                    <div className="w-full">
                        <div className="block sm:hidden">
                            <FindResult date={rewardDetail?.roundName} rewardData={data} color={color} bgColor={color_bg} />
                        </div>
                        <ResultLottery
                            status={statusReward}
                            data={data}
                            date={rewardDetail?.roundName}
                            color={color}
                            bgColor={color_bg}
                        />
                        <HistoryResult data={historyData} color={color} bgColor={color_bg} />
                    </div>

                    <div className="hidden w-[500px] sm:block">
                        <FindResult date={rewardDetail?.roundName} rewardData={data} color={color} bgColor={color_bg} />

                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip-1.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip-2.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip-3.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                        <Image
                            src={require("../../assets/images/lao-star-vip/lao-star-vip-4.png")}
                            preview={false}
                            rootClassName="w-full"
                            style={{ width: "100%" }}
                        />
                    </div>
                </Flex>
                <center className="py-[15px] bg-[#870000] my-[10px] text-white">Copyright 2024 © ລາວສະຕ່າ</center>
            </Container>
        </>
    );
}
