import { LoadingOutlined } from "@ant-design/icons";
import { Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { LAO_UNION_VIP_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const color = "#07a9e1";
const bgTopicColor = "white";
export default function LaoUnionVipPage() {
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: LAO_UNION_VIP_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: LAO_UNION_VIP_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "ຫວຍລາວສາມັກຄີ VIP JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">ກຳລັງໂຫຼດ ...</div>
                </div>
            )}
            {/* <div className="border-b-[8px] border-solid border-[#00f]" /> */}
            <Container>
                <div className="z-[-100] w-full h-[80%] absolute left-0" style={{ background: "#4CCAF4" }} />
                {/* header logo */}
                <div className="relative">
                    <Image
                        src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-header-2.png")}
                        preview={false}
                    />
                </div>
                {/* line */}
                <div className="h-[5px] border-b-[5px] border-solid border-[#fff] mb-2" />
                {/* find result block */}
                <div className="flex flex-col sm:px-10 md:flex-row md:gap-3 md:p-0">
                    <div className="w-full md:max-w-[450px]">
                        <Image
                            src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-1.png")}
                            preview={false}
                            rootClassName="w-full md:min-w-[450px]"
                        />
                        <FindResult date={rewardDetail?.roundName} rewardData={data} color={color} />
                        <div className="hidden md:block">
                            <Image
                                src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-2.png")}
                                preview={false}
                            />
                            <Image
                                src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-3.png")}
                                preview={false}
                            />
                            <Image
                                src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-4.png")}
                                preview={false}
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        {/* image 5 */}
                        <Image
                            src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-5.png")}
                            className="!w-[600px]"
                            preview={false}
                        />

                        {/* content result */}
                        <ResultLottery
                            status={statusReward}
                            data={data}
                            date={rewardDetail?.roundName}
                            color={color}
                            bgTopicColor={bgTopicColor}
                        />

                        {/* history */}
                        <div className="mt-[-24px]">
                            <HistoryResult data={historyData} color={color} />
                        </div>

                        {/* image 6 */}
                        <Image
                            src={require("../../assets/images/lao-union-lottery-vip/lao-union-lottery-vip-6.png")}
                            preview={false}
                            rootClassName="!w-full"
                        />
                    </div>
                </div>
            </Container>
            <div className="h-[5px] border-b-[5px] border-solid mb-1" style={{ borderColor: color }} />
            <center className="text-[#FFF] font-normal text-lg py-3" style={{ background: color }}>
                Copyright 2020 ©laounionvip.com
            </center>
        </div>
    );
}
