import { Flex } from "antd";
import React from "react";

export default function HistoryResult({
    data,
    dateText = "ງວດວັນທີ່",
    resultText = "​ເລກ 5 ໂຕ",
    rewardHistoryText = "ຜົນການອອກເລກລາງວັນ",
    color = "#BA0909",
    style,
    bgColor = "white",
    lang = "LAO",
}) {
    const textLang = {
        LAO: {
            dateText: "ງວດວັນທີ່",
            resultText: "​ເລກ 5 ໂຕ",
            rewardHistoryText: "ຜົນການອອກເລກລາງວັນ",
        },
        VIM: {
            dateText: "của ngày",
            resultText: "số có 5 chữ số",
            rewardHistoryText: "Kết quả xổ số",
        },
        ENG: {
            dateText: "Date Round",
            resultText: "Number 5 digit",
            rewardHistoryText: "Result Number History",
        },
    };
    dateText = textLang[lang].dateText;
    resultText = textLang[lang].resultText;
    rewardHistoryText = textLang[lang].rewardHistoryText;
    return (
        <div className="px-3 sm:px-0" style={style}>
            <center
                style={{ color: color }}
                className="mt-6 text-[20px] py-[10px] bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]"
            >
                {rewardHistoryText}
            </center>
            <Flex>
                <Flex
                    flex={1}
                    justify="center"
                    className="text-[20px] border-t-0 border-[1.5px] border-solid border-[#B9B9B9] border-r-0 border-b-0"
                    style={{ background: bgColor }}
                >
                    <div className={`py-[7px] flex flex-1 justify-center`}>{dateText}</div>
                </Flex>
                <Flex
                    flex={1}
                    justify="center"
                    className="text-[20px] border-t-0 border-[1.5px] border-solid border-[#B9B9B9] border-b-0"
                    style={{ background: bgColor }}
                >
                    <div className={`py-[7px] flex flex-1 justify-center`}>{resultText}</div>
                </Flex>
            </Flex>
            <div className="rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] overflow-hidden">
                {data?.map((item, index) => {
                    return (
                        <Flex key={index}>
                            <Flex
                                flex={1}
                                justify="center"
                                className="text-[20px] border-s-0 border-l-0 border-b-0 border-[1.5px] border-solid border-[#B9B9B9]"
                                style={{ background: bgColor }}
                            >
                                <div className={`py-[7px] flex flex-1 justify-center`}>{item.date}</div>
                            </Flex>
                            <Flex
                                flex={1}
                                justify="center"
                                className="text-[20px] border-s-0 border-l-0 border-b-0  border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                                style={{ background: bgColor }}
                            >
                                <div className={`py-[7px] flex flex-1 justify-center`}>{item.result}</div>
                            </Flex>
                        </Flex>
                    );
                })}
            </div>
        </div>
    );
}
