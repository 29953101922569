import { Image } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
};

function ImageSlide({ data, className, style }) {
    return (
        <Slider {...settings} className={className} style={style}>
            {data?.map((value, valueIndex) => {
                const { image } = value;
                return (
                    <div key={valueIndex}>
                        <Image src={image} preview={false} />
                    </div>
                );
            })}
        </Slider>
    );
}

export default ImageSlide;
