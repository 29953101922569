export const nameResult = {
    en: {
        firstName: "morning",
        secondName: "afternoon",
        thirdName: "evening",
        shortFirstName: "morning",
        shortSecondName: "afternoon",
        shortThirdName: "evening",
    },
    vi: {
        firstName: "buổi sáng",
        secondName: "buổi chiều",
        thirdName: "buổi tối",
        shortFirstName: "sáng",
        shortSecondName: "chiều",
        shortThirdName: "tối",
    },
    lo: {
        firstName: "ຕອນເຊົ້າ",
        secondName: "ຕອນບ່າຍ",
        thirdName: "ຕອນແລງ",
        shortFirstName: "ຕອນເຊົ້າ",
        shortSecondName: "ຕອນບ່າຍ",
        shortThirdName: "ຕອນແລງ",
    },
};
