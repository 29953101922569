import { Button, Flex } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function HomePage() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Title</title>
                <link rel="icon" href={require("../../assets/images/phone.png")} />
            </Helmet>
            <Flex>
                <Flex gap={10} vertical className="p-5">
                    <div>
                        <Link to={"/lao-red-cross"}>
                            <Button>LAO RED CROSS</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/hanoi-extra"}>
                            <Button>HANOI EXTRA</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-star-vip"}>
                            <Button>LAO STAR VIP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-union-vip"}>
                            <Button>LAO UNION VIP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-sa-sean"}>
                            <Button>LAO SA SEAN</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-union"}>
                            <Button>LAO UNION</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-star"}>
                            <Button>DOW JONES STAR</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-midnight"}>
                            <Button>DOW JONES MIDNIGHT</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-extra"}>
                            <Button>DOW JONES EXTRA</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/tv-dow-jones"}>
                            <Button>TV DOW JONES</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-pa-tu-xay"}>
                            <Button>LAO PA TU XAY</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-san-ti-pap"}>
                            <Button>LAO SAN TI PAP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-citizen"}>
                            <Button>LAO CITIZEN</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/laos-extra"}>
                            <Button>LAOS EXTRA</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xosodevelop"}>
                            <Button>XOSO DEVELOP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/hanoi-asean"}>
                            <Button>HANOI ASEAN</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-tv"}>
                            <Button>LAO TV</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xo-so-hd"}>
                            <Button>XO SO HD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-hd"}>
                            <Button>LAO HD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/minhngoc-tv"}>
                            <Button>MIN HN GOC TV</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-star"}>
                            <Button>LAO STAR</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xo-so-red-cross"}>
                            <Button>XO SO RED CROSS</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xo-so-union"}>
                            <Button>XO SO UNION</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/minhngoc-star"}>
                            <Button>MIN HN GOC STAR</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/minhngoc"}>
                            <Button>MIN HN GOC</Button>
                        </Link>
                    </div>
                </Flex>
                <Flex gap={10} vertical className="p-5">
                    <div>
                        <Link to={"/lao-red-cross-old"}>
                            <Button>LAO RED CROSS OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/hanoi-extra-old"}>
                            <Button>HANOI EXTRA OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-star-vip-old"}>
                            <Button>LAO STAR VIP OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-union-vip-old"}>
                            <Button>LAO UNION VIP OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-sa-sean-old"}>
                            <Button>LAO SA SEAN OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-union-old"}>
                            <Button>LAO UNION OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-star-old"}>
                            <Button>DOW JONES STAR OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-midnight-old"}>
                            <Button>DOW JONES MIDNIGHT OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-extra-old"}>
                            <Button>DOW JONES EXTRA OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/tv-dow-jones-old"}>
                            <Button>TV DOW JONES OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-pa-tu-xay-old"}>
                            <Button>LAO PA TU XAY OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-san-ti-pap-old"}>
                            <Button>LAO SAN TI PAP OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-citizen-old"}>
                            <Button>LAO CITIZEN OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/laos-extra-old"}>
                            <Button>LAOS EXTRA OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xosodevelop-old"}>
                            <Button>XOSO DEVELOP OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/hanoi-asean-old"}>
                            <Button>HANOI ASEAN OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-tv-old"}>
                            <Button>LAO TV OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xo-so-hd-old"}>
                            <Button>XO SO HD OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-hd-old"}>
                            <Button>LAO HD OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/minhngoc-tv-old"}>
                            <Button>MIN HN GOC TV OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lao-star-old"}>
                            <Button>LAO STAR OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xo-so-red-cross-old"}>
                            <Button>XO SO RED CROSS OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/xo-so-union-old"}>
                            <Button>XO SO UNION OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/minhngoc-star-old"}>
                            <Button>MIN HN GOC STAR OLD</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/minhngoc-old"}>
                            <Button>MIN HN GOC OLD</Button>
                        </Link>
                    </div>
                </Flex>
                <Flex gap={10} vertical className="p-5">
                    <div>
                        <Link to={"/nv-index-vip2"}>
                            <Button>NV INDEX VIP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/lotto-supper-rich"}>
                            <Button>LOTTO SUPPER RICH</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/sgx-vip"}>
                            <Button>SGX VIP</Button>
                        </Link>
                    </div>

                    <div>
                        <Link to={"/lsx-vip"}>
                            <Button>LSX VIP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/dow-jones-power-ball"}>
                            <Button>DOW JONES POWER BALL</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/heng-seng-vip"}>
                            <Button>HENG SENG VIP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/nikkei-vip-stock"}>
                            <Button>NIKKEI VIP STOCK</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/tsec-vip-index"}>
                            <Button>TSEC VIP INDEX</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/shen-zhen-index"}>
                            <Button>SHEN ZHEN INDEX</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/kt-vip-index"}>
                            <Button>K TOP VIP INDEX</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/india-vip"}>
                            <Button>INDIA VIP</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={"/india-vip-plus"}>
                            <Button>INDIA VIP PLUS</Button>
                        </Link>
                    </div>
                </Flex>
            </Flex>
        </>
    );
}
