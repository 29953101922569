import { Flex } from "antd";
import { useEffect, useRef, useState } from "react";
import SlotCounter from "react-slot-counter";
import { getDateNameSlash } from "../utils/ConvertDate";
import { generateData } from "../utils/GenerateNumber";
import ChartGen from "./ChartGen";
import ChartTest from "./ChartTest";
import PlusResultValueText from "./PlusResultValueText";

const PlusResult = ({ color, lang = "LAO", date, status, resultNumber, value, closeNumber }) => {
    const slotCounterRef = useRef(null);
    const result_mock_number = "12345";

    const result = ChartGen("12d1294793a29b38a9156", "21/07/2024", "08:00", "16:00", "16:01", result_mock_number);

    const [closeValue, setCloseValue] = useState({
        closeNumber: 0,
        changeNumber: 0,
        changePercent: 0,
    });

    const [_data, setData] = useState({
        1: ["-----"],
        2: ["----"],
        "2back": ["--"],
        "2front": ["--"],
        3: ["---"],
        "3front": ["---"],
        "3permute": ["---", "---", "---", "---", "---"],
        "4permute": ["----", "----", "----", "----", "----"],
        "5permute": ["-----", "-----", "-----", "-----", "-----"],
        near: ["-----", "-----"],
    });

    // useEffect(() => {
    //     if (_data["1"]?.toString() === "-----")
    //         setInterval(() => {
    //             slotCounterRef?.current?.startAnimation();
    //         }, 3000);
    // }, [_data]);

    // useEffect(() => {
    //     if (status === "ANNOUNCED") {
    //         setData(data);
    //     }
    // }, [status, data]);

    const textLang = {
        LAO: {
            topic: "ຜົນການອອກເລກລາງວັນ",
            name: "ງວດປະຈຳວັນທີ່",
            rewardText1: "ລາງວັນທີ່ 1",
            rewardNear1: "ລາງວັນໃກ້ໆລາງວັນທີ່ 1",
            rewardText2: "ລາງວັນທີ່ 2",
            rewardText3: "ລາງວັນທີ່ 3",
            rewardText4: "ລາງວັນທີ່ 4",
            rewardText5: "ລາງວັນທີ່ 5",
            rewardText6: "ລາງວັນທີ່ 6",
            rewardText2font: "ລາງວັນ 2 ໂຕໜ້າ",
            rewardText2back: "ລາງວັນ 2 ໂຕສິ້ນສຸດ",
            rewardText3font: "ລາງວັນ 3 ໂຕໜ້າ",
            rewardTextSub2font: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub2back: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub3font: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub2: "ຊື່ 4 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub3: "ຊື່ 3 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub4: "ໂຕດ 5 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub5: "ໂຕດ 4 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub6: "ໂຕດ 3 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
        },
        VIM: {
            topic: "Kết quả xổ số",
            name: "của ngày",
            rewardText1: "Giải nhất",
            rewardNear1: "Gần giải nhất",
            rewardText2: "Giải nhì",
            rewardText3: "Giải ba",
            rewardText4: "Giải tư",
            rewardText5: "Giải năm",
            rewardText6: "Giải sáu",
            rewardText2font: "Giải 2 chữ phía trước",
            rewardText2back: "Giải 2 chữ ở cuối",
            rewardText3font: "Giải 3 chữ phía trước",
            rewardTextSub2font: "Giải nhất",
            rewardTextSub2back: "Giải nhất",
            rewardTextSub3font: "Giải nhất",
            rewardTextSub2: "Bốn lần cuối cùng",
            rewardTextSub3: "ba lần cuối cùng",
            rewardTextSub4: "5 thẻ cuối giải nhất",
            rewardTextSub5: "4 thẻ cuối giải nhất",
            rewardTextSub6: "3 thẻ cuối giải nhất",
        },
        ENG: {
            topic: "Winning Number",
            name: "Of date",
            rewardText1: "1st prize",
            rewardNear1: "Near 1st prize",
            rewardText2: "2nd prize",
            rewardText3: "3rd prize",
            rewardText4: "4th prize",
            rewardText5: "5th prize",
            rewardText6: "6th prize",
            rewardText2font: "2 font digit",
            rewardText2back: "2 back digit",
            rewardText3font: "3 font digit",
            rewardTextSub2font: "1st prize",
            rewardTextSub2back: "1st prize",
            rewardTextSub3font: "1st prize",
            rewardTextSub2: "4 back digit",
            rewardTextSub3: "3 back digit",
            rewardTextSub4: "5 permutation",
            rewardTextSub5: "4 permutation",
            rewardTextSub6: "3 permutation",
        },
    };

    const RenderResult = ({ value, size }) => {
        return (
            <Flex gap={3}>
                {value.split("").map((item) => {
                    return (
                        <Flex
                            justify="center"
                            align="center"
                            className={`text-white rounded-full text-[22px]`}
                            style={{
                                background: color,
                                width: size || "26px",
                                height: size || "26px",
                            }}
                        >
                            <div>{item}</div>
                        </Flex>
                    );
                })}
            </Flex>
        );
    };

    const RenderEmpty = () => {
        return (
            <SlotCounter
                value="-----"
                animateUnchanged
                ref={slotCounterRef}
                animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                style={{
                    color: color,
                }}
            />
        );
    };
    const showViVip = (open, close, result) => {
        const result_close = Number(close.toString().slice(0, -4) + result.slice(0, 1) + "." + result.slice(1, 3));
        setCloseValue({
            closeNumber: result_close,
            changeNumber: Number((result_close - open).toString().split(".")[0] + "." + result.slice(3, 5)),
            changePercent: (((result_close - open) / open) * 100).toFixed(2),
        });
    };
    useEffect(() => {
        if (status === "ANNOUNCED") {
            const _data = generateData(resultNumber);
            setData(_data);
            showViVip(value.open, value.close, resultNumber);
        }
    }, [status, resultNumber]);

    const RenderReward = ({ text, subText, data, style, styleText }) => {
        return (
            <Flex
                style={{
                    borderColor: color,
                    color: color,
                    ...style,
                }}
                flex={1}
                className={"border-x-0 border-y-0 border-solid border-[1px] w-full"}
            >
                <Flex
                    vertical
                    align="center"
                    justify="center"
                    className="w-[30%] border-x-0 border-b-0 border-solid border-[1px]"
                    style={{
                        borderColor: color,
                        color: color,
                        ...styleText,
                    }}
                >
                    <div className="text-base">{text}</div>
                    {subText && <div className="text-black text-xs">{subText}</div>}
                </Flex>
                <Flex flex={1} wrap="wrap">
                    {data?.map((item, index) => {
                        return (
                            <Flex
                                flex={"1 1 33.33%"}
                                className="items-center justify-center border-solid border-t-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                style={{
                                    borderColor: color,
                                }}
                            >
                                <RenderResult key={`3-${index}`} value={item} />
                            </Flex>
                        );
                    })}
                </Flex>
            </Flex>
        );
    };

    return (
        <div>
            <Flex gap={5} flex={1}>
                <Flex
                    vertical
                    className="text-md border-solid border-[1px] font-medium w-full"
                    style={{
                        borderColor: color,
                    }}
                    justify="center"
                    align="center"
                >
                    <div className="text-xl font-medium">ຜົນການອອກເລກລາງວັນ</div>
                    <div
                        style={{
                            color: color,
                        }}
                        className="text-xl font-medium"
                    >
                        ງວດປະຈຳວັນທີ່ {getDateNameSlash(date)}
                    </div>
                    <RenderReward text={textLang[lang].rewardText1} data={_data["1"]} />
                    <RenderReward text={textLang[lang].rewardNear1} data={_data["near"]} />
                    <RenderReward text={textLang[lang].rewardText2} subText={textLang[lang].rewardTextSub2} data={_data["2"]} />
                    <RenderReward text={textLang[lang].rewardText3} subText={textLang[lang].rewardTextSub3} data={_data["3"]} />
                    {_data["4permute"].length > 0 && (
                        <RenderReward
                            text={textLang[lang].rewardText4}
                            subText={textLang[lang].rewardTextSub4}
                            data={_data["5permute"]}
                        />
                    )}
                    {_data["4permute"].length > 0 && (
                        <RenderReward
                            text={textLang[lang].rewardText5}
                            subText={textLang[lang].rewardTextSub5}
                            data={_data["4permute"]}
                        />
                    )}
                    {_data["3permute"].length > 0 && (
                        <RenderReward
                            text={textLang[lang].rewardText6}
                            subText={textLang[lang].rewardTextSub6}
                            data={_data["3permute"]}
                        />
                    )}
                    <Flex className="w-full">
                        <RenderReward
                            style={{ width: "50%", borderRight: `1px solid ${color}` }}
                            styleText={{ width: "50%" }}
                            text={textLang[lang].rewardText3font}
                            subText={textLang[lang].rewardText3fontSub}
                            data={_data["3front"]}
                        />
                        <RenderReward
                            style={{ width: "50%" }}
                            styleText={{ width: "50%" }}
                            text={textLang[lang].rewardText2back}
                            subText={textLang[lang].rewardText2backSub}
                            data={_data["2back"]}
                        />
                    </Flex>
                </Flex>
                <Flex className="w-[350px]" vertical>
                    <PlusResultValueText
                        value={{
                            open: 5937.94,
                            close: 5900.56,
                            change: -37.78,
                            change_percent: -0.64,
                            high: 5977.05,
                            low: 5925.22,
                            total_value: 60966000,
                            total_volume: 901700,
                        }}
                        status={"ANNOUNCED"}
                        resultNumber={"12345"}
                        closeNumber={5930.23}
                        result={result}
                    />
                    {/* <div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>Vi-VIP</div>
                                <div style={value.close > value.open ? { color: "#28a745" } : { color: "#dc3545" }}>
                                    {closeValue?.closeNumber ? numberFormat2Decimal(closeValue?.closeNumber) : "-"}
                                </div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>Open</div>
                                <div>{numberFormat2Decimal(value.open)}</div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>Change</div>
                                <div style={closeValue?.changeNumber > 0 ? { color: "#28a745" } : { color: "#dc3545" }}>
                                    {closeValue?.changeNumber > 0 ? (
                                        <CaretUpOutlined className="text-sm" />
                                    ) : (
                                        <CaretDownOutlined className="text-sm" />
                                    )}
                                    {numberFormat2Decimal(closeValue?.changeNumber)}
                                </div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>{`Change（%)`}</div>
                                <div style={closeValue?.changePercent > 0 ? { color: "#28a745" } : { color: "#dc3545" }}>
                                    {closeValue?.changePercent > 0 ? (
                                        <CaretUpOutlined className="text-sm" />
                                    ) : (
                                        <CaretDownOutlined className="text-sm" />
                                    )}
                                    {numberFormat2Decimal(closeValue?.changePercent)}
                                </div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>High</div>
                                <div>{numberFormat2Decimal(value.high)}</div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>Low</div>
                                <div>{numberFormat2Decimal(value.low)}</div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>Total Value (Mil.)</div>
                                <div>{numberFormat(value.total_value)}</div>
                            </Flex>
                        </div>
                        <div className="w-full">
                            <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                                <div>Total volume (Mil.)</div>
                                <div>{numberFormat(value.total_volume)}</div>
                            </Flex>
                        </div>
                    </div> */}
                    <ChartTest />
                    {/* <LineChart /> */}
                    {/* <ChartGen /> */}
                    {/* <LineChart2 /> */}
                    {/* <LineChart3 /> */}
                </Flex>
            </Flex>
        </div>
    );
};

export default PlusResult;
