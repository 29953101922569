import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { HANOI_EXTRA_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";
const { useBreakpoint } = Grid;

const color = "#e80000";
const warper_bg = "#d3d3d3";
const color_bg = "white";
export default function HanoiAseanOldPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: HANOI_EXTRA_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: HANOI_EXTRA_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    const onFetch = () => {
        // fetch data
        setData({
            prize_4th_1: "80528",
            prize_4th_2: "17469",
            prize_5th_3: "7163",
            prize_4th_3: "09171",
            prize_5th_4: "2288",
            prize_2digits_4: "23",
            prize_4th_4: "31651",
            prize_5th_1: "4001",
            prize_4th_5: "58118",
            prize_5th_2: "8449",
            prize_4th_6: "94097",
            prize_7th_3: "143",
            prize_7th_1: "883",
            prize_7th_2: "735",
            prize_1st: "53601",
            prize_6th_1: "2126",
            prize_6th_6: "9804",
            prize_3rd_2: "56972",
            prize_3rd_1: "10625",
            prize_2nd: "74356",
            prize_6th_2: "4009",
            prize_6th_3: "1297",
            prize_6th_4: "5259",
            prize_6th_5: "3542",
            prize_2digits_3: "92",
            prize_2digits_2: "28",
            prize_2digits_1: "90",
        });
        setLoadingHistory(false);
        setLoading(false);
    };

    useEffect(() => {
        document.title = "HANOI ASEAN";
        onFetch();
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/hanoiasean/hanoiasean-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1100px]"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/hanoiasean/hanoiasean-header.png")}
                        rootClassName="max-w-[1100px]"
                        style={{ width: 1100 }}
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "white",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1100,
                        width: "100%",
                    }}
                >
                    <div className="pt-[10px] px-3 min-[600px]:px-0">
                        <Flex gap={10} className="flex-col min-[600px]:flex-row">
                            {/* left content */}
                            <Flex
                                className="w-full hidden min-[600px]:flex min-[600px]:max-w-[250px] order-1 min-[600px]:order-0 overflow-hidden"
                                vertical
                            >
                                <iframe
                                    src="https://m.baomoi.com/"
                                    className="h-[1830px] pointer-events-none overflow-hidden mt-[-100px]"
                                />
                            </Flex>
                            <Flex className="w-full order-0 min-[600px]:order-1 min-[600px]:mx-0 min-w-[320px]" vertical gap={10}>
                                {/* content result */}
                                <Flex className="px-0 border-[#ccc] border-solid border-[1.5px]" vertical>
                                    <center className="py-[10px] font-semibold text-[16px]">HANOI ASEAN LOTTERY</center>
                                    <Flex flex={1}>
                                        <Flex vertical className="w-[20%] text-sm ">
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                                Đặc biệt
                                            </div>
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                Giải nhất
                                            </div>
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                                Giải nhì
                                            </div>
                                            <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                Giải ba
                                            </div>
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0  font-medium">
                                                Giải tư
                                            </div>
                                            <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                Giải năm
                                            </div>
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                                Giải sáu
                                            </div>
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                Giải bảy
                                            </div>
                                        </Flex>
                                        <Flex flex={1} vertical>
                                            {/* biet */}
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-r-0 border-[1.5px] w-full border-b-0 text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                <div>{data.prize_1st}</div>
                                            </div>
                                            {/* nhat */}
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-r-0 border-[1.5px] w-full border-b-0 bg-[#efefef] text-[#000] font-extrabold text-[20px]">
                                                <div>{data.prize_2nd}</div>
                                            </div>
                                            {/* nhi */}
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                                <Flex className="h-full" flex={1}>
                                                    <Flex
                                                        flex={1}
                                                        align="center"
                                                        justify="center"
                                                        className="border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                    >
                                                        {data.prize_3rd_1}
                                                    </Flex>
                                                    <Flex
                                                        flex={1}
                                                        align="center"
                                                        justify="center"
                                                        className="border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                    >
                                                        {data.prize_3rd_2}
                                                    </Flex>
                                                </Flex>
                                            </div>
                                            {/* ba */}
                                            <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                <Flex className="h-full" flex={1} wrap="wrap">
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_4th_1}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_4th_2}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_4th_3}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_4th_4}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_4th_5}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_4th_6}
                                                    </Flex>
                                                </Flex>
                                            </div>
                                            {/* tu */}
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-y-0 font-medium">
                                                <div className="flex flex-1 h-full">
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_5th_1}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_5th_2}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_5th_3}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_5th_4}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* nam */}
                                            <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                <Flex className="h-full" flex={1} wrap="wrap">
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_6th_1}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_6th_2}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_6th_3}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_6th_4}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_6th_5}
                                                    </Flex>
                                                    <Flex
                                                        flex={"1 1 33%"}
                                                        className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                    >
                                                        {data.prize_6th_6}
                                                    </Flex>
                                                </Flex>
                                            </div>
                                            {/* sau */}
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0">
                                                <div className="flex flex-1 h-full">
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_7th_1}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_7th_2}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                        {data.prize_7th_3}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* bay */}
                                            <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                                <div className="flex flex-1 h-full">
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                        {data.prize_2digits_1}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                        {data.prize_2digits_2}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                        {data.prize_2digits_3}
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                        {data.prize_2digits_4}
                                                    </div>
                                                </div>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Image
                                    src={require("../../assets/images/hanoiasean/hanoiasean-1.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />

                                <iframe
                                    src="https://m.baomoi.com/chung-khoan.epi"
                                    className="h-[1550px] pointer-events-none overflow-hidden mt-[-120px]"
                                />
                            </Flex>

                            {/* right content */}
                            <Flex
                                className="w-full min-[600px]:max-w-[250px] order-1 min-[600px]:order-0 overflow-hidden"
                                vertical
                            >
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/hanoiasean/hanoiasean-2.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <iframe
                                        src="https://www.voatiengviet.com/"
                                        className="h-[800px] pointer-events-none overflow-hidden mt-[-80px]"
                                    />
                                    <Image
                                        src={require("../../assets/images/hanoiasean/hanoiasean-3.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <Divider className="bg-[#e80000] h-[2px] mb-5" />

                    <center className="text-black font-normal text-lg mb-5">©2021 HanoiAsean.com, All Right Reserved.</center>
                </Flex>
            </Container>
        </div>
    );
}
