export const numberFormat2Decimal = (value) => {
    return Number(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const numberFormat = (value) => {
    return Number(value).toLocaleString("en-US");
};
