const CryptoJS = require("crypto-js");

const ChartGen = (huayId, date, startTime, endTime, currentTime, result = false, disableDate = []) => {
    // var huayId = "12d1294793a29b38a9156";
    // var date = "21/07/2024"; //timezone +0
    // var startTime = "08:00";
    // var endTime = "16:00";
    // var currentTime = "16:01";
    // var result = "12345";

    const baseNumberArray = [12000, 6000, 7000, 13000, 15000, 9000, 2000, 3000, 7500, 7300];

    const basePatternArray = [
        [
            -76.78, -79.55, -79.85, -78.96, -79.34, -79.17, -78.69, -78.42, -79.92, -79.1, -79.92, -79.93, -75.62, -71.33, -68.85,
            -68.56, -71.16, -74.72, -79.05, -79.55, -79.63, -79.51, -74.19, -70.85, -69.01, -65.16, -61.9, -63.49, -62.65, -58.97,
            -58.06, -63.07, -65.38, -68.43, -72.18, -70.05, -74.05, -72.43, -75.1, -78.78, -79.06, -76.76, -79.29, -78.64, -79.94,
            -79.33, -79.79, -76.77, -75.74, -71.78, -71.41, -76.82, -79.76, -78.93, -78.56, -75.28, -78.36, -73.76, -70.39,
            -67.54, -69.42, -71.79, -69.77, -67.31, -72.38, -74.05, -69.19, -71.36, -74.76, -72.51, -72.06, -69.1, -74.76, -72.56,
            -73.78, -76.22, -79.01, -79.27, -79.34, -79.17, -79.89, -79.22, -79.3, -79.69, -74.18, -78.66, -79.65, -76.68, -71.29,
            -70.36, -74.64, -71.72, -72.41, -74.78, -71.09, -69.44, -66.61, -67.27, -65.17, -68.69, -66.94, -68.28, -65.22,
            -67.49, -70.03, -69.39, -72.51, -69.97, -67.23, -64.11, -67.97, -72.67, -73.38, -76.29, -73.45, -78.46, -75.81,
            -78.59, -79.75, -79.45, -79.98, -76.73, -79.58, -79.58, -79.08, -79.33, -79.03, -79.29, -79.58, -79.88, -74.1, -75.53,
            -71.34, -69.72, -64.95, -67.99, -67.84, -71.57, -75.33, -79.13, -79.41, -79.31, -79.72, -79.62, -79.09, -79.64,
            -79.49, -75.58, -70.01, -73.09, -68.48, -65.68, -64.66, -59.25, -56.64, -52.06, -52.75, -47.64, -48.35, -51.46,
            -49.37, -45.13, -45.68, -47.58, -44.4, -42.44, -42.62, -39.61, -36.86, -35.94, -40.97, -36.62, -31.28, -34.83, -36.32,
            -33.4, -31.25, -28.31, -31.97, -28.43, -30.74, -30.12, -35.64, -36.02, -33.35, -35.15, -35.52, -40.17, -43.51, -38.61,
            -39.67, -44.9, -46.18, -51.44, -52.85, -51.33, -47.94, -44.43, -42.01, -42.27, -37.36, -42.92, -45.79, -49.01, -53.14,
            -49.16, -51.11, -50.26, -52.77, -56.94, -56.27, -55.1, -57.87, -62.2, -60.85, -55.57, -51.41, -50.65, -52.77, -48.83,
            -52.18, -54.62, -50.09, -47.81, -44.99, -48.64, -50.97, -50.19, -45.56, -40.94, -38.69, -39.9, -44.94, -44.99, -44.65,
            -48.81, -45.71, -40.25, -43.69, -42.13, -44.99, -39.82, -40.26, -45.5, -47.91, -48.1, -52.34, -51.41, -52.17, -53.54,
            -52.8, -54.59, -55, -54.31, -58.11, -53.82, -51.9, -56.95, -56.75, -52.59, -57.94, -56.07, -60.04, -65.8, -68.31,
            -72.88, -67.26, -68.03, -69.48, -68.29, -71.4, -72.27, -71.9, -71.27, -68.67, -70.02, -70.43, -74.01, -75.89, -75.56,
            -75.39, -79.27, -76.02, -79.71, -79.72, -79.75, -79.19, -79.8, -79.44, -79.39, -78.34, -79.17, -77.9, -74.07, -69.31,
            -71.07, -72.54, -67.31, -66.12, -64.86, -61.48, -65.77, -68.31, -63.21, -61.53, -58.47, -61.96, -57.35, -52.14,
            -50.58, -46.78, -49.62, -45.52, -42.37, -42.87, -39.06, -41.28, -42.15, -41.12, -39.15, -36.67, -35.68, -37.81,
            -42.68, -39.73, -40.96, -44.26, -45.19, -48.05, -46.82, -48.19, -53.88, -54.7, -55.85, -53.99, -58.77, -53.25, -49.51,
            -46.12, -50.26, -49.33, -54.18, -59.92, -54.18, -56.19, -61.07, -66.91, -67.6, -72.7, -67.24, -72.94, -73.61, -71.32,
            -76.65, -79.18, -79.43, -79.11, -79.18, -79.04, -79.33, -79.8, -75.57, -77.47, -79.79, -76.25, -79.8, -79.21, -75.31,
            -72.39, -68.11, -70.64, -72.97, -72.37, -67.06, -62.4, -64.42, -66.45, -70.11, -68.09, -72.36, -72.82, -70.33, -68.71,
            -73.98, -70.74, -75.25, -70.01, -73.89, -78.84, -79.55, -74.9, -69.03, -71.64, -76.39, -73.17, -78.53, -75.93, -77.96,
            -73.86, -78.67, -73.54, -73.24, -71.4, -74.67, -71.43, -69.45, -64.89, -68.28, -70.98, -69.64, -74.85, -74.79, -69.4,
            -68.71, -71.64, -69.15, -64.56, -61.98, -61.86, -63.02, -65.64, -67.1, -64.36, -69.16, -70.3, -68.22, -72.17, -74.81,
            -75.15, -73.18, -76.58, -71.56, -76.99, -72.41, -73.51, -76.31, -71.83, -73.56, -77.15, -76.18, -79.88, -79.77,
            -79.43, -79.06, -76.93, -73.05, -72.57, -68.13, -72.51, -71.21, -73.36, -76.22, -72.92, -69.21, -71.91, -67.9, -71.16,
            -69.07, -74.1, -71.41, -74.93, -77.31, -73.95, -68.25, -67.9, -62.61, -67.65, -72.43, -75.33, -74.8, -77.77, -76.27,
            -73.01, -71.12, -71.04, -70.92, -71.93, -76.65, -75.24, -79.36,
        ],
        [
            127.13, 125.68, 124.13, 127.47, 132.04, 133.91, 137.5, 133.51, 128.64, 128.65, 133.46, 135.89, 134.23, 132.95, 127.35,
            128.48, 125.46, 127.6, 130.05, 129.12, 128.92, 126.79, 122.83, 124.95, 122.06, 126.64, 123.04, 123.83, 124.25, 128.56,
            124.5, 123.59, 127.53, 130.35, 127.17, 131.95, 130.07, 126.4, 130.53, 126.54, 128.81, 127, 127.01, 122.87, 121.67,
            118.97, 122.51, 119.86, 117.48, 120.72, 124.13, 128.27, 127.97, 123.13, 128.86, 132.73, 137.84, 140.01, 141.32,
            143.61, 142.57, 137.95, 142.94, 137.15, 142.26, 139.97, 144.84, 142.22, 138, 138.4, 133.55, 136.56, 132.61, 131.27,
            135.69, 134.83, 139.93, 143.19, 143.8, 146.15, 141.84, 139.29, 142.95, 146.87, 142.81, 138.37, 137.81, 136.75, 132.92,
            137.31, 141, 139.88, 137.46, 135.08, 133.61, 137.64, 142.29, 137.81, 141.34, 136.55, 131.46, 129.51, 131.78, 128.29,
            127.47, 132.9, 127.92, 130.06, 130.23, 135.74, 131.34, 131.38, 128.44, 131.24, 132.34, 132.41, 136.18, 132.75, 128.11,
            128.98, 132.47, 133.71, 135.73, 134.45, 134.03, 137.53, 142.04, 143.51, 144.82, 139.4, 134.1, 139.63, 135.45, 136.32,
            137.67, 133.67, 129.42, 133.78, 128.58, 133.28, 133.62, 137.9, 135.65, 138.49, 138.01, 138.93, 137.04, 137.27, 136.35,
            140.07, 143.41, 147.19, 150.56, 147.02, 147.99, 145.76, 141.96, 142.05, 145.98, 143.81, 148.22, 144.47, 148.57,
            143.51, 139.09, 138.61, 135.28, 133.92, 128.77, 130.6, 130.58, 131.21, 130.3, 129.61, 129.91, 134.79, 135.96, 135.56,
            130.78, 128.26, 131.66, 133.56, 131.33, 128.69, 131.95, 132.78, 134.83, 135.23, 133.91, 129.63, 129.09, 128.79,
            128.86, 130.22, 131.16, 129.6, 132.12, 128.05, 128.24, 129.39, 133.8, 136.79, 137.57, 136.16, 138.81, 143.61, 147.76,
            150.84, 147.96, 150.75, 150.38, 150.71, 146.17, 149.55, 148.23, 148.12, 150.02, 147.29, 150.02, 150.43, 150.7, 145.31,
            149.1, 149.52, 150.89, 150.89, 150.54, 147.17, 149.49, 150.06, 149.36, 150.92, 150.11, 145.37, 141.17, 136.35, 141.22,
            142.35, 137.64, 140.4, 140.96, 142.52, 138.16, 134.35, 137.63, 136.67, 137.31, 137.04, 133.68, 137.83, 138.59, 141.47,
            146.08, 146.36, 146.01, 146.93, 147.35, 145.77, 142.31, 142.35, 146.35, 146.03, 149.36, 150.73, 150.83, 150.15,
            150.76, 146.74, 145.98, 143.2, 140.4, 136.52, 131.19, 131.88, 136.33, 131.84, 126.44, 125.11, 127.08, 130.42, 132.28,
            133.23, 138.79, 136.91, 133.94, 134.77, 132.28, 134.31, 131.03, 126.15, 128.25, 130.51, 126.56, 125.47, 121.64, 121.2,
            117.28, 122.31, 123.32, 125.3, 121.93, 116.31, 113.59, 117.12, 116.26, 112.14, 108.12, 112.19, 109.8, 104.11, 104.12,
            104.82, 106.44, 103.37, 103.02, 101.21, 100.58, 97.14, 96.35, 95.15, 100.51, 105.79, 103.81, 100.84, 102.17, 105.7,
            109.18, 112.24, 108.52, 110.75, 108.47, 108.85, 113.6, 117.51, 112.72, 111.69, 109.4, 105.34, 108.38, 111.79, 114.61,
            118.24, 114.61, 116.85, 119.81, 115.93, 110.71, 110.31, 105.31, 109.17, 107.23, 105.89, 102.1, 105.34, 109.87, 105.37,
            104.05, 102.86, 106.72, 110.07, 115.5, 114.5, 112.2, 113.85, 110.47, 113.63, 109.61, 111.43, 110.16, 113.95, 110.82,
            105.27, 101.92, 99.57, 104.88, 108.97, 110.73, 109.98, 111.81, 111.74, 106.93, 110.18, 114.2, 113.5, 108.02, 105.63,
            109.35, 109.77, 111.56, 109.21, 109.95, 113.32, 117.55, 121.79, 125.6, 125.97, 122.44, 119.33, 115.12, 112.72, 109.25,
            113.08, 113.23, 110.79, 110.81, 112.22, 116.64, 118.31, 118.31, 119.93, 119.41, 117.72, 121.89, 124.53, 125.28,
            123.23, 122.39, 119, 118.97, 115.25, 111.04, 113.93, 111.97, 109.14, 110.28, 107.43, 110.76, 106.43, 108.64, 106.27,
            101.14, 98.79, 102.84, 106.21, 111.87, 113.6, 110.56, 113.24, 118.83, 117.15, 121.49, 125.82, 120.87, 116.54, 116.22,
            114.07, 119.44, 122.31, 126.96, 128.25, 132.51, 130.98, 135.92, 133.76, 137.29, 136.5, 137.99, 138.97, 139.42, 142.52,
            145.78, 140.03, 141.83, 143.2, 141.57, 136.22, 140.41, 140.59, 137.28, 133.82, 138.76, 139.94, 140.92, 142.78, 137.96,
            139.93, 139.43, 143.8, 144.76, 141.85,
        ],
        [
            -16.12, -14.22, -10.64, -12.74, -12.21, -7.75, -3.03, -6.02, -7.39, -5.47, -9.31, -7.96, -8.83, -11.06, -7.67, -12.5,
            -17.57, -16.94, -21.86, -25.33, -21.19, -18.77, -17.6, -19.41, -14.05, -15.53, -14.46, -11.97, -7.59, -5.32, -1.03, 1,
            -3.24, -5.79, -1.85, 3.64, -1.44, -6.85, -10.31, -11.25, -15.67, -19.58, -22.11, -23.34, -19.21, -23.63, -25.65,
            -27.51, -24, -21.6, -19.42, -24.78, -19.51, -23.72, -28.47, -27.56, -28.5, -30.16, -34.86, -30.86, -25.54, -26.99,
            -23.5, -26.19, -24.64, -20.81, -23.93, -27.07, -24.52, -23.03, -18.35, -15.84, -13.94, -16.85, -11.12, -16.13, -17,
            -13.79, -16.37, -12.02, -14.49, -15.75, -14.52, -11.59, -9.76, -13.18, -18.71, -13.53, -17.52, -15.72, -11.29, -6.58,
            -7.64, -5.87, -2.28, 1.12, 3.3, -1.56, 3.39, -1.89, -1.98, -2.58, -5.15, -9.6, -6.61, -7.26, -5.41, -9.56, -9.75,
            -4.79, -9.22, -11.59, -13.57, -8.04, -13.51, -12.45, -17.86, -16.35, -14.46, -14.04, -17.04, -17.82, -14.15, -19.8,
            -17.31, -14.42, -16.74, -17.17, -22.96, -24.44, -22.29, -21.58, -16.27, -11.31, -14.47, -13.02, -12.52, -17.42,
            -22.47, -22.31, -22.38, -23.26, -27.19, -29.06, -24.46, -24.8, -20.01, -18.89, -15.38, -17.06, -12.77, -13.04, -15.85,
            -16.84, -14.26, -14.57, -11.98, -14.55, -16.02, -12.14, -10.02, -10.82, -15.55, -18.02, -16.48, -18.04, -19.99,
            -23.72, -22.19, -18.89, -21.25, -20.35, -25.03, -21.4, -19.29, -22.83, -23.06, -26.42, -25.01, -20.68, -16.57, -17.78,
            -21.02, -19.13, -14.07, -17.12, -17.12, -13.08, -14.18, -11.51, -12.82, -8.57, -10.71, -5.94, -3.2, -4.14, -2.33,
            -6.28, -7.52, -9, -11.6, -13.51, -10.81, -6.64, -11.39, -12.13, -8.9, -7.67, -3.72, -1.42, -5.19, -0.58, 1.9, -1.71,
            2.35, -1.81, -2.05, -0.59, 0.55, 1.62, 4.11, 3.85, 1.31, 6.52, 1.91, 3.13, 5, 2.05, -0.48, -4.09, -8.89, -10.22,
            -9.21, -13.37, -11.59, -10.16, -15.62, -13.35, -15.29, -13.56, -11.14, -7.24, -4.95, -5.32, -2.44, -7.98, -12.04,
            -11.11, -10.28, -15.04, -12.13, -13.69, -13.62, -15.62, -11.62, -7.51, -3.5, 1.93, -0.86, 4.29, 1.72, -1.75, 1.64,
            1.74, 6, 1.56, -0.08, 0.81, -4.47, -7.45, -3.43, -1.8, 2.46, -0.75, 3.32, 0.87, 1.59, -0.47, -5.78, -7.45, -10.12,
            -14.75, -12.7, -14.08, -19.43, -19.48, -15.29, -12.93, -8.68, -3.47, -8.03, -10.55, -6.03, -8.19, -12.29, -7.87,
            -2.11, -6.78, -3.07, -8.87, -3.32, -5.76, -3.68, 1.73, 0.34, -2.18, -4.24, -7.49, -8.56, -6.93, -6.43, -5.24, -1.67,
            -4.34, -2.37, 0.58, 3.25, 3.56, 6.59, 10.98, 10.58, 15.74, 19.62, 14.39, 11.34, 12.51, 11.22, 12.84, 11.2, 10.23, 6.2,
            10.13, 13.14, 14.07, 15.82, 14.91, 10.78, 9.07, 7.92, 6.49, 7.04, 6.23, 11.58, 12.96, 11.68, 7.63, 9.21, 5.55, 3.82,
            7.32, 3.71, 7.52, 10.34, 6.23, 8.38, 13.16, 14.43, 12.51, 8.25, 9.91, 14.63, 16.28, 14.1, 11.1, 8.28, 7.87, 9.39,
            11.72, 9.72, 8.91, 8.43, 7.28, 2.39, 6.69, 2.54, 7.48, 8.34, 8.59, 10.47, 8.12, 6.66, 9.51, 11.91, 13.36, 9.96, 11.34,
            6.76, 1.27, 2.72, 6.17, 6.39, 8.78, 9.29, 8.08, 10.89, 13.64, 18.47, 16.49, 11.76, 12.6, 13.43, 14.56, 9.01, 8.43,
            7.78, 10.07, 9.62, 14.75, 11.31, 9.34, 12.23, 9.67, 4.61, -0.73, -1.76, 2.37, 1.55, 6.86, 4.68, 8.69, 13.2, 15.72,
            13.75, 8.77, 9.81, 9.66, 10.88, 13.05, 18.55, 19.69, 22.64, 20.2, 20.48, 20.99, 25.86, 25.43, 26.18, 29, 31.22, 35.11,
            31.07, 31.91, 32.14, 32.36, 35.76, 41, 45.28, 44.21, 41.36, 38.67, 40.66, 44.75, 43.8, 43.01, 46.14, 47.86, 52.42,
            48.12, 49.38, 54.85, 51.07, 49.84, 54.53, 59.3, 60.69, 59.13, 61.23, 64.24, 63.31, 68.11, 70.15, 71.11, 76, 79.96,
            77.49, 82.08, 78.12, 82.14, 79.79, 77.45,
        ],
        [
            8.73, 6.17, 10.43, 13.56, 8.32, 8.6, 10.83, 14.31, 19.88, 15.87, 16.67, 15.63, 12.18, 7.51, 11.98, 11.19, 12.9, 13.91,
            13.68, 15.22, 20.33, 25.57, 25.08, 30.85, 31.99, 35.2, 40.29, 37.14, 35.09, 30.25, 26.54, 27.83, 25.36, 21.65, 23.15,
            19.89, 14.63, 17.4, 13.48, 14.17, 19.95, 24.55, 28.46, 26.69, 24.69, 20.24, 19.26, 16.64, 16.82, 12.14, 9.46, 8.89,
            11.96, 7.7, 6.47, 6.98, 9.69, 13.14, 18.13, 23.18, 21.55, 25.73, 28.37, 23.74, 25.6, 29.35, 24.46, 28.92, 26.05,
            23.39, 23.48, 20.52, 19.4, 14.1, 10.75, 11.27, 10.14, 15.51, 15.88, 10.69, 14.87, 17.16, 16.39, 11.02, 6.37, 9.27,
            9.47, 14.7, 17.93, 21.92, 26.88, 24.11, 19.38, 17.83, 12.12, 17.72, 21.51, 17.55, 14.17, 17.31, 14.52, 10.77, 9.87,
            13.75, 12.72, 15.67, 19.17, 23.98, 22.06, 24.56, 22.43, 19.08, 15.67, 13.99, 9.6, 9.08, 8.26, 3.07, 8.31, 3.35, 8.44,
            9.77, 11.35, 12.82, 10.55, 6.57, 3.06, 4.58, 4.5, 7.48, 4.87, 3.21, 2.09, 1.03, 0.2, -0.37, -0.32, -4.68, -6.98,
            -8.28, -9.9, -7.45, -2.2, -1.48, -6.94, -3.9, -7.14, -5.31, -10.9, -6.82, -2.82, -3.1, -0.16, 1.32, 6.02, 7.39, 5.41,
            3.68, 0.37, 3.99, -1.93, -4.2, -5.69, -2.01, -4.27, -1.47, -3.47, -2.77, 2.13, 4.67, 6.09, 4.49, 0.77, 2.32, 1.79,
            2.84, -0.34, 0.01, 4.65, 7.26, 3.79, 5.35, 0.91, -4.22, -9.99, -13.59, -11.49, -9.07, -5.49, -0.91, -1.72, 0.78, 4.4,
            4.19, -0.64, -2.18, 0.23, -2.25, -5.22, -0.22, -4.57, -2.21, -2.13, -2.74, -7.14, -12.68, -8.3, -7.42, -12.29, -9.48,
            -8.81, -11.48, -12.25, -8.66, -4.09, -5.25, -9.66, -8.52, -8.53, -3.17, -4.08, -7.21, -7.85, -2.49, 2.22, 6.5, 7.74,
            7.29, 12, 15.18, 10.01, 13.69, 15.47, 15.02, 18.23, 16.93, 14.87, 19.88, 16.02, 17.09, 15.05, 19.16, 20.37, 18.34,
            23.46, 23.11, 26.2, 31.63, 36.32, 32.72, 34.66, 30.58, 26.86, 23.77, 25, 30.94, 29.76, 25.32, 30.43, 31.95, 27.62,
            28.33, 30.5, 34, 34.85, 37.1, 38.02, 43.9, 44.03, 39.83, 36.09, 37.6, 39.93, 44.48, 45.19, 40.4, 39.5, 35.96, 33.12,
            38.4, 35.21, 32.98, 32.55, 36.9, 32.76, 31.4, 29.53, 29, 30.29, 28.7, 27.21, 30.08, 27.86, 29.79, 31.95, 26.06, 23.66,
            24.64, 23.82, 20.68, 17.19, 15.35, 17.83, 20.73, 18.45, 22.69, 22.07, 20.37, 23.17, 27.65, 32.45, 29.43, 29.62, 33.61,
            28.81, 26.07, 22.42, 27.17, 25.32, 27.66, 29.03, 29.83, 33.66, 34.61, 30.15, 34.66, 39.01, 44.83, 43.28, 47.1, 44.97,
            46.64, 43.78, 43.18, 45.59, 43.02, 40.3, 35.12, 31.05, 26.48, 27.42, 24.81, 19.28, 23.24, 21.74, 26.79, 22.9, 19.8,
            18.1, 18.38, 16.38, 17.51, 18.46, 18.94, 19.82, 23.91, 28.44, 27.9, 25.76, 21.07, 23.65, 19.49, 23.74, 20.3, 19.14,
            23.01, 20.4, 23.02, 26.81, 22.82, 26.71, 29.21, 28.52, 27.91, 27.66, 30.47, 30.49, 26.52, 21.36, 19.78, 17.76, 15.98,
            10.06, 15.05, 18.36, 20.37, 21.74, 23.09, 28.16, 23.95, 27.72, 27.01, 32.39, 31.81, 35.15, 32.98, 28.47, 23.6, 19.47,
            23.1, 23.04, 26.57, 24.51, 22.28, 21.9, 20.13, 19.83, 18.93, 17.61, 15.39, 18.32, 21.16, 20.94, 20.26, 25.17, 24.79,
            25.75, 23.96, 28.8, 27.02, 31.59, 31.81, 34.4, 29.58, 33.29, 34.79, 36.02, 41.03, 39, 37.55, 41.5, 46.12, 45.15,
            44.78, 43.66, 39.96, 34.05, 35.33, 33.52, 31.27, 36.24, 39.48, 39.13, 41.83, 36.79, 32.77, 37.95, 34.91, 29.32, 33.41,
            35.26, 39.12, 37.11, 39.82, 39.88, 38.5, 36.36, 33.62, 35.34, 36.49, 39.59, 42.29, 41.29, 39.74, 35.22, 35.81, 30.75,
            34.87, 35.05, 32.55, 37.36, 37.85, 32.92, 37.86, 33.57, 33.11, 29.72, 34.32, 36.63, 35.01,
        ],
        [
            -59.21, -62.03, -66.06, -68.2, -73.02, -73.09, -77.63, -79.2, -79.28, -79.2, -79.79, -78.48, -79.9, -79.94, -79.09,
            -79.95, -76.81, -79.66, -79.13, -75.3, -76.27, -74.06, -76.69, -71.34, -67.39, -69.2, -72, -67.9, -64.45, -63.77,
            -66.36, -69.81, -72.25, -73.34, -71.44, -72.64, -71.23, -75.12, -72.34, -77.19, -79.07, -77.91, -74.73, -75.31,
            -75.54, -71.72, -74.74, -69.32, -71.27, -69.52, -70.12, -74.23, -76.82, -73.91, -76.66, -71.56, -66.55, -66.34,
            -71.74, -75.84, -72.13, -69.62, -64.65, -60.93, -56.11, -60.86, -60.75, -59.03, -57.69, -52.17, -53.12, -49.06, -53.3,
            -50.2, -47.01, -45.67, -49.47, -53.98, -48.27, -45.87, -46.53, -46.1, -50.47, -45.76, -44.21, -47.02, -42.67, -40.38,
            -35.95, -32.52, -37.23, -41.17, -44.64, -43.32, -44.44, -45.31, -42.71, -39.54, -38.75, -38.03, -43.71, -43.75,
            -48.93, -47.79, -42.81, -39.96, -43.76, -42.63, -41.02, -41.55, -43.94, -41.71, -46.36, -46, -48.94, -50.14, -53.5,
            -49.74, -50.24, -54.5, -54.29, -56.71, -57.04, -62.64, -61.58, -56.11, -61.52, -60.05, -61.02, -59.82, -57.32, -61.21,
            -58.12, -63.68, -68.31, -65.53, -70.9, -69.9, -70.18, -71.68, -73.46, -75.97, -75.2, -71.89, -67.84, -66.2, -62.7,
            -61.95, -64.02, -64.38, -68.81, -64.64, -60.14, -59.45, -55.14, -58.5, -57.36, -59.15, -62.53, -57.1, -62.55, -61.29,
            -60.61, -57.97, -53.1, -56.08, -60, -60.14, -57.12, -57.32, -57.36, -53.34, -48.29, -45.76, -45.4, -50.25, -46.09,
            -41.15, -41.93, -46.25, -44.43, -44.58, -40.11, -37.72, -33.78, -36.21, -32.05, -28.14, -25.99, -24.99, -25.32, -27.4,
            -22.55, -26.22, -23.89, -20.06, -25.43, -23.05, -22.65, -20.74, -17.35, -19.45, -20.1, -22.91, -22.55, -21.77, -26.16,
            -28.38, -25.99, -20.87, -17.57, -16.4, -21.26, -23.1, -21.77, -25.41, -23.56, -22.25, -24.32, -26.51, -21.5, -19.7,
            -20.09, -24.45, -23.97, -27.75, -23.29, -25.95, -28.77, -31.83, -27.1, -23.39, -18.29, -21.19, -20.01, -16.66, -22,
            -17.53, -20.94, -25.18, -20.17, -15.17, -11.89, -10.48, -5.29, -6.65, -6.27, -3.61, -2.19, -7.78, -4.84, -0.88, -1.66,
            0.84, 2.43, 7.67, 10, 11.6, 6.86, 9.87, 4.5, 0.38, 2.11, 2.39, 7.97, 2.53, 5, 7.77, 7.32, 3, 1.49, 0.97, 2.07, 3.83,
            2.89, 7.18, 4.89, 8.07, 8.99, 12.82, 11.78, 7.78, 6.46, 9.55, 5.17, 10.11, 7.72, 8.33, 6.79, 8.51, 4.81, 6.49, 6.41,
            3.82, 1.26, 2.3, 7.76, 3.37, 1.4, -0.06, -5.06, -6, -9.01, -14.09, -11.1, -16.89, -11.93, -7.42, -10.55, -8.08,
            -12.57, -17.58, -16.14, -17.37, -16.61, -18.79, -15.74, -11.29, -6.31, -6.8, -7.42, -4.17, -5.84, -4.15, -0.27, -3.04,
            -3.91, -0.53, -1.97, -1.76, 1.34, -0.4, 2.1, 6.63, 11.42, 7.82, 7.76, 11.79, 12.39, 13, 11.97, 7.54, 7.85, 11.04,
            11.21, 13.38, 14.85, 10.64, 12.81, 14.95, 9.67, 5.78, 1.35, -0.98, -5.64, -2.41, 2.79, -0.6, 0.2, 2.07, 1.1, -3.62,
            1.93, -2.88, -2.72, 2.67, 6.32, 8.53, 5.47, 7.51, 6.43, 6.84, 2.31, 1.09, -0.04, 2.77, 1.84, 1.44, -3.33, -4.91,
            -3.91, -1.67, 0.15, 4.31, 3.57, 0, 2.05, 1.21, 3.54, 8.69, 10.4, 13.08, 12.92, 15.28, 18.56, 19.85, 17.21, 22.29,
            27.62, 31.22, 28.91, 32.74, 28.87, 26.11, 31.64, 28.01, 27.1, 30.26, 32.55, 29.63, 31.37, 30.81, 33.9, 34.76, 37.98,
            36.46, 33.75, 32.42, 29.47, 26.65, 26.45, 22.99, 23.46, 26.31, 21.75, 23.33, 18.9, 23.4, 24.13, 28.44, 28.45, 29.42,
            27.08, 32.58, 30.08, 35.89, 38.71, 34.64, 38.71, 37.99, 34.47, 34.38, 30.59, 29.68, 26.71, 28.22, 30.95, 31.63, 27.73,
            31.72, 31.04, 34.48, 38.49, 33.69, 36.74, 39.42, 44.07, 48.55, 50.05, 53.36, 55.9, 58.69, 55.21, 54.51, 52.13, 51.71,
            49.1, 49.29, 46.25, 44.37, 39.68, 36.97, 35.59, 36.84, 41.74, 46.91, 43.08, 42.04, 39.98, 37.05,
        ],
        [
            134.06, 137.57, 139.04, 134.6, 129.69, 133.78, 132.7, 137.4, 135.44, 138.18, 136.87, 140.19, 139.21, 140.16, 142.46,
            144.57, 149.08, 150.55, 149.86, 150.16, 149.59, 144.32, 147.59, 145.24, 150.84, 148.03, 149.41, 147.96, 150.75, 148,
            148.68, 145.25, 146.99, 145.03, 145.63, 141.26, 136.58, 132.75, 136.94, 139.24, 139, 134.25, 132.33, 137.92, 142.43,
            139.93, 137.6, 133.98, 136.83, 134.92, 132.99, 127.33, 128.03, 129.14, 132.66, 131.8, 131.06, 129.04, 129.24, 124.36,
            127.82, 125.21, 125.75, 125.58, 129.71, 128.68, 133.34, 134.99, 135.63, 140.11, 137.68, 132.39, 133.61, 133.95,
            137.89, 137.16, 141.95, 136.46, 140.75, 143.27, 145.82, 145.74, 148.74, 143.15, 145.51, 146.64, 141.73, 137.21,
            140.56, 144.68, 146.66, 142.49, 142.72, 141.18, 143.85, 140.87, 144.72, 144.15, 144.69, 141.67, 145.68, 145.54,
            147.87, 146.14, 147.93, 150.15, 150.69, 149.85, 149.85, 147.5, 145.67, 144.93, 149.78, 150.47, 150.42, 148.54, 145.53,
            148.73, 143.92, 146.74, 148.45, 148.89, 150.38, 150.54, 146.17, 150.49, 150.94, 147.2, 150.65, 145.8, 142.45, 137.36,
            142.26, 146.38, 141.63, 144.35, 141.94, 138.42, 139.53, 140.43, 145.38, 150.25, 146.69, 147.19, 147.57, 150.9, 150.23,
            150.29, 150.28, 147.56, 148.08, 148.48, 144.77, 141.16, 144.22, 142.69, 146.36, 147.34, 150.43, 150.04, 150.68, 150.8,
            150.03, 150.59, 148.86, 148.85, 150.96, 147.84, 144.43, 144.99, 139.07, 137.99, 133.21, 131.29, 128.74, 128.52,
            127.58, 129.92, 127.99, 132.18, 129.57, 132.66, 127.66, 122.95, 123.59, 127.68, 126.27, 131.97, 133.06, 132.93,
            133.11, 131.65, 136.32, 141.29, 143.69, 143.84, 144.4, 146.07, 144.76, 146.79, 144.2, 143.71, 140.56, 142.16, 143.16,
            146.08, 147.78, 146.33, 142.85, 143.62, 147.24, 150.13, 149.98, 150.75, 148.34, 143.86, 147.3, 142.97, 147.76, 146.87,
            149.89, 150.84, 150.5, 147.03, 149.67, 150.18, 150.73, 145.14, 141.15, 140.51, 137.08, 141.09, 145.26, 145.2, 140.04,
            140.34, 143.37, 148.42, 146.03, 147.32, 150.5, 150.39, 150.55, 150.46, 150.62, 150.73, 145.05, 146.62, 150.15, 145.3,
            147.27, 148.96, 147.17, 150.6, 150.45, 150.16, 147.56, 142.78, 140.03, 144.3, 145.8, 145.16, 148.45, 150.14, 150.52,
            150.66, 147.16, 148.93, 144.9, 141.97, 141.4, 139.51, 143.45, 145.23, 140.97, 143.51, 140.14, 144.09, 144.65, 148.24,
            144.64, 144.86, 149.81, 148.05, 150.52, 150.98, 147.72, 149.26, 150.1, 150.78, 150.1, 146.66, 148.9, 146.85, 148.66,
            150.52, 147.04, 144.04, 142.75, 140.66, 145, 148.55, 149.09, 150.71, 150.57, 145.87, 147.31, 142.29, 147.96, 150.48,
            150.08, 150.88, 150.6, 150.94, 149.6, 145.78, 143.39, 143.16, 141.03, 145.17, 147.33, 150.15, 145.52, 148.94, 150.14,
            150.77, 149.45, 150.13, 150.47, 148.49, 150.22, 150.43, 150.29, 146.88, 142.01, 139.29, 139.51, 138.51, 137.05,
            134.09, 135.64, 138.17, 141.56, 143.13, 146.25, 145.06, 149.79, 144.5, 140.5, 136.06, 141.53, 146.86, 148.72, 147.88,
            150.32, 145.82, 150.85, 150.29, 150.39, 150.77, 150.7, 146.45, 143.45, 142.32, 140.17, 138.11, 142.02, 143.45, 144.63,
            142.28, 137.75, 141.04, 144.54, 141.99, 142.51, 146.56, 150.03, 150.86, 147.83, 150.75, 150.98, 149.57, 150.77, 150.5,
            150.24, 150.94, 150.11, 148.6, 148.33, 150.29, 150.77, 150.1, 150.74, 146.62, 146.47, 142.64, 142.35, 143.66, 142.68,
            140.92, 138.72, 142.32, 137.28, 132.07, 130.35, 130.27, 134.28, 129.68, 129.89, 128.88, 124.99, 121.2, 121.41, 122.79,
            119.23, 114.95, 116.88, 112.3, 109.97, 113.44, 112.37, 111.82, 116.17, 115.66, 114.33, 119.55, 120.16, 119.54, 120.94,
            116.56, 117.88, 122.61, 122.6, 118.1, 120.23, 125.39, 126.98, 127.95, 127.92, 123.87, 122.79, 117.06, 115.04, 117.45,
            113.7, 108.16, 108.41, 113.63, 110.23, 106.55, 103.45, 101.53, 106.36, 101.34, 97.42, 92.88, 91.23, 91.66, 88.89,
            92.13, 89.59, 90.37, 95.33, 93.88, 98.38, 100.07, 102.15, 98.71, 99.39, 103.55, 108.35, 104.27, 106.83, 111.08,
            107.35, 106.49, 111.72, 116.27, 117.64, 116.55,
        ],
        [
            -64.91, -61.43, -63.3, -68.46, -66.49, -62.32, -63.57, -67.38, -67.9, -64.69, -59.07, -56.4, -53.15, -48.46, -52.58,
            -55.95, -58.19, -59.78, -58.93, -54.29, -49.48, -48.76, -48.86, -43.18, -40.98, -37.35, -41.94, -39.82, -37.21,
            -39.74, -34.7, -33.43, -35.21, -33.41, -36.43, -33.71, -28.24, -30.83, -25.51, -30.53, -25.49, -29.74, -24.48, -21.34,
            -26.27, -23.17, -26.12, -29.81, -30.53, -32.41, -27.2, -31.42, -31.19, -28.84, -31.63, -30.86, -25.41, -22.18, -21.39,
            -22.63, -18.55, -20.14, -24.73, -20.57, -20.66, -22.23, -25.81, -27.29, -27.13, -30.35, -32.12, -33.5, -29.66, -33.63,
            -28.77, -24.81, -25.76, -21.62, -24.48, -19.62, -17.96, -19.29, -16.05, -19.77, -16.89, -15.33, -16.76, -21.6, -22.55,
            -24.8, -25.07, -30.41, -33.44, -36.36, -32.14, -33.42, -34.59, -31.1, -36.5, -37.24, -35.5, -34.08, -33.54, -38.32,
            -36.75, -34.01, -37.92, -38.7, -40.23, -36.12, -36.25, -38.3, -35.98, -35.23, -31.32, -30.65, -29.16, -34.73, -37.17,
            -40.85, -37.36, -40.64, -37.81, -32.16, -30.1, -35.57, -39.96, -35.75, -34.4, -37.5, -38.23, -35.55, -36.5, -36.26,
            -31.04, -28.55, -28.01, -24.79, -26.02, -24.31, -29.04, -24.28, -20.55, -17.59, -22.98, -24.2, -27.66, -32.54, -34.31,
            -36.4, -34.67, -34.59, -30.52, -25.3, -21.44, -23.85, -26.61, -24.33, -20.71, -22.82, -22.45, -19.25, -22.99, -27.2,
            -30.79, -27.09, -31.89, -33.86, -35.67, -31.73, -30.47, -29.44, -31.09, -29.77, -32.32, -27.19, -32.2, -32.47, -33.71,
            -36.56, -32.02, -27.59, -32.55, -31.98, -29.68, -28.76, -25.47, -22.66, -23.38, -28.53, -25.2, -26.2, -21.61, -16.36,
            -14.25, -17.62, -12.99, -10.39, -5.08, -8.72, -3.01, -5.51, -4.17, -6.49, -11.4, -10.27, -6.64, -8.55, -9.69, -14.08,
            -19.17, -17.92, -16.27, -14.92, -17.78, -14.74, -9.15, -14.53, -16.03, -16.56, -12.14, -12.12, -11.85, -14.1, -19.66,
            -14.37, -15.16, -12.8, -10.86, -10.81, -13.02, -11.14, -10.3, -10.73, -9.5, -14.79, -15.6, -12.79, -7.64, -12.01,
            -11.51, -16.95, -17.79, -19.05, -20.13, -23.42, -25.44, -29.87, -27.86, -32.32, -33.96, -33.52, -29.74, -25.65, -28.4,
            -30.47, -35.18, -35.18, -31.79, -36.68, -40.91, -35.18, -31.79, -34.86, -37.7, -33.21, -32.27, -31.14, -35.01, -35.55,
            -31.78, -29.17, -25.44, -28.3, -27.96, -31.89, -29.33, -29.2, -28.56, -31.58, -36.41, -37.75, -35.34, -40.98, -36.01,
            -34.42, -34.66, -32.65, -37.03, -37.61, -40.2, -42.12, -47.53, -52.87, -50.64, -47.81, -48.57, -44.62, -49.02, -54.33,
            -51.38, -56.63, -53.41, -54.11, -51.36, -55.57, -51.68, -46.93, -51.29, -52.33, -56.34, -53.02, -54.58, -58.27,
            -55.53, -52.05, -52.38, -52.03, -57.88, -62.13, -58.35, -57.44, -57.31, -57.54, -53.04, -48.89, -51.43, -51.75,
            -46.29, -42.88, -37.07, -40.12, -35.76, -31.87, -35.44, -30.6, -33.54, -29.07, -28.1, -30.7, -27.89, -32.14, -33.7,
            -28.5, -27.51, -26.47, -31.56, -27.92, -22.09, -24.64, -22.35, -24.09, -20.18, -24.88, -25.12, -30.31, -33.89, -36.29,
            -39.53, -35.9, -32.54, -31.93, -30.59, -28.71, -32.36, -37.84, -36.87, -37.7, -36.36, -39.19, -38.82, -40.01, -40.66,
            -41.62, -36.19, -36.3, -38.15, -43.55, -46.29, -41.76, -46.09, -49.39, -53.82, -56.21, -59.05, -61.12, -56.01, -53.98,
            -54.31, -57.35, -54.97, -52.87, -56.86, -54.28, -51.27, -49.84, -50.23, -50.29, -45.11, -49.08, -47.35, -52.09,
            -47.13, -46.49, -45.43, -50.67, -47.93, -47.08, -52.06, -55.71, -51.21, -47.63, -44.57, -42.72, -38.56, -36.73,
            -35.42, -39.54, -34.49, -30.44, -26.41, -27.57, -26.58, -24.31, -25.47, -28.92, -30.56, -28.73, -31.74, -27.38,
            -23.25, -23.69, -23.92, -23.04, -22.62, -21.62, -24.89, -21.71, -17.99, -20.8, -25.22, -28.81, -25.33, -27.23, -32.42,
            -36.84, -40.29, -45.89, -50.66, -49.94, -50.21, -47.49, -49.13, -48.2, -46.28, -43.23, -47.61, -50.11, -52.84, -55.95,
            -58.92, -59.37, -62.9, -60.33, -59.09, -54.56, -51.71, -52.97, -55.82, -55.58, -60.59, -61.33, -59.24, -64.62, -63.04,
            -63.49, -67.77, -64.01, -68.39, -73.25,
        ],
        [
            135.74, 136.05, 133.04, 136.63, 140.45, 140.04, 140.19, 142.45, 147.23, 150.71, 150.08, 150.52, 145.12, 145.38,
            145.05, 145.16, 142.89, 139.14, 140.82, 144.66, 139.93, 143.76, 138.8, 133.08, 128.13, 124.39, 127.43, 130.13, 129.67,
            127.82, 126.65, 128.05, 129.16, 132.59, 130.43, 127.89, 126, 131.09, 133.35, 131.08, 132.12, 136.27, 135.31, 137.62,
            138.35, 138.47, 143.15, 139.34, 141.31, 136.48, 136.28, 138.22, 140.37, 138.79, 142.09, 141.6, 146.38, 147.86, 150.14,
            148.35, 148.4, 150.62, 145.7, 146.25, 150.98, 150.41, 150.34, 147.73, 148.54, 150.88, 150.61, 150.55, 150.35, 150.37,
            150.24, 150.39, 146.68, 149.51, 150.18, 149.93, 150.57, 146.66, 144.51, 147.64, 149.07, 150.64, 145.96, 140.76,
            143.06, 141.75, 137.02, 140.13, 138.63, 136.54, 137.52, 141.93, 143.92, 147.5, 145.33, 149.87, 147.54, 149.72, 150.76,
            148.51, 144.76, 148.15, 144.62, 142.45, 141.22, 142.54, 140.79, 142.37, 138.1, 135.75, 139.85, 140.06, 138.62, 139.87,
            144.89, 141.09, 136.11, 138.26, 138.61, 142.4, 140.92, 142.15, 141.08, 138.53, 136.19, 132.95, 136.71, 133.2, 131.84,
            135.52, 140.44, 140.97, 145.45, 146.57, 144.73, 148.12, 147.79, 146.72, 143.66, 139.86, 144.67, 146.77, 149.41,
            150.81, 147.08, 146, 149.11, 148.48, 150.13, 148.84, 148.84, 146.24, 146.3, 145.41, 145.09, 141, 140.98, 135.76,
            138.35, 137.49, 134.06, 136.69, 141.37, 143, 148.82, 149.04, 150.06, 150.61, 150.71, 150.5, 150.09, 148.61, 146.32,
            141.06, 146.45, 144, 144.36, 143.43, 147.58, 145.11, 147.61, 146.32, 145.34, 146.11, 148.67, 150.23, 150.4, 150.44,
            149.19, 150.88, 146.95, 150.19, 147.87, 146.09, 143.46, 144.36, 147.84, 150.76, 150.49, 150.19, 149.37, 146.36,
            148.69, 148.62, 143.81, 138.36, 134.19, 134.71, 139.81, 138.26, 139.7, 139.91, 135.15, 131.59, 126.09, 130.64, 126.14,
            128.11, 125.86, 120.58, 115.14, 119.32, 115.54, 111.83, 107.46, 102.87, 106.74, 106, 103.9, 102.64, 105.18, 101.38,
            100.97, 104.71, 100.99, 100.25, 96.37, 97.95, 93.11, 97.68, 96.13, 99.33, 98.79, 102.64, 100.56, 96.81, 101.46, 99.38,
            95.2, 91.05, 93.67, 92.55, 92.44, 90.54, 85.81, 90.44, 92.29, 88.57, 88.34, 92.44, 96.38, 91.12, 96.87, 100.73, 97.16,
            100.38, 95.68, 99.97, 97.42, 98.55, 93.02, 98.25, 100.57, 96.17, 97.16, 99.17, 101.16, 105.79, 103.36, 106.88, 111.74,
            110.27, 111.39, 107.56, 102.4, 101.01, 100.25, 105, 100.18, 104.47, 104.57, 99.91, 103.16, 103.4, 100.79, 103.17,
            104.46, 108.14, 110.98, 106.5, 110.11, 110.98, 110.26, 114.67, 118.79, 116.53, 121.64, 123.43, 121.77, 119.39, 119.7,
            124.26, 126.61, 125.34, 123.41, 124.36, 128.48, 131.62, 132.99, 127.28, 123.4, 120.76, 121.91, 123.03, 122.05, 122.67,
            124.4, 126.77, 122.42, 118.2, 113.96, 116.46, 115.94, 117.78, 118.96, 113.58, 114.06, 112.84, 111.29, 106.55, 109.26,
            110.28, 110.63, 105.8, 101.95, 105.58, 105.68, 106.62, 108.79, 113.74, 108.16, 109.59, 104.89, 102.57, 106.03, 102.86,
            102.38, 103.44, 101.94, 101.76, 104.34, 99.72, 98.32, 95.22, 92.81, 87.56, 84.29, 80.02, 79.45, 79.4, 78.41, 74.52,
            76.41, 80.14, 80.34, 76.08, 72.77, 76.84, 81.17, 81.24, 79.44, 82.63, 80.34, 79.23, 75.26, 78, 73.04, 68.26, 67.2,
            62.42, 62.76, 66.86, 69.74, 67.06, 69.84, 68.19, 67.84, 70.38, 70.35, 65.17, 64.53, 59.32, 56.6, 52.82, 53.29, 49.87,
            48.36, 43.56, 46.23, 41.16, 43.8, 44.66, 46.1, 51.16, 53.32, 53.18, 53.96, 48.74, 45.37, 40.15, 42.27, 45.22, 44.67,
            48.18, 52.41, 50.37, 47.68, 46.11, 49.68, 54.92, 51.84, 50.96, 47.76, 50.94, 50.87, 47.99, 50.1, 51.95, 56.18, 52.87,
            55.47, 52.7, 51.07, 46.35, 44.73, 39.45, 40.73, 45.09, 49.92, 49.21, 46.59, 48.66, 53.73, 51.2, 50.77, 53.78, 56.42,
            55.93, 56.74, 54.85, 55.8, 59.34, 54.63, 49.18, 54.73, 56.45, 54.44, 59.69, 55.7, 57.56, 57.54, 60.13, 60.83, 55.78,
            57.96, 52.18,
        ],
        [
            -39.4, -44.75, -49.09, -49.24, -46.29, -46.35, -45.36, -44.54, -48.25, -48.2, -46.79, -41.69, -46.8, -49.65, -50.54,
            -49.29, -47.6, -44.75, -44.13, -48.04, -49.26, -45.27, -44.4, -47.9, -44.62, -42.66, -39.02, -41.44, -37.91, -35.96,
            -35.89, -32.64, -37.2, -35.93, -39.44, -36.35, -34.55, -30.74, -33.14, -31.99, -30.23, -25.08, -28.6, -28.66, -24.69,
            -24.58, -21.12, -23.15, -18.13, -18.06, -19.08, -15.25, -13.65, -18.95, -15.1, -16.25, -14.58, -19.82, -23.07, -21.79,
            -20.36, -17.48, -19.48, -22.65, -19.91, -23.72, -23.43, -27.19, -25.8, -27.48, -28.58, -24.69, -21.44, -25.34, -20.8,
            -20.16, -17.33, -18, -19.7, -14.75, -11.97, -6.92, -4.47, 0.36, -0.63, -5.24, -8.14, -9.77, -9.3, -6.22, -8.74,
            -12.47, -9.72, -12.85, -12.2, -7.19, -10.97, -7.8, -12.18, -14.54, -12.54, -10.82, -14.27, -16.72, -16.43, -16.84,
            -20.65, -15.86, -20.08, -15.73, -11.4, -15.02, -14.66, -15.05, -11.56, -14.99, -14.3, -13.65, -15.55, -19.35, -22.3,
            -26.59, -27.08, -32.71, -28.1, -26.22, -22.94, -19.56, -16.92, -11.63, -8.45, -4.54, -2.66, -1.13, 1.33, 0.37, 2.47,
            0.65, 0.02, 2.9, -2.39, 0.7, 1.15, -3.81, -0.48, -4.22, -9.54, -13.32, -14.88, -19.54, -21.46, -19.71, -14.67, -16.97,
            -14.15, -19.97, -18.48, -13.43, -14.95, -13.54, -9.47, -4.06, -8.39, -11.04, -8.43, -8.64, -4.78, -0.16, -2.68, -4.5,
            -3.34, -6.11, -10.21, -15.43, -12.03, -7.35, -8.18, -6.63, -7.02, -12.44, -14.57, -10.84, -11.89, -8.7, -4.3, -0.01,
            -4.17, -4.68, -7.67, -8.68, -5.79, -7.09, -11.46, -12.93, -17.91, -16.22, -17.34, -15.76, -12.01, -7.39, -11.67,
            -16.66, -16.38, -15.17, -18.07, -15.67, -14.43, -13.39, -11.4, -11.05, -7.11, -10.98, -7.39, -3.14, -7.79, -11.71,
            -7.12, -12.19, -13.44, -10.1, -9.84, -6.88, -3.52, -3.73, -4.6, -1.36, -6.86, -8.64, -9.06, -12.88, -17.53, -14.22,
            -16.45, -21.41, -24.8, -24.49, -21.91, -26.53, -31.25, -26.61, -23.04, -19.85, -20.5, -17.64, -19.97, -16.16, -20.84,
            -16.05, -14.16, -14.83, -12.16, -11.62, -6.08, -8.21, -11.14, -15.85, -16.8, -14.47, -19.49, -16.6, -12.87, -15.79,
            -16.54, -20.63, -22.55, -25, -30.5, -27.57, -27.5, -30.02, -26.62, -24.22, -27.77, -31.31, -31.06, -36.49, -40.89,
            -42.73, -44.32, -41.45, -40.37, -41.8, -37.42, -36.43, -39.78, -41.49, -40.92, -39.92, -38.66, -43.7, -38.75, -41.1,
            -43.54, -45.1, -40.01, -42.97, -44.55, -48.11, -44.63, -46.72, -41.92, -44.52, -45.19, -48.71, -53.2, -57.72, -55.35,
            -59.19, -62.56, -60.36, -60.97, -63.16, -62.64, -58.61, -62.74, -62.5, -66.02, -68.1, -69.51, -68.16, -73.51, -70.28,
            -71.5, -73.77, -74.75, -73.81, -70.41, -67.89, -66.29, -63.93, -68.05, -68.45, -67.15, -71.22, -67, -62.42, -65.25,
            -68.23, -67.73, -62.25, -59.14, -54.51, -52.51, -51.55, -55.22, -60.8, -64.56, -62.94, -61.51, -60.1, -60.62, -61.93,
            -66.73, -62.95, -60.55, -57.26, -53.16, -58.02, -63.93, -65.38, -67.69, -66.6, -61.5, -65.55, -70.67, -69.73, -74.3,
            -69.13, -70.07, -70.53, -73.88, -77.37, -79.13, -79.3, -78.51, -79.83, -76.34, -75.36, -73.12, -71.73, -75.02, -74.16,
            -75.56, -79.62, -79.89, -77.9, -77.1, -73.61, -70.38, -73.81, -75.51, -70.55, -71.29, -73.24, -70.95, -70.89, -65.12,
            -69.82, -74.62, -69.89, -73.75, -77.78, -72, -67.74, -68.98, -70.05, -74.29, -75.74, -79.32, -74.82, -74.25, -78.52,
            -78.23, -79.58, -79.82, -76.97, -74.47, -75.97, -76.81, -78.87, -79.77, -77.04, -76.61, -75.46, -76.37, -71.32,
            -70.05, -65.5, -65.68, -62.68, -57.6, -60.59, -64.01, -64.38, -66.9, -66.76, -71.11, -70.64, -75.24, -79.29, -79.69,
            -79.45, -79.29, -75.98, -72.7, -69.82, -71.51, -66.49, -65.56, -62.03, -58.78, -53.62, -49.32, -50.29, -47.93, -46.72,
            -48.96, -43.68, -38.45, -40.09, -42.29, -40.46, -40.91, -45.65, -40.31, -39.87, -43.29, -42.71, -46.13, -49.19,
            -47.03, -53, -48.53, -52.15, -47.73, -43.65, -48.29, -47.88, -47.05, -51.41,
        ],
        [
            30.58, 27.17, 22.51, 22.79, 17.24, 19.74, 20.77, 15.06, 14.56, 11.42, 10.02, 10.65, 14.72, 15.79, 16.89, 20.73, 22.91,
            23.58, 24.09, 28.5, 27.73, 29.59, 33.9, 35.88, 35.64, 36.57, 34.86, 32.48, 30.64, 34.81, 32.36, 27.66, 23.78, 23.64,
            27.72, 23.33, 19.03, 14.7, 16.52, 15.29, 10.57, 9.03, 8.46, 10.06, 14.28, 17.53, 14.1, 11.98, 7.81, 2.77, 6.66, 3.96,
            2.98, 2.77, 1.52, 2.55, -1.15, -2.54, 2.8, 4.84, 0.57, 3.36, -1.24, 0.72, 4.38, 3.68, 7.54, 5.14, 5.33, 10.52, 10.44,
            10.16, 11.63, 7.92, 6.47, 7.07, 2.09, 4.77, 7, 8.92, 4.9, 2.23, 7.29, 3.38, 5.9, 8.99, 12.49, 11.98, 6.8, 1.72, 3.35,
            5.29, 4.76, 5.72, 8.66, 10.88, 14.02, 11.76, 7.53, 9.6, 4.45, 6.13, 11.89, 7.85, 12.08, 17.96, 12.23, 9.36, 6.27,
            7.33, 12.45, 10.02, 14.67, 9.9, 13.08, 15.71, 18.72, 19.42, 16.17, 13.42, 12.58, 11.37, 6.16, 10.54, 8.76, 6.08, 7.78,
            7.55, 2.69, -2.91, -4.6, -0.33, 4.94, 9.16, 13.9, 18.33, 22.29, 22.46, 23.59, 22.86, 23.58, 21.68, 19.07, 17.03,
            22.82, 18.32, 14.38, 17.11, 13.9, 18.52, 16.29, 19.17, 19.7, 14.21, 13.61, 17.05, 12.69, 10.39, 12.78, 15.11, 20.6,
            17.15, 21.44, 17.54, 12.79, 8.85, 13.13, 17.33, 21.91, 20.91, 22.18, 20.34, 17.24, 17.63, 22.67, 18.75, 20.6, 24.24,
            26.89, 23.64, 23.63, 26.66, 27.17, 30.07, 32.09, 32.32, 37.72, 41.84, 38.43, 33.51, 34.47, 35.68, 33.68, 37.16, 35.54,
            37.34, 38.41, 39.22, 43.94, 44.6, 41.98, 43.22, 38.73, 39.86, 42.35, 40.44, 43.43, 38.4, 35.12, 34.21, 37.59, 35.69,
            32.36, 28.33, 30.01, 29.93, 25.72, 27.78, 27.01, 25.5, 21.46, 23.68, 20.89, 16, 15.84, 19.25, 15.27, 17.21, 18.15,
            15.3, 13.3, 13.99, 18.56, 19.59, 15.83, 14.19, 10.89, 5.23, 8.07, 4.79, 5.5, 4.35, 8.3, 3.9, 2.45, 1.67, 0.57, -1.53,
            -3.89, -8.92, -4.68, -6.71, -4.44, -0.02, -4.09, -6.73, -3.34, 1.04, 6.94, 6.85, 10.81, 6.55, 1.81, -0.15, -1.11,
            -4.47, -5.99, -5.77, -6.97, -2.95, 0.15, -0.45, -3.09, 1.81, 3.04, -1.25, -1.14, -0.24, 2.66, 3.92, 1.86, 2.21, 3.65,
            3.48, 3.43, 1.16, -2.42, 1.97, 1.13, 3.4, 0.47, -0.43, -2.01, -4.85, 0.61, -2.93, -4.2, 0.58, 1.14, 6.04, 1.6, 1.36,
            5.62, 0.95, 2.4, 6.83, 10.8, 12.91, 11.58, 11.62, 13.64, 17.49, 17.28, 14.98, 13.99, 18.22, 13.98, 9.02, 7.64, 7.16,
            11.47, 7.14, 8.78, 5.39, 9.36, 11.27, 7.1, 2.64, 6.76, 10.81, 12.25, 12.51, 15.99, 17.68, 16.82, 19.83, 15.37, 16.39,
            20, 23.15, 27.2, 28.99, 23.82, 20.11, 20.33, 21.26, 17.68, 16.22, 19.96, 24.16, 28.69, 30.5, 34.48, 29.37, 25.57,
            20.96, 25.13, 30.92, 33.06, 36.01, 41.11, 46.65, 47.69, 44.05, 43.27, 44.98, 48.35, 49.23, 45.1, 49.93, 52.71, 56.58,
            58.51, 54.04, 55.45, 56.62, 56.08, 61.4, 64.4, 61.94, 59.96, 61.62, 60.89, 55.68, 60.81, 55.92, 52.33, 53.2, 56.94,
            60.37, 63.79, 62.01, 61.77, 65.7, 65.05, 64.71, 67.25, 71.17, 71.23, 69.85, 66.35, 69.36, 64.33, 61.26, 66.26, 70.58,
            66.42, 71.87, 72.59, 74.27, 69.19, 71.58, 72.28, 69.88, 64.84, 61.95, 62.78, 63.1, 68.31, 63.64, 59.88, 62.24, 60.64,
            55.91, 51.05, 54.86, 59.6, 60.87, 62.16, 58.28, 59.85, 56.18, 57.15, 59.69, 62.33, 62.8, 65.01, 64.12, 68.1, 70.06,
            75.54, 74.39, 76.98, 78.7, 77.16, 81.11, 76.13, 81.57, 79.99, 78.19, 79.87, 82.12, 81.95, 86.71, 85.63, 86.18, 86.45,
            84.64, 80.68, 81.86, 79.29, 81.99, 82.75, 77.23, 79.62, 78.99, 82.43, 86.79, 84.9, 83.76, 80.2, 82.64, 78.86, 81.86,
            77.46, 73.64, 68.57, 63.94, 60.31, 61.31,
        ],
    ];
    function getRandomNuberFromString(value) {
        // ????? hash ??? input string
        // const hash = crypto.createHash("sha256").update(value).digest("hex");
        const hash = CryptoJS.SHA256(value).toString(CryptoJS.enc.Hex);

        // ???? hash ????????????? 16 ?????????????????? 10
        const hashValue = parseInt(hash, 16);

        // ????????? 10 ???????????????? random ?????? 1-10
        const randomNumber = hashValue % 10;

        return randomNumber;
    }

    function timeToMinutes(timeString) {
        const [hours, minutes] = timeString.split(":").map(Number);
        return hours * 60 + minutes;
    }
    function minutesToTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        // ???? 0 ?????? ???????????????? 10
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(mins).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
    }

    function replaceDigits(a, b) {
        // ???? a ????????????????????
        let aStr = a.toFixed(2);

        // ??????? integer ??? decimal ??? a
        let [integerPart, decimalPart] = aStr.split(".");

        // ?????????????? integerPart ???? b ????????????
        let newIntegerPart = integerPart
            .split("")
            .reverse()
            .map((digit, index) => {
                return b[b.length - 3 - index] || digit;
            })
            .reverse()
            .join("");

        // ??????? integer ??? decimal ???????????????
        let newNumberStr = newIntegerPart + "." + b.slice(-2);

        // ???????????? float ??? return ???????
        return parseFloat(newNumberStr);
    }

    function generateGraphRaw(baseNumber, graphPattern, startTime, endTime, currentTime, result = false) {
        const startTimeInt = timeToMinutes(startTime);
        const endTimeInt = timeToMinutes(endTime);
        const currentTimeInt = timeToMinutes(currentTime);
        const maxDiffTime = endTimeInt - startTimeInt + 1;
        const diffTime = Math.min(currentTimeInt - startTimeInt, maxDiffTime);

        const graph = [];
        let open = "-";
        let high = -Infinity;
        let low = Infinity;
        let volume = 0;

        for (let i = 0; i < diffTime; i++) {
            volume += diffTime * (baseNumber * 0.01) + 200;

            let stockValue = baseNumber + basePatternArray[graphPattern][i > 479 ? 479 - (i - 479) : i];
            if (i === 0) {
                open = stockValue;
                high = stockValue;
                low = stockValue;
            } else if (i !== diffTime - 1) {
                low = Math.min(low, stockValue);
                high = Math.max(high, stockValue);
            }

            if (disableDate.findIndex((item) => item == minutesToTime(startTimeInt + i)) > -1) {
                graph.push({
                    time: minutesToTime(startTimeInt + i),
                    value: graph[graph.length - 1]?.value,
                });
            } else {
                graph.push({
                    time: minutesToTime(startTimeInt + i),
                    value: stockValue,
                });
            }
        }

        let lastValue = graph[graph.length - 2]?.value || open;
        let change = lastValue - open;
        if (currentTimeInt > endTimeInt && result) {
            const treeFront = result.substring(0, 3);
            const twoBack = result.substring(3);
            lastValue = replaceDigits(lastValue, treeFront);
            change = replaceDigits(change, twoBack);
            low = Math.min(low, lastValue);
            high = Math.max(high, lastValue);
            graph[graph.length - 1] = {
                time: graph[graph.length - 1]?.time,
                value: lastValue,
            };
        }

        function getListOfTimes(startDate, endDate, intervalMinutes) {
            let start = new Date(startDate);
            const end = new Date(endDate);

            const times = [];

            // Loop while the start time is less than or equal to the end time
            while (start <= end) {
                // Format the current time as HH:mm
                const hours = String(start.getHours()).padStart(2, "0");
                const minutes = String(start.getMinutes()).padStart(2, "0");
                times.push(`${hours}:${minutes}`);

                // Move to the next interval
                start = new Date(start.getTime() + intervalMinutes * 60 * 1000);
            }

            return times;
        }

        // Example usage
        const intervalMinutes = 1;
        const graph_empty = getListOfTimes(
            "2000-01-01T" + graph[graph.length - 1]?.time,
            "2000-01-01T" + endTime,
            intervalMinutes
        );

        if (graph_empty.length > 0) {
            graph_empty.map((item) => {
                graph.push({
                    time: item.toString(),
                    value: undefined,
                });
            });
        }

        return {
            open,
            close: lastValue,
            high,
            low,
            volume: volume,
            change,
            value: lastValue * volume,
            graph,
        };
    }

    var baseNumber = baseNumberArray[getRandomNuberFromString(huayId)];
    var graphPattern = getRandomNuberFromString(huayId + date);

    // console.log("Base Number is " + baseNumber + " and graph pattern is " + graphPattern);
    var chartData = generateGraphRaw(baseNumber, graphPattern, startTime, endTime, currentTime, result);
    return chartData;
};
export default ChartGen;
