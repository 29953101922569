import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { useState } from "react";
import { numberFormat, numberFormat2Decimal } from "../utils/ConvertNumber";

const PlusResultValueText = ({ status, closeNumber, result }) => {
    const [closeValue, setCloseValue] = useState({
        openNumber: 0,
        closeNumber: 0,
        changeNumber: 0,
        changePercent: 0,
    });

    const showViVip = (open, close, result, status) => {
        const openNumber = Number(open);
        if (status === "ANNOUNCED") {
            const result_close = Number(close.toString().slice(0, -4) + result?.slice(0, 1) + "." + result?.slice(1, 3));
            setCloseValue({
                openNumber: openNumber,
                closeNumber: result_close,
                changeNumber: Number((result_close - open).toString().split(".")[0] + "." + result?.slice(3, 5)),
                changePercent: (((result_close - open) / open) * 100).toFixed(2),
            });
        } else {
            setCloseValue({
                openNumber: openNumber,
                closeNumber: closeNumber,
                changeNumber: Number(closeNumber - open),
                changePercent: (((closeNumber - open) / open) * 100).toFixed(2),
            });
        }
    };

    const change_percent = (((result?.close - result?.open) / result?.open) * 100).toFixed(2);

    return (
        <div className="w-full">
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>Vi-VIP</div>
                    {result?.volume == 0 || !result ? (
                        <div>-</div>
                    ) : (
                        <div style={result?.close > result?.open ? { color: "#28a745" } : { color: "#dc3545" }}>
                            {numberFormat2Decimal(result?.close) || "-"}
                        </div>
                    )}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>Open</div>
                    {result?.volume == 0 || !result ? <div>-</div> : <div>{numberFormat2Decimal(result?.open)}</div>}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>Change</div>
                    {result?.volume == 0 || !result ? (
                        <div>-</div>
                    ) : (
                        <div style={result?.change > 0 ? { color: "#28a745" } : { color: "#dc3545" }}>
                            {result?.change > 0 ? (
                                <CaretUpOutlined className="text-sm" />
                            ) : (
                                <CaretDownOutlined className="text-sm" />
                            )}
                            {numberFormat2Decimal(result?.change)}
                        </div>
                    )}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>{`Change（%)`}</div>
                    {result?.volume == 0 || !result ? (
                        <div>-</div>
                    ) : (
                        <div style={change_percent > 0 ? { color: "#28a745" } : { color: "#dc3545" }}>
                            {change_percent > 0 ? (
                                <CaretUpOutlined className="text-sm" />
                            ) : (
                                <CaretDownOutlined className="text-sm" />
                            )}
                            {numberFormat2Decimal(change_percent)}
                        </div>
                    )}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>High</div>
                    {result?.volume == 0 || !result ? <div>-</div> : <div>{numberFormat2Decimal(result?.high)}</div>}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>Low</div>
                    {result?.volume == 0 || !result ? <div>-</div> : <div>{numberFormat2Decimal(result?.low)}</div>}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>Total Value (Mil.)</div>
                    {result?.volume == 0 || !result ? <div>-</div> : <div>{numberFormat(result?.value)}</div>}
                </Flex>
            </div>
            <div className="w-full">
                <Flex justify="space-between" className="w-full border-0 border-b-[1px] border-solid border-[#eee]">
                    <div>Total volume (Mil.)</div>
                    {result?.volume == 0 || !result ? <div>-</div> : <div>{numberFormat(result?.volume)}</div>}
                </Flex>
            </div>
        </div>
    );
};

export default PlusResultValueText;
