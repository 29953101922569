import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Warper from "../../assets/images/xoso-develop/xo-sodevelop-header.png";
import Container from "../../components/Container";
import { XOSODEVELOP_ID } from "../../constants/Configs";
const { useBreakpoint } = Grid;

const color = "#720000";
const color_bg = "white";
const huayId = XOSODEVELOP_ID;
const hauayName = "Xoso Develop";

export default function XosodevelopPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    useEffect(() => {
        document.title = hauayName;
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setData({
            prize_4th_1: "03800",
            prize_4th_2: "52601",
            prize_5th_3: "8221",
            prize_4th_3: "96231",
            prize_5th_4: "9008",
            prize_2digits_4: "26",
            prize_4th_4: "28492",
            prize_5th_1: "9111",
            prize_4th_5: "20208",
            prize_5th_2: "8065",
            prize_4th_6: "74132",
            prize_7th_3: "771",
            prize_7th_1: "306",
            prize_7th_2: "261",
            prize_1st: "41500",
            prize_6th_1: "6962",
            prize_6th_6: "2548",
            prize_3rd_2: "51383",
            prize_3rd_1: "06239",
            prize_2nd: "72809",
            prize_6th_2: "1250",
            prize_6th_3: "3077",
            prize_6th_4: "3091",
            prize_6th_5: "9117",
            prize_2digits_3: "72",
            prize_2digits_2: "85",
            prize_2digits_1: "50",
        });
        setLoadingHistory(false);
        setLoading(false);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/xoso-develop/xo-sodevelop-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1050px]"}>
                <dev
                    style={{
                        width: "100%",
                        borderTop: "15px solid #ffc60a",
                        borderBottom: "5px solid #720000",
                        marginBottom: "4px",
                    }}
                />
                {/* header logo */}
                <Flex
                    align="center"
                    style={{
                        backgroundImage: `url(${Warper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1050,
                        width: "100%",
                    }}
                >
                    <Image src={Warper} preview={false} />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: `white`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        // maxWidth: 1024,
                        width: "100%",
                        borderTop: "5px solid #720000",
                        marginTop: 5,
                    }}
                >
                    <div className="pt-3 min-[600px]:px-0 px-[5px]">
                        <Flex gap={10} className="min-[600px]:flex-row flex-col">
                            {/* left content */}
                            <Flex vertical className="w-[200px] min-[600px]:flex hidden max-w-full">
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-1.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-2.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-3.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-4.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-5.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                            {/* data */}
                            <Flex flex={1} gap={10} vertical className="min-w-[300px]">
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-6.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />

                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-7.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-result-header.png")}
                                    preview={false}
                                />
                                {/* content data */}
                                <Flex className="mt-[-5px]">
                                    <Flex
                                        vertical
                                        className="w-[20%] text-sm border-solid border-0 border-[#690000] border-s-[1px] border-b-[1px]"
                                    >
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Đặc biệt
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải nhất
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Giải nhì
                                        </div>
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải ba
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0  font-medium">
                                            Giải tư
                                        </div>
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải năm
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Giải sáu
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải bảy
                                        </div>
                                    </Flex>
                                    <Flex
                                        flex={1}
                                        vertical
                                        className="border-solid border-0 border-[#690000] border-e-[1px] border-b-[1px]"
                                    >
                                        {/* biet */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-r-0 border-[1.5px] w-full border-b-0 text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                            <div>{data.prize_1st}</div>
                                        </div>
                                        {/* nhat */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-r-0 border-[1.5px] w-full border-b-0 bg-[#efefef] text-[#000] font-extrabold text-[20px]">
                                            <div>{data.prize_2nd}</div>
                                        </div>
                                        {/* nhi */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            <Flex className="h-full" flex={1}>
                                                <Flex
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {data.prize_3rd_1}
                                                </Flex>
                                                <Flex
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {data.prize_3rd_2}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* ba */}
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <Flex className="h-full" flex={1} wrap="wrap">
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_1}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_2}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_3}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_4}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_5}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_6}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* tu */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-y-0 font-medium">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_3}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_5th_4}
                                                </div>
                                            </div>
                                        </div>
                                        {/* nam */}
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <Flex className="h-full" flex={1} wrap="wrap">
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_1}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_2}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_3}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_4}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_5}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_6}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* sau */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_7th_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_7th_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]">
                                                    {data.prize_7th_3}
                                                </div>
                                            </div>
                                        </div>
                                        {/* bay */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_3}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_4}
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                </Flex>
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-result-footer.png")}
                                    className="mt-[-5px]"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-8.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-9.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xoso-develop/xo-sodevelop-10.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                            </Flex>
                            {/* right content */}
                            <Flex vertical className="w-full min-[600px]:w-[300px] max-w-full">
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/xoso-develop/xo-sodevelop-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <Divider className="bg-[#FFF] h-3 m-0" />
                    <center
                        className="py-2"
                        style={{ width: "100%", backgroundColor: "#ffc60a", borderTop: "5px solid #720000" }}
                    >
                        ©2021 xosodevelop.com, All Right Reserved.
                    </center>
                </Flex>
            </Container>
        </div>
    );
}
