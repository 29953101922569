import { Flex } from "antd";
import { useEffect, useRef, useState } from "react";
import SlotCounter from "react-slot-counter";

export default function ResultLottery({
    data,
    status,
    topic = "ຜົນການອອກເລກລາງວັນ",
    date = "",
    name = "ງວດປະຈຳວັນທີ່",
    lang = "LAO", // LAO, VIM, ENG
    color = "#BA0909",
    style,
    bgColor = "white",
    bgTopicColor = "transparent",
}) {
    const slotCounterRef = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [_data, setData] = useState({
        1: ["-----"],
        2: ["----"],
        "2back": ["--"],
        "2front": ["--"],
        3: ["---"],
        "3front": ["---"],
        "3permute": ["---", "---", "---", "---", "---"],
        "4permute": ["----", "----", "----", "----", "----"],
        "5permute": ["-----", "-----", "-----", "-----", "-----"],
        near: ["-----", "-----"],
    });

    useEffect(() => {
        if (_data["1"]?.toString() === "-----")
            setInterval(() => {
                slotCounterRef?.current?.startAnimation();
            }, 3000);
    }, [_data]);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (status === "ANNOUNCED") {
            setData(data);
        }
    }, [status, data]);

    const textLang = {
        LAO: {
            topic: "ຜົນການອອກເລກລາງວັນ",
            name: "ງວດປະຈຳວັນທີ່",
            rewardText1: "ລາງວັນທີ່ 1",
            rewardNear1: "ລາງວັນໃກ້ໆລາງວັນທີ່ 1",
            rewardText2: "ລາງວັນທີ່ 2",
            rewardText3: "ລາງວັນທີ່ 3",
            rewardText4: "ລາງວັນທີ່ 4",
            rewardText5: "ລາງວັນທີ່ 5",
            rewardText6: "ລາງວັນທີ່ 6",
            rewardText2font: "ລາງວັນ 2 ໂຕໜ້າ",
            rewardText2back: "ລາງວັນ 2 ໂຕສິ້ນສຸດ",
            rewardText3font: "ລາງວັນ 3 ໂຕໜ້າ",
            rewardTextSub2font: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub2back: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub3font: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub2: "ຊື່ 4 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub3: "ຊື່ 3 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub4: "ໂຕດ 5 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub5: "ໂຕດ 4 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub6: "ໂຕດ 3 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
        },
        VIM: {
            topic: "Kết quả xổ số",
            name: "của ngày",
            rewardText1: "Giải nhất",
            rewardNear1: "Gần giải nhất",
            rewardText2: "Giải nhì",
            rewardText3: "Giải ba",
            rewardText4: "Giải tư",
            rewardText5: "Giải năm",
            rewardText6: "Giải sáu",
            rewardText2font: "Giải 2 chữ phía trước",
            rewardText2back: "Giải 2 chữ ở cuối",
            rewardText3font: "Giải 3 chữ phía trước",
            rewardTextSub2font: "Giải nhất",
            rewardTextSub2back: "Giải nhất",
            rewardTextSub3font: "Giải nhất",
            rewardTextSub2: "Bốn lần cuối cùng",
            rewardTextSub3: "ba lần cuối cùng",
            rewardTextSub4: "5 thẻ cuối giải nhất",
            rewardTextSub5: "4 thẻ cuối giải nhất",
            rewardTextSub6: "3 thẻ cuối giải nhất",
        },
        ENG: {
            topic: "Winning Number",
            name: "Of date",
            rewardText1: "1st prize",
            rewardNear1: "Near 1st prize",
            rewardText2: "2nd prize",
            rewardText3: "3rd prize",
            rewardText4: "4th prize",
            rewardText5: "5th prize",
            rewardText6: "6th prize",
            rewardText2font: "2 font digit",
            rewardText2back: "2 back digit",
            rewardText3font: "3 font digit",
            rewardTextSub2font: "1st prize",
            rewardTextSub2back: "1st prize",
            rewardTextSub3font: "1st prize",
            rewardTextSub2: "4 back digit",
            rewardTextSub3: "3 back digit",
            rewardTextSub4: "5 permutation",
            rewardTextSub5: "4 permutation",
            rewardTextSub6: "3 permutation",
        },
    };

    topic = textLang[lang].topic;
    name = textLang[lang].name;
    let rewardText1 = textLang[lang].rewardText1;
    let rewardNear1 = textLang[lang].rewardNear1;
    let rewardText2 = textLang[lang].rewardText2;
    let rewardText3 = textLang[lang].rewardText3;
    let rewardText4 = textLang[lang].rewardText4;
    let rewardText5 = textLang[lang].rewardText5;
    let rewardText6 = textLang[lang].rewardText6;
    let rewardText2font = textLang[lang].rewardText2font;
    let rewardText2back = textLang[lang].rewardText2back;
    let rewardText3font = textLang[lang].rewardText3font;
    let rewardTextSub2font = textLang[lang].rewardTextSub2font;
    let rewardTextSub2back = textLang[lang].rewardTextSub2back;
    let rewardTextSub3font = textLang[lang].rewardTextSub3font;
    let rewardTextSub2 = textLang[lang].rewardTextSub2;
    let rewardTextSub3 = textLang[lang].rewardTextSub3;
    let rewardTextSub4 = textLang[lang].rewardTextSub4;
    let rewardTextSub5 = textLang[lang].rewardTextSub5;
    let rewardTextSub6 = textLang[lang].rewardTextSub6;

    const renderPermute = (_status, data_permute, _width) => {
        if (_status === "ANNOUNCED") {
            if (_width < 400) {
                return [
                    ...data_permute,
                    ...Array.from(Array(3 - (data_permute.length % 3 === 0 ? 3 : data_permute.length % 3)).keys()).map(() => ""),
                ]?.map((item, index) => {
                    return item !== "" ? (
                        <div
                            key={index}
                            className={`min-[900px]:tracking-[5px] tracking-[1px]border-solid border-[1.5px] border-s-0 border-b-0 border-[#B9B9B9] ${(index + 1) % 3 === 0 ? "border-e-transparent" : ""} py-[7px] flex w-[33.33%] justify-center`}
                        >
                            {item}
                        </div>
                    ) : (
                        <div
                            className={`w-[33.33%] border-s-0 border-b-0 border-[#B9B9B9] border-solid border-[1.5px] ${(index + 1) % 3 === 0 ? "border-e-transparent" : ""}`}
                        />
                    );
                });
            } else {
                return [
                    ...data_permute,
                    ...Array.from(Array(5 - (data_permute.length % 5 === 0 ? 5 : data_permute.length % 5)).keys()).map(() => ""),
                ]?.map((item, index) => {
                    return item !== "" ? (
                        <div
                            key={index}
                            className={`min-[900px]:tracking-[5px] tracking-[1px]border-solid border-[1.5px] border-s-0 border-b-0 border-[#B9B9B9] ${(index + 1) % 5 === 0 ? "border-e-transparent" : ""} py-[7px] flex w-[20%] justify-center`}
                        >
                            {item}
                        </div>
                    ) : (
                        <div
                            className={`w-[20%] border-s-0 border-b-0 border-[#B9B9B9] border-solid border-[1.5px] ${(index + 1) % 5 === 0 ? "border-e-transparent" : ""}`}
                        />
                    );
                });
            }
        } else {
            if (_width < 400) {
                return [...data_permute.slice(0, 3)]?.map((item, index) => {
                    return (
                        <div
                            className={`min-[900px]:tracking-[5px] tracking-[1px]border-solid border-[1.5px] border-s-0 border-b-0 border-[#B9B9B9] ${(index + 1) % 3 === 0 ? " border-e-transparent " : ""} py-[7px] flex w-[33.33%] justify-center`}
                        >
                            {item}
                        </div>
                    );
                });
            } else {
                return [...data_permute.slice(0, 5)]?.map((item, index) => {
                    return (
                        <div
                            className={`min-[900px]:tracking-[5px] tracking-[1px]border-solid border-[1.5px] border-s-0 border-b-0 border-[#B9B9B9] ${(index + 1) % 5 === 0 ? " border-e-transparent " : ""} py-[7px] flex w-[20%] justify-center`}
                        >
                            {item}
                        </div>
                    );
                });
            }
        }
    };

    return (
        <div className={`flex flex-col flex-wrap w-full pb-6 px-3 sm:px-0 rounded-lg`} style={style}>
            <Flex justify="center" className="text-[24px] rounded-ss-md rounded-se-md" style={{ background: bgTopicColor }}>
                {topic}
            </Flex>
            <Flex
                style={{ color: color, background: bgTopicColor }}
                justify="center"
                className="text-[20px] mb-3 bg-white rounded-es-md rounded-ee-md"
            >
                {name} {date}
            </Flex>
            <center
                style={{ color: color }}
                className="font-bold text-[20px] py-[10px] bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]"
            >
                {rewardText1}
            </center>
            <center
                className="min-[900px]:tracking-[5px] tracking-[1px]text-[20px] py-[10px] rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9]"
                style={{ background: bgColor }}
            >
                {_data["1"]?.toString() === "-----" ? (
                    <SlotCounter
                        value="-----"
                        animateUnchanged
                        ref={slotCounterRef}
                        animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                    />
                ) : (
                    _data["1"]?.map((item, index) => <div key={index}>{item}</div>)
                )}
            </center>
            {/* reward near 1 */}
            <center
                style={{ color: color }}
                className="mt-6 text-[20px] py-[10px] bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]"
            >
                {rewardNear1}
            </center>
            <center className="text-[20px] overflow-hidden rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] flex flex-1">
                {_data["near"]?.map((item, index) => (
                    <div
                        key={index}
                        className={`${index === 0 ? "border-solid border-e-[1.5px] border-[#B9B9B9]" : ""} py-[10px] flex flex-1 justify-center min-[900px]:tracking-[5px] tracking-[1px]`}
                        style={{ background: bgColor }}
                    >
                        {item}
                    </div>
                ))}
            </center>

            {/* reward 2 */}
            <Flex className="mt-6">
                <Flex
                    flex={1}
                    justify="center"
                    className="py-[0px] bg-[#f8f8f8] rounded-ss-md border-[1.5px] border-solid border-[#B9B9B9] flex-col"
                >
                    <center style={{ color: color }} className="text-[20px]">
                        {rewardText2}
                    </center>
                    <center className="text-[#000000] text-[13px]">{rewardTextSub2}</center>
                </Flex>

                <center
                    style={{ color: color }}
                    className="text-[20px] !w-[33%] !min-w-[120px] py-[7px] bg-[#f8f8f8] border-[1.5px] border-solid border-[#B9B9B9] border-x-0"
                >
                    <center style={{ color: color }} className="text-[20px]">
                        {rewardText3}
                    </center>
                    <center className="text-[#000000] text-[13px]">{rewardTextSub3}</center>
                </center>
                {/* </Flex> */}
                <center
                    style={{ color: color }}
                    className="text-[20px] !w-[33%] !min-w-[120px] py-[7px] bg-[#f8f8f8] rounded-se-md border-[1.5px] border-solid border-[#B9B9B9]"
                >
                    <center style={{ color: color }} className=" text-[20px]">
                        {rewardText3font}
                    </center>
                    <center className="text-[#000000] text-[13px]">{rewardTextSub3font}</center>
                </center>
            </Flex>
            <Flex>
                <Flex
                    flex={1}
                    justify="center"
                    className="text-[20px] rounded-es-md border-t-0 border-[1.5px] border-solid border-[#B9B9B9] flex flex-1 border-r-0"
                    style={{ background: bgColor }}
                >
                    {_data["2"]?.map((item, index) => (
                        <div
                            key={index}
                            className={`py-[7px] flex flex-1 justify-center min-[900px]:tracking-[5px] tracking-[1px]`}
                        >
                            {item}
                        </div>
                    ))}
                </Flex>
                <center
                    className="!w-[33%] !min-w-[121px] text-[20px] border-t-0 border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                    style={{ background: bgColor }}
                >
                    {_data["3"]?.map((item, index) => (
                        <div key={index} className={`py-[7px] !w-[33%] !min-w-[120px] min-[900px]:tracking-[5px] tracking-[1px]`}>
                            {item}
                        </div>
                    ))}
                </center>
                {/* </Flex> */}
                <center
                    className="!w-[33%] !min-w-[120px] text-[20px] rounded-ee-md border-t-0 border-[1.5px] border-solid border-[#B9B9B9]"
                    style={{ background: bgColor }}
                >
                    {_data["3front"]?.map((item, index) => (
                        <div key={index} className={`py-[7px] !w-[33%] !min-w-[120px] min-[900px]:tracking-[5px] tracking-[1px]`}>
                            {item}
                        </div>
                    ))}
                </center>
            </Flex>

            {/* reward front */}
            <Flex className="mt-6">
                <Flex
                    flex={1}
                    justify="center"
                    className="py-[0px] bg-[#f8f8f8] rounded-ss-md border-[1.5px] border-solid border-[#B9B9B9] flex-col"
                >
                    <center style={{ color: color }} className="text-[20px]">
                        {rewardText2back}
                    </center>
                    <center className="text-[#000000] text-[13px]">{rewardTextSub2back}</center>
                </Flex>
                <Flex
                    flex={1}
                    justify="center"
                    className="py-[0px] bg-[#f8f8f8] border-l-0 border-[1.5px] border-solid border-[#B9B9B9] rounded-se-md flex-col"
                >
                    <center style={{ color: color }} className="text-[20px]">
                        {rewardText2font}
                    </center>
                    <center className="text-[#000000] text-[13px]">{rewardTextSub2font}</center>
                </Flex>
            </Flex>

            <Flex>
                <Flex
                    flex={1}
                    justify="center"
                    className="text-[20px] rounded-es-md border-t-0 border-[1.5px] border-solid border-[#B9B9B9] border-r-0"
                    style={{ background: bgColor }}
                >
                    {_data["2back"]?.map((item, index) => (
                        <div
                            key={index}
                            className={`py-[7px] flex flex-1 justify-center min-[900px]:tracking-[5px] tracking-[1px]`}
                        >
                            {item}
                        </div>
                    ))}
                </Flex>
                <Flex
                    flex={1}
                    justify="center"
                    className="text-[20px] rounded-ee-md border-t-0 border-[1.5px] border-solid border-[#B9B9B9]"
                    style={{ background: bgColor }}
                >
                    {_data["2front"]?.map((item, index) => (
                        <div
                            key={index}
                            className={`py-[7px] flex flex-1 justify-center min-[900px]:tracking-[5px] tracking-[1px]`}
                        >
                            {item}
                        </div>
                    ))}
                </Flex>
            </Flex>

            {/* reward 4 */}
            {_data["5permute"]?.length > 0 && (
                <>
                    <center className="mt-6 py-[7px] border-b-0 bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]">
                        <center style={{ color: color }} className="text-[20px]">
                            {rewardText4}
                        </center>
                        <center className="text-[#000000] text-[13px]">{rewardTextSub4}</center>
                    </center>
                    <center
                        className="text-[20px] rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] flex flex-wrap"
                        style={{ background: bgColor }}
                    >
                        {renderPermute(status, _data["5permute"], width)}
                    </center>
                </>
            )}

            {/* reward 5 */}
            {_data["4permute"]?.length > 0 && (
                <>
                    <center className="mt-6 py-[7px] border-b-0 bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]">
                        <center style={{ color: color }} className="text-[20px]">
                            {rewardText5}
                        </center>
                        <center className="text-[#000000] text-[13px]">{rewardTextSub5}</center>
                    </center>
                    <center
                        className="text-[20px] rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] flex flex-1 flex-wrap"
                        style={{ background: bgColor }}
                    >
                        {renderPermute(status, _data["4permute"], width)}
                    </center>
                </>
            )}

            {/* reward 6 */}
            {_data["3permute"]?.length > 0 && (
                <>
                    <center className="mt-6 py-[7px] border-b-0 bg-[#f8f8f8] rounded-md rounded-ee-none rounded-es-none border-[1.5px] border-solid border-[#B9B9B9]">
                        <center style={{ color: color }} className="text-[20px]">
                            {rewardText6}
                        </center>
                        <center className="text-[#000000] text-[13px]">{rewardTextSub6}</center>
                    </center>
                    <center
                        className="text-[20px] rounded-md rounded-se-none rounded-ss-none border-t-0 border-[1.5px] border-solid border-[#B9B9B9] flex flex-1 flex-wrap"
                        style={{ background: bgColor }}
                    >
                        {renderPermute(status, _data["3permute"], width)}
                    </center>
                </>
            )}
        </div>
    );
}
