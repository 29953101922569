import React from "react";

export function SadSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
            <path
                fill="#FF0000"
                d="M12 1.998c5.524 0 10.002 4.478 10.002 10.002c0 5.523-4.478 10-10.002 10c-5.524.001-10-4.477-10-10C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003a8.502 8.502 0 0 0 0-17.003m0 10c1.631 0 3.16.654 4.281 1.795a.75.75 0 1 1-1.07 1.05a4.485 4.485 0 0 0-3.21-1.346a4.485 4.485 0 0 0-3.217 1.352a.75.75 0 1 1-1.072-1.05A5.984 5.984 0 0 1 12 13.498M9 8.75a1.25 1.25 0 1 1 0 2.499A1.25 1.25 0 0 1 9 8.75m6 0a1.25 1.25 0 1 1 0 2.499a1.25 1.25 0 0 1 0-2.499"
            ></path>
        </svg>
    );
}
