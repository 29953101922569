import { Flex } from "antd";
import { useEffect, useRef, useState } from "react";
import SlotCounter from "react-slot-counter";
import { generateData } from "../utils/GenerateNumber";
import FindPlusResult from "./FindPlusResult";

const PlusResult2 = ({ color, lang = "lo", date, status, resultNumber, name }) => {
    const slotCounter1Ref = useRef(null);
    const slotCounter2Ref = useRef(null);
    const slotCounter3Ref = useRef(null);
    const slotCounter4Ref = useRef(null);
    const slotCounter5Ref = useRef(null);

    // const [closeValue, setCloseValue] = useState({
    //     closeNumber: 0,
    //     changeNumber: 0,
    //     changePercent: 0,
    // });

    const [_data, setData] = useState({
        1: ["-----"],
        2: ["----"],
        "2back": ["--"],
        "2front": ["--"],
        3: ["---"],
        "3front": ["---"],
        "3permute": ["---", "---", "---", "---", "---"],
        "4permute": ["----", "----", "----", "----", "----"],
        "5permute": ["-----", "-----", "-----", "-----", "-----"],
        near: ["-----", "-----"],
    });

    // useEffect(() => {
    //     if (_data["1"]?.toString() === "-----")
    //         setInterval(() => {
    //             slotCounterRef?.current?.startAnimation();
    //         }, 3000);
    // }, [_data]);

    // useEffect(() => {
    //     if (status === "ANNOUNCED") {
    //         setData(data);
    //     }
    // }, [status, data]);

    const textLang = {
        lo: {
            topic: "ຜົນການອອກເລກລາງວັນ",
            name: "ງວດປະຈຳວັນທີ່",
            rewardText1: "ລາງວັນທີ່ 1",
            rewardNear1: "ລາງວັນໃກ້ໆລາງວັນທີ່ 1",
            rewardText2: "ລາງວັນທີ່ 2",
            rewardText3: "ລາງວັນທີ່ 3",
            rewardText4: "ລາງວັນທີ່ 4",
            rewardText5: "ລາງວັນທີ່ 5",
            rewardText6: "ລາງວັນທີ່ 6",
            rewardText2font: "ລາງວັນ 2 ໂຕໜ້າ",
            rewardText2back: "ລາງວັນ 2 ໂຕສິ້ນສຸດ",
            rewardText3font: "ລາງວັນ 3 ໂຕໜ້າ",
            rewardTextSub2font: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub2back: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub3font: "ຂອງລາງວັນທີ່ 1",
            rewardTextSub2: "ຊື່ 4 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub3: "ຊື່ 3 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub4: "ໂຕດ 5 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub5: "ໂຕດ 4 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
            rewardTextSub6: "ໂຕດ 3 ໂຕສິ້ນສຸດຂອງລາງວັນທີ່ 1",
        },
        vi: {
            topic: "Kết quả xổ số",
            name: "của ngày",
            rewardText1: "Giải nhất",
            rewardNear1: "Gần giải nhất",
            rewardText2: "Giải nhì",
            rewardText3: "Giải ba",
            rewardText4: "Giải tư",
            rewardText5: "Giải năm",
            rewardText6: "Giải sáu",
            rewardText2font: "Giải 2 chữ phía trước",
            rewardText2back: "Giải 2 chữ ở cuối",
            rewardText3font: "Giải 3 chữ phía trước",
            rewardTextSub2font: "Giải nhất",
            rewardTextSub2back: "Giải nhất",
            rewardTextSub3font: "Giải nhất",
            rewardTextSub2: "Bốn lần cuối cùng",
            rewardTextSub3: "ba lần cuối cùng",
            rewardTextSub4: "5 thẻ cuối giải nhất",
            rewardTextSub5: "4 thẻ cuối giải nhất",
            rewardTextSub6: "3 thẻ cuối giải nhất",
        },
        en: {
            topic: "Winning Number",
            name: "Of date",
            rewardText1: "1st prize",
            rewardNear1: "Near 1st prize",
            rewardText2: "2nd prize",
            rewardText3: "3rd prize",
            rewardText4: "4th prize",
            rewardText5: "5th prize",
            rewardText6: "6th prize",
            rewardText2font: "2 font digit",
            rewardText2back: "2 back digit",
            rewardText3font: "3 font digit",
            rewardTextSub2font: "1st prize",
            rewardTextSub2back: "1st prize",
            rewardTextSub3font: "1st prize",
            rewardTextSub2: "4 back digit",
            rewardTextSub3: "3 back digit",
            rewardTextSub4: "5 permutation",
            rewardTextSub5: "4 permutation",
            rewardTextSub6: "3 permutation",
        },
    };

    const RenderResult = ({ value, size }) => {
        return (
            <Flex gap={3}>
                {value.split("").map((item) => {
                    return (
                        <Flex
                            justify="center"
                            align="center"
                            className={`text-white rounded-full text-[22px]`}
                            style={{
                                background: color,
                                width: size || "26px",
                                height: size || "26px",
                            }}
                        >
                            <div>{item}</div>
                        </Flex>
                    );
                })}
            </Flex>
        );
    };

    const RenderEmpty = () => {
        return (
            <SlotCounter
                value="-----"
                animateUnchanged
                animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                style={{
                    color: color,
                }}
            />
        );
    };
    const showViVip = (open, close, result) => {
        const result_close = Number(close?.toString().slice(0, -4) + result?.slice(0, 1) + "." + result?.slice(1, 3));
        // setCloseValue({
        //     closeNumber: result_close,
        //     changeNumber: Number((result_close - open).toString().split(".")[0] + "." + result?.slice(3, 5)),
        //     changePercent: (((result_close - open) / open) * 100).toFixed(2),
        // });
    };
    useEffect(() => {
        if (status === "ANNOUNCED") {
            if (resultNumber) {
                const _data = generateData(resultNumber);
                setData(_data);
                // showViVip(result?.open, result?.close, resultNumber);
            }
        } else if (status === "LOADING") {
            setInterval(() => {
                slotCounter1Ref?.current?.startAnimation();
                slotCounter2Ref?.current?.startAnimation();
                slotCounter3Ref?.current?.startAnimation();
                slotCounter4Ref?.current?.startAnimation();
                slotCounter5Ref?.current?.startAnimation();
            }, 3000);
        }
    }, [status, resultNumber]);

    const RenderReward = ({ text, subText, data, style, styleText, statsReward }) => {
        return (
            <Flex
                style={{
                    borderColor: color,
                    color: color,
                    ...style,
                }}
                flex={1}
                className={"border-x-0 border-y-0 border-solid border-[1px] w-full"}
            >
                <Flex
                    vertical
                    align="center"
                    justify="center"
                    className="w-[30%] border-x-0 border-b-0 border-solid border-[1px]"
                    style={{
                        borderColor: color,
                        color: color,
                        ...styleText,
                    }}
                >
                    <center className="text-base">{text}</center>
                    {subText && <center className="text-black text-xs">{subText}</center>}
                </Flex>
                <Flex flex={1} wrap="wrap">
                    {statsReward === "LOADING" ? (
                        <Flex
                            flex={"1 1 33.33%"}
                            className="items-center justify-center border-solid border-t-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px] py-2"
                            style={{
                                borderColor: color,
                            }}
                            gap={5}
                        >
                            <Flex
                                justify="center"
                                align="center"
                                className={`text-white rounded-full text-[22px]`}
                                style={{
                                    background: color,
                                    width: "26px",
                                    height: "26px",
                                }}
                            >
                                <SlotCounter
                                    value="-"
                                    animateUnchanged
                                    ref={slotCounter1Ref}
                                    animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                                />
                            </Flex>
                            <Flex
                                justify="center"
                                align="center"
                                className={`text-white rounded-full text-[22px]`}
                                style={{
                                    background: color,
                                    width: "26px",
                                    height: "26px",
                                }}
                            >
                                <SlotCounter
                                    value="-"
                                    animateUnchanged
                                    ref={slotCounter2Ref}
                                    animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                                />
                            </Flex>
                            <Flex
                                justify="center"
                                align="center"
                                className={`text-white rounded-full text-[22px]`}
                                style={{
                                    background: color,
                                    width: "26px",
                                    height: "26px",
                                }}
                            >
                                <SlotCounter
                                    value="-"
                                    animateUnchanged
                                    ref={slotCounter3Ref}
                                    animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                                />
                            </Flex>
                            <Flex
                                justify="center"
                                align="center"
                                className={`text-white rounded-full text-[22px]`}
                                style={{
                                    background: color,
                                    width: "26px",
                                    height: "26px",
                                }}
                            >
                                <SlotCounter
                                    value="-"
                                    animateUnchanged
                                    ref={slotCounter4Ref}
                                    animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                                />
                            </Flex>
                            <Flex
                                justify="center"
                                align="center"
                                className={`text-white rounded-full text-[22px]`}
                                style={{
                                    background: color,
                                    width: "26px",
                                    height: "26px",
                                }}
                            >
                                <SlotCounter
                                    value="-"
                                    animateUnchanged
                                    ref={slotCounter5Ref}
                                    animateOnVisible={{ triggerOnce: false, rootMargin: "0px 0px -100px 0px" }}
                                />
                            </Flex>
                        </Flex>
                    ) : (
                        data?.map((item, index) => {
                            return (
                                <Flex
                                    flex={"1 1 33.33%"}
                                    className="items-center justify-center border-solid border-t-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px] py-2"
                                    style={{
                                        borderColor: color,
                                    }}
                                >
                                    <RenderResult key={`${index}`} value={item} />
                                </Flex>
                            );
                        })
                    )}
                </Flex>
            </Flex>
        );
    };

    return (
        <div className="w-full">
            <FindPlusResult date={name} rewardData={_data} color={color} bgColor={"white"} lang={lang} />
            <Flex gap={5} flex={1}>
                <Flex
                    vertical
                    className="text-md border-solid border-[1px] font-medium w-full"
                    style={{
                        borderColor: color,
                    }}
                    justify="center"
                    align="center"
                >
                    <div className="text-xl font-medium">{textLang[lang].topic}</div>
                    <div
                        style={{
                            color: color,
                        }}
                        className="text-xl font-medium"
                    >
                        {textLang[lang].name} {name}
                    </div>

                    <RenderReward text={textLang[lang].rewardText1} data={_data["1"]} statsReward={status} />

                    <RenderReward text={textLang[lang].rewardNear1} data={_data["near"]} />
                    <RenderReward text={textLang[lang].rewardText2} subText={textLang[lang].rewardTextSub2} data={_data["2"]} />
                    <RenderReward text={textLang[lang].rewardText3} subText={textLang[lang].rewardTextSub3} data={_data["3"]} />
                    {_data["4permute"].length > 0 && (
                        <RenderReward
                            text={textLang[lang].rewardText4}
                            subText={textLang[lang].rewardTextSub4}
                            data={_data["5permute"]}
                        />
                    )}
                    {_data["4permute"].length > 0 && (
                        <RenderReward
                            text={textLang[lang].rewardText5}
                            subText={textLang[lang].rewardTextSub5}
                            data={_data["4permute"]}
                        />
                    )}
                    {_data["3permute"].length > 0 && (
                        <RenderReward
                            text={textLang[lang].rewardText6}
                            subText={textLang[lang].rewardTextSub6}
                            data={_data["3permute"]}
                        />
                    )}
                    <Flex className="w-full">
                        <RenderReward
                            style={{ width: "50%", borderRight: `1px solid ${color}` }}
                            styleText={{ width: "50%" }}
                            text={textLang[lang].rewardText3font}
                            subText={textLang[lang].rewardText3fontSub}
                            data={_data["3front"]}
                        />
                        <RenderReward
                            style={{ width: "50%" }}
                            styleText={{ width: "50%" }}
                            text={textLang[lang].rewardText2back}
                            subText={textLang[lang].rewardText2backSub}
                            data={_data["2back"]}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
};

export default PlusResult2;
