import React from "react";

export function HappySvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
            <path
                fill="#408302"
                d="M10.25 10a1.25 1.25 0 1 0-2.499 0a1.25 1.25 0 0 0 2.499 0m6 0a1.25 1.25 0 1 0-2.499 0a1.25 1.25 0 0 0 2.499 0m-6.114 5.106a.75.75 0 0 0-.772 1.286c.767.46 1.72.67 2.636.67c.916 0 1.87-.21 2.636-.67a.75.75 0 1 0-.772-1.286c-.483.29-1.154.456-1.864.456c-.71 0-1.38-.166-1.864-.456M22.002 12c0-5.524-4.478-10.002-10.002-10.002C6.476 1.998 2 6.476 2 12c-.001 5.523 4.476 10 10 10s10.002-4.478 10.002-10.001M3.499 12a8.502 8.502 0 1 1 17.003 0a8.502 8.502 0 0 1-17.003 0"
            ></path>
        </svg>
    );
}
