import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
    ChartGen,
    Container,
    DayRewardPlus,
    HistoryPlusResult,
    LineChart2,
    PlusResult2,
    PlusResultValueText,
} from "../../components";
import { LSX_VIP_DEFAULT } from "../../constants/ConfigPlus";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getResultTime } from "../../utils/ConvertDate";
import { nameResult } from "../../utils/ConvertNamePlus";

const color = LSX_VIP_DEFAULT?.color;
const bgDayReward = LSX_VIP_DEFAULT?.bgReward;
const idFirst = LSX_VIP_DEFAULT?.first_id;
const idSecond = LSX_VIP_DEFAULT?.second_id;
const idThird = LSX_VIP_DEFAULT?.third_id;
const starTime = LSX_VIP_DEFAULT?.start_time;
const endTime = LSX_VIP_DEFAULT?.end_time;
const startFirstTime = LSX_VIP_DEFAULT?.start_first_time;
const startSecondTime = LSX_VIP_DEFAULT?.start_second_time;
const startThirdTime = LSX_VIP_DEFAULT?.start_third_time;
const firstName = nameResult[LSX_VIP_DEFAULT?.lang]?.firstName;
const secondName = nameResult[LSX_VIP_DEFAULT?.lang]?.secondName;
const thirdName = nameResult[LSX_VIP_DEFAULT?.lang]?.thirdName;
const closeTime = LSX_VIP_DEFAULT?.disable_time_list;

function LsxVipPage() {
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(undefined);
    const [resultNumber, serResultNumber] = useState("");
    const [name, setName] = useState("");

    const [statusReward, setStatusReward] = useState("");
    const [getReward, result_reward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [history, setHistory] = useState([]);
    const [firstReward, setFirstReward] = useState("");
    const [secondReward, setSecondReward] = useState("");
    const [thirdReward, setThirdReward] = useState("");

    const [roundName, setRoundName] = useState("");
    const [announceAt, setAnnounceAt] = useState("");

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        if (currentTime && reloadTime) {
            const timeReload = setInterval(() => {
                const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
                const reload_time = convertTime(reloadTime);
                setTime((prev) => prev + 10);
                if (statusReward == "ANNOUNCED") {
                    clearInterval(timeReload);
                }
                if (reload_time < now && statusReward !== "ANNOUNCED") {
                    onFetch();
                } else if (statusReward !== "ANNOUNCED") {
                    // reload chart
                    const disable_list = getCloseTimeList();
                    const _result = ChartGen(
                        idFirst,
                        roundName,
                        starTime,
                        announceAt,
                        now?.split(":")?.slice(0, 2)?.join(":"),
                        "",
                        disable_list
                    );
                    setResult(_result);
                }
            }, 10000);
            return () => {
                clearInterval(timeReload);
            };
        }
    }, [time, currentTime, reloadTime]);

    function generateTimes(start, end, interval = 1) {
        const times = [];
        const [startHours, startMinutes] = start.split(":").map(Number);
        const [endHours, endMinutes] = end.split(":").map(Number);

        let startTimeInMinutes = startHours * 60 + startMinutes;
        const endTimeInMinutes = endHours * 60 + endMinutes;

        for (let i = startTimeInMinutes; i <= endTimeInMinutes; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            times.push(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`);
        }

        return times;
    }
    const getCloseTimeList = () => {
        let result_disable_time = [];
        const interval = 1; // interval in minutes
        closeTime.map((item) => {
            result_disable_time = [...result_disable_time, ...generateTimes(item.start, item.end, interval)];
        });
        return result_disable_time || [];
    };

    useEffect(() => {
        document.title = "LSX - Lao Securities Exchange VIP";
        onFetch();
    }, []);

    const onFetch = async () => {
        try {
            let result_number = "";

            const result_first = await getReward({ huayId: idFirst }).unwrap();
            const result_history_first = await getHistoryReward({ huayId: idFirst }).unwrap();

            setReloadTime(result_first?.data?.reloadTime);
            setCurrentTime(result_first?.data?.serverTime);

            // const result_second = await getReward({ huayId: idSecond }).unwrap();
            // const result_history_second = await getHistoryReward({ huayId: idSecond }).unwrap();

            // const result_third = await getReward({ huayId: idThird }).unwrap();
            // const result_history_third = await getHistoryReward({ huayId: idThird }).unwrap();
            const disable_list = getCloseTimeList();
            const history = [
                ...(result_history_first?.data?.map((item) => {
                    return {
                        ...item,
                        roundName: item?.roundName + ``,
                    };
                }) || []),
                // ...(result_history_second?.data?.map((item) => {
                //     return {
                //         ...item,
                //         roundName: item?.roundName + `(${secondName})`,
                //     };
                // }) || []),
                // ...(result_history_third?.data?.map((item) => {
                //     return {
                //         ...item,
                //         roundName: item?.roundName + `(${thirdName})`,
                //     };
                // }) || []),
            ];
            const _history = history.sort(function (a, b) {
                return new Date(b.announceAt) - new Date(a.announceAt);
            });

            const now = getResultTime(dayjs());
            let name = "";
            const roundName = result_first?.data?.roundName;
            if (result_first?.data?.viewStatus === "LOADING") {
                result_number = "";
                name = `${roundName} `;
                setStatusReward("LOADING");
            }
            // else if (result_second?.data?.viewStatus === "LOADING") {
            //     result_number = "";
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("LOADING");
            // } else if (result_third?.data?.viewStatus === "LOADING") {
            //     result_number = "";
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("LOADING");
            // }
            else if (startFirstTime < now && result_first?.data?.viewStatus == "PREPARE") {
                result_number = "";
                name = `${roundName} `;
                setStatusReward("PREPARE");
            }
            //  else if (startSecondTime < now && result_second?.data?.viewStatus == "PREPARE") {
            //     result_number = "";
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("PREPARE");
            // } else if (startThirdTime < now && result_third?.data?.viewStatus == "PREPARE") {
            //     result_number = "";
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("PREPARE");
            // }
            else if (result_first?.data?.viewStatus === "ANNOUNCED") {
                result_number = result_first?.data?.result?.primaryNumber;
                name = `${roundName} `;
                setStatusReward("ANNOUNCED");
            }
            //  else if (result_second?.data?.viewStatus === "ANNOUNCED" && now < getResultTime(result_third?.data?.announceAt)) {
            //     result_number = result_second?.data?.result?.primaryNumber;
            //     name = `${roundName} (${secondName})`;
            //     setStatusReward("ANNOUNCED");
            // } else if (result_third?.data?.viewStatus === "ANNOUNCED" && now > getResultTime(result_third?.data?.announceAt)) {
            //     result_number = result_third?.data?.result?.primaryNumber;
            //     name = `${roundName} (${thirdName})`;
            //     setStatusReward("ANNOUNCED");
            // }

            // id,date,start,end,current,result,disable_time_list
            setRoundName(result_first?.data?.roundName);
            setAnnounceAt(getResultTime(result_first?.data?.announceAt));
            const _result = ChartGen(
                idFirst,
                result_first?.data?.roundName,
                starTime,
                getResultTime(result_first?.data?.announceAt),
                getResultTime(result_first?.data?.serverTime),
                result_number,
                disable_list
            );

            const __result = {
                ..._result,
                graph: _result?.graph?.map((item) => {
                    if (
                        item.time === getResultTime(result_first?.data?.announceAt) &&
                        result_first?.data?.viewStatus === "ANNOUNCED"
                    ) {
                        return item;
                        // return {
                        //     time: item?.time,
                        //     value: Number(
                        //                                         String(item.value)?.split(".")[0]?.slice(0, -1) +

                        //             result_first?.data?.result?.primaryNumber.slice(0, 1) +
                        //             "." +
                        //             result_first?.data?.result?.primaryNumber.slice(1, 3)
                        //     ),
                        // };
                    }
                    // if (
                    //     item.time === getResultTime(result_second?.data?.announceAt) &&
                    //     result_second?.data?.viewStatus === "ANNOUNCED"
                    // ) {
                    //     return {
                    //         time: item.time,
                    //         value: Number(
                    //                                             String(item.value)?.split(".")[0]?.slice(0, -1) +

                    //                 result_second?.data?.result?.primaryNumber.slice(0, 1) +
                    //                 "." +
                    //                 result_second?.data?.result?.primaryNumber.slice(1, 3)
                    //         ),
                    //     };
                    // }
                    // if (
                    //     item.time === getResultTime(result_third?.data?.announceAt) &&
                    //     result_third?.data?.viewStatus === "ANNOUNCED"
                    // ) {
                    //     return {
                    //         time: item.time,
                    //         value: Number(
                    //                                             String(item.value)?.split(".")[0]?.slice(0, -1) +

                    //                 result_third?.data?.result?.primaryNumber.slice(0, 1) +
                    //                 "." +
                    //                 result_third?.data?.result?.primaryNumber.slice(1, 3)
                    //         ),
                    //     };
                    // }
                    else {
                        return item;
                    }
                }),
            };

            setHistory(_history);
            setFirstReward(result_first?.data?.result?.primaryNumber || "");
            // setSecondReward(result_second?.data?.result?.primaryNumber || "");
            // setThirdReward(result_third?.data?.result?.primaryNumber || "");
            setName(name);
            setResult(__result);
            serResultNumber(result_number);
            setLoadingHistory(false);
            setLoading(false);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
            setLoadingHistory(false);
            setLoading(false);
        }
    };
    return (
        <div style={{ background: "white" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/plus/lsxvip-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative flex flex-col max-w-[900px] gap-[10px]"}>
                <Flex>
                    <Image src={require("../../assets/images/plus/lsxvip-logo.png")} className="!h-[60px]" preview={false} />
                </Flex>
                <Flex
                    style={{
                        backgroundColor: color,
                    }}
                    className="rounded-ss-lg rounded-se-lg z-20"
                    justify="flex-end"
                >
                    <Flex className={`py-1 px-4 text-white  z-20 w-full max-w-[500px]`} justify="space-between" wrap="wrap">
                        <Flex className="cursor-pointer">Home</Flex>
                        <Flex className="cursor-pointer">About Us</Flex>
                        <Flex className="cursor-pointer">Stats</Flex>
                        <Flex className="cursor-pointer min-[400px]:flex hidden">FAQ</Flex>
                        <Flex className="cursor-pointer">Contact Us</Flex>
                    </Flex>
                </Flex>
                <Flex align="center" className="mt-[-40px] min-[700px]:flex-row flex-col">
                    {result?.graph?.length > 0 ? (
                        <LineChart2 result={result?.graph || []} className="min-[700px]:ml-[-70px]" color={color} />
                    ) : (
                        <div className="h-[400px] mr-[115px]" />
                    )}
                    <center className="w-full z-20">
                        <div
                            style={{
                                width: result?.graph?.length ? "auto" : 400,
                            }}
                            className="min-[700px]:w-full min-[700px]:ml-[-115px] mt-[-20px] min-[700px]:mt-0"
                        >
                            <PlusResultValueText status={statusReward} result={result} />
                        </div>
                    </center>
                </Flex>

                <Flex className="min-[500px]:mx-0 mx-3 md:mt-[-30px] z-20" vertical>
                    <center>
                        <div className="pb-2 max-w-[600px]">
                            <DayRewardPlus
                                firstReward={firstReward}
                                secondReward={secondReward}
                                thirdReward={thirdReward}
                                firstRewardText=" "
                                // disableFirst
                                disableSecond
                                disableThird
                                color={color}
                                bgColor={bgDayReward}
                                lang={LSX_VIP_DEFAULT?.lang}
                            />
                        </div>
                    </center>
                    <PlusResult2
                        color={color}
                        resultNumber={resultNumber}
                        status={statusReward}
                        result={result}
                        name={name}
                        lang={LSX_VIP_DEFAULT?.lang}
                        date={result_reward?.data?.data?.roundName}
                    />
                    <HistoryPlusResult color={color} data={history} lang={LSX_VIP_DEFAULT?.lang} />
                </Flex>
            </Container>
        </div>
    );
}

export default LsxVipPage;
