import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Warper from "../../assets/images/laoextra/laoextra-header-bg.png";
import { ImageSlide } from "../../components";
import Container from "../../components/Container";
import { getDateNameLao } from "../../utils/ConvertDate";
const { useBreakpoint } = Grid;

const color = "#b8860a";
const warper_bg = "#F8F8F8";
const color_bg = "white";
export default function LaosExtraOldPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState(undefined);
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const listName = {
        digit2_top: {
            name: "ເລກ 2 ໂຕເທິ່ງ",
            length: 2,
        },
        digit5: {
            name: "ເລກ 5 ໂຕ",
            length: 5,
        },
        digit1: {
            name: "ເລກ 1 ໂຕ",
            length: 1,
        },
        digit3: {
            name: "ເລກ 3 ໂຕ",
            length: 3,
        },
        digit2_special: {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
        },
        digit4: {
            name: "ເລກ 4 ໂຕ",
            length: 4,
        },
        digit2_bottom: {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
        },
    };

    const sortKey = {
        digit5: 0,
        digit4: 1,
        digit3: 2,
        digit2_special: 5,
        digit2_top: 3,
        digit1: 4,
        digit2_bottom: 5,
    };

    const [_numberList] = useState([
        {
            name: "ເລກ 5 ໂຕ",
            length: 5,
            result: "",
        },
        {
            name: "ເລກ 3 ໂຕ",
            length: 3,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕເທິ່ງ	",
            length: 2,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
            result: "",
        },
    ]);

    const onFetch = () => {
        // fetch data
        setData({
            start_spin: "2024-07-29 08:25:00",
            lotto_date: "2024-07-29",
            show_result: "2024-07-29 08:30:00",
            results: {
                digit2_top: "51",
                digit5: "07151",
                digit3: "151",
                digit4: "7151",
                digit2_bottom: "07",
            },
        });
        setHistoryData([
            {
                lotto_date: "2024-07-28",
                results: {
                    digit2_top: "21",
                    digit5: "03421",
                    digit3: "421",
                    digit4: "3421",
                    digit2_bottom: "03",
                },
            },
            {
                lotto_date: "2024-07-27",
                results: {
                    digit2_top: "59",
                    digit5: "46959",
                    digit3: "959",
                    digit4: "6959",
                    digit2_bottom: "46",
                },
            },
            {
                lotto_date: "2024-07-26",
                results: {
                    digit2_top: "74",
                    digit5: "98574",
                    digit3: "574",
                    digit4: "8574",
                    digit2_bottom: "98",
                },
            },
            {
                lotto_date: "2024-07-25",
                results: {
                    digit2_top: "94",
                    digit5: "26894",
                    digit3: "894",
                    digit4: "6894",
                    digit2_bottom: "26",
                },
            },
            {
                lotto_date: "2024-07-24",
                results: {
                    digit2_top: "28",
                    digit5: "97828",
                    digit3: "828",
                    digit4: "7828",
                    digit2_bottom: "97",
                },
            },
            {
                lotto_date: "2024-07-23",
                results: {
                    digit2_top: "92",
                    digit5: "07292",
                    digit3: "292",
                    digit4: "7292",
                    digit2_bottom: "07",
                },
            },
            {
                lotto_date: "2024-07-22",
                results: {
                    digit2_top: "13",
                    digit5: "73413",
                    digit3: "413",
                    digit4: "3413",
                    digit2_bottom: "73",
                },
            },
            {
                lotto_date: "2024-07-21",
                results: {
                    digit2_top: "08",
                    digit5: "72008",
                    digit3: "008",
                    digit4: "2008",
                    digit2_bottom: "72",
                },
            },
            {
                lotto_date: "2024-07-20",
                results: {
                    digit2_top: "70",
                    digit5: "94970",
                    digit3: "970",
                    digit4: "4970",
                    digit2_bottom: "94",
                },
            },
            {
                lotto_date: "2024-07-19",
                results: {
                    digit2_top: "82",
                    digit5: "45082",
                    digit3: "082",
                    digit4: "5082",
                    digit2_bottom: "45",
                },
            },
            {
                lotto_date: "2024-07-18",
                results: {
                    digit2_top: "83",
                    digit5: "74483",
                    digit3: "483",
                    digit4: "4483",
                    digit2_bottom: "74",
                },
            },
            {
                lotto_date: "2024-07-17",
                results: {
                    digit2_top: "78",
                    digit5: "23078",
                    digit3: "078",
                    digit4: "3078",
                    digit2_bottom: "23",
                },
            },
            {
                lotto_date: "2024-07-16",
                results: {
                    digit2_top: "10",
                    digit5: "81610",
                    digit3: "610",
                    digit4: "1610",
                    digit2_bottom: "81",
                },
            },
            {
                lotto_date: "2024-07-15",
                results: {
                    digit2_top: "44",
                    digit5: "94744",
                    digit3: "744",
                    digit4: "4744",
                    digit2_bottom: "94",
                },
            },
            {
                lotto_date: "2024-07-14",
                results: {
                    digit2_top: "62",
                    digit5: "85562",
                    digit3: "562",
                    digit4: "5562",
                    digit2_bottom: "85",
                },
            },
        ]);
        setLoadingHistory(false);
        setLoading(false);
    };

    useEffect(() => {
        document.title = "Laos Extra";
        onFetch();
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/laoextra/laoextra-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1100px]"}>
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Flex
                    align="center"
                    style={{
                        backgroundImage: `url(${Warper})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1100,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Image
                        src={require("../../assets/images/laoextra/laoextra-header.png")}
                        preview={false}
                        className="!h-[80%]"
                    />
                </Flex>
                <Divider className="bg-[#B00000] h-[20px] m-0" />

                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: `white`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1100,
                        width: "100%",
                    }}
                >
                    <div className="pt-3">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="w-full md:max-w-[700px]" vertical>
                                <ImageSlide
                                    data={[
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-1.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-2.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-3.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-4.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-1.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-2.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-3.png"),
                                        },
                                        {
                                            image: require("../../assets/images/laoextra/laoextra-1-4.png"),
                                        },
                                    ]}
                                />

                                {data && (
                                    <Flex vertical gap={10}>
                                        {/* result */}
                                        <Flex
                                            className="border-solid border-[4px] bg-white"
                                            style={{ borderColor: color }}
                                            vertical
                                        >
                                            <center className="pt-5">
                                                <div className="text-[21px] font-semibold text-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji]">
                                                    ງວດປະຈຳວັນທີ່ {getDateNameLao(data?.lotto_date)}
                                                </div>
                                                <div className="text-[4rem] font-bold tracking-[10px]">
                                                    {data.results?.digit5}
                                                </div>
                                                <div className="text-[24px] font-medium">
                                                    <h3 data-v-494f7d57="">ອອກຜົນລາງວັນ 08:30</h3>
                                                </div>
                                            </center>
                                            <Flex className="py-[20px] px-[50px]">
                                                <Flex className="border-[#999] border-solid border-t-[1px]" vertical flex={1}>
                                                    {Object.entries(data.results).map(([key, value]) => {
                                                        if (key !== "digit2_special" && key !== "digit1") {
                                                            return (
                                                                <div
                                                                    className="flex"
                                                                    style={{
                                                                        order: sortKey[key],
                                                                        background: "white",
                                                                        color: "#000",
                                                                    }}
                                                                >
                                                                    <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium border-[#999] border-solid border-b-[1px] border-l-[1px] border-r-[1px]">
                                                                        {listName[key]?.name}
                                                                    </div>
                                                                    <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                                        {value ? (
                                                                            <div>{value}</div>
                                                                        ) : (
                                                                            Array.from(Array(listName[key]?.length).keys()).map(
                                                                                () => <div>-</div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Flex>
                                            </Flex>
                                        </Flex>

                                        <Image
                                            src={require("../../assets/images/laoextra/laoextra-2.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />

                                        {/* history */}
                                        <Flex vertical className="min-h-[150px]">
                                            <Flex
                                                className="border-solid border-[3px] bg-white"
                                                style={{ borderColor: color, background: color, color: "white" }}
                                                vertical
                                            >
                                                <center className="text-[26px] font-medium">ຜົນການອອກເລກລາງວັນ</center>
                                            </Flex>
                                            <div className="border-[#999] border-solid border-t-[1px] mt-2 flex bg-white font-bold z-[1]">
                                                <div className="flex flex-1 border-x-[1px] p-[5px] font-base border-[#999] border-solid justify-center border-b-[1px]">
                                                    ງວດວັນທີ່
                                                </div>
                                                {_numberList?.map((number) => {
                                                    return (
                                                        <div className="w-[18%] flex p-[5px] justify-center font-base border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                            <center>{number.name}</center>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {historyData.map((result) => {
                                                return (
                                                    <div className="flex bg-white z-[1]">
                                                        <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#999] border-solid border-b-[1px] border-r-[1px] border-l-[1px]">
                                                            <center className="font-normal">
                                                                {getDateNameLao(result?.lotto_date)}
                                                            </center>
                                                        </div>
                                                        {Object.entries(result.results).map(([key, value]) => {
                                                            if (key !== "digit2_special" && key !== "digit1" && key !== "digit4")
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            order: sortKey[key],
                                                                        }}
                                                                        className="w-[18%] flex p-[5px] justify-center font-base  border-[#999] border-solid border-b-[1px] border-r-[1px]"
                                                                    >
                                                                        <center className="font-normal">{value}</center>
                                                                    </div>
                                                                );
                                                            return null;
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </Flex>

                                        <Image
                                            src={require("../../assets/images/laoextra/laoextra-3.png")}
                                            rootClassName="w-full"
                                            preview={false}
                                        />
                                    </Flex>
                                )}
                            </Flex>
                            <Flex vertical flex={1}>
                                <Flex vertical gap={10} className="px-0">
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra--1.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra-5.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra-6.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laoextra/laoextra-7.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <Image
                        src={require("../../assets/images/laoextra/laoextra--2.png")}
                        rootClassName="w-full"
                        className="mt-5"
                        preview={false}
                    />
                    <Divider className="bg-[#FFF] h-3 m-0" />

                    <center className="text-white font-normal text-sm py-[15px] bg-[#011e3a]">
                        Copyright ©2024 - laoextra.com
                    </center>
                </Flex>
            </Container>
        </div>
    );
}
