import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";

const { useBreakpoint } = Grid;

const color = "#e80000";
const warper_bg = "#d6d6d6";
const color_bg = "white";
export default function XoSoRedCrossPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    useEffect(() => {
        document.title = "Xổ số Union";
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setData({
            prize_4th_1: "38401",
            prize_4th_2: "24229",
            prize_5th_3: "9329",
            prize_4th_3: "03272",
            prize_5th_4: "8021",
            prize_2digits_4: "62",
            prize_4th_4: "15155",
            prize_5th_1: "3467",
            prize_4th_5: "75165",
            prize_5th_2: "8055",
            prize_4th_6: "11970",
            prize_7th_3: "113",
            prize_7th_1: "452",
            prize_7th_2: "134",
            prize_1st: "53284",
            prize_6th_1: "4038",
            prize_6th_6: "2706",
            prize_3rd_2: "75086",
            prize_3rd_1: "99865",
            prize_2nd: "90759",
            prize_6th_2: "4746",
            prize_6th_3: "0579",
            prize_6th_4: "9020",
            prize_6th_5: "4992",
            prize_2digits_3: "58",
            prize_2digits_2: "39",
            prize_2digits_1: "92",
        });
        setLoadingHistory(false);
        setLoading(false);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/xosounion/xosounion-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col max-w-[1050px]"}>
                <Divider className="bg-[#e5e533] h-[15px] m-0" />
                <Divider className="bg-[#e50000] h-[5px] m-0 mb-1" />
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* header logo */}
                <Image src={require("../../assets/images/xosounion/xosounion-header.png")} preview={false} />
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "white",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1050,
                        width: "100%",
                        paddingBottom: 10,
                        marginTop: 5,
                    }}
                >
                    <Divider className="bg-[#e50000] h-[5px] m-0" />

                    {/* content */}
                    <div className="pt-3">
                        <Flex gap={10} className="min-[600px]:flex-row flex-col">
                            <Flex
                                className="hidden min-[600px]:block min-[600px]:w-full min-[600px]:max-w-[250px] order-1 min-[600px]:order-0"
                                vertical
                                gap={10}
                            >
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-1.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-2.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-3.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-4.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-5.png")}
                                    rootClassName="w-full"
                                    preview={false}
                                />
                            </Flex>
                            <Flex className="w-full order-0 min-[600px]:order-1 min-[600px]:mx-0" vertical gap={5}>
                                <Flex vertical gap={10} className="mb-3 px-3 min-[600px]:px-0">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-6.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image src={require("../../assets/images/xosounion/xosounion-7.png")} preview={false} />
                                </Flex>
                                <Flex className="mt-[-10px] px-3 min-[600px]:px-0 min-w-[330px]">
                                    <Flex
                                        vertical
                                        className="w-[20%] text-sm border-solid border-0 border-[#690000] border-s-[1px] border-b-[1px]"
                                    >
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Đặc biệt
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải nhất
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Giải nhì
                                        </div>
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải ba
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0  font-medium">
                                            Giải tư
                                        </div>
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải năm
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            Giải sáu
                                        </div>
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            Giải bảy
                                        </div>
                                    </Flex>
                                    <Flex
                                        flex={1}
                                        vertical
                                        className="border-solid border-0 border-[#690000] border-e-[1px] border-b-[1px]"
                                    >
                                        {/* biet */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-r-0 border-[1.5px] w-full border-b-0 text-[#e80000] font-bold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                            <div>{data.prize_1st}</div>
                                        </div>
                                        {/* nhat */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-r-0 border-[1.5px] w-full border-b-0 bg-[#efefef] text-[#000] font-bold text-[20px]">
                                            <div>{data.prize_2nd}</div>
                                        </div>
                                        {/* nhi */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                            <Flex className="h-full" flex={1}>
                                                <Flex
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]"
                                                >
                                                    {data.prize_3rd_1}
                                                </Flex>
                                                <Flex
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]"
                                                >
                                                    {data.prize_3rd_2}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* ba */}
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <Flex className="h-full" flex={1} wrap="wrap">
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_1}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_2}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_3}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_4}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_5}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_4th_6}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* tu */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-y-0 font-medium">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_5th_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_5th_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_5th_3}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_5th_4}
                                                </div>
                                            </div>
                                        </div>
                                        {/* nam */}
                                        <div className="h-[80px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <Flex className="h-full" flex={1} wrap="wrap">
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_1}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_2}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_3}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_4}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_5}
                                                </Flex>
                                                <Flex
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#690000] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-bold text-[20px]  h-[40px]"
                                                >
                                                    {data.prize_6th_6}
                                                </Flex>
                                            </Flex>
                                        </div>
                                        {/* sau */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_7th_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_7th_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#000] font-bold text-[20px]">
                                                    {data.prize_7th_3}
                                                </div>
                                            </div>
                                        </div>
                                        {/* bay */}
                                        <div className="h-[40px] flex items-center justify-center border-[#690000] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                            <div className="flex flex-1 h-full">
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-bold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-bold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_2}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-bold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_3}
                                                </div>
                                                <div className="flex flex-1 items-center justify-center border-[#690000] border-solid border-l-[1.5px] text-[#e80000] font-bold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                                    {data.prize_2digits_4}
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                </Flex>
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-8-1.png")}
                                    rootClassName="w-full px-3 min-[600px]:px-0"
                                    preview={false}
                                />
                                <Image
                                    src={require("../../assets/images/xosounion/xosounion-8.png")}
                                    rootClassName="w-full px-3 min-[600px]:px-0"
                                    preview={false}
                                />

                                <Flex vertical gap={10} className="px-3 min-[600px]:px-0">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-9.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-10.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-11.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>

                            <Flex className="w-full min-[600px]:max-w-[250px] order-1 min-[600px]:order-0" vertical>
                                <Flex vertical gap={10} className="px-3 min-[600px]:px-0 min-[600px]:flex">
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-12.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <div className="overflow-hidden">
                                        <iframe
                                            src="https://nguoivietboston.com/"
                                            className="h-[1670px] min-[600px]:mt-[-270px] mt-[-350px] pointer-events-none overflow-hidden border-0 w-full"
                                        />
                                    </div>
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-13.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-14.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-15.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/xosounion/xosounion-16.png")}
                                        rootClassName="w-full"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
                <Divider className="bg-[#e50000] h-[5px] m-0" />
                <center className="text-black font-normal text-sm bg-[#e5e533] w-full py-3">
                    ©2021 xosounion.com, All Right Reserved.
                </center>
            </Container>
        </div>
    );
}
