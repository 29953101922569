import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { DOW_JONES_STAR_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const color = "#212529";
export default function DowJonesStarPage() {
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: DOW_JONES_STAR_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: DOW_JONES_STAR_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "DOW JONES STAR JACKPOT";
        fetchReward();
    }, [fetchReward]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/dowjonestar/dowjonestar-icon.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"relative items-center flex flex-col"}>
                <Image
                    src={require("../../assets/images/dowjonestar/dowjonestar-warpper.png")}
                    preview={false}
                    rootClassName="fixed z-[-1] w-full max-w-[800px]"
                    style={{ height: "100vh", width: "100%" }}
                />
                {/* header logo */}
                <Flex justify="center">
                    <Image src={require("../../assets/images/dowjonestar/dowjonestar-header-2.png")} preview={false} />
                </Flex>
                {/* line */}
                <Flex justify="center">
                    <div className="w-full max-w-[700px]">
                        <FindResult
                            date={rewardDetail?.roundName}
                            rewardData={data}
                            color={color}
                            bgColor={"transparent"}
                            lang="ENG"
                        />
                        {/* content result */}
                        <ResultLottery
                            status={statusReward}
                            data={data}
                            date={rewardDetail?.roundName}
                            color={color}
                            bgColor={"transparent"}
                            lang="ENG"
                        />

                        {/* history */}
                        <div className="mt-[-24px]">
                            <HistoryResult data={historyData} color={color} lang="ENG" />
                        </div>
                    </div>
                </Flex>
            </Container>
        </div>
    );
}
