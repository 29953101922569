import { BASE_PATH } from "../constants/PathConfig";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_PATH,
});

const FetchService = async (args, api, extraOptions) => {
    return baseQuery(args, api, extraOptions);
};

export default FetchService;
