import { useCallback, useState } from "react";
import Chart from "react-apexcharts";

const time = [
    "08:00",
    "08:01",
    "08:02",
    "08:03",
    "08:04",
    "08:05",
    "08:06",
    "08:07",
    "08:08",
    "08:09",
    "08:10",
    "08:11",
    "08:12",
    "08:13",
    "08:14",
    "08:15",
    "08:16",
    "08:17",
    "08:18",
    "08:19",
    "08:20",
    "08:21",
    "08:22",
    "08:23",
    "08:24",
    "08:25",
    "08:26",
    "08:27",
    "08:28",
    "08:29",
    "08:30",
    "08:31",
    "08:32",
    "08:33",
    "08:34",
    "08:35",
    "08:36",
    "08:37",
    "08:38",
    "08:39",
    "08:40",
    "08:41",
    "08:42",
    "08:43",
    "08:44",
    "08:45",
    "08:46",
    "08:47",
    "08:48",
    "08:49",
    "08:50",
    "08:51",
    "08:52",
    "08:53",
    "08:54",
    "08:55",
    "08:56",
    "08:57",
    "08:58",
    "08:59",
    "09:00",
    "09:01",
    "09:02",
    "09:03",
    "09:04",
    "09:05",
    "09:06",
    "09:07",
    "09:08",
    "09:09",
    "09:10",
    "09:11",
    "09:12",
    "09:13",
    "09:14",
    "09:15",
    "09:16",
    "09:17",
    "09:18",
    "09:19",
    "09:20",
    "09:21",
    "09:22",
    "09:23",
    "09:24",
    "09:25",
    "09:26",
    "09:27",
    "09:28",
    "09:29",
    "09:30",
    "09:31",
    "09:32",
    "09:33",
    "09:34",
    "09:35",
    "09:36",
    "09:37",
    "09:38",
    "09:39",
    "09:40",
    "11:00",
    "11:01",
    "11:02",
    "11:03",
    "11:04",
    "11:05",
    "11:06",
    "11:07",
    "11:08",
    "11:09",
    "11:10",
    "11:11",
    "11:12",
    "11:13",
    "11:14",
    "11:15",
    "11:16",
    "11:17",
    "11:18",
    "11:19",
    "11:20",
    "11:21",
    "11:22",
    "11:23",
    "11:24",
    "11:25",
    "11:26",
    "11:27",
    "11:28",
    "11:29",
    "11:30",
    "11:31",
    "11:32",
    "11:33",
    "11:34",
    "11:35",
    "11:36",
    "11:37",
    "11:38",
    "11:39",
    "11:40",
    "11:41",
    "11:42",
    "11:43",
    "11:44",
    "11:45",
    "11:46",
    "11:47",
    "11:48",
    "11:49",
    "11:50",
    "11:51",
    "11:52",
    "11:53",
    "11:54",
    "11:55",
    "11:56",
    "11:57",
    "11:58",
    "11:59",
    "12:00",
    "12:01",
    "12:02",
    "12:03",
    "12:04",
    "12:05",
    "12:06",
    "12:07",
    "12:08",
    "12:09",
    "12:10",
    "12:11",
    "12:12",
    "12:13",
    "12:14",
    "12:15",
    "12:16",
    "12:17",
    "12:18",
    "12:19",
    "12:20",
    "12:21",
    "12:22",
    "12:23",
    "12:24",
    "12:25",
    "12:26",
    "12:27",
    "12:28",
    "12:29",
    "12:30",
    "12:31",
    "12:32",
    "12:33",
    "12:34",
    "12:35",
    "12:36",
    "12:37",
    "12:38",
    "12:39",
    "12:40",
    "12:41",
    "12:42",
    "12:43",
    "12:44",
    "12:45",
    "12:46",
    "12:47",
    "12:48",
    "12:49",
    "12:50",
    "12:51",
    "12:52",
    "12:53",
    "12:54",
    "12:55",
    "12:56",
    "12:57",
    "12:58",
    "12:59",
    "13:00",
    "13:01",
    "13:02",
    "13:03",
    "13:04",
    "13:05",
    "13:06",
    "13:07",
    "13:08",
    "13:09",
    "13:10",
    "13:11",
    "13:12",
    "13:13",
    "13:14",
    "13:15",
    "13:16",
    "13:17",
    "13:18",
    "13:19",
    "13:20",
    "13:21",
    "13:22",
    "13:23",
    "13:24",
    "13:25",
    "13:26",
    "13:27",
    "13:28",
    "13:29",
    "13:30",
    "13:31",
    "13:32",
    "13:33",
    "13:34",
    "13:35",
    "13:36",
    "13:37",
    "13:38",
    "13:39",
    "13:40",
    "13:41",
    "13:42",
    "13:43",
    "13:44",
    "13:45",
    "13:46",
    "13:47",
    "13:48",
    "13:49",
    "13:50",
    "13:51",
    "13:52",
    "13:53",
    "13:54",
    "13:55",
    "13:56",
    "13:57",
    "13:58",
    "13:59",
    "14:00",
    "14:01",
    "14:02",
    "14:03",
    "14:04",
    "14:05",
    "14:06",
    "14:07",
    "14:08",
    "14:09",
    "14:10",
    "15:20",
    "15:21",
    "15:22",
    "15:23",
    "15:24",
    "15:25",
    "15:26",
    "15:27",
    "15:28",
    "15:29",
    "15:30",
    "15:31",
    "15:32",
    "15:33",
    "15:34",
    "15:35",
    "15:36",
    "15:37",
    "15:38",
    "15:39",
    "15:40",
    "15:41",
    "15:42",
    "15:43",
    "15:44",
    "15:45",
    "15:46",
    "15:47",
    "15:48",
    "15:49",
    "15:50",
    "15:51",
    "15:52",
    "15:53",
    "15:54",
    "15:55",
    "15:56",
    "15:57",
    "15:58",
    "15:59",
    "16:00",
    "16:01",
    "16:02",
    "16:03",
    "16:04",
    "16:05",
    "16:06",
    "16:07",
    "16:08",
    "16:09",
    "16:10",
    "16:11",
    "16:12",
    "16:13",
    "16:14",
    "16:15",
    "16:16",
    "16:17",
    "16:18",
    "16:19",
    "16:20",
    "16:21",
    "16:22",
    "16:23",
    "16:24",
    "16:25",
    "16:26",
    "16:27",
    "16:28",
    "16:29",
    "16:30",
    "16:31",
    "16:32",
    "16:33",
    "16:34",
    "16:35",
    "16:36",
    "16:37",
    "16:38",
    "16:39",
    "16:40",
    "16:41",
    "16:42",
    "16:43",
    "16:44",
    "16:45",
];

const price = [
    {
        val: 150000,
        high: 5943.06,
        vol: 1000,
        low: 5937.94,
        change: 5.12,
        available: true,
        time: "08:00",
        close: 5943.06,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 361000,
        high: 5943.06,
        vol: 3600,
        low: 5937.94,
        change: 3.5,
        available: true,
        time: "08:01",
        close: 5941.44,
        percent: 0.06,
        open: 5937.94,
    },
    {
        val: 630000,
        high: 5943.06,
        vol: 5700,
        low: 5937.94,
        change: 0.64,
        available: true,
        time: "08:02",
        close: 5938.58,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 779000,
        high: 5943.06,
        vol: 7100,
        low: 5937.94,
        change: 1.9,
        available: true,
        time: "08:03",
        close: 5939.84,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 946000,
        high: 5943.56,
        vol: 9300,
        low: 5937.94,
        change: 5.62,
        available: true,
        time: "08:04",
        close: 5943.56,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 1154000,
        high: 5943.56,
        vol: 10300,
        low: 5937.94,
        change: 4.32,
        available: true,
        time: "08:05",
        close: 5942.26,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 1208000,
        high: 5943.56,
        vol: 12500,
        low: 5937.58,
        change: -0.36,
        available: true,
        time: "08:06",
        close: 5937.58,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 1450000,
        high: 5943.56,
        vol: 14900,
        low: 5937.58,
        change: 2.06,
        available: true,
        time: "08:07",
        close: 5940,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 1657000,
        high: 5943.56,
        vol: 16800,
        low: 5936.58,
        change: -1.36,
        available: true,
        time: "08:08",
        close: 5936.58,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 1748000,
        high: 5943.56,
        vol: 20200,
        low: 5936.58,
        change: 0.49,
        available: true,
        time: "08:09",
        close: 5938.43,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 1865000,
        high: 5943.56,
        vol: 22300,
        low: 5936.58,
        change: 5.33,
        available: true,
        time: "08:10",
        close: 5943.27,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 1973000,
        high: 5946.24,
        vol: 26100,
        low: 5936.58,
        change: 8.3,
        available: true,
        time: "08:11",
        close: 5946.24,
        percent: 0.14,
        open: 5937.94,
    },
    {
        val: 2095000,
        high: 5946.24,
        vol: 28800,
        low: 5936.58,
        change: 5.43,
        available: true,
        time: "08:12",
        close: 5943.37,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 2385000,
        high: 5946.27,
        vol: 30900,
        low: 5936.58,
        change: 8.33,
        available: true,
        time: "08:13",
        close: 5946.27,
        percent: 0.14,
        open: 5937.94,
    },
    {
        val: 2664000,
        high: 5949.02,
        vol: 33400,
        low: 5936.58,
        change: 11.08,
        available: true,
        time: "08:14",
        close: 5949.02,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 2765000,
        high: 5950.59,
        vol: 35200,
        low: 5936.58,
        change: 12.65,
        available: true,
        time: "08:15",
        close: 5950.59,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 2786000,
        high: 5950.59,
        vol: 38400,
        low: 5936.58,
        change: 9.7,
        available: true,
        time: "08:16",
        close: 5947.64,
        percent: 0.16,
        open: 5937.94,
    },
    {
        val: 2842000,
        high: 5950.59,
        vol: 41600,
        low: 5936.58,
        change: 10.92,
        available: true,
        time: "08:17",
        close: 5948.86,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 2886000,
        high: 5950.59,
        vol: 45300,
        low: 5936.58,
        change: 10,
        available: true,
        time: "08:18",
        close: 5947.94,
        percent: 0.17,
        open: 5937.94,
    },
    {
        val: 3138000,
        high: 5950.59,
        vol: 48100,
        low: 5936.58,
        change: 10.23,
        available: true,
        time: "08:19",
        close: 5948.17,
        percent: 0.17,
        open: 5937.94,
    },
    {
        val: 3251000,
        high: 5952.53,
        vol: 50000,
        low: 5936.58,
        change: 14.59,
        available: true,
        time: "08:20",
        close: 5952.53,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 3434000,
        high: 5952.53,
        vol: 51700,
        low: 5936.58,
        change: 10.76,
        available: true,
        time: "08:21",
        close: 5948.7,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 3622000,
        high: 5952.53,
        vol: 53300,
        low: 5936.58,
        change: 8.24,
        available: true,
        time: "08:22",
        close: 5946.18,
        percent: 0.14,
        open: 5937.94,
    },
    {
        val: 3900000,
        high: 5952.53,
        vol: 56900,
        low: 5936.58,
        change: 5.4,
        available: true,
        time: "08:23",
        close: 5943.34,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 3981000,
        high: 5952.53,
        vol: 59100,
        low: 5936.58,
        change: 2.53,
        available: true,
        time: "08:24",
        close: 5940.47,
        percent: 0.04,
        open: 5937.94,
    },
    {
        val: 4026000,
        high: 5952.53,
        vol: 60600,
        low: 5936.58,
        change: 6.02,
        available: true,
        time: "08:25",
        close: 5943.96,
        percent: 0.1,
        open: 5937.94,
    },
    {
        val: 4284000,
        high: 5952.53,
        vol: 62300,
        low: 5936.58,
        change: 2.77,
        available: true,
        time: "08:26",
        close: 5940.71,
        percent: 0.05,
        open: 5937.94,
    },
    {
        val: 4343000,
        high: 5952.53,
        vol: 64000,
        low: 5936.58,
        change: 4.22,
        available: true,
        time: "08:27",
        close: 5942.16,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 4485000,
        high: 5952.53,
        vol: 66800,
        low: 5936.58,
        change: 8.91,
        available: true,
        time: "08:28",
        close: 5946.85,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 4679000,
        high: 5952.53,
        vol: 69500,
        low: 5936.58,
        change: 10.71,
        available: true,
        time: "08:29",
        close: 5948.65,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 4873000,
        high: 5952.53,
        vol: 71200,
        low: 5936.58,
        change: 14.06,
        available: true,
        time: "08:30",
        close: 5952,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 5145000,
        high: 5952.53,
        vol: 73600,
        low: 5936.58,
        change: 10.81,
        available: true,
        time: "08:31",
        close: 5948.75,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 5370000,
        high: 5953.3,
        vol: 75300,
        low: 5936.58,
        change: 15.36,
        available: true,
        time: "08:32",
        close: 5953.3,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 5541000,
        high: 5953.3,
        vol: 77400,
        low: 5936.58,
        change: 14.49,
        available: true,
        time: "08:33",
        close: 5952.43,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 5724000,
        high: 5954.94,
        vol: 80700,
        low: 5936.58,
        change: 17,
        available: true,
        time: "08:34",
        close: 5954.94,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 6013000,
        high: 5956.41,
        vol: 84500,
        low: 5936.58,
        change: 18.47,
        available: true,
        time: "08:35",
        close: 5956.41,
        percent: 0.31,
        open: 5937.94,
    },
    {
        val: 6072000,
        high: 5960.19,
        vol: 86000,
        low: 5936.58,
        change: 22.25,
        available: true,
        time: "08:36",
        close: 5960.19,
        percent: 0.37,
        open: 5937.94,
    },
    {
        val: 6195000,
        high: 5960.19,
        vol: 87900,
        low: 5936.58,
        change: 18.87,
        available: true,
        time: "08:37",
        close: 5956.81,
        percent: 0.32,
        open: 5937.94,
    },
    {
        val: 6458000,
        high: 5960.19,
        vol: 91500,
        low: 5936.58,
        change: 16.8,
        available: true,
        time: "08:38",
        close: 5954.74,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 6680000,
        high: 5960.19,
        vol: 93400,
        low: 5936.58,
        change: 15.54,
        available: true,
        time: "08:39",
        close: 5953.48,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 6808000,
        high: 5960.19,
        vol: 95100,
        low: 5936.58,
        change: 14.79,
        available: true,
        time: "08:40",
        close: 5952.73,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 7000000,
        high: 5960.19,
        vol: 98800,
        low: 5936.58,
        change: 14.48,
        available: true,
        time: "08:41",
        close: 5952.42,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 7058000,
        high: 5960.19,
        vol: 100300,
        low: 5936.58,
        change: 13.17,
        available: true,
        time: "08:42",
        close: 5951.11,
        percent: 0.22,
        open: 5937.94,
    },
    {
        val: 7346000,
        high: 5960.19,
        vol: 104000,
        low: 5936.58,
        change: 14.5,
        available: true,
        time: "08:43",
        close: 5952.44,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 7391000,
        high: 5960.19,
        vol: 105300,
        low: 5936.58,
        change: 19.51,
        available: true,
        time: "08:44",
        close: 5957.45,
        percent: 0.33,
        open: 5937.94,
    },
    {
        val: 7643000,
        high: 5960.19,
        vol: 107100,
        low: 5936.58,
        change: 16.05,
        available: true,
        time: "08:45",
        close: 5953.99,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 7831000,
        high: 5960.19,
        vol: 109600,
        low: 5936.58,
        change: 14.65,
        available: true,
        time: "08:46",
        close: 5952.59,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 7997000,
        high: 5960.19,
        vol: 111600,
        low: 5936.58,
        change: 12.22,
        available: true,
        time: "08:47",
        close: 5950.16,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 8185000,
        high: 5960.19,
        vol: 114200,
        low: 5936.58,
        change: 12.44,
        available: true,
        time: "08:48",
        close: 5950.38,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 8303000,
        high: 5960.19,
        vol: 117200,
        low: 5936.58,
        change: 10.65,
        available: true,
        time: "08:49",
        close: 5948.59,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 8525000,
        high: 5960.19,
        vol: 120100,
        low: 5936.58,
        change: 8.98,
        available: true,
        time: "08:50",
        close: 5946.92,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 8646000,
        high: 5960.19,
        vol: 122300,
        low: 5936.58,
        change: 7.75,
        available: true,
        time: "08:51",
        close: 5945.69,
        percent: 0.13,
        open: 5937.94,
    },
    {
        val: 8923000,
        high: 5960.19,
        vol: 126000,
        low: 5936.58,
        change: 4.93,
        available: true,
        time: "08:52",
        close: 5942.87,
        percent: 0.08,
        open: 5937.94,
    },
    {
        val: 9018000,
        high: 5960.19,
        vol: 129600,
        low: 5936.58,
        change: 0.51,
        available: true,
        time: "08:53",
        close: 5938.45,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 9184000,
        high: 5960.19,
        vol: 131300,
        low: 5936.58,
        change: 2.34,
        available: true,
        time: "08:54",
        close: 5940.28,
        percent: 0.04,
        open: 5937.94,
    },
    {
        val: 9475000,
        high: 5960.19,
        vol: 132600,
        low: 5936.15,
        change: -1.79,
        available: true,
        time: "08:55",
        close: 5936.15,
        percent: -0.03,
        open: 5937.94,
    },
    {
        val: 9617000,
        high: 5960.19,
        vol: 134800,
        low: 5936.15,
        change: 0.75,
        available: true,
        time: "08:56",
        close: 5938.69,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 9740000,
        high: 5960.19,
        vol: 138300,
        low: 5935.58,
        change: -2.36,
        available: true,
        time: "08:57",
        close: 5935.58,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 9994000,
        high: 5960.19,
        vol: 142100,
        low: 5935.58,
        change: 1.89,
        available: true,
        time: "08:58",
        close: 5939.83,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 10300000,
        high: 5960.19,
        vol: 145000,
        low: 5935.58,
        change: 6.24,
        available: true,
        time: "08:59",
        close: 5944.18,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 10543000,
        high: 5960.19,
        vol: 148700,
        low: 5935.58,
        change: 8.01,
        available: true,
        time: "09:00",
        close: 5945.95,
        percent: 0.13,
        open: 5937.94,
    },
    {
        val: 10717000,
        high: 5960.19,
        vol: 150200,
        low: 5935.58,
        change: 12.24,
        available: true,
        time: "09:01",
        close: 5950.18,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 10988000,
        high: 5960.19,
        vol: 153900,
        low: 5935.58,
        change: 14.6,
        available: true,
        time: "09:02",
        close: 5952.54,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 11039000,
        high: 5960.19,
        vol: 155800,
        low: 5935.58,
        change: 19.17,
        available: true,
        time: "09:03",
        close: 5957.11,
        percent: 0.32,
        open: 5937.94,
    },
    {
        val: 11152000,
        high: 5960.19,
        vol: 157900,
        low: 5935.58,
        change: 16.38,
        available: true,
        time: "09:04",
        close: 5954.32,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 11377000,
        high: 5960.19,
        vol: 160800,
        low: 5935.58,
        change: 17.34,
        available: true,
        time: "09:05",
        close: 5955.28,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 11588000,
        high: 5960.19,
        vol: 162100,
        low: 5935.58,
        change: 17.75,
        available: true,
        time: "09:06",
        close: 5955.69,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 11882000,
        high: 5960.19,
        vol: 165400,
        low: 5935.58,
        change: 19.78,
        available: true,
        time: "09:07",
        close: 5957.72,
        percent: 0.33,
        open: 5937.94,
    },
    {
        val: 12157000,
        high: 5961.1,
        vol: 168600,
        low: 5935.58,
        change: 23.16,
        available: true,
        time: "09:08",
        close: 5961.1,
        percent: 0.39,
        open: 5937.94,
    },
    {
        val: 12278000,
        high: 5961.1,
        vol: 169800,
        low: 5935.58,
        change: 19.27,
        available: true,
        time: "09:09",
        close: 5957.21,
        percent: 0.32,
        open: 5937.94,
    },
    {
        val: 12295000,
        high: 5961.1,
        vol: 172200,
        low: 5935.58,
        change: 16.23,
        available: true,
        time: "09:10",
        close: 5954.17,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 12489000,
        high: 5961.1,
        vol: 175400,
        low: 5935.58,
        change: 17.91,
        available: true,
        time: "09:11",
        close: 5955.85,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 12720000,
        high: 5961.1,
        vol: 177200,
        low: 5935.58,
        change: 14.57,
        available: true,
        time: "09:12",
        close: 5952.51,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 12791000,
        high: 5961.1,
        vol: 181100,
        low: 5935.58,
        change: 16.08,
        available: true,
        time: "09:13",
        close: 5954.02,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 13072000,
        high: 5961.1,
        vol: 183200,
        low: 5935.58,
        change: 19.85,
        available: true,
        time: "09:14",
        close: 5957.79,
        percent: 0.33,
        open: 5937.94,
    },
    {
        val: 13211000,
        high: 5962.45,
        vol: 184200,
        low: 5935.58,
        change: 24.51,
        available: true,
        time: "09:15",
        close: 5962.45,
        percent: 0.41,
        open: 5937.94,
    },
    {
        val: 13397000,
        high: 5962.45,
        vol: 186700,
        low: 5935.58,
        change: 21.8,
        available: true,
        time: "09:16",
        close: 5959.74,
        percent: 0.37,
        open: 5937.94,
    },
    {
        val: 13574000,
        high: 5962.45,
        vol: 190500,
        low: 5935.58,
        change: 21.44,
        available: true,
        time: "09:17",
        close: 5959.38,
        percent: 0.36,
        open: 5937.94,
    },
    {
        val: 13814000,
        high: 5962.45,
        vol: 194400,
        low: 5935.58,
        change: 23.43,
        available: true,
        time: "09:18",
        close: 5961.37,
        percent: 0.39,
        open: 5937.94,
    },
    {
        val: 13899000,
        high: 5962.45,
        vol: 195500,
        low: 5935.58,
        change: 18.37,
        available: true,
        time: "09:19",
        close: 5956.31,
        percent: 0.31,
        open: 5937.94,
    },
    {
        val: 13910000,
        high: 5962.45,
        vol: 199100,
        low: 5935.58,
        change: 15.1,
        available: true,
        time: "09:20",
        close: 5953.04,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 13960000,
        high: 5962.45,
        vol: 201800,
        low: 5935.58,
        change: 11.8,
        available: true,
        time: "09:21",
        close: 5949.74,
        percent: 0.2,
        open: 5937.94,
    },
    {
        val: 14262000,
        high: 5962.45,
        vol: 205000,
        low: 5935.58,
        change: 8.31,
        available: true,
        time: "09:22",
        close: 5946.25,
        percent: 0.14,
        open: 5937.94,
    },
    {
        val: 14444000,
        high: 5962.45,
        vol: 207300,
        low: 5935.58,
        change: 9.27,
        available: true,
        time: "09:23",
        close: 5947.21,
        percent: 0.16,
        open: 5937.94,
    },
    {
        val: 14580000,
        high: 5962.45,
        vol: 208500,
        low: 5935.58,
        change: 7.21,
        available: true,
        time: "09:24",
        close: 5945.15,
        percent: 0.12,
        open: 5937.94,
    },
    {
        val: 14698000,
        high: 5962.45,
        vol: 210900,
        low: 5935.58,
        change: 3.66,
        available: true,
        time: "09:25",
        close: 5941.6,
        percent: 0.06,
        open: 5937.94,
    },
    {
        val: 14744000,
        high: 5962.45,
        vol: 212700,
        low: 5935.58,
        change: -1.4,
        available: true,
        time: "09:26",
        close: 5936.54,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 14764000,
        high: 5962.45,
        vol: 213700,
        low: 5935.58,
        change: 0.85,
        available: true,
        time: "09:27",
        close: 5938.79,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 14903000,
        high: 5962.45,
        vol: 217300,
        low: 5935.58,
        change: 5.94,
        available: true,
        time: "09:28",
        close: 5943.88,
        percent: 0.1,
        open: 5937.94,
    },
    {
        val: 14940000,
        high: 5962.45,
        vol: 221000,
        low: 5935.58,
        change: 2.29,
        available: true,
        time: "09:29",
        close: 5940.23,
        percent: 0.04,
        open: 5937.94,
    },
    {
        val: 15055000,
        high: 5962.45,
        vol: 222600,
        low: 5935.58,
        change: -1.17,
        available: true,
        time: "09:30",
        close: 5936.77,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 15335000,
        high: 5962.45,
        vol: 226300,
        low: 5935.58,
        change: 1.81,
        available: true,
        time: "09:31",
        close: 5939.75,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 15496000,
        high: 5962.45,
        vol: 229500,
        low: 5935.58,
        change: 3.86,
        available: true,
        time: "09:32",
        close: 5941.8,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 15639000,
        high: 5962.45,
        vol: 232800,
        low: 5935.58,
        change: 5.93,
        available: true,
        time: "09:33",
        close: 5943.87,
        percent: 0.1,
        open: 5937.94,
    },
    {
        val: 15853000,
        high: 5962.45,
        vol: 235200,
        low: 5935.58,
        change: 6.56,
        available: true,
        time: "09:34",
        close: 5944.5,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 16100000,
        high: 5962.45,
        vol: 237300,
        low: 5935.58,
        change: 7.79,
        available: true,
        time: "09:35",
        close: 5945.73,
        percent: 0.13,
        open: 5937.94,
    },
    {
        val: 16301000,
        high: 5962.45,
        vol: 240200,
        low: 5935.58,
        change: 9.5,
        available: true,
        time: "09:36",
        close: 5947.44,
        percent: 0.16,
        open: 5937.94,
    },
    {
        val: 16561000,
        high: 5962.45,
        vol: 242500,
        low: 5935.58,
        change: 6.33,
        available: true,
        time: "09:37",
        close: 5944.27,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 16580000,
        high: 5962.45,
        vol: 244400,
        low: 5935.58,
        change: 7.17,
        available: true,
        time: "09:38",
        close: 5945.11,
        percent: 0.12,
        open: 5937.94,
    },
    {
        val: 16716000,
        high: 5962.45,
        vol: 247700,
        low: 5935.58,
        change: 12.06,
        available: true,
        time: "09:39",
        close: 5950,
        percent: 0.2,
        open: 5937.94,
    },
    {
        val: 17004000,
        high: 5962.45,
        vol: 251100,
        low: 5935.58,
        change: 16.07,
        available: true,
        time: "09:40",
        close: 5954.63,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 17207000,
        high: 5962.45,
        vol: 253400,
        low: 5935.58,
        change: 14.28,
        available: true,
        time: "11:00",
        close: 5952.22,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 17476000,
        high: 5962.45,
        vol: 257100,
        low: 5935.58,
        change: 13.38,
        available: true,
        time: "11:01",
        close: 5951.32,
        percent: 0.23,
        open: 5937.94,
    },
    {
        val: 17699000,
        high: 5962.45,
        vol: 260800,
        low: 5935.58,
        change: 10.52,
        available: true,
        time: "11:02",
        close: 5948.46,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 17946000,
        high: 5962.45,
        vol: 263500,
        low: 5935.58,
        change: 6.87,
        available: true,
        time: "11:03",
        close: 5944.81,
        percent: 0.12,
        open: 5937.94,
    },
    {
        val: 17992000,
        high: 5962.45,
        vol: 264600,
        low: 5935.58,
        change: 4.59,
        available: true,
        time: "11:04",
        close: 5942.53,
        percent: 0.08,
        open: 5937.94,
    },
    {
        val: 18089000,
        high: 5962.45,
        vol: 267600,
        low: 5935.58,
        change: 7.91,
        available: true,
        time: "11:05",
        close: 5945.85,
        percent: 0.13,
        open: 5937.94,
    },
    {
        val: 18118000,
        high: 5962.45,
        vol: 271200,
        low: 5935.58,
        change: 9,
        available: true,
        time: "11:06",
        close: 5946.94,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 18356000,
        high: 5962.45,
        vol: 274500,
        low: 5935.58,
        change: 10.23,
        available: true,
        time: "11:07",
        close: 5948.17,
        percent: 0.17,
        open: 5937.94,
    },
    {
        val: 18564000,
        high: 5962.45,
        vol: 275600,
        low: 5935.58,
        change: 13.45,
        available: true,
        time: "11:08",
        close: 5951.39,
        percent: 0.23,
        open: 5937.94,
    },
    {
        val: 18586000,
        high: 5962.45,
        vol: 277400,
        low: 5935.58,
        change: 9.2,
        available: true,
        time: "11:09",
        close: 5947.14,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 18782000,
        high: 5962.45,
        vol: 279200,
        low: 5935.58,
        change: 12.45,
        available: true,
        time: "11:10",
        close: 5950.39,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 18851000,
        high: 5962.45,
        vol: 283000,
        low: 5935.58,
        change: 14.32,
        available: true,
        time: "11:11",
        close: 5952.26,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 18947000,
        high: 5962.45,
        vol: 284800,
        low: 5935.58,
        change: 17.33,
        available: true,
        time: "11:12",
        close: 5955.27,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 19065000,
        high: 5962.45,
        vol: 287700,
        low: 5935.58,
        change: 15.48,
        available: true,
        time: "11:13",
        close: 5953.42,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 19322000,
        high: 5962.45,
        vol: 291400,
        low: 5935.58,
        change: 20.19,
        available: true,
        time: "11:14",
        close: 5958.13,
        percent: 0.34,
        open: 5937.94,
    },
    {
        val: 19480000,
        high: 5962.45,
        vol: 294300,
        low: 5935.58,
        change: 24.33,
        available: true,
        time: "11:15",
        close: 5962.27,
        percent: 0.41,
        open: 5937.94,
    },
    {
        val: 19751000,
        high: 5962.45,
        vol: 296700,
        low: 5935.58,
        change: 23.55,
        available: true,
        time: "11:16",
        close: 5961.49,
        percent: 0.4,
        open: 5937.94,
    },
    {
        val: 19923000,
        high: 5963.63,
        vol: 298100,
        low: 5935.58,
        change: 25.69,
        available: true,
        time: "11:17",
        close: 5963.63,
        percent: 0.43,
        open: 5937.94,
    },
    {
        val: 20029000,
        high: 5963.63,
        vol: 299600,
        low: 5935.58,
        change: 23.81,
        available: true,
        time: "11:18",
        close: 5961.75,
        percent: 0.4,
        open: 5937.94,
    },
    {
        val: 20209000,
        high: 5963.63,
        vol: 300600,
        low: 5935.58,
        change: 24.86,
        available: true,
        time: "11:19",
        close: 5962.8,
        percent: 0.42,
        open: 5937.94,
    },
    {
        val: 20423000,
        high: 5963.63,
        vol: 303600,
        low: 5935.58,
        change: 23.6,
        available: true,
        time: "11:20",
        close: 5961.54,
        percent: 0.4,
        open: 5937.94,
    },
    {
        val: 20440000,
        high: 5963.63,
        vol: 307300,
        low: 5935.58,
        change: 24.28,
        available: true,
        time: "11:21",
        close: 5962.22,
        percent: 0.41,
        open: 5937.94,
    },
    {
        val: 20687000,
        high: 5963.63,
        vol: 309100,
        low: 5935.58,
        change: 24.14,
        available: true,
        time: "11:22",
        close: 5962.08,
        percent: 0.41,
        open: 5937.94,
    },
    {
        val: 20859000,
        high: 5964.94,
        vol: 312600,
        low: 5935.58,
        change: 27,
        available: true,
        time: "11:23",
        close: 5964.94,
        percent: 0.45,
        open: 5937.94,
    },
    {
        val: 20903000,
        high: 5969.37,
        vol: 316200,
        low: 5935.58,
        change: 31.43,
        available: true,
        time: "11:24",
        close: 5969.37,
        percent: 0.53,
        open: 5937.94,
    },
    {
        val: 21043000,
        high: 5969.37,
        vol: 319700,
        low: 5935.58,
        change: 29.66,
        available: true,
        time: "11:25",
        close: 5967.6,
        percent: 0.5,
        open: 5937.94,
    },
    {
        val: 21114000,
        high: 5969.37,
        vol: 323500,
        low: 5935.58,
        change: 30.69,
        available: true,
        time: "11:26",
        close: 5968.63,
        percent: 0.52,
        open: 5937.94,
    },
    {
        val: 21136000,
        high: 5971.8,
        vol: 326900,
        low: 5935.58,
        change: 33.86,
        available: true,
        time: "11:27",
        close: 5971.8,
        percent: 0.57,
        open: 5937.94,
    },
    {
        val: 21267000,
        high: 5971.94,
        vol: 328300,
        low: 5935.58,
        change: 34,
        available: true,
        time: "11:28",
        close: 5971.94,
        percent: 0.57,
        open: 5937.94,
    },
    {
        val: 21377000,
        high: 5971.94,
        vol: 330100,
        low: 5935.58,
        change: 32.9,
        available: true,
        time: "11:29",
        close: 5970.84,
        percent: 0.55,
        open: 5937.94,
    },
    {
        val: 21530000,
        high: 5971.94,
        vol: 332500,
        low: 5935.58,
        change: 30.42,
        available: true,
        time: "11:30",
        close: 5968.36,
        percent: 0.51,
        open: 5937.94,
    },
    {
        val: 21673000,
        high: 5971.94,
        vol: 334800,
        low: 5935.58,
        change: 30.87,
        available: true,
        time: "11:31",
        close: 5968.81,
        percent: 0.52,
        open: 5937.94,
    },
    {
        val: 21946000,
        high: 5971.94,
        vol: 336400,
        low: 5935.58,
        change: 27.47,
        available: true,
        time: "11:32",
        close: 5965.41,
        percent: 0.46,
        open: 5937.94,
    },
    {
        val: 21980000,
        high: 5971.94,
        vol: 337900,
        low: 5935.58,
        change: 30.05,
        available: true,
        time: "11:33",
        close: 5967.99,
        percent: 0.51,
        open: 5937.94,
    },
    {
        val: 22167000,
        high: 5971.94,
        vol: 340500,
        low: 5935.58,
        change: 26.11,
        available: true,
        time: "11:34",
        close: 5964.05,
        percent: 0.44,
        open: 5937.94,
    },
    {
        val: 22228000,
        high: 5971.94,
        vol: 343300,
        low: 5935.58,
        change: 25.82,
        available: true,
        time: "11:35",
        close: 5963.76,
        percent: 0.43,
        open: 5937.94,
    },
    {
        val: 22487000,
        high: 5971.94,
        vol: 345500,
        low: 5935.58,
        change: 25.55,
        available: true,
        time: "11:36",
        close: 5963.49,
        percent: 0.43,
        open: 5937.94,
    },
    {
        val: 22515000,
        high: 5971.94,
        vol: 347200,
        low: 5935.58,
        change: 22.28,
        available: true,
        time: "11:37",
        close: 5960.22,
        percent: 0.38,
        open: 5937.94,
    },
    {
        val: 22724000,
        high: 5971.94,
        vol: 349500,
        low: 5935.58,
        change: 23.76,
        available: true,
        time: "11:38",
        close: 5961.7,
        percent: 0.4,
        open: 5937.94,
    },
    {
        val: 22923000,
        high: 5971.94,
        vol: 350700,
        low: 5935.58,
        change: 27.26,
        available: true,
        time: "11:39",
        close: 5965.2,
        percent: 0.46,
        open: 5937.94,
    },
    {
        val: 23111000,
        high: 5971.94,
        vol: 353100,
        low: 5935.58,
        change: 26.09,
        available: true,
        time: "11:40",
        close: 5964.03,
        percent: 0.44,
        open: 5937.94,
    },
    {
        val: 23209000,
        high: 5971.94,
        vol: 354500,
        low: 5935.58,
        change: 22.64,
        available: true,
        time: "11:41",
        close: 5960.58,
        percent: 0.38,
        open: 5937.94,
    },
    {
        val: 23383000,
        high: 5971.94,
        vol: 356200,
        low: 5935.58,
        change: 17.89,
        available: true,
        time: "11:42",
        close: 5955.83,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 23534000,
        high: 5971.94,
        vol: 359800,
        low: 5935.58,
        change: 15.45,
        available: true,
        time: "11:43",
        close: 5953.39,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 23745000,
        high: 5971.94,
        vol: 362000,
        low: 5935.58,
        change: 19.37,
        available: true,
        time: "11:44",
        close: 5957.31,
        percent: 0.33,
        open: 5937.94,
    },
    {
        val: 24046000,
        high: 5971.94,
        vol: 364300,
        low: 5935.58,
        change: 16.79,
        available: true,
        time: "11:45",
        close: 5954.73,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 24317000,
        high: 5971.94,
        vol: 367600,
        low: 5935.58,
        change: 18.45,
        available: true,
        time: "11:46",
        close: 5956.39,
        percent: 0.31,
        open: 5937.94,
    },
    {
        val: 24541000,
        high: 5971.94,
        vol: 370300,
        low: 5935.58,
        change: 18.1,
        available: true,
        time: "11:47",
        close: 5956.04,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 24734000,
        high: 5971.94,
        vol: 371300,
        low: 5935.58,
        change: 22.54,
        available: true,
        time: "11:48",
        close: 5960.48,
        percent: 0.38,
        open: 5937.94,
    },
    {
        val: 24817000,
        high: 5971.94,
        vol: 374800,
        low: 5935.58,
        change: 21.84,
        available: true,
        time: "11:49",
        close: 5959.78,
        percent: 0.37,
        open: 5937.94,
    },
    {
        val: 25035000,
        high: 5971.94,
        vol: 378500,
        low: 5935.58,
        change: 22.82,
        available: true,
        time: "11:50",
        close: 5960.76,
        percent: 0.38,
        open: 5937.94,
    },
    {
        val: 25225000,
        high: 5971.94,
        vol: 381100,
        low: 5935.58,
        change: 25.76,
        available: true,
        time: "11:51",
        close: 5963.7,
        percent: 0.43,
        open: 5937.94,
    },
    {
        val: 25302000,
        high: 5971.94,
        vol: 384900,
        low: 5935.58,
        change: 21.74,
        available: true,
        time: "11:52",
        close: 5959.68,
        percent: 0.37,
        open: 5937.94,
    },
    {
        val: 25563000,
        high: 5971.94,
        vol: 388600,
        low: 5935.58,
        change: 26.15,
        available: true,
        time: "11:53",
        close: 5964.09,
        percent: 0.44,
        open: 5937.94,
    },
    {
        val: 25774000,
        high: 5971.94,
        vol: 391400,
        low: 5935.58,
        change: 25.68,
        available: true,
        time: "11:54",
        close: 5963.62,
        percent: 0.43,
        open: 5937.94,
    },
    {
        val: 26014000,
        high: 5971.94,
        vol: 393000,
        low: 5935.58,
        change: 27.84,
        available: true,
        time: "11:55",
        close: 5965.78,
        percent: 0.47,
        open: 5937.94,
    },
    {
        val: 26246000,
        high: 5971.94,
        vol: 396800,
        low: 5935.58,
        change: 30.55,
        available: true,
        time: "11:56",
        close: 5968.49,
        percent: 0.51,
        open: 5937.94,
    },
    {
        val: 26366000,
        high: 5971.94,
        vol: 398500,
        low: 5935.58,
        change: 33.1,
        available: true,
        time: "11:57",
        close: 5971.04,
        percent: 0.56,
        open: 5937.94,
    },
    {
        val: 26461000,
        high: 5972.23,
        vol: 400000,
        low: 5935.58,
        change: 34.29,
        available: true,
        time: "11:58",
        close: 5972.23,
        percent: 0.58,
        open: 5937.94,
    },
    {
        val: 26594000,
        high: 5977.05,
        vol: 401900,
        low: 5935.58,
        change: 39.11,
        available: true,
        time: "11:59",
        close: 5977.05,
        percent: 0.66,
        open: 5937.94,
    },
    {
        val: 26815000,
        high: 5977.05,
        vol: 404100,
        low: 5935.58,
        change: 37.43,
        available: true,
        time: "12:00",
        close: 5975.37,
        percent: 0.63,
        open: 5937.94,
    },
    {
        val: 27117000,
        high: 5977.05,
        vol: 406500,
        low: 5935.58,
        change: 37.16,
        available: true,
        time: "12:01",
        close: 5975.1,
        percent: 0.63,
        open: 5937.94,
    },
    {
        val: 27405000,
        high: 5977.05,
        vol: 408900,
        low: 5935.58,
        change: 35.9,
        available: true,
        time: "12:02",
        close: 5973.84,
        percent: 0.6,
        open: 5937.94,
    },
    {
        val: 27557000,
        high: 5977.05,
        vol: 412700,
        low: 5935.58,
        change: 32.52,
        available: true,
        time: "12:03",
        close: 5970.46,
        percent: 0.55,
        open: 5937.94,
    },
    {
        val: 27604000,
        high: 5977.05,
        vol: 416200,
        low: 5935.58,
        change: 28.36,
        available: true,
        time: "12:04",
        close: 5966.3,
        percent: 0.48,
        open: 5937.94,
    },
    {
        val: 27829000,
        high: 5977.05,
        vol: 417300,
        low: 5935.58,
        change: 27.09,
        available: true,
        time: "12:05",
        close: 5965.03,
        percent: 0.46,
        open: 5937.94,
    },
    {
        val: 27940000,
        high: 5977.05,
        vol: 420900,
        low: 5935.58,
        change: 27.43,
        available: true,
        time: "12:06",
        close: 5965.37,
        percent: 0.46,
        open: 5937.94,
    },
    {
        val: 28068000,
        high: 5977.05,
        vol: 422300,
        low: 5935.58,
        change: 23.61,
        available: true,
        time: "12:07",
        close: 5961.55,
        percent: 0.4,
        open: 5937.94,
    },
    {
        val: 28110000,
        high: 5977.05,
        vol: 424300,
        low: 5935.58,
        change: 26.54,
        available: true,
        time: "12:08",
        close: 5964.48,
        percent: 0.45,
        open: 5937.94,
    },
    {
        val: 28227000,
        high: 5977.05,
        vol: 427200,
        low: 5935.58,
        change: 23.93,
        available: true,
        time: "12:09",
        close: 5961.87,
        percent: 0.4,
        open: 5937.94,
    },
    {
        val: 28271000,
        high: 5977.05,
        vol: 430900,
        low: 5935.58,
        change: 24.62,
        available: true,
        time: "12:10",
        close: 5962.56,
        percent: 0.41,
        open: 5937.94,
    },
    {
        val: 28541000,
        high: 5977.05,
        vol: 432500,
        low: 5935.58,
        change: 21.85,
        available: true,
        time: "12:11",
        close: 5959.79,
        percent: 0.37,
        open: 5937.94,
    },
    {
        val: 28614000,
        high: 5977.05,
        vol: 436200,
        low: 5935.58,
        change: 19.62,
        available: true,
        time: "12:12",
        close: 5957.56,
        percent: 0.33,
        open: 5937.94,
    },
    {
        val: 28687000,
        high: 5977.05,
        vol: 438600,
        low: 5935.58,
        change: 17.02,
        available: true,
        time: "12:13",
        close: 5954.96,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 28756000,
        high: 5977.05,
        vol: 440500,
        low: 5935.58,
        change: 12.8,
        available: true,
        time: "12:14",
        close: 5950.74,
        percent: 0.22,
        open: 5937.94,
    },
    {
        val: 28842000,
        high: 5977.05,
        vol: 442200,
        low: 5935.58,
        change: 9.35,
        available: true,
        time: "12:15",
        close: 5947.29,
        percent: 0.16,
        open: 5937.94,
    },
    {
        val: 29120000,
        high: 5977.05,
        vol: 445900,
        low: 5935.58,
        change: 12.67,
        available: true,
        time: "12:16",
        close: 5950.61,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 29407000,
        high: 5977.05,
        vol: 448000,
        low: 5935.58,
        change: 14.84,
        available: true,
        time: "12:17",
        close: 5952.78,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 29655000,
        high: 5977.05,
        vol: 450100,
        low: 5935.58,
        change: 10.12,
        available: true,
        time: "12:18",
        close: 5948.06,
        percent: 0.17,
        open: 5937.94,
    },
    {
        val: 29890000,
        high: 5977.05,
        vol: 451700,
        low: 5935.58,
        change: 14.37,
        available: true,
        time: "12:19",
        close: 5952.31,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 30187000,
        high: 5977.05,
        vol: 453000,
        low: 5935.58,
        change: 15.84,
        available: true,
        time: "12:20",
        close: 5953.78,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 30404000,
        high: 5977.05,
        vol: 456500,
        low: 5935.58,
        change: 18.67,
        available: true,
        time: "12:21",
        close: 5956.61,
        percent: 0.31,
        open: 5937.94,
    },
    {
        val: 30583000,
        high: 5977.05,
        vol: 458400,
        low: 5935.58,
        change: 16.44,
        available: true,
        time: "12:22",
        close: 5954.38,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 30892000,
        high: 5977.05,
        vol: 461900,
        low: 5935.58,
        change: 11.77,
        available: true,
        time: "12:23",
        close: 5949.71,
        percent: 0.2,
        open: 5937.94,
    },
    {
        val: 31104000,
        high: 5977.05,
        vol: 464300,
        low: 5935.58,
        change: 16.66,
        available: true,
        time: "12:24",
        close: 5954.6,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 31268000,
        high: 5977.05,
        vol: 466900,
        low: 5935.58,
        change: 12.67,
        available: true,
        time: "12:25",
        close: 5950.61,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 31503000,
        high: 5977.05,
        vol: 468700,
        low: 5935.58,
        change: 16.98,
        available: true,
        time: "12:26",
        close: 5954.92,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 31795000,
        high: 5977.05,
        vol: 470500,
        low: 5935.58,
        change: 15.9,
        available: true,
        time: "12:27",
        close: 5953.84,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 31973000,
        high: 5977.05,
        vol: 472100,
        low: 5935.58,
        change: 18.14,
        available: true,
        time: "12:28",
        close: 5956.08,
        percent: 0.31,
        open: 5937.94,
    },
    {
        val: 32196000,
        high: 5977.05,
        vol: 475500,
        low: 5935.58,
        change: 16.8,
        available: true,
        time: "12:29",
        close: 5954.74,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 32275000,
        high: 5977.05,
        vol: 479000,
        low: 5935.58,
        change: 14.22,
        available: true,
        time: "12:30",
        close: 5952.16,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 32500000,
        high: 5977.05,
        vol: 481100,
        low: 5935.58,
        change: 13.28,
        available: true,
        time: "12:31",
        close: 5951.22,
        percent: 0.22,
        open: 5937.94,
    },
    {
        val: 32659000,
        high: 5977.05,
        vol: 482500,
        low: 5935.58,
        change: 17.34,
        available: true,
        time: "12:32",
        close: 5955.28,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 32734000,
        high: 5977.05,
        vol: 486000,
        low: 5935.58,
        change: 12.59,
        available: true,
        time: "12:33",
        close: 5950.53,
        percent: 0.21,
        open: 5937.94,
    },
    {
        val: 32874000,
        high: 5977.05,
        vol: 487300,
        low: 5935.58,
        change: 11.31,
        available: true,
        time: "12:34",
        close: 5949.25,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 32910000,
        high: 5977.05,
        vol: 489400,
        low: 5935.58,
        change: 16.22,
        available: true,
        time: "12:35",
        close: 5954.16,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 33046000,
        high: 5977.05,
        vol: 492400,
        low: 5935.58,
        change: 16.5,
        available: true,
        time: "12:36",
        close: 5954.44,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 33332000,
        high: 5977.05,
        vol: 493700,
        low: 5935.58,
        change: 18,
        available: true,
        time: "12:37",
        close: 5955.94,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 33468000,
        high: 5977.05,
        vol: 495100,
        low: 5935.58,
        change: 17.54,
        available: true,
        time: "12:38",
        close: 5955.48,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 33659000,
        high: 5977.05,
        vol: 498100,
        low: 5935.58,
        change: 18.06,
        available: true,
        time: "12:39",
        close: 5956,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 33920000,
        high: 5977.05,
        vol: 501000,
        low: 5935.58,
        change: 15.78,
        available: true,
        time: "12:40",
        close: 5953.72,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 33974000,
        high: 5977.05,
        vol: 504700,
        low: 5935.58,
        change: 11.39,
        available: true,
        time: "12:41",
        close: 5949.33,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 34108000,
        high: 5977.05,
        vol: 506700,
        low: 5935.58,
        change: 9.81,
        available: true,
        time: "12:42",
        close: 5947.75,
        percent: 0.17,
        open: 5937.94,
    },
    {
        val: 34227000,
        high: 5977.05,
        vol: 508100,
        low: 5935.58,
        change: 10.65,
        available: true,
        time: "12:43",
        close: 5948.59,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 34494000,
        high: 5977.05,
        vol: 509100,
        low: 5935.58,
        change: 6.29,
        available: true,
        time: "12:44",
        close: 5944.23,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 34659000,
        high: 5977.05,
        vol: 510200,
        low: 5935.58,
        change: 3.06,
        available: true,
        time: "12:45",
        close: 5941,
        percent: 0.05,
        open: 5937.94,
    },
    {
        val: 34793000,
        high: 5977.05,
        vol: 511400,
        low: 5935.58,
        change: 0.82,
        available: true,
        time: "12:46",
        close: 5938.76,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 34965000,
        high: 5977.05,
        vol: 513100,
        low: 5935.58,
        change: 1.33,
        available: true,
        time: "12:47",
        close: 5939.27,
        percent: 0.02,
        open: 5937.94,
    },
    {
        val: 35182000,
        high: 5977.05,
        vol: 514700,
        low: 5935.2,
        change: -2.74,
        available: true,
        time: "12:48",
        close: 5935.2,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 35255000,
        high: 5977.05,
        vol: 517000,
        low: 5935.2,
        change: -1.77,
        available: true,
        time: "12:49",
        close: 5936.17,
        percent: -0.03,
        open: 5937.94,
    },
    {
        val: 35501000,
        high: 5977.05,
        vol: 519700,
        low: 5935.2,
        change: 0.8,
        available: true,
        time: "12:50",
        close: 5938.74,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 35749000,
        high: 5977.05,
        vol: 522500,
        low: 5935.2,
        change: -0.37,
        available: true,
        time: "12:51",
        close: 5937.57,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 35783000,
        high: 5977.05,
        vol: 524600,
        low: 5935.2,
        change: 1.31,
        available: true,
        time: "12:52",
        close: 5939.25,
        percent: 0.02,
        open: 5937.94,
    },
    {
        val: 35796000,
        high: 5977.05,
        vol: 528500,
        low: 5935.2,
        change: -0.53,
        available: true,
        time: "12:53",
        close: 5937.41,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 35856000,
        high: 5977.05,
        vol: 531200,
        low: 5935.13,
        change: -2.81,
        available: true,
        time: "12:54",
        close: 5935.13,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 36065000,
        high: 5977.05,
        vol: 533300,
        low: 5935.13,
        change: -2.42,
        available: true,
        time: "12:55",
        close: 5935.52,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 36358000,
        high: 5977.05,
        vol: 536800,
        low: 5935.13,
        change: 1.99,
        available: true,
        time: "12:56",
        close: 5939.93,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 36515000,
        high: 5977.05,
        vol: 540200,
        low: 5935.13,
        change: 6.62,
        available: true,
        time: "12:57",
        close: 5944.56,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 36549000,
        high: 5977.05,
        vol: 542000,
        low: 5935.13,
        change: 11.59,
        available: true,
        time: "12:58",
        close: 5949.53,
        percent: 0.2,
        open: 5937.94,
    },
    {
        val: 36689000,
        high: 5977.05,
        vol: 545100,
        low: 5935.13,
        change: 6.98,
        available: true,
        time: "12:59",
        close: 5944.92,
        percent: 0.12,
        open: 5937.94,
    },
    {
        val: 36958000,
        high: 5977.05,
        vol: 546900,
        low: 5935.13,
        change: 5.56,
        available: true,
        time: "13:00",
        close: 5943.5,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 37072000,
        high: 5977.05,
        vol: 550500,
        low: 5935.13,
        change: 4.07,
        available: true,
        time: "13:01",
        close: 5942.01,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 37234000,
        high: 5977.05,
        vol: 552300,
        low: 5935.13,
        change: 3.61,
        available: true,
        time: "13:02",
        close: 5941.55,
        percent: 0.06,
        open: 5937.94,
    },
    {
        val: 37353000,
        high: 5977.05,
        vol: 555400,
        low: 5935.13,
        change: 7.31,
        available: true,
        time: "13:03",
        close: 5945.25,
        percent: 0.12,
        open: 5937.94,
    },
    {
        val: 37619000,
        high: 5977.05,
        vol: 556900,
        low: 5935.13,
        change: 2.43,
        available: true,
        time: "13:04",
        close: 5940.37,
        percent: 0.04,
        open: 5937.94,
    },
    {
        val: 37851000,
        high: 5977.05,
        vol: 558400,
        low: 5935.13,
        change: 2.95,
        available: true,
        time: "13:05",
        close: 5940.89,
        percent: 0.05,
        open: 5937.94,
    },
    {
        val: 38159000,
        high: 5977.05,
        vol: 561400,
        low: 5935.13,
        change: 1.9,
        available: true,
        time: "13:06",
        close: 5939.84,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 38283000,
        high: 5977.05,
        vol: 565300,
        low: 5935.13,
        change: 0.85,
        available: true,
        time: "13:07",
        close: 5938.79,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 38303000,
        high: 5977.05,
        vol: 567900,
        low: 5935.13,
        change: -1.27,
        available: true,
        time: "13:08",
        close: 5936.67,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 38369000,
        high: 5977.05,
        vol: 571200,
        low: 5935.13,
        change: -2.29,
        available: true,
        time: "13:09",
        close: 5935.65,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 38403000,
        high: 5977.05,
        vol: 573500,
        low: 5931.96,
        change: -5.98,
        available: true,
        time: "13:10",
        close: 5931.96,
        percent: -0.1,
        open: 5937.94,
    },
    {
        val: 38674000,
        high: 5977.05,
        vol: 576700,
        low: 5930.9,
        change: -7.04,
        available: true,
        time: "13:11",
        close: 5930.9,
        percent: -0.12,
        open: 5937.94,
    },
    {
        val: 38752000,
        high: 5977.05,
        vol: 580300,
        low: 5930.9,
        change: -5.73,
        available: true,
        time: "13:12",
        close: 5932.21,
        percent: -0.1,
        open: 5937.94,
    },
    {
        val: 38985000,
        high: 5977.05,
        vol: 582600,
        low: 5930.9,
        change: -6.17,
        available: true,
        time: "13:13",
        close: 5931.77,
        percent: -0.1,
        open: 5937.94,
    },
    {
        val: 39123000,
        high: 5977.05,
        vol: 585400,
        low: 5930.9,
        change: -1.66,
        available: true,
        time: "13:14",
        close: 5936.28,
        percent: -0.03,
        open: 5937.94,
    },
    {
        val: 39286000,
        high: 5977.05,
        vol: 589000,
        low: 5930.9,
        change: -4.58,
        available: true,
        time: "13:15",
        close: 5933.36,
        percent: -0.08,
        open: 5937.94,
    },
    {
        val: 39541000,
        high: 5977.05,
        vol: 592300,
        low: 5930.9,
        change: -4.04,
        available: true,
        time: "13:16",
        close: 5933.9,
        percent: -0.07,
        open: 5937.94,
    },
    {
        val: 39575000,
        high: 5977.05,
        vol: 593500,
        low: 5929.51,
        change: -8.43,
        available: true,
        time: "13:17",
        close: 5929.51,
        percent: -0.14,
        open: 5937.94,
    },
    {
        val: 39651000,
        high: 5977.05,
        vol: 596000,
        low: 5925.81,
        change: -12.13,
        available: true,
        time: "13:18",
        close: 5925.81,
        percent: -0.2,
        open: 5937.94,
    },
    {
        val: 39749000,
        high: 5977.05,
        vol: 598200,
        low: 5925.81,
        change: -9.51,
        available: true,
        time: "13:19",
        close: 5928.43,
        percent: -0.16,
        open: 5937.94,
    },
    {
        val: 39769000,
        high: 5977.05,
        vol: 600800,
        low: 5925.81,
        change: -5.16,
        available: true,
        time: "13:20",
        close: 5932.78,
        percent: -0.09,
        open: 5937.94,
    },
    {
        val: 40002000,
        high: 5977.05,
        vol: 604000,
        low: 5925.81,
        change: -3.22,
        available: true,
        time: "13:21",
        close: 5934.72,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 40274000,
        high: 5977.05,
        vol: 607600,
        low: 5925.81,
        change: -5.76,
        available: true,
        time: "13:22",
        close: 5932.18,
        percent: -0.1,
        open: 5937.94,
    },
    {
        val: 40582000,
        high: 5977.05,
        vol: 610300,
        low: 5925.81,
        change: -10.15,
        available: true,
        time: "13:23",
        close: 5927.79,
        percent: -0.17,
        open: 5937.94,
    },
    {
        val: 40859000,
        high: 5977.05,
        vol: 611700,
        low: 5925.81,
        change: -7.87,
        available: true,
        time: "13:24",
        close: 5930.07,
        percent: -0.13,
        open: 5937.94,
    },
    {
        val: 41016000,
        high: 5977.05,
        vol: 615600,
        low: 5925.81,
        change: -4.48,
        available: true,
        time: "13:25",
        close: 5933.46,
        percent: -0.08,
        open: 5937.94,
    },
    {
        val: 41209000,
        high: 5977.05,
        vol: 617000,
        low: 5925.81,
        change: 0.08,
        available: true,
        time: "13:26",
        close: 5938.02,
        percent: 0,
        open: 5937.94,
    },
    {
        val: 41512000,
        high: 5977.05,
        vol: 618700,
        low: 5925.81,
        change: -2.45,
        available: true,
        time: "13:27",
        close: 5935.49,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 41584000,
        high: 5977.05,
        vol: 621900,
        low: 5925.81,
        change: -4.38,
        available: true,
        time: "13:28",
        close: 5933.56,
        percent: -0.07,
        open: 5937.94,
    },
    {
        val: 41796000,
        high: 5977.05,
        vol: 623000,
        low: 5925.81,
        change: -7.55,
        available: true,
        time: "13:29",
        close: 5930.39,
        percent: -0.13,
        open: 5937.94,
    },
    {
        val: 42071000,
        high: 5977.05,
        vol: 626500,
        low: 5925.81,
        change: -6.24,
        available: true,
        time: "13:30",
        close: 5931.7,
        percent: -0.11,
        open: 5937.94,
    },
    {
        val: 42270000,
        high: 5977.05,
        vol: 630300,
        low: 5925.81,
        change: -1.57,
        available: true,
        time: "13:31",
        close: 5936.37,
        percent: -0.03,
        open: 5937.94,
    },
    {
        val: 42511000,
        high: 5977.05,
        vol: 633900,
        low: 5925.81,
        change: -2.91,
        available: true,
        time: "13:32",
        close: 5935.03,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 42674000,
        high: 5977.05,
        vol: 636200,
        low: 5925.81,
        change: -3.43,
        available: true,
        time: "13:33",
        close: 5934.51,
        percent: -0.06,
        open: 5937.94,
    },
    {
        val: 42874000,
        high: 5977.05,
        vol: 637300,
        low: 5925.81,
        change: -0.98,
        available: true,
        time: "13:34",
        close: 5936.96,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 42951000,
        high: 5977.05,
        vol: 638800,
        low: 5925.81,
        change: -0.24,
        available: true,
        time: "13:35",
        close: 5937.7,
        percent: 0,
        open: 5937.94,
    },
    {
        val: 43227000,
        high: 5977.05,
        vol: 640800,
        low: 5925.81,
        change: -1.01,
        available: true,
        time: "13:36",
        close: 5936.93,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 43407000,
        high: 5977.05,
        vol: 642400,
        low: 5925.81,
        change: -2.4,
        available: true,
        time: "13:37",
        close: 5935.54,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 43628000,
        high: 5977.05,
        vol: 644700,
        low: 5925.81,
        change: -1.06,
        available: true,
        time: "13:38",
        close: 5936.88,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 43934000,
        high: 5977.05,
        vol: 647100,
        low: 5925.81,
        change: 1,
        available: true,
        time: "13:39",
        close: 5938.94,
        percent: 0.02,
        open: 5937.94,
    },
    {
        val: 44136000,
        high: 5977.05,
        vol: 648300,
        low: 5925.81,
        change: -3.07,
        available: true,
        time: "13:40",
        close: 5934.87,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 44182000,
        high: 5977.05,
        vol: 650900,
        low: 5925.81,
        change: -2.28,
        available: true,
        time: "13:41",
        close: 5935.66,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 44227000,
        high: 5977.05,
        vol: 653900,
        low: 5925.81,
        change: -7.34,
        available: true,
        time: "13:42",
        close: 5930.6,
        percent: -0.12,
        open: 5937.94,
    },
    {
        val: 44498000,
        high: 5977.05,
        vol: 656500,
        low: 5925.81,
        change: -11.18,
        available: true,
        time: "13:43",
        close: 5926.76,
        percent: -0.19,
        open: 5937.94,
    },
    {
        val: 44728000,
        high: 5977.05,
        vol: 657900,
        low: 5925.81,
        change: -6.3,
        available: true,
        time: "13:44",
        close: 5931.64,
        percent: -0.11,
        open: 5937.94,
    },
    {
        val: 44867000,
        high: 5977.05,
        vol: 660800,
        low: 5925.81,
        change: -5.27,
        available: true,
        time: "13:45",
        close: 5932.67,
        percent: -0.09,
        open: 5937.94,
    },
    {
        val: 45161000,
        high: 5977.05,
        vol: 662900,
        low: 5925.81,
        change: -2.61,
        available: true,
        time: "13:46",
        close: 5935.33,
        percent: -0.04,
        open: 5937.94,
    },
    {
        val: 45200000,
        high: 5977.05,
        vol: 664500,
        low: 5925.81,
        change: 0.66,
        available: true,
        time: "13:47",
        close: 5938.6,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 45215000,
        high: 5977.05,
        vol: 665800,
        low: 5925.81,
        change: -3.06,
        available: true,
        time: "13:48",
        close: 5934.88,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 45225000,
        high: 5977.05,
        vol: 669700,
        low: 5925.81,
        change: -1.46,
        available: true,
        time: "13:49",
        close: 5936.48,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 45380000,
        high: 5977.05,
        vol: 670900,
        low: 5925.81,
        change: 1.99,
        available: true,
        time: "13:50",
        close: 5939.93,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 45670000,
        high: 5977.05,
        vol: 673200,
        low: 5925.81,
        change: -0.5,
        available: true,
        time: "13:51",
        close: 5937.44,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 45692000,
        high: 5977.05,
        vol: 675900,
        low: 5925.81,
        change: 3.94,
        available: true,
        time: "13:52",
        close: 5941.88,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 45987000,
        high: 5977.05,
        vol: 679700,
        low: 5925.81,
        change: 3.51,
        available: true,
        time: "13:53",
        close: 5941.45,
        percent: 0.06,
        open: 5937.94,
    },
    {
        val: 46202000,
        high: 5977.05,
        vol: 682300,
        low: 5925.81,
        change: 2.68,
        available: true,
        time: "13:54",
        close: 5940.62,
        percent: 0.05,
        open: 5937.94,
    },
    {
        val: 46296000,
        high: 5977.05,
        vol: 684800,
        low: 5925.81,
        change: 1.95,
        available: true,
        time: "13:55",
        close: 5939.89,
        percent: 0.03,
        open: 5937.94,
    },
    {
        val: 46344000,
        high: 5977.05,
        vol: 688300,
        low: 5925.81,
        change: 0.53,
        available: true,
        time: "13:56",
        close: 5938.47,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 46639000,
        high: 5977.05,
        vol: 691200,
        low: 5925.81,
        change: 5.22,
        available: true,
        time: "13:57",
        close: 5943.16,
        percent: 0.09,
        open: 5937.94,
    },
    {
        val: 46657000,
        high: 5977.05,
        vol: 693900,
        low: 5925.81,
        change: 0.85,
        available: true,
        time: "13:58",
        close: 5938.79,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 46819000,
        high: 5977.05,
        vol: 696900,
        low: 5925.81,
        change: -3.1,
        available: true,
        time: "13:59",
        close: 5934.84,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 46863000,
        high: 5977.05,
        vol: 699400,
        low: 5925.81,
        change: -0.54,
        available: true,
        time: "14:00",
        close: 5937.4,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 47145000,
        high: 5977.05,
        vol: 702300,
        low: 5925.81,
        change: -1.41,
        available: true,
        time: "14:01",
        close: 5936.53,
        percent: -0.02,
        open: 5937.94,
    },
    {
        val: 47176000,
        high: 5977.05,
        vol: 706200,
        low: 5925.81,
        change: -0.43,
        available: true,
        time: "14:02",
        close: 5937.51,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 47452000,
        high: 5977.05,
        vol: 708400,
        low: 5925.81,
        change: -2.87,
        available: true,
        time: "14:03",
        close: 5935.07,
        percent: -0.05,
        open: 5937.94,
    },
    {
        val: 47553000,
        high: 5977.05,
        vol: 712300,
        low: 5925.81,
        change: -3.79,
        available: true,
        time: "14:04",
        close: 5934.15,
        percent: -0.06,
        open: 5937.94,
    },
    {
        val: 47838000,
        high: 5977.05,
        vol: 714200,
        low: 5925.81,
        change: -8.56,
        available: true,
        time: "14:05",
        close: 5929.38,
        percent: -0.14,
        open: 5937.94,
    },
    {
        val: 47998000,
        high: 5977.05,
        vol: 717100,
        low: 5925.81,
        change: -5.9,
        available: true,
        time: "14:06",
        close: 5932.04,
        percent: -0.1,
        open: 5937.94,
    },
    {
        val: 48187000,
        high: 5977.05,
        vol: 719900,
        low: 5925.81,
        change: -7.29,
        available: true,
        time: "14:07",
        close: 5930.65,
        percent: -0.12,
        open: 5937.94,
    },
    {
        val: 48427000,
        high: 5977.05,
        vol: 721600,
        low: 5925.81,
        change: -6.48,
        available: true,
        time: "14:08",
        close: 5931.46,
        percent: -0.11,
        open: 5937.94,
    },
    {
        val: 48456000,
        high: 5977.05,
        vol: 724600,
        low: 5925.81,
        change: -4.72,
        available: true,
        time: "14:09",
        close: 5933.22,
        percent: -0.08,
        open: 5937.94,
    },
    {
        val: 48658000,
        high: 5977.05,
        vol: 726300,
        low: 5925.81,
        change: -8.26,
        available: true,
        time: "14:10",
        close: 5929.34,
        percent: -0.14,
        open: 5937.94,
    },
    {
        val: 48793000,
        high: 5977.05,
        vol: 727400,
        low: 5925.81,
        change: -3.32,
        available: true,
        time: "15:20",
        close: 5934.62,
        percent: -0.06,
        open: 5937.94,
    },
    {
        val: 49018000,
        high: 5977.05,
        vol: 729300,
        low: 5925.81,
        change: -0.06,
        available: true,
        time: "15:21",
        close: 5937.88,
        percent: 0,
        open: 5937.94,
    },
    {
        val: 49040000,
        high: 5977.05,
        vol: 733000,
        low: 5925.81,
        change: 1.07,
        available: true,
        time: "15:22",
        close: 5939.01,
        percent: 0.02,
        open: 5937.94,
    },
    {
        val: 49212000,
        high: 5977.05,
        vol: 734300,
        low: 5925.81,
        change: 0.57,
        available: true,
        time: "15:23",
        close: 5938.51,
        percent: 0.01,
        open: 5937.94,
    },
    {
        val: 49503000,
        high: 5977.05,
        vol: 737400,
        low: 5925.81,
        change: 3.79,
        available: true,
        time: "15:24",
        close: 5941.73,
        percent: 0.06,
        open: 5937.94,
    },
    {
        val: 49790000,
        high: 5977.05,
        vol: 741000,
        low: 5925.81,
        change: 8.69,
        available: true,
        time: "15:25",
        close: 5946.63,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 49838000,
        high: 5977.05,
        vol: 743800,
        low: 5925.81,
        change: 10.5,
        available: true,
        time: "15:26",
        close: 5948.44,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 50066000,
        high: 5977.05,
        vol: 745600,
        low: 5925.81,
        change: 8.72,
        available: true,
        time: "15:27",
        close: 5946.66,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 50236000,
        high: 5977.05,
        vol: 748900,
        low: 5925.81,
        change: 4.43,
        available: true,
        time: "15:28",
        close: 5942.37,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 50483000,
        high: 5977.05,
        vol: 749900,
        low: 5925.81,
        change: 3.06,
        available: true,
        time: "15:29",
        close: 5941,
        percent: 0.05,
        open: 5937.94,
    },
    {
        val: 50700000,
        high: 5977.05,
        vol: 753100,
        low: 5925.81,
        change: 4.67,
        available: true,
        time: "15:30",
        close: 5942.61,
        percent: 0.08,
        open: 5937.94,
    },
    {
        val: 50791000,
        high: 5977.05,
        vol: 756800,
        low: 5925.81,
        change: 6.48,
        available: true,
        time: "15:31",
        close: 5944.42,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 50818000,
        high: 5977.05,
        vol: 759400,
        low: 5925.81,
        change: 2.48,
        available: true,
        time: "15:32",
        close: 5940.42,
        percent: 0.04,
        open: 5937.94,
    },
    {
        val: 50863000,
        high: 5977.05,
        vol: 761700,
        low: 5925.81,
        change: 4.13,
        available: true,
        time: "15:33",
        close: 5942.07,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 51097000,
        high: 5977.05,
        vol: 762800,
        low: 5925.81,
        change: 2.11,
        available: true,
        time: "15:34",
        close: 5940.05,
        percent: 0.04,
        open: 5937.94,
    },
    {
        val: 51163000,
        high: 5977.05,
        vol: 765100,
        low: 5925.81,
        change: 3.29,
        available: true,
        time: "15:35",
        close: 5941.23,
        percent: 0.06,
        open: 5937.94,
    },
    {
        val: 51359000,
        high: 5977.05,
        vol: 767200,
        low: 5925.81,
        change: 6.39,
        available: true,
        time: "15:36",
        close: 5944.33,
        percent: 0.11,
        open: 5937.94,
    },
    {
        val: 51647000,
        high: 5977.05,
        vol: 768200,
        low: 5925.81,
        change: 10.96,
        available: true,
        time: "15:37",
        close: 5948.9,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 51726000,
        high: 5977.05,
        vol: 771600,
        low: 5925.81,
        change: 11.53,
        available: true,
        time: "15:38",
        close: 5949.47,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 51918000,
        high: 5977.05,
        vol: 775500,
        low: 5925.81,
        change: 11.08,
        available: true,
        time: "15:39",
        close: 5949.02,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 52035000,
        high: 5977.05,
        vol: 778200,
        low: 5925.81,
        change: 12.81,
        available: true,
        time: "15:40",
        close: 5950.75,
        percent: 0.22,
        open: 5937.94,
    },
    {
        val: 52165000,
        high: 5977.05,
        vol: 781400,
        low: 5925.81,
        change: 8.85,
        available: true,
        time: "15:41",
        close: 5946.79,
        percent: 0.15,
        open: 5937.94,
    },
    {
        val: 52430000,
        high: 5977.05,
        vol: 783700,
        low: 5925.81,
        change: 11.43,
        available: true,
        time: "15:42",
        close: 5949.37,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 52691000,
        high: 5977.05,
        vol: 787100,
        low: 5925.81,
        change: 14.92,
        available: true,
        time: "15:43",
        close: 5952.86,
        percent: 0.25,
        open: 5937.94,
    },
    {
        val: 52819000,
        high: 5977.05,
        vol: 788400,
        low: 5925.81,
        change: 18.84,
        available: true,
        time: "15:44",
        close: 5956.78,
        percent: 0.32,
        open: 5937.94,
    },
    {
        val: 52958000,
        high: 5977.05,
        vol: 790200,
        low: 5925.81,
        change: 18.03,
        available: true,
        time: "15:45",
        close: 5955.97,
        percent: 0.3,
        open: 5937.94,
    },
    {
        val: 52992000,
        high: 5977.05,
        vol: 794000,
        low: 5925.81,
        change: 16.92,
        available: true,
        time: "15:46",
        close: 5954.86,
        percent: 0.28,
        open: 5937.94,
    },
    {
        val: 53283000,
        high: 5977.05,
        vol: 795500,
        low: 5925.81,
        change: 19.13,
        available: true,
        time: "15:47",
        close: 5957.07,
        percent: 0.32,
        open: 5937.94,
    },
    {
        val: 53330000,
        high: 5977.05,
        vol: 797800,
        low: 5925.81,
        change: 15.31,
        available: true,
        time: "15:48",
        close: 5953.25,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 53428000,
        high: 5977.05,
        vol: 799100,
        low: 5925.81,
        change: 14.04,
        available: true,
        time: "15:49",
        close: 5951.98,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 53639000,
        high: 5977.05,
        vol: 801000,
        low: 5925.81,
        change: 15.42,
        available: true,
        time: "15:50",
        close: 5953.36,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 53819000,
        high: 5977.05,
        vol: 804700,
        low: 5925.81,
        change: 18.75,
        available: true,
        time: "15:51",
        close: 5956.69,
        percent: 0.32,
        open: 5937.94,
    },
    {
        val: 54081000,
        high: 5977.05,
        vol: 807400,
        low: 5925.81,
        change: 17.25,
        available: true,
        time: "15:52",
        close: 5955.19,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 54179000,
        high: 5977.05,
        vol: 811300,
        low: 5925.81,
        change: 17.46,
        available: true,
        time: "15:53",
        close: 5955.4,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 54291000,
        high: 5977.05,
        vol: 814300,
        low: 5925.81,
        change: 21.23,
        available: true,
        time: "15:54",
        close: 5959.17,
        percent: 0.36,
        open: 5937.94,
    },
    {
        val: 54562000,
        high: 5977.05,
        vol: 816500,
        low: 5925.81,
        change: 20.56,
        available: true,
        time: "15:55",
        close: 5958.5,
        percent: 0.35,
        open: 5937.94,
    },
    {
        val: 54662000,
        high: 5977.05,
        vol: 818600,
        low: 5925.81,
        change: 21.82,
        available: true,
        time: "15:56",
        close: 5959.76,
        percent: 0.37,
        open: 5937.94,
    },
    {
        val: 54890000,
        high: 5977.05,
        vol: 819800,
        low: 5925.81,
        change: 25.53,
        available: true,
        time: "15:57",
        close: 5963.47,
        percent: 0.43,
        open: 5937.94,
    },
    {
        val: 55105000,
        high: 5977.05,
        vol: 823600,
        low: 5925.81,
        change: 26.41,
        available: true,
        time: "15:58",
        close: 5964.35,
        percent: 0.44,
        open: 5937.94,
    },
    {
        val: 55333000,
        high: 5977.05,
        vol: 825100,
        low: 5925.81,
        change: 29.19,
        available: true,
        time: "15:59",
        close: 5967.13,
        percent: 0.49,
        open: 5937.94,
    },
    {
        val: 55490000,
        high: 5977.05,
        vol: 828000,
        low: 5925.81,
        change: 30.02,
        available: true,
        time: "16:00",
        close: 5967.96,
        percent: 0.51,
        open: 5937.94,
    },
    {
        val: 55565000,
        high: 5977.05,
        vol: 830500,
        low: 5925.81,
        change: 25.02,
        available: true,
        time: "16:01",
        close: 5962.96,
        percent: 0.42,
        open: 5937.94,
    },
    {
        val: 55746000,
        high: 5977.05,
        vol: 832900,
        low: 5925.81,
        change: 26.63,
        available: true,
        time: "16:02",
        close: 5964.57,
        percent: 0.45,
        open: 5937.94,
    },
    {
        val: 55851000,
        high: 5977.05,
        vol: 834900,
        low: 5925.81,
        change: 24.32,
        available: true,
        time: "16:03",
        close: 5962.26,
        percent: 0.41,
        open: 5937.94,
    },
    {
        val: 56160000,
        high: 5977.05,
        vol: 837800,
        low: 5925.81,
        change: 21.46,
        available: true,
        time: "16:04",
        close: 5959.4,
        percent: 0.36,
        open: 5937.94,
    },
    {
        val: 56315000,
        high: 5977.05,
        vol: 841300,
        low: 5925.81,
        change: 24.98,
        available: true,
        time: "16:05",
        close: 5962.92,
        percent: 0.42,
        open: 5937.94,
    },
    {
        val: 56375000,
        high: 5977.05,
        vol: 844500,
        low: 5925.81,
        change: 21.36,
        available: true,
        time: "16:06",
        close: 5959.3,
        percent: 0.36,
        open: 5937.94,
    },
    {
        val: 56444000,
        high: 5977.05,
        vol: 846500,
        low: 5925.81,
        change: 19.71,
        available: true,
        time: "16:07",
        close: 5957.65,
        percent: 0.33,
        open: 5937.94,
    },
    {
        val: 56631000,
        high: 5977.05,
        vol: 848100,
        low: 5925.81,
        change: 22.72,
        available: true,
        time: "16:08",
        close: 5960.66,
        percent: 0.38,
        open: 5937.94,
    },
    {
        val: 56837000,
        high: 5977.05,
        vol: 849400,
        low: 5925.81,
        change: 20.44,
        available: true,
        time: "16:09",
        close: 5958.38,
        percent: 0.34,
        open: 5937.94,
    },
    {
        val: 57028000,
        high: 5977.05,
        vol: 851000,
        low: 5925.81,
        change: 15.85,
        available: true,
        time: "16:10",
        close: 5953.79,
        percent: 0.27,
        open: 5937.94,
    },
    {
        val: 57270000,
        high: 5977.05,
        vol: 852200,
        low: 5925.81,
        change: 17.07,
        available: true,
        time: "16:11",
        close: 5955.01,
        percent: 0.29,
        open: 5937.94,
    },
    {
        val: 57394000,
        high: 5977.05,
        vol: 854300,
        low: 5925.81,
        change: 13.01,
        available: true,
        time: "16:12",
        close: 5950.95,
        percent: 0.22,
        open: 5937.94,
    },
    {
        val: 57630000,
        high: 5977.05,
        vol: 856900,
        low: 5925.81,
        change: 12.04,
        available: true,
        time: "16:13",
        close: 5949.98,
        percent: 0.2,
        open: 5937.94,
    },
    {
        val: 57845000,
        high: 5977.05,
        vol: 858300,
        low: 5925.81,
        change: 10.46,
        available: true,
        time: "16:14",
        close: 5948.4,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 58005000,
        high: 5977.05,
        vol: 860500,
        low: 5925.81,
        change: 14.11,
        available: true,
        time: "16:15",
        close: 5952.05,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 58237000,
        high: 5977.05,
        vol: 861700,
        low: 5925.81,
        change: 13.3,
        available: true,
        time: "16:16",
        close: 5951.24,
        percent: 0.22,
        open: 5937.94,
    },
    {
        val: 58531000,
        high: 5977.05,
        vol: 864100,
        low: 5925.81,
        change: 9.65,
        available: true,
        time: "16:17",
        close: 5947.59,
        percent: 0.16,
        open: 5937.94,
    },
    {
        val: 58659000,
        high: 5977.05,
        vol: 865800,
        low: 5925.81,
        change: 14.44,
        available: true,
        time: "16:18",
        close: 5952.38,
        percent: 0.24,
        open: 5937.94,
    },
    {
        val: 58688000,
        high: 5977.05,
        vol: 869700,
        low: 5925.81,
        change: 15.3,
        available: true,
        time: "16:19",
        close: 5953.24,
        percent: 0.26,
        open: 5937.94,
    },
    {
        val: 58768000,
        high: 5977.05,
        vol: 872200,
        low: 5925.81,
        change: 10.67,
        available: true,
        time: "16:20",
        close: 5948.61,
        percent: 0.18,
        open: 5937.94,
    },
    {
        val: 59024000,
        high: 5977.05,
        vol: 874600,
        low: 5925.81,
        change: 11.51,
        available: true,
        time: "16:21",
        close: 5949.45,
        percent: 0.19,
        open: 5937.94,
    },
    {
        val: 59094000,
        high: 5977.05,
        vol: 876000,
        low: 5925.81,
        change: 7.64,
        available: true,
        time: "16:22",
        close: 5945.58,
        percent: 0.13,
        open: 5937.94,
    },
    {
        val: 59192000,
        high: 5977.05,
        vol: 877600,
        low: 5925.81,
        change: 4.16,
        available: true,
        time: "16:23",
        close: 5942.1,
        percent: 0.07,
        open: 5937.94,
    },
    {
        val: 59471000,
        high: 5977.05,
        vol: 878800,
        low: 5925.81,
        change: -0.19,
        available: true,
        time: "16:24",
        close: 5937.75,
        percent: 0,
        open: 5937.94,
    },
    {
        val: 59758000,
        high: 5977.05,
        vol: 879800,
        low: 5925.81,
        change: -4.29,
        available: true,
        time: "16:25",
        close: 5933.65,
        percent: -0.07,
        open: 5937.94,
    },
    {
        val: 59795000,
        high: 5977.05,
        vol: 881500,
        low: 5925.81,
        change: -3.27,
        available: true,
        time: "16:26",
        close: 5934.67,
        percent: -0.06,
        open: 5937.94,
    },
    {
        val: 60025000,
        high: 5977.05,
        vol: 885400,
        low: 5925.81,
        change: -1.66,
        available: true,
        time: "16:27",
        close: 5936.28,
        percent: -0.03,
        open: 5937.94,
    },
    {
        val: 60232000,
        high: 5977.05,
        vol: 888600,
        low: 5925.81,
        change: -3.43,
        available: true,
        time: "16:28",
        close: 5934.51,
        percent: -0.06,
        open: 5937.94,
    },
    {
        val: 60525000,
        high: 5977.05,
        vol: 891200,
        low: 5925.81,
        change: -0.6,
        available: true,
        time: "16:29",
        close: 5937.34,
        percent: -0.01,
        open: 5937.94,
    },
    {
        val: 60604000,
        high: 5977.05,
        vol: 892500,
        low: 5925.81,
        change: -3.97,
        available: true,
        time: "16:30",
        close: 5933.97,
        percent: -0.07,
        open: 5937.94,
    },
    {
        val: 60801000,
        high: 5977.05,
        vol: 895300,
        low: 5925.81,
        change: -6.77,
        available: true,
        time: "16:31",
        close: 5931.17,
        percent: -0.11,
        open: 5937.94,
    },
    {
        val: 60832000,
        high: 5977.05,
        vol: 898200,
        low: 5925.81,
        change: -10.59,
        available: true,
        time: "16:32",
        close: 5927.35,
        percent: -0.18,
        open: 5937.94,
    },
    {
        val: 60966000,
        high: 5977.05,
        vol: 901700,
        low: 5925.22,
        change: -12.72,
        available: true,
        time: "16:33",
        close: 5925.22,
        percent: -0.21,
        open: 5937.94,
    },
    {
        val: 61073000,
        high: 5977.05,
        vol: 903300,
        low: 5925,
        change: -12.94,
        available: true,
        time: "16:34",
        close: 5925,
        percent: -0.22,
        open: 5937.94,
    },
    {
        val: 61206000,
        high: 5977.05,
        vol: 906800,
        low: 5925,
        change: -10.25,
        available: true,
        time: "16:35",
        close: 5927.69,
        percent: -0.17,
        open: 5937.94,
    },
    {
        val: 61312000,
        high: 5977.05,
        vol: 908400,
        low: 5925,
        change: -10.56,
        available: true,
        time: "16:36",
        close: 5927.38,
        percent: -0.18,
        open: 5937.94,
    },
    {
        val: 61388000,
        high: 5977.05,
        vol: 911300,
        low: 5923.02,
        change: -14.92,
        available: true,
        time: "16:37",
        close: 5923.02,
        percent: -0.25,
        open: 5937.94,
    },
    {
        val: 61529000,
        high: 5977.05,
        vol: 915200,
        low: 5918.37,
        change: -19.57,
        available: true,
        time: "16:38",
        close: 5918.37,
        percent: -0.33,
        open: 5937.94,
    },
    {
        val: 61567000,
        high: 5977.05,
        vol: 916300,
        low: 5917.47,
        change: -20.47,
        available: true,
        time: "16:39",
        close: 5917.47,
        percent: -0.34,
        open: 5937.94,
    },
    {
        val: 61607000,
        high: 5977.05,
        vol: 917600,
        low: 5914.89,
        change: -23.05,
        available: true,
        time: "16:40",
        close: 5914.89,
        percent: -0.39,
        open: 5937.94,
    },
    {
        val: 61830000,
        high: 5977.05,
        vol: 921400,
        low: 5911.69,
        change: -26.25,
        available: true,
        time: "16:41",
        close: 5911.69,
        percent: -0.44,
        open: 5937.94,
    },
    {
        val: 61881000,
        high: 5977.05,
        vol: 924700,
        low: 5908.3,
        change: -29.64,
        available: true,
        time: "16:42",
        close: 5908.3,
        percent: -0.5,
        open: 5937.94,
    },
    {
        val: 61901000,
        high: 5977.05,
        vol: 926300,
        low: 5904.33,
        change: -33.61,
        available: true,
        time: "16:43",
        close: 5904.33,
        percent: -0.57,
        open: 5937.94,
    },
    {
        val: 62084000,
        high: 5977.05,
        vol: 928200,
        low: 5904.33,
        change: -32.43,
        available: true,
        time: "16:44",
        close: 5905.51,
        percent: -0.55,
        open: 5937.94,
    },
    {
        val: 62211000,
        high: 5977.05,
        vol: 930800,
        low: 5901.9,
        change: -37.78,
        available: true,
        time: "16:45",
        close: 5900.56,
        percent: -0.64,
        open: 5937.94,
    },
];

const categories = ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00"];
const ChartTest = () => {
    const [optionsMixedChart, setOptionsMixedChart] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
                endingShape: "arrow",
            },
        },
        stroke: {
            width: [4, 0, 0],
        },
        xaxis: {
            categories: ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00"],
            // categories: time,
            // labels: {
            //     rotate: -45, // Rotate labels if needed
            // },
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 5500,
            max: 6700,
        },
    });

    const aggregateDataByHour = (data, categories) => {
        const aggregated = new Array(categories.length).fill(0);
        const count = new Array(categories.length).fill(0);

        data.forEach((value, index) => {
            const hourIndex = Math.floor(index / 60); // Assuming data is minute-by-minute
            if (hourIndex < categories.length) {
                aggregated[hourIndex] += value;
                count[hourIndex] += 1;
            }
        });

        return aggregated.map((sum, i) => sum / count[i]);
    };

    const [seriesMixedChart, setSeriesMixedChart] = useState([
        {
            name: "series-1",
            type: "line",
            // data: [
            //     ...price.map((p) => {
            //         return {
            //             x: p.time,
            //             y: p.close,
            //         };
            //     }),
            // ],

            data: [5937.94, 5900, 6300, 5700, 6500, 6400, 6300, 6200, 5901.23],
        },
        // {
        //     name: "series-2",
        //     type: "column",
        //     data: [6000, 12, 54, 61, 32, 56, 81, 19],
        // },
        // {
        //     name: "series-3",
        //     type: "column",
        //     data: [62, 12, 45, 55, 76, 41, 23, 43],
        // },
    ]);

    const [optionsRadial, setOptionsRadial] = useState({
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#fff",
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24,
                    },
                },
                track: {
                    background: "#fff",
                    strokeWidth: "67%",
                    margin: 0, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35,
                    },
                },

                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: -20,
                        show: true,
                        color: "#888",
                        fontSize: "13px",
                    },
                    value: {
                        formatter: function (val) {
                            return val;
                        },
                        color: "#111",
                        fontSize: "30px",
                        show: true,
                    },
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: ["#ABE5A1"],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            lineCap: "round",
        },
        labels: ["Percent"],
    });

    const [seriesRadial, setSeriesRadial] = useState([76]);

    const [optionsBar, setOptionsBar] = useState({
        chart: {
            stacked: true,
            stackType: "100%",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            dropShadow: {
                enabled: true,
            },
        },
        stroke: {
            width: 0,
        },
        xaxis: {
            categories: ["Fav Color"],
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        fill: {
            opacity: 1,
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                shadeIntensity: 0.35,
                gradientToColors: undefined,
                inverseColors: false,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [90, 0, 100],
            },
        },
        legend: {
            position: "bottom",
            horizontalAlign: "right",
        },
    });

    const [seriesBar, setSeriesBar] = useState([
        {
            name: "blue",
            data: [32],
        },
        {
            name: "green",
            data: [41],
        },
        {
            name: "yellow",
            data: [12],
        },
        {
            name: "red",
            data: [65],
        },
    ]);

    const updateCharts = useCallback(() => {
        const max = 90;
        const min = 30;
        const newMixedSeries = [];
        const newBarSeries = [];

        seriesMixedChart.forEach((s) => {
            const data = s.data.map(() => {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            });
            newMixedSeries.push({ data: data, type: s.type });
        });

        seriesBar.forEach((s) => {
            const data = s.data.map(() => {
                return Math.floor(Math.random() * (180 - min + 1)) + min;
            });
            newBarSeries.push({ data, name: s.name });
        });

        setSeriesMixedChart(newMixedSeries);
        setSeriesBar(newBarSeries);
        setSeriesRadial([Math.floor(Math.random() * (90 - 50 + 1)) + 50]);
    }, [seriesMixedChart, seriesBar]);

    return (
        <div className="app">
            <div className="row">
                <div className="col mixed-chart">
                    <Chart options={optionsMixedChart} series={seriesMixedChart} type="line" width="350" />
                </div>

                {/* <div className="col radial-chart">
                    <Chart options={optionsRadial} series={seriesRadial} type="radialBar" width="280" />
                </div> */}
            </div>

            {/* <div className="row">
                <div className="col percentage-chart">
                    <Chart options={optionsBar} height={140} series={seriesBar} type="bar" width={500} />
                </div>

                <p className="col">
                    <button onClick={updateCharts}>Update!</button>
                </p>
            </div> */}
        </div>
    );
};

export default ChartTest;
