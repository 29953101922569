import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { HANOI_EXTRA_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const color = "#BA0909";
const color_bg = "white";

export default function HanoiExtraOldPage() {
    const [result, setResult] = useState({
        specialPrize: "05613",
        firstPrize: "26794",
        secondPrize: ["81046", "96424"],
        thirdPrize: ["17818", "20140", "72031", "82202", "62728", "87579"],
        fourthPrize: ["8725", "0622", "4636", "6899"],
        fifthPrize: ["5697", "5031", "2273", "3620", "5970", "9909"],
        sixthPrize: ["367", "159", "080"],
        seventhPrize: ["57", "63", "46", "91"],
    });
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: HANOI_EXTRA_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: HANOI_EXTRA_ID }).unwrap();
            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "ເກີດຄວາມຜິດພາດຂຶ້ນ !",
                content: "ກະລຸນາລອງອີກຄັ້ງ",
                okText: "ປິດ",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "XOSOKQX EXTRA";
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setLoadingHistory(false);
        setLoading(false);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/hanoi-extra/hanoi-extra-image-logo.png")} />
                <title>XOSOKQX EXTRA</title>
                <meta name="description" content="result lotto XOSOKQX EXTRA" />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"!max-w-[1040px]"}>
                <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-bg-header.png")} preview={false} />
                <Image
                    src={require("../../assets/images/hanoi-extra/hanoi-extra-header.png")}
                    rootClassName="w-full bg-[#C20000]"
                    preview={false}
                />
                <div className="flex mt-[10px]">
                    {/* frame 1 */}
                    <Flex className="hidden min-[840px]:flex w-[200px]" vertical gap={12}>
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-1.png")}
                            preview={false}
                            rootClassName="!w-full"
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-3.png")}
                            preview={false}
                            rootClassName="!w-full"
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-4.png")}
                            preview={false}
                            rootClassName="!w-full"
                        />
                    </Flex>
                    <div className="flex flex-1 flex-col px-[10px]">
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-9.png")}
                            className="block sm:hidden"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-2.png")}
                            preview={false}
                            rootClassName="mb-[10px]"
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-10.png")}
                            className="block sm:hidden"
                            preview={false}
                        />

                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-result-header.png")} preview={false} />
                        <Flex className="mt-3" vertical>
                            {/* result */}
                            <Flex>
                                <Flex vertical className="w-[20%] text-sm">
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                        Đặc biệt
                                    </div>
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        Giải nhất
                                    </div>
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                        Giải nhì
                                    </div>
                                    <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        Giải ba
                                    </div>
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0  font-medium">
                                        Giải tư
                                    </div>
                                    <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        Giải năm
                                    </div>
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                        Giải sáu
                                    </div>
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        Giải bảy
                                    </div>
                                </Flex>
                                <div className="flex-1 flex flex-col">
                                    {/* biet */}
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-r-0 border-[1.5px] w-full border-b-0 text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]">
                                        <div>{result.specialPrize}</div>
                                    </div>
                                    {/* nhat */}
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-r-0 border-[1.5px] w-full border-b-0 bg-[#efefef] text-[#000] font-extrabold text-[20px]">
                                        <div>{result.firstPrize}</div>
                                    </div>
                                    {/* nhi */}
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 font-medium">
                                        <Flex className="h-full" flex={1}>
                                            {result.secondPrize.map((item, index) => (
                                                <Flex
                                                    key={`${index}-${item}`}
                                                    flex={1}
                                                    align="center"
                                                    justify="center"
                                                    className="border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {item}
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </div>
                                    {/* ba */}
                                    <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        <Flex className="h-full" flex={1} wrap="wrap">
                                            {result.thirdPrize.map((item, index) => (
                                                <Flex
                                                    key={`${index}-${item}`}
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {item}
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </div>
                                    {/* tu */}
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-y-0 font-medium">
                                        <div className="flex flex-1 h-full">
                                            {result.fourthPrize.map((item, index) => (
                                                <div
                                                    key={`${index}-${item}`}
                                                    className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* nam */}
                                    <div className="h-[80px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        <Flex className="h-full" flex={1} wrap="wrap">
                                            {result.fifthPrize.map((item, index) => (
                                                <Flex
                                                    key={`${index}-${item}`}
                                                    flex={"1 1 33%"}
                                                    className="items-center justify-center border-[#ccc] border-solid border-b-[1.5px] border-l-[1.5px] text-[#000] font-extrabold text-[20px]  h-[40px]"
                                                >
                                                    {item}
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </div>
                                    {/* sau */}
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0">
                                        <div className="flex flex-1 h-full">
                                            {result.sixthPrize.map((item, index) => (
                                                <div
                                                    key={`${index}-${item}`}
                                                    className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#000] font-extrabold text-[20px]"
                                                >
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* bay */}
                                    <div className="h-[40px] flex items-center justify-center border-[#ccc] border-solid border-x-0 border-[1.5px] w-full border-b-0 bg-[#efefef] font-medium">
                                        <div className="flex flex-1 h-full">
                                            {result.seventhPrize.map((item, index) => (
                                                <div
                                                    key={`${index}-${item}`}
                                                    className="flex flex-1 items-center justify-center border-[#ccc] border-solid border-l-[1.5px] text-[#e80000] font-extrabold text-[26px] text-[Arial, Tahoma, Helvetica, sans-serif]"
                                                >
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Flex>
                        </Flex>
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-result-footer.png")}
                            className="mb-[5px]"
                            preview={false}
                        />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-5.png")} preview={false} />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-6.gif")}
                            className="py-[5px]"
                            preview={false}
                        />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-7.png")} preview={false} />
                    </div>
                    <div className="hidden md:!w-[300px] max-w-[300px] sm:block sm:!w-[30%]">
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-8.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-9.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-10.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-11.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-12.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                    </div>
                </div>
                <div className="w-full flex justify-center items-center mt-[20px] mb-[10px] flex-col">
                    <Flex className="w-full" align="center" justify="center" vertical>
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-bg-footer.png")} preview={false} />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-footer.png")}
                            className="!h-[45px]"
                            preview={false}
                        />
                    </Flex>
                </div>
            </Container>
        </>
    );
}
