import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FindResult, HistoryResult, ResultLottery } from "../../components";
import Container from "../../components/Container";
import { HANOI_EXTRA_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { generateData } from "../../utils/GenerateNumber";

const color = "#BA0909";
const color_bg = "white";

export default function HanoiExtraPage() {
    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    useEffect(() => {
        const timeReload = setInterval(() => {
            const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
            const reload_time = convertTime(reloadTime);
            setTime((prev) => prev + 10);
            if (statusReward == "ANNOUNCED") {
                clearInterval(timeReload);
            }
            if (reload_time < now && statusReward !== "ANNOUNCED") {
                fetchReward();
            }
        }, 10000);
        return () => {
            clearInterval(timeReload);
        };
    });

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: HANOI_EXTRA_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: HANOI_EXTRA_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }
            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "XOSOKQX EXTRA JACKPOT";
        fetchReward();
    }, [fetchReward]);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/hanoi-extra/hanoi-extra-image-logo-2.png")} />
                <title>XOSOKQX EXTRA</title>
                <meta name="description" content="result lotto XOSOKQX EXTRA" />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <Container className={"!max-w-[1040px]"}>
                <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-bg-header.png")} preview={false} />
                <Image
                    src={require("../../assets/images/hanoi-extra/hanoi-extra-header-new-4.png")}
                    rootClassName="w-full bg-[#C20000]"
                    preview={false}
                />
                <div className="flex">
                    {/* frame 1 */}
                    <div className="hidden md:block w-[150px]">
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-1.png")} preview={false} />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-3.png")} preview={false} />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-4.png")} preview={false} />
                    </div>
                    <div className="flex flex-1 flex-col px-[10px]">
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-9.png")}
                            className="block sm:hidden"
                            preview={false}
                        />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-2.png")} preview={false} />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-10.png")}
                            className="block sm:hidden"
                            preview={false}
                        />

                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-result-header-2.png")} preview={false} />
                        <Flex className="mt-3" vertical>
                            <div className={"block sm:!hidden"}>
                                <FindResult
                                    date={rewardDetail?.roundName}
                                    rewardData={data}
                                    color={color}
                                    bgColor={color_bg}
                                    lang="VIM"
                                />
                            </div>

                            <ResultLottery
                                status={statusReward}
                                data={data}
                                date={rewardDetail?.roundName}
                                color={color}
                                bgColor={color_bg}
                                lang="VIM"
                            />
                            <HistoryResult data={historyData} color={color} bgColor={color_bg} lang="VIM" />
                        </Flex>
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-result-footer.png")}
                            className="mb-[5px]"
                            preview={false}
                        />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-5.png")} preview={false} />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-6.gif")}
                            className="py-[5px]"
                            preview={false}
                        />
                        <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-7.png")} preview={false} />
                    </div>
                    <div className="hidden md:!w-[330px] max-w-[330px] sm:block sm:!w-[30%]">
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-8.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <FindResult
                            date={rewardDetail?.roundName}
                            rewardData={data}
                            color={color}
                            bgColor={color_bg}
                            lang="VIM"
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-9.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-10.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-11.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-12.png")}
                            rootClassName="w-full"
                            preview={false}
                        />
                    </div>
                </div>
                <div className="w-full flex justify-center items-center mt-[20px] flex-col">
                    {/* <Image src={require("../../assets/images/hanoi-extra/hanoi-extra-bg-footer.png")} preview={false} /> */}
                    <Flex className="w-full justify-center items-center bg-red-800">
                        <Image
                            src={require("../../assets/images/hanoi-extra/hanoi-extra-header-new.png")}
                            className="!h-[45px] mt-[5px]"
                            preview={false}
                        />
                    </Flex>
                </div>
            </Container>
        </>
    );
}
