import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { getDateNameLao } from "../../utils/ConvertDate";
const { useBreakpoint } = Grid;

const color = "#011b5c";
const warper_bg = "#FCFCFC";
const color_bg = "white";
export default function LaoStarOldPage() {
    const screens = useBreakpoint();

    const [data, setData] = useState(undefined);
    const [statusReward, setStatusReward] = useState("LOADING");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const listName = {
        digit2_top: {
            name: "ເລກ 2 ໂຕເທິ່ງ",
            length: 2,
        },
        digit5: {
            name: "ເລກ 5 ໂຕ",
            length: 5,
        },
        digit1: {
            name: "ເລກ 1 ໂຕ",
            length: 1,
        },
        digit3: {
            name: "ເລກ 3 ໂຕ",
            length: 3,
        },
        digit2_special: {
            name: "ເລກ 2 ພິເສດ",
            length: 2,
        },
        digit4: {
            name: "ເລກ 4 ໂຕ",
            length: 4,
        },
        digit2_bottom: {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
        },
    };

    const sortKey = {
        digit5: 0,
        digit4: 1,
        digit3: 2,
        digit2_special: 5,
        digit2_top: 3,
        digit1: 4,
        digit2_bottom: 5,
    };

    const [_numberList] = useState([
        {
            name: "ເລກ 5 ໂຕ",
            length: 5,
            result: "",
        },
        {
            name: "ເລກ 3 ໂຕ",
            length: 3,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕເທິ່ງ	",
            length: 2,
            result: "",
        },
        {
            name: "ເລກ 2 ໂຕລຸ່ມ",
            length: 2,
            result: "",
        },
    ]);

    useEffect(() => {
        document.title = "ລາວສະຕ່າ";
        onFetch();
    }, []);

    const onFetch = () => {
        // fetch data
        setData({
            start_spin: "2024-07-24 20:50:00",
            lotto_date: "2024-07-24",
            show_result: "2024-07-24 21:00:00",
            results: {
                digit2_top: "81",
                digit5: "33681",
                digit3: "681",
                digit4: "3681",
                digit2_bottom: "33",
            },
        });
        setHistoryData([
            {
                lotto_date: "2024-07-23",
                results: {
                    digit2_top: "54",
                    digit5: "88954",
                    digit3: "954",
                    digit4: "8954",
                    digit2_bottom: "88",
                },
            },
            {
                lotto_date: "2024-07-22",
                results: {
                    digit2_top: "68",
                    digit5: "55168",
                    digit3: "168",
                    digit4: "5168",
                    digit2_bottom: "55",
                },
            },
            {
                lotto_date: "2024-07-21",
                results: {
                    digit2_top: "19",
                    digit5: "30319",
                    digit3: "319",
                    digit4: "0319",
                    digit2_bottom: "30",
                },
            },
            {
                lotto_date: "2024-07-20",
                results: {
                    digit2_top: "34",
                    digit5: "76834",
                    digit3: "834",
                    digit4: "6834",
                    digit2_bottom: "76",
                },
            },
            {
                lotto_date: "2024-07-19",
                results: {
                    digit2_top: "96",
                    digit5: "07996",
                    digit3: "996",
                    digit4: "7996",
                    digit2_bottom: "07",
                },
            },
            {
                lotto_date: "2024-07-18",
                results: {
                    digit2_top: "98",
                    digit5: "36798",
                    digit3: "798",
                    digit4: "6798",
                    digit2_bottom: "36",
                },
            },
            {
                lotto_date: "2024-07-17",
                results: {
                    digit2_top: "07",
                    digit5: "65107",
                    digit3: "107",
                    digit4: "5107",
                    digit2_bottom: "65",
                },
            },
            {
                lotto_date: "2024-07-16",
                results: {
                    digit2_top: "92",
                    digit5: "66492",
                    digit3: "492",
                    digit4: "6492",
                    digit2_bottom: "66",
                },
            },
            {
                lotto_date: "2024-07-15",
                results: {
                    digit2_top: "70",
                    digit5: "45270",
                    digit3: "270",
                    digit4: "5270",
                    digit2_bottom: "45",
                },
            },
            {
                lotto_date: "2024-07-14",
                results: {
                    digit2_top: "40",
                    digit5: "95340",
                    digit3: "340",
                    digit4: "5340",
                    digit2_bottom: "95",
                },
            },
            {
                lotto_date: "2024-07-13",
                results: {
                    digit2_top: "05",
                    digit5: "14605",
                    digit3: "605",
                    digit4: "4605",
                    digit2_bottom: "14",
                },
            },
            {
                lotto_date: "2024-07-12",
                results: {
                    digit2_top: "81",
                    digit5: "93281",
                    digit3: "281",
                    digit4: "3281",
                    digit2_bottom: "93",
                },
            },
            {
                lotto_date: "2024-07-11",
                results: {
                    digit2_top: "12",
                    digit5: "99912",
                    digit3: "912",
                    digit4: "9912",
                    digit2_bottom: "99",
                },
            },
            {
                lotto_date: "2024-07-10",
                results: {
                    digit2_top: "37",
                    digit5: "38637",
                    digit3: "637",
                    digit4: "8637",
                    digit2_bottom: "38",
                },
            },
            {
                lotto_date: "2024-07-09",
                results: {
                    digit2_top: "75",
                    digit5: "80075",
                    digit3: "075",
                    digit4: "0075",
                    digit2_bottom: "80",
                },
            },
        ]);
        setLoadingHistory(false);
        setLoading(false);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/laostars/laostars-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}

            <Container
                className={"relative items-center flex flex-col max-w-[1000px] font-[Montserrat, Helvetica, Arial, sans-serif]"}
            >
                <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: warper_bg }} />
                {/* <div className="z-[-100] w-full h-[100%] fixed left-0" style={{ background: "#000" }} /> */}

                {/* header logo */}
                <Flex justify="center">
                    <Image
                        src={require("../../assets/images/laostars/laostars-header.png")}
                        rootClassName="max-w-[1024px]"
                        style={{ width: 1024 }}
                        preview={false}
                    />
                </Flex>
                {/* line */}
                <Flex
                    vertical
                    style={{
                        background: "white",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        maxWidth: 1024,
                        width: "100%",
                    }}
                >
                    <div className="pt-[15px] px-[10px]">
                        <Flex gap={10} vertical={!screens.md}>
                            <Flex className="w-full order-0 md:order-1 sm:mx-0" vertical>
                                {data && (
                                    <>
                                        {/* result */}
                                        <center className="py-2">
                                            <div className="text-[21px] font-extranormal text-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji]">
                                                ງວດປະຈຳວັນທີ່ {getDateNameLao(data?.lotto_date)}
                                            </div>
                                            <div className="text-[4rem] font-bold tracking-[10px]">{data.results?.digit5}</div>
                                            <div className="text-[1.2rem] font-semibold">
                                                <h3 data-v-11dd4c9b="">ອອກຜົນລາງວັນ 15:45</h3>
                                            </div>
                                        </center>
                                        <Flex className="py-[20px] px-[50px]">
                                            <Flex className="border-[#999] border-solid border-t-[1px]" vertical flex={1}>
                                                {Object.entries(data.results).map(([key, value]) => {
                                                    if (key !== "digit2_special" && key !== "digit1") {
                                                        return (
                                                            <div
                                                                className="flex"
                                                                style={{
                                                                    order: sortKey[key],
                                                                    background: "white",
                                                                    color: "#000",
                                                                }}
                                                            >
                                                                <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium border-[#999] border-solid border-b-[1px] border-l-[1px] border-r-[1px]">
                                                                    {listName[key]?.name}
                                                                </div>
                                                                <div className="text-[20px] w-[50%] p-[5px] flex justify-center font-base font-medium border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                                    {value ? (
                                                                        <div>{value}</div>
                                                                    ) : (
                                                                        Array.from(Array(listName[key]?.length).keys()).map(
                                                                            () => <div>-</div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Flex>
                                        </Flex>
                                        {/* history */}
                                        <Flex vertical className="min-h-[150px] md:p-[15px] p-[10px]">
                                            <center className="text-[26px] font-medium">ຜົນການອອກເລກລາງວັນ</center>
                                            <div className="border-[#999] border-solid border-t-[1px] mt-2 flex bg-white font-bold z-[1]">
                                                <div className="flex flex-1 border-x-[1px] p-[5px] font-base border-[#999] border-solid justify-center border-b-[1px]">
                                                    ງວດວັນທີ່
                                                </div>
                                                {_numberList?.map((number) => {
                                                    return (
                                                        <div className="w-[18%] flex p-[5px] justify-center font-base border-[#999] border-solid border-b-[1px] border-r-[1px]">
                                                            <center>{number.name}</center>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {historyData.map((result) => {
                                                return (
                                                    <div className="flex bg-white z-[1]">
                                                        <div className="flex-1 flex break-all p-[5px] justify-center text-base border-[#999] border-solid border-b-[1px] border-r-[1px] border-l-[1px]">
                                                            <center className="font-normal">
                                                                {getDateNameLao(result?.lotto_date)}
                                                            </center>
                                                        </div>
                                                        {Object.entries(result.results).map(([key, value]) => {
                                                            if (key !== "digit2_special" && key !== "digit1" && key !== "digit4")
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            order: sortKey[key],
                                                                        }}
                                                                        className="w-[18%] flex p-[5px] justify-center font-base  border-[#999] border-solid border-b-[1px] border-r-[1px]"
                                                                    >
                                                                        <center className="font-normal">{value}</center>
                                                                    </div>
                                                                );
                                                            return null;
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </Flex>
                                    </>
                                )}
                            </Flex>

                            <Flex className="w-full md:max-w-[300px] order-1 md:order-0" vertical>
                                <Flex vertical gap={10} className="px-0" align="center">
                                    <Image
                                        src={require("../../assets/images/laostars/laostars-1.png")}
                                        rootClassName="md:w-full w-[300px]"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laostars/laostars-2.png")}
                                        rootClassName="md:w-full w-[300px]"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laostars/laostars-3.png")}
                                        rootClassName="md:w-full w-[300px]"
                                        preview={false}
                                    />
                                    <Image
                                        src={require("../../assets/images/laostars/laostars-4.png")}
                                        rootClassName="md:w-full w-[300px]"
                                        preview={false}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
                <center className="text-white font-normal text-lg bg-[#870000] w-full py-3 mt-3">
                    Copyright 2024 © ລາວສະຕ່າ
                </center>
            </Container>
        </div>
    );
}
