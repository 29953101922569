import { Flex, Input } from "antd";
import { useState } from "react";
import { findResult } from "../utils/GenerateNumber";
import { HappySvg } from "./HappySvg";
import { SadSvg } from "./SadSvg";

export default function FindPlusResult({
    rewardData,
    name = "ກວດເບິ່ງລາງວັນດ້ວຍຕົວທ່ານເອງ",
    titleDate = "ງວດປະຈຳວັນທີ່",
    date,
    nameButton = "ກວດເບິ່ງຜົນຂອງລາງວັນ",
    color = "#ff0000",
    bgColor = "white",
    lang = "lo",
}) {
    const [reward, setReward] = useState(-1);
    const [number, setNumber] = useState("");

    const textLang = {
        lo: {
            name: "ກວດເບິ່ງລາງວັນດ້ວຍຕົວທ່ານເອງ",
            titleDate: "ງວດປະຈຳວັນທີ່",
            nameButton: "ກວດເບິ່ງຜົນຂອງລາງວັນ",
            winReward: "ະນະລາງວັນ",
            loseReward: "ບໍ່ຊະນະລາງວັນ",
        },
        vi: {
            name: "Kiểm tra phần thưởng",
            titleDate: "của ngày",
            nameButton: "kiểm tra",
            winReward: "Giành được giải thưởng",
            loseReward: "Không được trao giải",
        },
        en: {
            name: "Check The Rewards for yourself",
            titleDate: "Date Of Number",
            nameButton: "Check Rewards",
            winReward: "win reward",
            loseReward: "lose reward",
        },
    };

    name = textLang[lang].name;
    titleDate = textLang[lang].name;
    nameButton = textLang[lang].nameButton;
    const winReward = textLang[lang].winReward;
    const loseReward = textLang[lang].loseReward;
    return (
        <div
            className={`border-solid border-[3px] flex flex-col mb-3 mx-3 sm:mx-0 bg-white`}
            style={{ borderColor: color, background: bgColor }}
        >
            <center className="px-5 pb-2">
                <div className="text-[24px]">{name}</div>
                <div>
                    {titleDate} {date}
                </div>
                <Input
                    type="numeric"
                    value={number}
                    min={0}
                    onChange={(e) => {
                        setReward(-1);
                        if (e.target.value <= 99999 && e.target.value >= 0) {
                            setNumber(e.target.value.slice(0, 5));
                        } else {
                            setNumber("99999");
                        }
                    }}
                    className="w-full text-[24px] my-4 text-right pr-[30px]"
                />
                <div className="w-full">
                    <div
                        onClick={() => {
                            if (number.length == 5) {
                                const result = findResult(number, rewardData);
                                setReward(result);
                            }
                        }}
                        className="cursor-pointer w-full  text-[white] p-3"
                        style={{
                            background: color,
                        }}
                    >
                        {nameButton}
                    </div>
                </div>
                {reward !== -1 && <div className="border-[#f8f8f8] border-solid border-[0.25px] w-full mt-4" />}
                {reward !== -1 &&
                    (reward === "0" ? (
                        <Flex
                            style={{
                                color: color,
                            }}
                            flex={1}
                            align="center"
                        >
                            <SadSvg style={{ fontSize: 100 }} />
                            <center className="w-full">
                                <div className="text-[24px]">{number}</div>
                                <div className="text-[24px]">{loseReward}</div>
                            </center>
                        </Flex>
                    ) : (
                        <Flex className="text-[#408302]" flex={1} align="center">
                            <HappySvg style={{ fontSize: 100 }} />
                            <center className="w-full">
                                <div className="text-[24px]">{number}</div>
                                <div className="text-[24px]">{winReward}</div>
                            </center>
                        </Flex>
                    ))}
            </center>
        </div>
    );
}
