import { configureStore } from "@reduxjs/toolkit";
import RewardService from "../servies/RewardService";

export const store = configureStore({
    reducer: {
        [RewardService.reducerPath]: RewardService.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(RewardService.middleware);
    },
});
